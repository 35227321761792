import React, { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

//npm imports
import styled, { withTheme, keyframes, css } from 'styled-components';
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';

//component imports
import { Caption12, Caption16, Heading35, Text13 } from '../../../../Components/Text/Text';
import Wrapper from '../../../../Components/Wrapper/Wrapper';

//animations
const swipeGestureTouchDown = keyframes`
    0%{
        opacity: 0;
        width: 100px;
        height: 100px;
        border-width: 1px;
        top: 0%;
        left: 60%;
    }
    40%{
        border-width: 1px;
        opacity: 0.3;
    }
    60%{
        border-width: 3px;
        opacity: 0.8;
    }
    99%{
        border-radius: 50%;
    }
    100%{
        opacity: 1;
        width: 30px;
        height: 30px;
        border-width: 6px;
        top: 3%;
        left: 60%;
        border-radius: 15px;
    }
`

const swipeGestureStartExpandingLeft = keyframes`
    0%{ 
        width: 30px;
        left: 60%;
    }
    100%{
        width: 120px;
        left: 47%;
    }
`

const swipeGestureSwipeLeft = keyframes`
    from{
        left: 47%;
    }
    to{
        left: 2%;
    }
`

const swipeGestureShrinkLeft = keyframes`
    from{
        width: 120px;
    }
    to{
        width: 30px;
    }
`

const swipeGestureExpandRight = keyframes`
    from{
        width: 30px;
    }
    to{
        width: 120px;
    }
`

const swipeGestureSwipeRight = keyframes`
    0%{
        left: 2%;
        opacity: 1;
    }
    10%{
        left: 20%;
        opacity: 0.3;
    }
    70%{
        opacity: 0;
    }
    100%{
        left: 80%;
        opacity: 0;
    }
`



const scrollTimelineToLeft = keyframes`
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-70%);
    }
`

const scrollTimelineToRight = keyframes`
    from{
        transform: translateX(-70%);
    }
    to{
        transform: translateX(0%);
    }
`

//styled-components
const PremiumTimelineWrapper = styled(Wrapper)`
    padding: 80px 0;
    ${Caption16}{
        letter-spacing: 3px;
        font-weight: 600;
    }
    &>h4{
        margin: 20px 0 40px 0;
        text-align: center;
    }
    &>p{
        text-align: center;
    }
    &>p:last-of-type{
        font-weight: 800;
    }
    &>button{
        margin-top: 55px;
    }
    .timeline-container{
        width: 100%;
        margin-top: 55px;
        margin-bottom: 75px;
        position: relative;
        .swipe-gesture{
            width: 100px;
            height: 100px;
            border: 1px solid ${props => props.theme.gray4};
            border-radius: 50%;
            position: absolute;
            top: 0%;
            left: 60%;
            opacity: 0;
            ${props => props.isTimelineSeen && css`
                animation: 
                ${swipeGestureTouchDown} .3s ease-in forwards .3s, 
                ${swipeGestureStartExpandingLeft} .15s ease-in forwards .6s, 
                ${swipeGestureSwipeLeft} .2s linear forwards .75s, 
                ${swipeGestureShrinkLeft} .15s ease-out forwards .95s, 
                ${swipeGestureExpandRight} .2s ease-in forwards 1.55s,
                ${swipeGestureSwipeRight} .3s linear forwards 1.75s;
            `}
        }
        &>div:first-child{
            height: 100%;
            width: 100%;
            display: flex;
            min-width: 688px;
            margin-bottom: 20px;
            ${props => props.isTimelineSeen && css`
                animation: 
                ${scrollTimelineToLeft} .65s cubic-bezier(0,.35,0,.8) forwards .8s,
                ${scrollTimelineToRight} .3s ease-in-out forwards 1.7s;
            `}
            .point-column{
                width: ${props => 100 / props.timelineColumns}%;
                .column-heading{
                    width: 80%;
                    height: 32px; 
                    margin-bottom: 8px;
                    p{
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 800;
                        color: ${props => props.theme.gray3};
                    }
                }
                .column-items{
                    width: 100%;
                    height: calc(100% - 40px);
                    padding: 23px 0;
                    border-left: 2px solid ${props => props.theme.gray4};
                    .column-item{
                        
                    }
                }
                &:last-child{
                    .column-items{
                        
                        border-right: 2px solid ${props => props.theme.gray4};
                    }
                }
            }
        }
    }
    @media (max-width: 774px){
        .timeline-container{
            overflow-x: scroll;
            scrollbar-height: none;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar{
                display: none;
            }
        }
    }
    @media (max-width: 500px){
        padding: 66px 0;
        align-items: flex-start;
        ${Caption16}{
            font-size: 12px;
            line-height: 20px;
        }
        &>h4, &>p{
            text-align: left;
        }
        &>h4{
            font-size: 28px;
            line-height: 33px;
            margin: 24px 0 40px 0;
        }
        &>p{
            font-size: 14px;
            line-height: 20px;

        }
        &>p:first-of-type{
            width: 80%;
            font-size: 12px;
            line-height: 20px;
                font-weight: 600;
        }
        .timeline-container{
            padding: 0 15px;
            width: 110%;
            margin-left: -5%;
            &>div:first-child{
                .point-column{
                    width: 258px;
                    margin-right: 24px;
                    .columnt-heading{
                        
                    width: 258px;
                    }
                    .column-items{

                        width: 258px;
                        border-color: transparent;

                    }
                    &:last-child{
                        .column-items{
                            border-right: none;
                        }
                    }
                }
            }
        }
        &>button{
            margin-top: 66px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
`

const ColumnItem = styled.div`
    width: 100%;
    padding: 15px;
    background-color: ${props => props.colors[0]};
    border-top: 2px solid;
    border-top-color: ${props => props.colors[1]};
    &:first-child{
        border-top-color: transparent;
        border-radius: 20px 20px 0 0;
    }
    &:last-child{
        border-radius: ${props => parseFloat(props.columnOrder) === 2 || parseFloat(props.columnOrder) === 4 ? "0 0 20px 0" : "0 0 0 20px"};
    }
    p{
        color: ${props => props.theme.white};
        font-weight: 600;
        word-break: break-word
    }
    @media (max-width: 1060px){
        padding: 10px 7px;
        
        &:first-child{
            border-radius: 10px 10px 0 0;
        }
        &:last-child{
            border-radius: ${props => parseFloat(props.columnOrder) === 2 || parseFloat(props.columnOrder) === 4 ? "0 0 10px 0" : "0 0 0 10px"};
        }
        p{
            // font-weight: 400;
            font-size: 12px;

        }
    }
    @media (max-width: 500px){
        padding: 9% 7%;
        &:first-child{
            border-radius: 20px 20px 0 0;
        }
        &:last-child{
            border-radius: ${props => parseFloat(props.columnOrder) === 2 || parseFloat(props.columnOrder) === 4 ? "0 0 20px 0" : "0 0 0 20px"};
        }
        p{
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
        }
    }
    `

//component
const PremiumTimeline = (props) => {
    const timelineRef = useRef();
    const isTimelineOverflowing = useMediaQuery({ maxWidth: 774 })
    const [isTimelineSeen, setIsTimelineSeen] = useState(false);
    useEffect(() => {
        if (isTimelineOverflowing && timelineRef.current) {
            let timelineRefcurrent = timelineRef.current;
            const observer = new IntersectionObserver(([entry]) => {
                setIsTimelineSeen(entry.isIntersecting)
            }, { threshold: 0.8 })
            observer.observe(timelineRefcurrent);
            return () => {
                observer.unobserve(timelineRefcurrent);
            }
        }
    }, [timelineRef, isTimelineOverflowing]);

    useEffect(() => {
        console.log(isTimelineSeen);
    }, [isTimelineSeen]);

    const columnItemsColors = [
        [props.theme.blue, "#4f6fe2"],
        [props.theme.black, "#595960"],
        [props.theme.incorrect, "#ed4065"],
        [props.theme.gray3, "#c7c9d0"],
        [props.theme.violet, "#6b6bff"],
        [props.theme.lightBlack, "#6b677d"]
    ]
    return (
        <PremiumTimelineWrapper timelineColumns={props.timeline.length} isTimelineSeen={isTimelineSeen}>
            <Caption16>еден месец. структуриран и оптимизиран.</Caption16>
            <Heading35>Програма која чекор по чекор воведува во сите полиња и аспекти кои се потребни за да се стигне од идеја до решение</Heading35>
            <Text13>Без разлика дали за прв пат се сретнуваш со овој вид на алатки или пак си секојдневно со нив, твоите способности во голема мера ќе се подобрат со помош на темелните часови и тематски единици кои ќе ги поминеме, предизвици кои ќе ги решаваме заедно, проекти кои ќе ги правиме за најразлични намени, како и Q&A сесси кои ќе ги имаме.</Text13>
            <div ref={timelineRef} className="timeline-container">
                <div>

                    {props.timeline.length > 0 && props.timeline.map((column, index) => (
                        <div key={index} className="point-column">
                            <div className="column-heading">
                                <Caption12>{column.point}</Caption12>
                            </div>
                            <div className="column-items">
                                {column.content.length > 0 && column.content.map((item, i) => (
                                    <ColumnItem colors={columnItemsColors[index > -1 ? index : index > 6 && index - 6]} columnOrder={index} key={i} className="column-item">
                                        <Text13>{item}</Text13>
                                    </ColumnItem>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="swipe-gesture"></div>
            </div>
            <Text13 fontWeight={800}>За време на овие 30 денови, научи да дефинираш проблем, да истражуваш за него, да го поделиш на помали единици, да дизајнираш решенија дел по дел и да ги споиш кон една целина.</Text13>
            <ButtonBasic text="Пријави се" />
        </PremiumTimelineWrapper>

    );
}

export default withTheme(PremiumTimeline);