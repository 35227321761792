import React, { memo, useRef } from 'react';

//imports
import styled from 'styled-components';
import Wrapper from '../Wrapper/Wrapper';
import { CarouselWrapper } from '../CarouselWrapper/CarouselWrapper';
import { Heading35 } from '../Text/Text';
import Carousel from '../Carousel/Carousel';
import CourseCard from '../CourseCard/CourseCard';
import LearningPathCard from '../LearningPathCard/LearningPathCard';
import MyCourseCard from '../MyCourseCard/MyCourseCard';
import { Link } from '@reach/router';
import { API } from '../../Consts/Api';
import Skeleton from '../Skeleton/Skeleton';

//styled-components
const SliderBlockContainer = styled.div`
    width: 100%;
    padding: 67px 0 0 0;
    &>div:first-child{
        align-items: flex-start;
    }
    h4{
        min-width: 50%;
        height: 42px;
        display: inline-block;
        margin-bottom: 50px;
        text-align: left;
        font-weight: 700;
    }

    .swiper-container {
        padding-bottom: 40px;
    }

    .non-sliding-cards {
        width: 90%;
        max-width: 1151px;
        display: grid;
        grid-template-columns: ${props => props.cardsPerSlide > 4 && props.renderCard === "CourseCard" ? "1fr 1fr 1fr 1fr 1fr" : props => !props.isMobile && props.renderCard === "LearningPathCard" ? "1fr 1fr" : props.cardsPerSlide === 4 ? "1fr 1fr 1fr 1fr" : "1fr 1fr"};
        grid-gap: ${props => props.cardsPerSlide > 4 && props.renderCard === "CourseCard" ? "19px" : props.cardsPerSlide === 4 && props.renderCard === "CourseCard" ? "25px" : "32px"};
        flex-flow: row wrap;
        margin: 0 auto;
        .learning-path-skeleton-container{
            width: 100%;
            margin-right: 30px;
            margin-bottom: 30px;
        }
        .course-skeleton-container{
            width: 100%;
            margin: 12px;
        }
    }
    .swiper-container{
        width: 90%;
        margin: 0 auto;
    }

    .padding-title{
        padding-left: 9px;
    }

    @media (max-width: 1141px){
        .non-sliding-cards {
            .learning-path-skeleton-container{
                width: 100%;
                margin-right: 12px;
                margin-bottom: 30px;
                &:last-child{
                    margin-left: 18px;
                }
            }
            .course-skeleton-container{
                width: 100;
                margin: 6px;
            }
        }
    }
    
    @media (max-width: 500px){
        overflow-x: hidden;
        width: 100vw;
        h4{
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 20px;
        }
        padding: 34px 0 0 0;
        .card-category {
            display: block;
        }
        .swiper-container{
            width: calc(100% - 18px);
            margin-left: 18px;
            padding-bottom: 0px;
        }
        .non-sliding-cards{
            display: flex;
            overflow: hidden;
            .learning-path-skeleton-container{
                width: 81%;
                margin-right: 30px;
                margin-bottom: 30px;
                &:last-child{
                    margin-left: 0px;
                }
            }
            .course-skeleton-container{
                width: 42%;
                margin: 0;
                margin-right: 10px;
            }
        }
    }
    `

const StaticCardContainer = styled.div`
    width: 100%;
    
    `

//component
const SliderBlock = memo((props) => {
    const sliderBlockRef = useRef();
    // define which component to render trough a string in props from this const
    const componentMapping = {
        LearningPathCard,
        CourseCard,
        MyCourseCard
    }


    const RenderComponent = componentMapping[props.renderCard];
    return (
        <SliderBlockContainer ref={sliderBlockRef} className={props.className && props.className + " slider-block"} cardsPerSlide={props.cardsPerSlide} renderCard={props.renderCard}>
            <Wrapper>
                <Heading35 className="slider-block-heading">{props.cardData && props.cardData.length > 0 ? props.heading : <Skeleton />}</Heading35>
            </Wrapper>
            {props.cardData.length === 0
                ? <div className="non-sliding-cards">
                    {props.renderCard === "LearningPathCard"
                        ? Array(props.cardsPerSlide || 4).fill().map((card, index) => (
                            <div key={index} className="learning-path-skeleton-container">
                                <LearningPathCard />
                            </div>
                        ))
                        : Array(props.cardsPerSlide || 4).fill().map((card, index) => (
                            <div key={index} className="course-skeleton-container">
                                <CourseCard />
                            </div>
                        ))
                    }
                </div>
                : !props.isMobile && props.cardData.length <= props.cardsPerSlide
                    ? <div className="non-sliding-cards">
                        {props.cardData.map((card, index) => {
                            return (
                                <StaticCardContainer key={index} staticCardWidth={props.renderCard === "LearningPathCard" ? 50 : 100 / props.cardsPerSlide}>
                                    {card.coming_soon && card.coming_soon !== 0
                                        ? <RenderComponent
                                            cardComingSoon={card.coming_soon > 0}
                                            id={card.id && card.id}
                                            cardImage={card.image}
                                            cardTitle={card.title && card.title}
                                            cardSubtitle={card.subtitle && card.subtitle}
                                            cardDescription={card.description && card.description}
                                            cardCategory={card.category && card.category.name}
                                            cardPoints={card.points && card.points}
                                            cardStudentsNumber={card.total_students && card.total_students}
                                            cardTime={card.duration && card.duration}
                                            cardProgress={card.progress && card.progress}
                                            cardCourseNumber={card.courses && card.courses.length}
                                            cardImgWidth={170}
                                            cardMinHeight={160}
                                            cardLocation={props.cardLocation && props.cardLocation}
                                            isMobile={props.isMobile}
                                            course={card}
                                            cardExclusive={props.exclusive}
                                        />
                                        : props.route !== "none"
                                            ? <Link to={props.route === "learning-path" ? API.createRoute("getLearningPath", { LEARNINGPATH_ID: card.id }) : API.createRoute("getCourse", { COURSE_ID: card.id })}>
                                                <RenderComponent
                                                    id={card.id && card.id}
                                                    cardImage={card.image}
                                                    cardTitle={card.title && card.title}
                                                    cardSubtitle={card.subtitle && card.subtitle}
                                                    cardDescription={card.description && card.description}
                                                    cardCategory={card.category && card.category.name}
                                                    cardPoints={card.points && card.points}
                                                    cardStudentsNumber={card.total_students && card.total_students}
                                                    cardTime={card.duration && card.duration}
                                                    cardProgress={card.progress && card.progress}
                                                    cardCourseNumber={card.courses && card.courses.length}
                                                    cardImgWidth={170}
                                                    cardMinHeight={160}
                                                    cardLocation={props.cardLocation && props.cardLocation}
                                                    isMobile={props.isMobile}
                                                    course={card}
                                                    cardExclusive={props.exclusive}
                                                />
                                            </Link>
                                            : <RenderComponent
                                                id={card.id && card.id}
                                                cardImage={card.image}
                                                cardTitle={card.title && card.title}
                                                cardSubtitle={card.subtitle && card.subtitle}
                                                cardDescription={card.description && card.description}
                                                cardCategory={card.category && card.category.name}
                                                cardPoints={card.points && card.points}
                                                cardStudentsNumber={card.total_students && card.total_students}
                                                cardTime={card.duration && card.duration}
                                                cardProgress={card.progress && card.progress}
                                                cardCourseNumber={card.courses && card.courses.length}
                                                cardImgWidth={170}
                                                cardMinHeight={160}
                                                cardLocation={props.cardLocation && props.cardLocation}
                                                isMobile={props.isMobile}
                                                course={card}
                                                cardExclusive={props.exclusive}
                                            />
                                    }
                                </StaticCardContainer>
                            )
                        })}
                    </div>
                    :
                    <CarouselWrapper>
                        <Carousel
                            cardData={props.cardData}
                            cardsPerSlide={props.cardsPerSlide}
                            renderCard={props.renderCard}
                            route={props.route}
                            navigation={props.navigation}
                            isMobile={props.isMobile}
                            gridTemplateColumns={props.gridTemplateColumns}
                            exclusive={props.exclusive}
                            pagination={props.pagination}
                        />
                    </CarouselWrapper>
            }
        </SliderBlockContainer>
    );
});

export default SliderBlock;