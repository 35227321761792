import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { prop, switchProp } from "styled-tools";
import { lighten } from 'polished';

// Components
import Icon from '../Icon/Icon';

// Styled components
const ButtonContainer = styled.button`
    width: ${props => props.width ? props.width + "px" : "unset"};
    height: ${props => props.height ? props.height + "px" : "unset"};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    transition: 0.2s ${props => props.theme.expo};
    position: relative;
    background: ${switchProp(prop("type", "primary"), {
    primary: prop('theme.black'),
    secondary: prop('theme.blue'),
})};
    ${props => props.disabled ? css`
        cursor: not-allowed;
        background: ${props => props.theme.gray2};
    `: css`
        cursor: pointer;
    `};
    .icon {
        transition: 0.25s ${props => props.theme.expo}
    }
    &:hover {
        background: ${switchProp(prop("type", "primary"), {
    primary: lighten(0.05, '#302F38'),
    secondary: lighten(0.05, '#234BDB'),
})};
        ${props => props.disabled && css`
            background: ${props => props.theme.gray2};
        `}
       
    }
`;

//component
const ButtonSmall = ({ type, disabled, theme, width, height }) => {
    return (
        <ButtonContainer tabIndex="0" disabled={disabled} type={type} width={width} height={height}><Icon icon="arrowRightSmall" width={width < 34 && 7} pathFill={theme.white} /></ButtonContainer>
    )
}

export default withTheme(ButtonSmall);