import styled from 'styled-components';

export const CarouselWrapper = styled.div`
    margin: 0 auto;
    width: 90%;
    max-width: 1151px;
    @media (max-width: 1441px){
    width: 100%;
    }
    @media (max-width: 500px){
        width: 100%;
        overflow-x: hidden;
    }
`