import styled, { css } from "styled-components";

// The component takes props:
//-background -> url to the background image
//-backgroundColor -> a string containing the color code for the background color

//The component must have 2 child elements
//-div element with className="headline-container"
//-form element

const PublicFacingPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  ${(props) =>
    props.background &&
    css`
      background-image: url("${(props) => props.background}");
    `}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${(props) => props.backgroundColor};
    `}
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  & > .headline-container {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translate(0, -25%);
    @media (max-width: 768px) {
      width: 100%;
      position: static;
      transform: translate(0, 0);
      height: unset;
    }
    & > h1 {
      @media (max-width: 1024px) {
        font-size: 52px;
        line-height: 60px;
      }

      @media (max-width: 870px) {
        font-size: 48px;
        line-height: 52px;
      }
      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 50px;
      }

      @media (max-width: 500px), (max-height: 500px) {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
  & > form {
    width: 50%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    align-self: flex-end;
    .form-container {
      width: 60%;
      display: flex;
      height: 100%;
      overflow-y: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .form-headline-container {
        h5 {
          margin-bottom: ${(props) => props.theme.spacer * 10}px;
          text-align: center;
        }
      }
      .terms-of-use {
        align-self: flex-start;
      }
      .or-register-via {
        text-align: center;
        margin-bottom: 24px;
        color: ${(props) => props.theme.gray3};
      }
      .already-registered {
        p {
          text-align: center;
          color: ${(props) => props.theme.black};
          text-align: center;
          font-size: 16px;
          font-family: 'Manrope', sans-serif;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          strong {
            color: ${(props) => props.theme.blue};
            text-decoration: underline;
          }
        }
      }
    }
    @media (max-width: 1000px) {
      .form-container {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      height: unset;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      padding: 8%;
      .already-registered {
        margin-bottom: 0px;
      }
    }

    @media (max-width: 500px) {
      padding-top: 70px;
      min-height: unset;
      .form-container {
        .form-headline-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > h5 {
            margin-top: 0;
            margin-bottom: 0;
          }
          .already-registered {
            display: block;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media (max-height: 770px) {
    .form-container {
      .form-header-container {
        & > h5 {
          margin-bottom: ${(props) => props.theme.spacer * 5}px!important;
        }
      }
      .form-headline-container {
        & > h5 {
          margin-bottom: ${(props) => props.theme.spacer * 5}px!important;
        }
      }
      .or-register-via {
        margin-bottom: ${(props) => props.theme.spacer * 5}px;
      }
    }
  }
`;

export default PublicFacingPageWrapper;
