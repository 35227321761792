import React, { useEffect, useContext } from 'react';
import { API } from '../Consts/Api';
import { navigate } from "@reach/router";
import { GlobalContext } from '../Context/GlobalContext';

const GlobalEvents = () => {
    // const { signedUser, setSignedUser, logoutUser } = useContext(GlobalContext);
    const { setAppConfig } = useContext(GlobalContext);
    // useEffect(() => {
    //     setSignedUser(JSON.parse(localStorage.getItem('user') || "[]"));
    // }, [])

    useEffect(() => {
        if (window.location.pathname === "/login"
            || window.location.pathname === "/singin") {
            navigate(API.createRoute("signin"))
        }

        //Get configs from backend and store them in GlobalContext so we can adjust app's frontend according to 
        //the dynamic settings that the admin can configure in the admin panel
        API.axios.get(API.createApiRoute("config"))
            .then(response => setAppConfig(response.data.data))
            .catch(err => {
                console.log("Ooops, config not available.");
            })
        //eslint-disable-next-line
    }, [])

    return (
        <></>
    );
}

export default GlobalEvents;