import React from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import { Caption16, Heading35, Text13 } from '../../../../Components/Text/Text';
import ProjectCard from '../../../../Components/ProjectCard/ProjectCard';

//styled-components
const PremiumRealProjectsWrapper = styled.div`
    width: 100%;
    background-color: ${props => props.theme.black};
    padding: 80px 0 80px 0;
    ${Caption16}{
        letter-spacing: 3px;
        font-weight: 600;
    }
    &>div &>h1,h2,h3,h4,h5,h6,p,span{
        color: ${props => props.theme.white};
    }
    &>div >${Heading35}{
        width: 65%;
        text-align: center;
        margin-top: 30px;
    }
    &>div >${Text13}{
        width: 78%;
        text-align-center;
        margin-top: 50px;
    }
    .real-projects-cards-container{
        width: 100%;
        display: flex;
        margin-top: 50px;
        .card-container{
            width: calc(25% - 24px);
            margin-left: 12px;
            margin-right: 12px;
        }
    }
    @media (max-width: 500px){
        padding: 66px 0;
        ${Caption16}{
            font-size: 12px;
            line-height: 20px;
        }
        &>div{
            align-items: flex-start;
        }
        &>div >${Heading35}{
            width: 90%;
            text-align: left;
            margin-top: 10px;
            font-size: 28px;
            line-height: 32px;
        }
        &>div >${Text13}{
            width: 100%;
            text-align: left;
            margin-top: 40px;
            font-size: 14px;
            line-height: 20px;
        }
        .real-projects-cards-container{
            flex-direction: column;
            align-items: center;
            .card-container{
                width: 72%;
                margin: 0 0 24px 0;
            }
        }
    }
`


//component
const PremiumRealProjects = (props) => {
    return (
        <PremiumRealProjectsWrapper>
            <Wrapper>
                <Caption16>учи преку искуства</Caption16>
                <Heading35>{props.realProjects.heading}</Heading35>
                <Text13>{props.realProjects.description}</Text13>
                <div className="real-projects-cards-container">
                    {props.realProjects.projects.length > 0 && props.realProjects.projects.map((project, index) => (

                        <div key={index} className="card-container">
                            <ProjectCard week_order={index + 1} title={project.heading} image={project.image} />
                        </div>
                    ))}

                </div>
            </Wrapper>
        </PremiumRealProjectsWrapper>
    );
}

export default PremiumRealProjects;