import React from 'react';

//imports
import styled from 'styled-components';
import { API } from '../../../../Consts/Api';

const CourseMobileBackgroundImage = styled.div`
    display: none;
    width: 100%;
    /* background-image: url(${props => props.isExclusiveCourse ? props.image : props.image}); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${props => props.bgColor ? props.bgColor : props.theme.gray3};
    clip-path: url('#clip-path');
    -webkit-clip-path: url('#clip-path');
    @media (max-width: 768px){
        display: block;
        height: 89vw;
        img {
            width: 100%;
            display: block;
            /* margin: -20px auto 0 auto */
        }
    }
`

const CourseMobileBackground = (props) => {
    return (
        <CourseMobileBackgroundImage  isExclusiveCourse={props.isExclusiveCourse} bgColor={props.bgColor}>
            <img src={props.image} alt="" />
            <svg width="100%" height="89%" viewBox="0 0 100 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <clipPath id="clip-path" clipPathUnits="objectBoundingBox">

                    <path fillRule="evenodd" clipRule="evenodd" d="M1,0 H0 V0.336 C0,0.537,0.145,0.7,0.325,0.7 H0.733 C0.881,0.7,1,0.835,1,1 V0" fill="#FED445" />
                </clipPath>
            </svg>

        </CourseMobileBackgroundImage>
    );
}

export default CourseMobileBackground;