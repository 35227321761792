export const PremiumCoursesArray = [
    {
        id: 0,
        course_heading: {
            technical_info: {
                start_date: "1 октомври",
                duration: "10ч неделно, 30 денови",
                result: "4 проекти изградени од тебе"
            },
            heading: "Creative engineering",
            description: "Со овој курс, откриј го креативниот потенцијал во тебе за инженерство и почни да ги создаваш твоите идеи во реални проекти. Следи ги часовите и насоките, а потоа најди примена и приказна за четирите проекти кои ќе бидат твоите први 4 иновации!",
            hero_image: "course_image"
        },
        course_info: [
            "Средношколци кои сакаат да продолжат кариера во инженерство и иновации",
            "Студенти од прва и втора година кои сакаат да учат низ практична работа и да следат вонкурикуларна акредитирана програма",
            "За сите tech ентузијасти кои имаат слободно време и сакаат да го збогатат својот сет на вештини"
        ],
        real_projects: {
            heading: "Научи за основите на инженерството преку 4 предизвици и проекти",
            description: "Со програмата од еден месец, ќе ве мотивираме да ги научите основите на инженерството и ќе ве мотивираме да изработите 4 проекти од самиот почеток, самите. Освен овие проекти кои ќе ги работиме за време на курсот, ќе изградите и научите навики и методи кои ќе ви користат во секој аспект на живтотот и ќе допринесат во вашиот пат кон креативно инженерско размислување.",
            projects: [
                {
                    image: "",
                    heading: "Направи сопствена персонална веб страница"
                },
                {
                    image: "",
                    heading: "Направи респонсивен блог за твоето хоби"
                },
                {
                    image: "",
                    heading: "Направи статична веб страна за мал бизнис"
                },
                {
                    image: "",
                    heading: "Направи игра / интерактивна веб страна со твоето хоби"
                },
            ]
        },
        prep_programme_timeline: [
            {
                point: "Воведни предавања",
                content: [
                    "Добредојде и што не очекува",
                    "Што сѐ ќе ни биде потребно",
                    "Спринт методологија"
                ]
            },
            {
                point: "ДЕН 1 - 7",
                content: [
                    "Запознавање во HTML и неговата улога",
                    "Мета тагови и табели во HTML",
                    "Зошто се користи CSS <br/> Градбени делови на CSS",
                    "Бреинсторминг, истражување и дизајн на проект #1",
                    "Изработка на проект #1"
                ]
            },
            {
                point: "ДЕН 8 - 14",
                content: [
                    "Листи и форми во HTML",
                    "Добри практики во HTML",
                    "Позиционирање и позадински вредности на CSS",
                    "Box модел во CSS",
                    "Бреинсторминг, истражување и дизајн на проект #2",
                    "Изработка на проект #2"
                ]
            },
            {
                point: "ДЕН 15 - 21",
                content: [
                    "Зошто се користи Bootstrap?",
                    "Grid, типографија и компоненти во Bootstrap",
                    "Поставување на структура на база на податоци",
                    "Структури за чување на податоци",
                    "Бреинсторминг, истражување и дизајн на проект #3",
                    "Изработка на проект #3"
                ]
            },
            {
                point: "ДЕН 22 - 32",
                content: [
                    "Javascript widgets (carousel, tooltip, collapse, popovers)",
                    "За што сѐ се користи Javascript, синтакса и променливи",
                    "Услови и циклуси",
                    "Функции",
                    "Изработка на динамика на игра",
                    "Дизајн на карактери за игри",
                    "Бреинсторминг, истражување и дизајн на проект #4",
                    "Изработка на проект #4"
                ]
            },
            {
                point: "ЗАВРШНИ ПРЕДАВАЊА",
                content: [
                    "Како да раскажувате приказни преку проектите кои ги работите",
                    "Како да барате инспирација и да продолжите да создавате"
                ]
            }
        ],
        your_instructors: [
            {
                image: "instructorBoko",
                name: "Бојан Стојковски",
                profession: "Frontend Web Developer",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            },
            {
                image: "instructorTamara",
                name: "Тамара Димитрова",
                profession: "Software Engineer",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            }
        ],
        community: {
            testimonials: [
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                }
            ]
        }
    },
    {
        id: 1,
        course_heading: {
            technical_info: {
                start_date: "1 октомври",
                duration: "10ч неделно, 30 денови",
                result: "4 проекти изградени од тебе"
            },
            heading: "Creative Media",
            description: "Со овој курс, откриј го креативниот потенцијал во тебе за брендинг и медиа. Започни да ги реализираш сопствените креативни бизнис идеи во реални проекти или ѕирни како се работи во маркетинг агенција и започни да работиш на твоите први маркетинг и брендинг стратегии. Следи ги часовите и насоките, а потоа најди примена и приказна за четирите проекти кои ќе бидат твоите први 4 брендинг приказни (стратегии)!",
            hero_image: "course_image"
        },
        course_info: [
            "Средношколци кои сакаат да продолжат со кариера во маркетинг, комуникации, ПР, новинартво, дизајн или медиа",
            "Претпримачи кои имаат идеја и сакаат да започнат сопствен креативен бизнис Студенти од прва и втора година кои сакаат да учат низ практична работа и да следат вонкурикуларна акредитирана програма",
            "За сите креативни ентузијасти кои имаат слободно време и сакаат да го збогатат својот сет на вештини"
        ],
        real_projects: {
            heading: "Научи за основите на бреднирање преку 4 предизвици и проекти",
            description: "Преку оваа едномесечна програма сакаме да ве мотивираме да ги научите основите на креативниот брендинг и изработка на целосна стратегија. Во текот на програмата ќе изработите вкупно 4 проекти од нула, ќе изградите и научите навики и методи кои ќе ви користат како идни креативци и ќе допринесат во вашиот пат кон работа во маркетинг агенција, брендинг студио или пак кон реализирање на вашиот сопствен план за бизнис.",
            projects: [
                {
                    image: "",
                    heading: "Креирај брендинг стратегија за нов бизнис"
                },
                {
                    image: "",
                    heading: "Креирај контент брендинг стратегија, блог/ страна и комуникација за социјални мрежи"
                },
                {
                    image: "",
                    heading: "Креирај дизајн насоки за брендинг: фотографија или видео сценарио, дизајн контент или корисничко искуство"
                },
                {
                    image: "",
                    heading: "Подготви презентација и пич за целосна брендинг и комуникациска стратегија за нов бизнис"
                },
            ]
        },
        prep_programme_timeline: [
            {
                point: "Воведни предавања",
                content: [
                    "Добредојде и што не очекува",
                    "Што сѐ ќе ни биде потребно",
                    "Спринт методологија"
                ]
            },
            {
                point: "ДЕН 1 - 7",
                content: [
                    "Бриф",
                    "Запознавање со локални бизнис идеи и брендови",
                    "Moodboards",
                    "Бренд позиционирање и истражување",
                    "Таргет и фокус групи",
                    "Бреинсторминг, истражување и дизајн на проект #1",
                    "Изработка на брендинг комуникациска стратегија: проект #1"
                ]
            },
            {
                point: "ДЕН 8 - 14",
                content: [
                    "Контент стратегија и канали на диструбуција",
                    "Маркетинг и комуникациска стратегија",
                    "Идеи кои се помнат",
                    "Копирајтинг кој резонира со таргет публиката",
                    "Бреинсторминг, истражување и дизајн на проект #2",
                    "Изработка на содржина: проект #2"
                ]
            },
            {
                point: "ДЕН 15 - 21",
                content: [
                    "Насоки за дизајн и начин на работа со дизајн тимот",
                    "Canva предлози",
                    "Истражување и пишување сценарио за видео",
                    "Истражување и пишување сценарио за фотографија",
                    "Бреинсторминг, истражување и дизајн на проект #3",
                    "Изработка на дизајн/ видео или фотографија: проект #3"
                ]
            },
            {
                point: "ДЕН 22 - 32",
                content: [
                    "Подготовка на презентација за брендинг комуникација",
                    "Контент и дизајн на презентација",
                    "Подготвка за пич",
                    "Функции",
                    "Пич и презентациски вештини",
                    "Бреинсторминг, истражување и дизајн на проект #4",
                    "Изработка на презентација и пич: проект #4"
                ]
            },
            {
                point: "ЗАВРШНИ ПРЕДАВАЊА",
                content: [
                    "Како да раскажувате приказни преку проектите кои ги работите",
                    "Како да барате инспирација и да продолжите да создавате"
                ]
            }
        ],
        your_instructors: [
            {
                image: "instructorBoko",
                name: "Ања Макеска",
                profession: "Learning & Hiring Coordinator",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            },
            {
                image: "instructorTamara",
                name: "Иван?",
                profession: "Content Marketing Specialist",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            }
        ],
        community: {
            testimonials: [
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                }
            ]
        }
    },
    {
        id: 2,
        course_heading: {
            technical_info: {
                start_date: "1 октомври",
                duration: "10ч неделно, 30 денови",
                result: "4 проекти изградени од тебе"
            },
            heading: "Creative Engineering",
            description: "Со овој курс, откриј го креативниот потенцијал во тебе за инженерство и почни да ги создаваш твоите идеи во реални проекти. Следи ги часовите и насоките, а потоа најди примена и приказна за четирите проекти кои ќе бидат твоите први 4 иновации!",
            hero_image: "course_image"
        },
        course_info: [
            "Средношколци кои сакаат да продолжат кариера во инженерство и иновации",
            "Студенти од прва и втора година кои сакаат да учат низ практична работа и да следат вонкурикуларна акредитирана програма",
            "За сите tech ентузијасти кои имаат слободно време и сакаат да го збогатат својот сет на вештини"
        ],
        real_projects: {
            heading: "Научи за основите на инженерството преку 4 предизвици и проекти",
            description: "Со програмата од еден месец, ќе ве мотивираме да ги научите основите на инженерството и ќе ве мотивираме да изработите 4 проекти од самиот почеток, самите. Освен овие проекти кои ќе ги работиме за време на курсот, ќе изградите и научите навики и методи кои ќе ви користат во секој аспект на живтотот и ќе допринесат во вашиот пат кон креативно инженерско размислување.",
            projects: [
                {
                    image: "",
                    heading: "X"
                },
                {
                    image: "",
                    heading: "X"
                },
                {
                    image: "",
                    heading: "X"
                },
                {
                    image: "",
                    heading: "X"
                },
            ]
        },
        prep_programme_timeline: [
            {
                point: "Воведни предавања",
                content: [
                    "Добредојде и што не очекува",
                    "Што сѐ ќе ни биде потребно"
                ]
            },
            {
                point: "ДЕН 1 - 7",
                content: [
                    "Бреинсторминг",
                    "Како да се пристапи кон правење на истражување",
                    "Ардуино чипови",
                    "Архитектура на чип",
                    "Бреинсторминг, истражување и дизајн на проект #1",
                    "Прототипирање на проект #1"
                ]
            },
            {
                point: "ДЕН 8 - 14",
                content: [
                    "Отпорници",
                    "Сензори",
                    "Поврзување во систем",
                    "Сензори во ардуино",
                    "Бреинсторминг, истражување и дизајн на проект #2",
                    "Прототипирање на проект #2"
                ]
            },
            {
                point: "ДЕН 15 - 21",
                content: [
                    "Прототипизирање",
                    "Оптимизација",
                    "Дебагирање",
                    "Сензори во ардуино и поврзување во систем",
                    "Бреинсторминг, истражување и дизајн на проект #3",
                    "Прототипирање на проект #3"
                ]
            },
            {
                point: "ДЕН 22 - 32",
                content: [
                    "Design thinking",
                    "Design engineering process",
                    "Динамика на игра",
                    "Подвижни делови и управување со нив",
                    "Напредни сензорски системи",
                    "Бреинсторминг, истражување и дизајн на проект #4",
                    "Прототипирање на проект #4"
                ]
            },
            {
                point: "ЗАВРШНИ ПРЕДАВАЊА",
                content: [
                    "Како да раскажувате приказни преку проектите кои ги работите",
                    "Како да барате инспирација и да продолжите да создавате"
                ]
            }
        ],
        your_instructors: [
            {
                image: "instructorBoko",
                name: "Митко Кинов",
                profession: "Robotics Engineer",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            },
            {
                image: "instructorTamara",
                name: "Тамара Димитрова",
                profession: "Software Engineer",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            }
        ],
        community: {
            testimonials: [
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                }
            ]
        }
    },
    {
        id: 3,
        course_heading: {
            technical_info: {
                start_date: "1 октомври",
                duration: "10ч неделно, 30 денови",
                result: "4 проекти изградени од тебе"
            },
            heading: "Creative Coding",
            description: "Со овој курс, откриј го креативниот потенцијал во тебе за инженерство и почни да ги создаваш твоите идеи во реални проекти. Следи ги часовите и насоките, а потоа најди примена и приказна за четирите проекти кои ќе бидат твоите први 4 иновации!",
            hero_image: "course_image"
        },
        course_info: [
            "Средношколци кои сакаат да продолжат со кариера во ИТ",
            "Студенти од прва и втора година кои сакаат да учат низ практична работа и да следат вонкурикуларна акредитирана програма",
            "За сите tech ентузијасти кои имаат слободно време и сакаат да го збогатат својот сет на вештини"
        ],
        real_projects: {
            heading: "Научи за основите на програмирањето преку 4 предизвици и проекти",
            description: "Со програмата од еден месец, ќе ве мотивираме да ги научите основите на програмирањето и ќе ве мотивираме да изработите 4 проекти од самиот почеток, самите. Освен овие проекти кои ќе ги работиме за време на курсот, ќе изградите и научите навики и методи кои ќе ви користат во секој аспект на живтотот и ќе допринесат во вашиот пат кон креативно инженерско размислување.",
            projects: [
                {
                    image: "",
                    heading: "Направи сопствена персонална веб страница"
                },
                {
                    image: "",
                    heading: "Направи респонсивен блог за твоето хоби"
                },
                {
                    image: "",
                    heading: "Направи статична веб страна за мал бизнис"
                },
                {
                    image: "",
                    heading: "Направи игра / интерактивна веб страна со твоето хоби"
                },
            ]
        },
        prep_programme_timeline: [
            {
                point: "Воведни предавања",
                content: [
                    "Добредојде и што не очекува",
                    "Што сѐ ќе ни биде потребно",
                    "Спринт методологија"
                ]
            },
            {
                point: "ДЕН 1 - 7",
                content: [
                    "Запознавање во HTML и неговата улога",
                    "Мета тагови и табели во HTML",
                    "Зошто се користи CSS <br/> Градбени делови на CSS",
                    "Бреинсторминг, истражување и дизајн на проект #1",
                    "Изработка на проект #1"
                ]
            },
            {
                point: "ДЕН 8 - 14",
                content: [
                    "Листи и форми во HTML",
                    "Добри практики во HTML",
                    "Позиционирање и позадински вредности на CSS",
                    "Box модел во CSS",
                    "Бреинсторминг, истражување и дизајн на проект #2",
                    "Изработка на проект #2"
                ]
            },
            {
                point: "ДЕН 15 - 21",
                content: [
                    "Зошто се користи Bootstrap?",
                    "Grid, типографија и компоненти во Bootstrap",
                    "Поставување на структура на база на податоци",
                    "Структури за чување на податоци",
                    "Бреинсторминг, истражување и дизајн на проект #3",
                    "Изработка на проект #3"
                ]
            },
            {
                point: "ДЕН 22 - 32",
                content: [
                    "Javascript widgets (carousel, tooltip, collapse, popovers)",
                    "За што сѐ се користи Javascript, синтакса и променливи",
                    "Услови и циклуси",
                    "Функции",
                    "Изработка на динамика на игра",
                    "Дизајн на карактери за игри",
                    "Бреинсторминг, истражување и дизајн на проект #4",
                    "Изработка на проект #4"
                ]
            },
            {
                point: "ЗАВРШНИ ПРЕДАВАЊА",
                content: [
                    "Како да раскажувате приказни преку проектите кои ги работите",
                    "Како да барате инспирација и да продолжите да создавате"
                ]
            }
        ],
        your_instructors: [
            {
                image: "instructorBoko",
                name: "Бојан Стојковски",
                profession: "Frontend Web Developer",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            },
            {
                image: "instructorTamara",
                name: "Тамара Димитрова",
                profession: "Software Engineer",
                description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години  Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
            }
        ],
        community: {
            testimonials: [
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                },
                {
                    image: "",
                    title: "Бојан Стојковски",
                    description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
                }
            ]
        }
    },
]