import React, { useState, useContext, useEffect, useRef } from 'react';

//imports
import styled, { css, withTheme } from 'styled-components';
import { Link, useLocation } from "@reach/router";
import { GlobalContext } from '../../Context/GlobalContext';
import { API } from '../../Consts/Api';
import { Caption14, Caption12, Text13 } from '../Text/Text';
import Popover from '../Popover/Popover';
import Icon from '../Icon/Icon';
import { useTranslation } from "react-i18next";

//styled-components
const NavbarNav = styled.nav`
    width: 90%;
    max-width: 1141px;
    display: flex;
    align-items: center;
    position: relative;

    .nav-items{
        width: 100%;
        display: flex;
        &>button{
            display: none;
        }
        @media (max-width: 500px){
            justify-content: space-between;
            .go-pro-link{
                display: none;
            }
            &>button{
                display: block;
            }
        }
    }
    
    @media (max-width: 768px){
        & {
            flex-direction: column;
            align-items: stretch; 
            position: static;          
        }
    }

    @media (max-width: 500px){
        .nav-items{
            display: flex;
            justify-content: space-between;
        }
        .logo{
            display: none;
        }
        &>div > a >p{
            display: none;
        }
    }
`

const Logo = styled.p`
    margin-right: 48px;
    font-size: 20px;
    text-transform: uppercase;
    color: #161616;
    display: flex;
    align-items: center;
    span {
        font-weight: bold
    }
    img {
        width: 144px;
    }
`

const NavLinkItem = styled(Caption14)`
    margin-right: 68px;
    text-transform: uppercase;
    text-decoration: none;
    color: unset;
    margin-top: 3px;
    &.active, &:hover {
        color: #234BDB;
    }

    `;

const NavLinkIcon = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    flex-flow: row wrap;
    display: none;
    .active-catalogue{
        path{
            stroke: ${props => props.theme.blue};
        }
    }
    .active{
        path{
            stroke: ${props => props.theme.blue};
            fill: ${props => props.theme.blue};
        }
    }
    &:hover{
        .catalogue-link{
            path{
                stroke: ${props => props.theme.blue};
            }
        }
        .my-courses-link{
            path{
                fill: ${props => props.theme.blue};
            }
        }
    }
    @media (max-width: 500px){
        display: flex;
    }
`

const NavLink = styled(Link)`
    text-decoration: none;
    color: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover{
        path, circle{
            fill: ${props => props.theme.blue};
        }
        &>div > svg.catalogue-link > path{
            fill: none;
        }
    }
    .active > circle, .active > path{
        fill: ${props => props.theme.blue};
    }
    @media (max-width: 768px) {
        width: 24px;
        & {
            float: left;
        }
        img{
            width: 20px;
            height: 24px;
        }
    }
`
const NavLinkButton = styled.button`
    width: 160px;
    text-decoration: none;
    color: unset;
    background: none;
    border: none;
    outline: none;
    font-weight: 500;
    font-family: 'Raleway';
    .profile-image{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-image: url('${props => props.profileImageUrl}');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .hover-icon-signout{
        display: none;
    }
    .icon-signout{
        display: block;
    }
    &{
        padding: 0px;
    }
    p{
        text-align: left;
    }
    &:hover{
        .hover-icon-signout{
            display: block;
        }
        .icon-signout{
            display: none;
        }
        path{
            fill: ${props => props.theme.blue};
        }
    }
    @media (max-width: 768px) {
        & {
            float: left;
        }
    }
    @media (max-width: 500px){
        width: 24px;
        height: 24px;
    }
`

const ProfileDropdown = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .username {
        margin-right: 10px;
    }

    .mobile-nav-item{
        display: none;
    }
    
    .profile-image {
        width: 38px;
        height: 38px;
        background-color: #E5E5E5;
        ${props => props.profileImageUrl && css`
        background-image: url('${props.profileImageUrl}');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        `}
        border-radius: 50%;
        display: inline-block;
        .curtain-menu{
            display: none;
        }
        .profile-dropdown {
            width: 219px;
            max-height: 0;
            transition: max-height 0.2s ease-out, padding 0.5s ease-out;
            overflow: hidden;
            padding-top: 0;
            position: absolute;
            top: 38px;
            right: -17px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99999;
            p{
                margin: 0;
            }
        }
        &:hover {
            cursor: pointer;
    
            & .profile-dropdown {
                max-height: 100vh;
                transition: max-height 0.2s ease-in, padding 0.25s ease-in;
            }
        }
    }

    @media (max-width: 768px){
        & {
            width: 100%;
            height: 100vh;
            position: fixed;
            bottom: 0px;
            transition: left, 0.4s;
            left: ${props => props.show ? "0%" : "100%"};
            &>.profile-image{
                float: right;
                width: 80%;
                height: 100%;
                padding-left: 43px;
                background-color: ${props => props.theme.gray0};
                background-image: none;
                border-radius: 0px;
                .curtain-menu{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-flow: column wrap;
                    justify-content: space-between;
                    .curtain-links-container{
                        height: 70%;
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                    }
                    .user-name{
                        margin-top: 97px;
                    }
                    .my-profile{
                        width: unset;
                        height: unset;
                        margin-top: 124px;
                        .active {
                            color: ${props => props.theme.blue};
                        }
                    }
                    .go-pro{
                        margin-top: 73px;
                    }
                    &>button{
                        width: unset;
                        height: unset;
                        margin-bottom: 43px;
                    }
                }
                .profile-dropdown{
                    display: none;
                    
                }
                .mobile-nav-item{
                    display: block;
                }
                p{
                    font-weight: 600;
                }
            }
        }
    }
`

const HamburgerDropdown = styled.div`
    display: flex;    
    align-items: center;
    margin-left: 20px;
    transition: max-height 0.1s;
    flex-grow: 1;
    .mobile-nav-item {
        display: none;
    }
    @media (max-width: 768px){
        & {
            max-height: ${props => props.visible ? '1000px' : '0px'};
            flex-direction: column;
            align-items: flex-end;
            margin-top: ${props => props.visible ? "10px" : "0px"};
            overflow: hidden;
            .mobile-nav-item{
                display: block;
                margin: 20px 0;
            }
            p{
                margin: 0;
            }
        }
    }
`;

const NavbarWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    display: ${props => props.isComponentVisible ? "flex" : "none"};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 4.00278px 4.00278px 20.0139px rgba(0, 0, 0, 0.1);
    padding: 17px 0;
    position: relative;
    z-index: 99999;

    @media (max-width: 768px){
        & ${HamburgerDropdown} {          
            ${NavLink} {
                margin: 20px 0;
                
                ${NavLinkItem} {
                    margin: 0;
                }
            }
        }
        & .hamburger-toggle {
            float: right;
        }
    }

    @media (max-width: 500px){
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: ${props => props.theme.gray0};
    }
`

const Navbar = (props) => {
    const { t } = useTranslation();
    const [showCurtain, setShowCurtain] = useState(false)
    const { signedUser, logoutUser, setBodyUnscrollable } = useContext(GlobalContext);
    const profileDropdownRef = useRef();
    const popoverRef = useRef();
    const [isNavbarVisible, setIsNavbarVisible] = useState(false);
    const location = useLocation();

    useEffect(() => {
        props.except && setIsNavbarVisible(!props.except.some(exceptRoute => (new RegExp(`^${exceptRoute}/?$`)).test(location.pathname)))

        //Whenver the url changes, scroll to the top of the page
        window.scrollTo(0, 0);
        setShowCurtain(false);
    }, [props.except, location.pathname])

    useEffect(() => {
        setBodyUnscrollable(showCurtain)
    }, [showCurtain, setBodyUnscrollable])




    return (
        <NavbarWrapper isComponentVisible={isNavbarVisible}>
            <NavbarNav>
                <div>
                    <NavLink className="logo" to='/'><Logo><Icon icon="brainsterNextLogo" pathFill={props.theme.black} /></Logo></NavLink>
                </div>
                <div className="nav-items">
                    <NavLink to='/catalogue'>
                        <NavLinkItem className={location.pathname === '/catalogue' ? 'active-catalogue' : ''}>{t("navigation.catalogue")}</NavLinkItem>
                        <NavLinkIcon>
                            <Icon icon="icnCatalogue" className={location.pathname.indexOf("catalogue") > -1 ? "active-catalogue catalogue-link" : "catalogue-link"} />
                        </NavLinkIcon>
                    </NavLink>
                    <NavLink to='my-courses/' className="my-courses-link">
                        <NavLinkItem className={location.pathname === '/my-courses' ? 'active' : ''}>{t("navigation.my_courses")}</NavLinkItem>
                        <NavLinkIcon>
                            <Icon icon="icnMyCourses" className={location.pathname.indexOf("my-courses") > -1 ? "active my-courses-link" : "my-courses-link"} />
                        </NavLinkIcon>
                    </NavLink>
                    {(Object.keys(signedUser).length > 0)
                        &&
                        <>
                            <NavLinkButton profileImageUrl={API.storage + signedUser.profile_image} onClick={() => setShowCurtain(true)}>
                                <div className="profile-image"></div>
                            </NavLinkButton>
                            <ProfileDropdown
                                ref={profileDropdownRef}
                                profileImageUrl={API.storage + signedUser.profile_image}
                                show={showCurtain}
                                onClick={(e) => e.target === profileDropdownRef.current && setShowCurtain(false)}
                            >
                                <div className="profile-image">
                                    <div className="curtain-menu">
                                        <div className="curtain-links-container">
                                            <Text13 className="user-name">{`${signedUser.name} ${signedUser.surname}`}</Text13>
                                            <NavLink className="my-profile" to="/student/profile">
                                                <Caption12 className={location.pathname.indexOf("student/profile") > -1 && "active"}>{t('general.my_profile')}</Caption12>
                                            </NavLink>
                                        </div>
                                        <NavLinkButton className="mobile-nav-item" onClick={() => logoutUser(API.appRoutes.signin)}>
                                            <Caption12>{t("general.sign_out")}</Caption12>
                                        </NavLinkButton>
                                    </div>
                                    <div className='profile-dropdown'>
                                        <Popover ref={popoverRef} pointerPosition="top" top={110} card="learning-path-card">
                                            <NavLink to='/student/profile' className="profile">
                                                <Icon className={location.pathname.indexOf("/student/profile") > - 1 ? 'active' : ''} icon="icnMyProfile" pathFill={props.theme.gray3} width={12} height={15} />
                                                <NavLinkItem className={location.pathname.indexOf("/student/profile") > - 1 ? 'active' : ''}>{t('general.my_profile')}</NavLinkItem>
                                            </NavLink>
                                            <NavLinkButton onClick={() => logoutUser(API.appRoutes.signin)}>
                                                <Icon icon="icnSignOut" pathFill={props.theme.gray4} />
                                                <NavLinkItem>{t("general.sign_out")}</NavLinkItem>
                                            </NavLinkButton>
                                        </Popover>
                                    </div>
                                </div>
                            </ProfileDropdown>
                        </>
                    }

                </div>

            </NavbarNav>
        </NavbarWrapper>
    )
}

export default withTheme(Navbar);