import React, { forwardRef, memo, useContext } from 'react';
import { GlobalContext } from '../../../Context/GlobalContext'

// Imports
import styled from 'styled-components';
import { Link } from '@reach/router';
import { API } from '../../../Consts/Api';
import { Caption12 } from '../../../Components/Text/Text';
import Section from '../../../Components/Section/Section';
import Carousel from '../../../Components/Carousel/Carousel';
import { CarouselWrapper } from '../../../Components/CarouselWrapper/CarouselWrapper';
import CourseCard from '../../../Components/CourseCard/CourseCard';
import LearningPathCard from '../../../Components/LearningPathCard/LearningPathCard';

// Styled components
const CatalogueStudyFieldContainer = styled(Section)`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    &:nth-child(2) > div:nth-child(1){
        margin-top: 50px;
        @media (max-width: 500px){
            margin-top: 20px;
        }
    }
    .section-title {
        margin-bottom: ${props => props.theme.spacer * 9 + 'px'};
        margin-top: ${props => props.theme.spacer * 18 + "px"};
        color: ${props => props.theme.gray6};
        letter-spacing: 1px;
        @media (max-width: 500px){
            padding-left: 18px!important;
        }
    }

    .padding-title{
        padding-left: 9px;
    }

    .catalogue-wrapper {
        align-items: flex-start;
    }
    @media (max-width: 1270px){
        width: 90%;
        margin: 0 auto;
    }
    @media (max-width: 500px){
        width: 100%;
        padding-top: 0px;
        .section-title{
            margin-top: 34px;
            margin-bottom: 20px;
        }
    }
`

const CatalogueCategoryContainer = styled(CarouselWrapper)`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    .static-card-container{
        width: 100%;
        max-width: 1151px;
        display: grid;
        grid-template-columns: ${props => props.renderCard === "CourseCard" ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr"};
        grid-gap: ${props => props.renderCard === "CourseCard" ? "19px" : "32px"};
        margin: 0 auto;
    }
    @media (max-width: 1141px){
        .static-card-container{
            grid-template-columns: ${props => props.renderCard === "CourseCard" ? "1fr 1fr 1fr 1fr" : "1fr 1fr"};
        }
    }
    @media (max-width: 950px){
        .static-card-container{
            grid-template-columns: ${props => props.renderCard === "CourseCard" ? "1fr 1fr 1fr" : "1fr 1fr"};
        }
    }
`

const StaticCardContainer = styled.div`
    width: 100%;
    `


const CatalogueCourses = memo(forwardRef((props, ref) => {
    const { appConfig } = useContext(GlobalContext);

    return (
        <CatalogueStudyFieldContainer ref={ref} id={props.blockId}>
            {props.categories.map((category, i) => {
                return (
                    <CatalogueCategoryContainer className="study-field-container" key={i}>
                        {category.courses.length > 0 && props.filterValue !== "learning_paths" &&
                            <CatalogueCategoryContainer key={1 + i} renderCard="CourseCard">
                                <Caption12 className={!props.isMobile ? "padding-title section-title" : "section-title"}
                                    font={'Raleway'}
                                    weight={700}
                                >
                                    {category.name}
                                </Caption12>
                                {category.courses.length <= (props.isSmallLaptop ? 3 : props.isLaptop ? 4 : 5) && !props.isMobile
                                    ? <div className="static-card-container">
                                        {category.courses.map((course, index) => {
                                            return (
                                                <StaticCardContainer key={index} isSmallLaptop={props.isSmallLaptop}>
                                                    {course.coming_soon && course.coming_soon !== 0
                                                        ? <CourseCard
                                                            cardComingSoon={course.coming_soon && course.coming_soon !== 0}
                                                            id={course.id && course.id}
                                                            cardImage={course.image}
                                                            cardTitle={course.title && course.title}
                                                            cardSubtitle={course.subtitle && course.subtitle}
                                                            cardDescription={course.description && course.description}
                                                            cardCategory={props.header ? props.header : course.category && course.category.study_field.name}
                                                            cardPoints={course.points && course.points}
                                                            cardStudentsNumber={course.total_students && course.total_students}
                                                            cardTime={course.duration && course.duration}
                                                            cardProgress={course.progress && course.progress}
                                                            cardCourseNumber={course.courses && course.courses.length}
                                                            cardImgWidth={170}
                                                            cardMinHeight={160}
                                                            cardLocation={props.cardLocation && props.cardLocation}
                                                            isMobile={props.isMobile}
                                                            course={course}
                                                            cardExclusive={props.exclusive}
                                                        />
                                                        : <Link to={API.createRoute("getCourse", { COURSE_ID: course.id })}>
                                                            <CourseCard
                                                                id={course.id && course.id}
                                                                cardImage={course.image}
                                                                cardTitle={course.title && course.title}
                                                                cardSubtitle={course.subtitle && course.subtitle}
                                                                cardDescription={course.description && course.description}
                                                                cardCategory={props.header ? props.header : course.category && course.category.study_field.name}
                                                                cardPoints={course.points && course.points}
                                                                cardStudentsNumber={course.total_students && course.total_students}
                                                                cardTime={course.duration && course.duration}
                                                                cardProgress={course.progress && course.progress}
                                                                cardCourseNumber={course.courses && course.courses.length}
                                                                cardImgWidth={170}
                                                                cardMinHeight={160}
                                                                cardLocation={props.cardLocation && props.cardLocation}
                                                                isMobile={props.isMobile}
                                                                course={course}
                                                                cardExclusive={props.exclusive}
                                                            />
                                                        </Link>
                                                    }
                                                </StaticCardContainer>
                                            )
                                        })}
                                    </div>
                                    :
                                    <Carousel
                                        cardData={category.courses}
                                        renderCard={"CourseCard"}
                                        route="courses"
                                        isMobile={props.isMobile}
                                        isSmallLaptop={props.isSmallLaptop}
                                        cardsPerSlide={props.isSmallLaptop ? 3 : props.isLaptop ? 4 : 5}
                                        navigation={false}
                                        cardLocation={"catalogue"}
                                    />
                                }
                            </CatalogueCategoryContainer>
                        }
                        {appConfig.learning_paths === "1" &&
                            category.learning_paths.length > 0 && props.filterValue !== "courses" &&
                            <CatalogueCategoryContainer className="study-field-container" renderCard="LearningPathCard" key={0 + i}>
                                {category.learning_paths.length > 0 && (props.filterValue === "learning_paths" || props.filterValue === "all") &&
                                    <Caption12
                                        className={!props.isMobile ? "padding-title section-title" : "section-title"}
                                        font={'Raleway'}
                                        weight={700}
                                    >
                                        {category.name}
                                    </Caption12>
                                }
                                {category.learning_paths.length <= 2 && !props.isMobile
                                    ? <div className="static-card-container">
                                        {category.learning_paths.map((learning_path, index) => {
                                            return (
                                                <StaticCardContainer key={index}>
                                                    <Link to={API.createRoute("getLearningPath", { LEARNINGPATH_ID: learning_path.id })}>

                                                        <LearningPathCard
                                                            id={learning_path.id && learning_path.id}
                                                            cardImage={learning_path.image}
                                                            cardTitle={learning_path.title && learning_path.title}
                                                            cardSubtitle={learning_path.subtitle && learning_path.subtitle}
                                                            cardDescription={learning_path.description && learning_path.description}
                                                            cardCategory={props.header ? props.header : learning_path.category && learning_path.category.study_field.name}
                                                            cardPoints={learning_path.points && learning_path.points}
                                                            cardStudentsNumber={learning_path.total_students && learning_path.total_students}
                                                            cardTime={learning_path.duration && learning_path.duration}
                                                            cardProgress={learning_path.progress && learning_path.progress}
                                                            cardCourseNumber={learning_path.courses && learning_path.courses.length}
                                                            cardImgWidth={170}
                                                            cardMinHeight={160}
                                                            cardLocation={props.cardLocation && props.cardLocation}
                                                            isMobile={props.isMobile}
                                                            course={learning_path}
                                                            cardExclusive={props.exclusive}
                                                        />
                                                    </Link>
                                                </StaticCardContainer>
                                            )
                                        })}
                                    </div>
                                    :
                                    <Carousel
                                        header={category.name}
                                        cardData={category.learning_paths}
                                        renderCard={"LearningPathCard"}
                                        route="learning-path"
                                        isMobile={props.isMobile}
                                        navigation={false}
                                        cardsPerSlide={2}
                                    />
                                }
                            </CatalogueCategoryContainer>
                        }
                    </CatalogueCategoryContainer>
                )
            }
            )}

        </CatalogueStudyFieldContainer>
    )
}
));

export default CatalogueCourses;