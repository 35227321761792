import React from 'react';
import styled from 'styled-components';

// Styled Components
const CourseCardWrapper = styled.article`
    max-width: 360px;
    height: 420px;
    background: ${props => props.theme.white};
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    display: flex;
    flex-direction: column;
`;

// Main Component
const CourseCardFallback = ({category, image, courseTitle, courseSubtitle, courseProgress, courseTime, courseStudentsNumber, coursePoints}) => (
    <CourseCardWrapper>
        no course added
    </CourseCardWrapper>
)


export default CourseCardFallback;