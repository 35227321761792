import React, { useEffect, useState } from 'react';

//npm imports
import styled, { keyframes } from 'styled-components';

//Component imports
import Icon from '../Icon/Icon';

//animations
// rotate_image is applied to child svg and creates realistic rotation from air resistance during fall of a confetto
const rotate_image = keyframes`
    0%{
        transform: rotateX( 0deg) rotateY(0deg);
    }
    100%{
        transform: rotateX(3240deg) rotateY(6480deg);
    }
`

// drop_and_fade is applied to the component to create fall and fade away effect of a confetto
const drop_and_fade = (top) => keyframes`
0%{
    opacity: 1;
    top: -20px;
}
85%{
    opacity: 1;
}
100%{
   opacity: 0; 
   //
   top: ${top}%;
}
`

//styled-components
const ConfettoWrapper = styled.div`
    width: ${props => props.width}px;
    position: absolute;
    z-index: 99;
    top: -40px;
    left: ${props => props.left}vw;
    animation: ${props => drop_and_fade(props.top)}  ${props => props.animationDuration}s ${props => props.animationDelay}s ease-out infinite forwards;
    svg{
        width: 100%;
        animation: ${rotate_image} ${props => props.animationDuration * 0.8}s  ease-out infinite forwards;
        fill: none;
        path{
            width: 30%;
            margin-left: 5px;
        }
    }
`

//component
const Confetto = (props) => {
    const confetti = ["icnBlueStar", "icnGrayCross", "icnGreenArrow", "icnGreenEllipse", "icnPinkCircledSquare", "icnRedCircle", "icnRedQuarterCircle", "icnYellowEllipse"];
    const [currentConfetto, setCurrentConfetto] = useState();
    const [animationDuration, setAnimationDuration] = useState();
    const [animationDelay, setAnimationDelay] = useState();
    const [width, setWidth] = useState();
    const [left, setLeft] = useState();
    const [top, setTop] = useState();

    useEffect(() => {
        setWidth(Math.round(Math.random() * (22) + 15));
        setAnimationDuration(Math.random() * (3) + 2);
        setAnimationDelay(Math.random() * 6);
        setLeft(Math.round(Math.random() * 100));
        setTop(Math.round(Math.random() * (95 - 50 + 1) + 50))
        let arrayFromIndex = Array.from(props.index.toString());

        props.index > 9 ? setCurrentConfetto(confetti[parseInt(arrayFromIndex.pop())]) : setCurrentConfetto(confetti[props.index])
        //eslint-disable-next-line
    }, []);

    return (
        <ConfettoWrapper width={width} animationDuration={animationDuration} animationDelay={animationDelay} left={left} top={top}>
            <Icon icon={currentConfetto} />
        </ConfettoWrapper>

    );
}

export default Confetto;