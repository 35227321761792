export const MyProfileSideBarItemsData = [
    {
        text: "my_profile.side_bar.basic_info"
    },
    {
        text: "my_profile.side_bar.interests"
    },
    {
        text: "my_profile.side_bar.password"
    },
]