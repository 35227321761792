import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import ButtonBasic from '../../Components/ButtonBasic/ButtonBasic';
import InputField from '../../Components/InputField/InputField';
import InputFieldError from '../../Components/InputField/InputFieldError';
// import background from '../../Static/backgrounds/sign-in-background.svg';
import { SpecHeading2, Heading20, Text13, Text10 } from "../../Components/Text/Text";
import Wrapper from '../../Components/Wrapper/Wrapper';
import { API } from '../../Consts/Api';
import Icon from '../../Components/Icon/Icon';
import { useTranslation, Trans } from 'react-i18next';

//styled-components
const ForgotPasswordContainer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.green};
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    &>div {
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 500px){
            width: 100%;
            flex-direction: column;
        }
    }
    @media (max-width: 768px){
        flex-direction: column;
        justify-content: flex-end;
    }
`
const ForgotPasswordHeadLine = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px){
        width: 100%;
        padding:8%;
    }
    ${SpecHeading2}, ${Heading20}{
        color: ${props => props.theme.beige};
    }
    ${SpecHeading2}{
        @media (max-width: 1031px){
            font-size: 52px;
            line-height: 60px;
        }
        
        @media (max-width: 910px){
            font-size: 48px;
            line-height: 52px;
        }
        @media (max-width: 768px){
            font-size: 36px;
            line-height: 48px;
        }

        @media (max-width: 500px), (max-height: 500px){
            font-size: 26px;
            line-height: 30px;
        }

    
    }
`

const ForgotPasswordForm = styled.form`
    width: 50%;
    height: 100vh;
    padding: 0 74px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    h5, p{
        align-self: flex-start;
    }
    h5{
        margin-bottom: 30px;
    }
    
    @media (max-width: 768px){
        width: 100%;
        height: unset;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        padding: 8%;
    }
`

const ResetPasswordField = styled.div`
    display: ${props => props.response ? "none" : "block"};
`

const InputContainer = styled.div`
    width: 100%;
    margin-top: 62px;
    margin-bottom: 40px;
    @media (max-width: 768px){
        margin-bottom: 10px;
    }
`

const ButtonContainer = styled.div`
    align-self: flex-end;
`

const ConfirmResend = styled.div`
    display: ${props => props.response ? "block" : "none"};
    .send-icon-container{
        width: 62px;
        height: 62px;
        margin-bottom: 60px;
        border-radius: 50%;
        background-color: ${props => props.theme.gray4};
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .resend-button {
        color: ${props => props.theme.blue};
        &:hover{
            cursor: pointer;
        }
    }
    span{
        color: ${props => props.theme.red};
    }
    .confirm-password-instructions{
        margin-bottom: 55px;
    }
    .resend-cta{
        display: ${props => props.resend ? "none" : "block"};
    }
    .confirm-resend-message {
        display: ${props => props.resend ? "block" : "none"};
    }
`


//component
const RecoverPassword = (props) => {
    const { t } = useTranslation();
    const resetErrors = { email: [] };
    const [errors, setErrors] = useState(resetErrors);
    const [email, setEmail] = useState({ email: "" });
    const [confirmReset, setConfirmReset] = useState(false);
    const [confirmResend, setConfirmResend] = useState(false);

    const emailRef = useRef();

    const location = useLocation();

    useEffect(() => {
        location.pathname.includes("confirm") && setConfirmReset(true);
    }, [location.pathname])


    const requestPasswordResetLink = useCallback((e) => {
        e.preventDefault()
        API.axios.post(API.createApiRoute("recoverPassword"), email)
            .then(() => setConfirmReset(true))
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors({ ...resetErrors, ...error.response.data.errors });
                } else {
                    setErrors({ ...errors, additional: "An error occured. Please try again later." });
                }
            })
    }, [setConfirmReset, setErrors, email, errors, resetErrors])

    const resendPasswordResetLink = () => {
        API.axios.post(API.createApiRoute("recoverPassword"), email)
            .then(response => {
                if (response.data.success === true) {
                    setConfirmResend(true)
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors({ ...resetErrors, ...error.response.data.errors });
                } else {
                    setErrors({ ...errors, additional: "An error occured. Please try again later." });
                }
            })
    }

    const submitOnEnter = useCallback((e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            requestPasswordResetLink(e)
        }
    }, [requestPasswordResetLink])

    useEffect(() => {
        window.addEventListener('keydown', submitOnEnter);

        return () => {
            window.removeEventListener('keydown', submitOnEnter);
        };
    }, [submitOnEnter]);
    return (
        <ForgotPasswordContainer>
            <Wrapper>
                <ForgotPasswordHeadLine>
                    <SpecHeading2><Trans key={t}>Внеси лозинка <br /> погрешно <br /> погрешно <br /> погрешно <br /> промени ?</Trans></SpecHeading2>
                    <Heading20>{t('forgot_password.header')}</Heading20>
                </ForgotPasswordHeadLine>
                <ForgotPasswordForm>
                    <ResetPasswordField response={confirmReset}>
                        <Heading20>{t('forgot_password.recover.header')}</Heading20>
                        <Text13 className="forgot-password-instructions">{t('forgot_password.recover.description')}</Text13>
                        <InputFieldError />
                        <InputContainer>
                            <InputField
                                name="email"
                                ref={emailRef}
                                defaultValue={email.email}
                                inputLabel={t("general.email")}
                                type="text"
                                autocomplete="email"
                                onChange={e => { setEmail({ ...email, email: e.target.value }) }}
                            />
                            <InputFieldError error={errors.email[0]} />
                        </InputContainer>
                        <ButtonContainer>
                            <ButtonBasic
                                text={t("general.submit")}
                                variation="primary"
                                type="button"
                                onClick={(e) => requestPasswordResetLink(e)}
                            />
                        </ButtonContainer>
                    </ResetPasswordField>
                    <ConfirmResend response={confirmReset} resend={confirmResend}>
                        <div className="send-icon-container">
                            <Icon icon="icnSend" />
                        </div>
                        <Heading20>{t("forgot_password.reset.confirm_resend.header")}.</Heading20>
                        <Text13 className="confirm-password-instructions">{t("forgot_password.recover.confirm_resend.description")}.<br />{t("forgot_password.recover.confirm_resend.link_valid")} <span>{t("forgot_password.recover.confirm_resend.minutes")}</span></Text13>
                        <Text10 className="resend-cta">{t('forgot_password.recover.confirm_resend.no_link')}? <span className="resend-button" onClick={() => resendPasswordResetLink()}>{t("general.resend")}</span></Text10>
                        <Text10 className="confirm-resend-message">{t('forgot_password.recover.confirm_resend.confirm_resend_message')}</Text10>
                    </ConfirmResend>
                </ForgotPasswordForm>
            </Wrapper>
        </ForgotPasswordContainer>
    );
}

export default RecoverPassword;