export const NavigationItems = [
    {
        name: "Learning Paths",
        text: "general.learning_paths",
        icon: "icnLearningPath",
        width: 14,
        height: 14,
    },
    {
        name: "Courses",
        text: "general.courses",
        icon: "icnCourse",
        width: 14,
        height: 18,
    },
    {
        name: "History",
        text: "my_courses.navigation.watch_history",
        icon: "icnHistory",
        width: 15,
        height: 14,
    },
]