import React, { useState, useEffect, useContext } from 'react';

//imports
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import { SpecHeading2, Text13, Caption12 } from '../../../Components/Text/Text';
import { GlobalContext } from '../../../Context/GlobalContext';
import ratingIcons from './RatingIcons/RatingIcons';
import PageWrapper from '../../../Components/PageWrapper/PageWrapper';
import ButtonBasic from '../../../Components/ButtonBasic/ButtonBasic';
import BackLink from '../../../Components/BackLink/BackLink';
import { API } from '../../../Consts/Api';
import CircularProgressBar from '../../../Components/CircularProgressBar/CircularProgressBar';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Components/Icon/Icon';

//styled-components
const WellDoneRateWrapper = styled(PageWrapper)`
    background-color: ${props => props.theme.gray4};
    @media (max-width: 500px){
        padding: 45px 60px;
    }
`
const GreatJobContainer = styled.div`
    text-align: center;
    &>h1 {
        margin-bottom: 15px;
    }
    &>p {
        text-align: center;
    }
    @media (max-width: 500px){
        &>h1 {
            font-size: 40px;
            line-height: 46px;
        }
    }
`

const RatingIconsContainer = styled.div`
    display: flex;
    margin-top: 110px;
    &>div {
        margin: 0 30px;
        display: flex;
        align-items: center;
        &:hover{
            cursor: pointer;
            p{
                color: ${props => props.theme.blue};
            }
        }
    }
    &>div >p {
        margin-left: 8px;
    }

    span {
        color: ${props => props.theme.blue};
    }

    @media (max-width: 917px ){
        flex-direction: column;
    }

    @media (max-width: 500px){
        margin-top: 45px;
        &>div {
            margin: 30px 0;
            display: flex;
            align-items: center;
        }
    }
`

const ButtonsContainer = styled.div`
    margin-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 500px){
        margin-top: 45px;
    }
`

//component
const WellDoneRate = (props) => {
    const { t } = useTranslation();
    const [ratings, setRatings] = useState()
    const [rating, setRating] = useState();
    const [nextCourse, setNextCourse] = useState({});
    const { signedUser } = useContext(GlobalContext);
    useEffect(() => {
        API.axios.get(API.createApiRoute("getCourseFeedback", { COURSE_ID: props.courseId }))
            .then(response => setRatings(response.data.data))
            .catch(error => console.log(error))
        //check if there is learning path id, if there is it means user is comming from a learning path
        //get the learning path from database and find the next course if there is one and set it inside nextCourse hook
        if (props.learningPathId) {
            API.axios.get(API.createApiRoute("getLearningPath", { LEARNINGPATH_ID: props.learningPathId }))
                .then(response => {
                    //availableCourses is an array that stores all the available courses from current learning path
                    let availableCourses = []

                    //we itterate courses from the data in response,
                    response.data.data.courses.forEach((course, index) => {
                        //and check if course is available, or not comming_soon
                        //if so, we push that course to the availableCourses array
                        //otherwise we do skip the course
                        if (!course.comming_soon || course.comming_soon === 0) {
                            availableCourses.push(course);
                        }
                    })

                    //finally we itterate trough availableCourses
                    availableCourses.forEach((course, index) => {
                        //when we find the first available course that matches the course that is currently being rated in this page
                        if (parseInt(course.id) === parseInt(props.courseId)) {
                            //we check if there is a next course in availableCourses array
                            if (availableCourses[index + 1]) {
                                //if so, we set nextCourse state to that next available course
                                setNextCourse(availableCourses[index + 1]);
                            }
                        }
                    })
                })
        }
    }, [props.courseId, props.learningPathId]);
    useEffect(() => {
        if (rating) {
            API.axios.post(API.createApiRoute("setCourseFeedback", { COURSE_ID: props.courseId }), {
                feedback_option_id: rating
            }).then(response => console.log(response))
                .catch(error => console.log(error))
            let interval = setInterval(() => {
                navigate("/learning-mode/" + props.courseId + "/well-done/next")
            }, 700);
            return () => clearInterval(interval)
        }
    }, [rating, props.courseId])
    return (
        <WellDoneRateWrapper>
            <GreatJobContainer>
                <SpecHeading2>{t('learning_mode.course_complete.rate.heading')} {signedUser.name}</SpecHeading2>
                <Text13>{t('learning_mode.course_complete.rate.description')}</Text13>
            </GreatJobContainer>
            <RatingIconsContainer>
                {ratings && ratings.map((icon, i) => (
                    <div onClick={() => !rating && setRating(icon.id)} key={i}>
                        <CircularProgressBar width={47} pathTransitionDuration={0.7} percentage={0} color={ratingIcons[i].color} trailColor="#F3F3F5" strokeWidth={16}>
                            <Icon icon={ratingIcons[i].icon} />
                        </CircularProgressBar>
                        <Caption12><span>{icon.total} </span>{t(`learning_mode.course_complete.rate.ratings.${icon.name}`)}</Caption12>
                    </div>
                ))}
            </RatingIconsContainer>
            <ButtonsContainer>
                <Link to={props.learningPathId && nextCourse.id
                    ? API.createRoute("getCourseWithPath", { LEARNINGPATH_ID: props.learningPathId, COURSE_ID: nextCourse.id })//if there is a next course in the learning path then link to it
                    : props.learningPathId
                        ? API.createRoute("learningModeWhatsNextWithLearningPath", { LEARNINGPATH_ID: props.learningPathId, COURSE_ID: props.courseId }) //if there isn't next course, link to WhatsNext.jsx component
                        //if the user is not comming from a learning path, just take him to whats next page
                        : API.createRoute("learningModeWhatsNext", { COURSE_ID: props.courseId })}>
                    <ButtonBasic text={t("learning_mode.course_complete.rate.next_course")} variation="primary" spanTransform="30" iconTransform="170" />
                </Link>
                <BackLink url="/my-courses" text={t('general.back_to_my_courses')} fill={"#234BDB"} />
            </ButtonsContainer>
        </WellDoneRateWrapper >
    );
}

export default WellDoneRate;