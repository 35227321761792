import React, { createContext, useState, useCallback } from 'react';
import { useEffect } from 'react';
import { logout } from '../Functions/Logout';

export const GlobalContext = createContext({});

const { Provider } = GlobalContext;

export const GlobalContextProvider = (props) => {
    //while the prelaunch is active, we don't store anythng in the local storage
    //however, beta users should be able to login so adding logic here to know when to read the local storage
    const [signedUser, setSignedUser] = useState(
        process.env.REACT_APP_STATUS === "prelaunch" ? JSON.parse("[]") : JSON.parse(localStorage.getItem('user') || "[]")
    );
    const [isGettingStartedSeen, setIsGettingStartedSeen] = useState(true);
    const [appConfig, setAppConfig] = useState({});

    const unsetSignedUser = () => {
        setSignedUser({});
    }

    const logoutUser = (navigateTo) => {
        logout(unsetSignedUser, navigateTo)
    }

    const checkIfClickedOutside = useCallback((e, ref, callback) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback(false)
        }
    }, [])

    const setBodyUnscrollable = (value) => {
        let scrollHeight = window.scrollY;
        if (value) {
            document.body.style.position = 'fixed';
            document.body.style.width = '100vw';
            document.body.style.height = '100vh';
            document.body.style.overflowY = 'hidden';
            document.body.style.top = `-${scrollHeight}px`;
        } else {
            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.height = '';
            document.body.style.overflowY = '';
            document.body.style.top = "";
        }
    }

    const globalState = {
        signedUser,
        setSignedUser,
        unsetSignedUser,
        logoutUser,
        isGettingStartedSeen,
        setIsGettingStartedSeen,
        setBodyUnscrollable,
        appConfig,
        setAppConfig,
        checkIfClickedOutside,
    }

    useEffect(() => {
        if (localStorage.getItem("show_walkthrough") !== null) {
            setIsGettingStartedSeen(true)
        } else {
            setIsGettingStartedSeen(false)
        }
    }, []);

    return (
        <Provider value={globalState}>
            {props.children}
        </Provider>
    )
}