import React, { useState, useContext, useEffect } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

//component imports
import Icon from '../../../Components/Icon/Icon';
import Wrapper from '../../../Components/Wrapper/Wrapper';
import { Caption9, CardCaption15, SpecHeadingSmall } from '../../../Components/Text/Text';
import CopyLinkInput from '../../../Components/CopyLinkInput/CopyLinkInput';
import PrelaunchPopup from '../../../Components/PrelaunchPopup/PrelaunchPopup';
import PrelaunchRules from '../PrelaunchRules/PrelaunchRules';
import PrelaunchProgress from '../../../Components/PrelaunchProgress/PrelaunchProgress';
import SocialMediaShareButton from '../../../Components/SocialMediaShareButton/SocialMediaSHareButton';

//other imports
import { API } from '../../../Consts/Api';
import { GlobalContext } from '../../../Context/GlobalContext';
import photo1 from '../../../Static/prelaunch-photos/Main_Image_1.png';
import photo2 from '../../../Static/prelaunch-photos/Main_Image_2.png';
import photo3 from '../../../Static/prelaunch-photos/Main_Image_3.png';
import photo4 from '../../../Static/prelaunch-photos/Main_Image_4.png';
import prize_1_colored from '../../../Static/prelaunch-prizes/step_1_popup.png';
import prize_2_colored from '../../../Static/prelaunch-prizes/step-2-colored.png';
import prize_3_colored from '../../../Static/prelaunch-prizes/step-3-colored.png';
import prize_4_colored from '../../../Static/prelaunch-prizes/step-4-colored.png';

//styled-components
const PrelaunchStatusWrapper = styled.div`
    width: 100%;
    min-height: ${props => !props.verified ? "calc(100vh - 105px)" : "calc(100vh - 60px)"};
    background-color: ${props => props.theme.blue};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .prelaunch-navbar{
        width: 100%;
        padding-top: 28px;
        padding-bottom: 20px;
        ul{
            width: 100%;
            display: flex;
            justify-content: space-between;
            li{
                display: flex;
                align-items: center;
                img{
                    width: 150px;
                }
                p{
                    color: ${props => props.theme.white};
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    .prelaunch-status-share-container{
        width: 90%;
        max-width: 1141px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        img{
            width: 45%;
            align-self: flex-end;
        }
        .prelaunch-status-share{
            width: 53%;
            padding-bottom: 30px;
            h3{
                color: ${props => props.theme.white};
                margin-bottom: 60px;
                img{
                    display: none;
                }
            }
            .or-share-via{
                color: ${props => props.theme.gray3};
                margin-top: 20px;
                margin-bottom: 20px;
                text-align: center;
            }
            .sharing-input-and-buttons-container{
                width: 90%;
                .prelaunch-sharing-container{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
    .prelaunch-progress-progress-container{
        width: 100%;
        background-color: ${props => props.theme.black};
    }
    @media (max-width: 500px){
        min-height: unset;
        .prelaunch-navbar{
            padding-top: 25px;
            padding-bottom: 51px;
        }
        .prelaunch-status-share-container{
                width: 100%;
                img{
                    display: none;
                }
                .prelaunch-status-share{
                    width: 100%;
                    padding-bottom: 28px;
                    h3{
                        margin-left: 15px;
                        font-size: 23px!important;
                        line-height: 27px!important;
                        overflow: hidden;
                        display: flex;
                        margin-bottom: 0px;
                        z-index: 999;
                        img{
                            display: block;
                            width: 100%;
                            margin-left: -80px;
                        }
                    }
                    .sharing-input-and-buttons-container{
                        width: 90%;
                        margin: 0 auto;
                        .or-share-via{
                            margin-top: 18px;
                            margin-bottom: 18px;
                        }
                    }
                }
            
        }
    }
    @media (max-width: 1141px){
        .prelaunch-status-share-container{
            .prelaunch-status-share{
                h3{
                    font-size: 32px;
                    line-height: 37px;
                }
            }
        }
    }
`




const ProgressWrapper = styled(Wrapper)`
    padding: 0 100px;
    @media (max-width: 1141px){
        padding: 0;
    }
    @media (max-width: 500px){
        width: 100%;
        margin-bottom: -60px;
        height:1091px;
    }
`

const socialMedia = [
    {
        id: "facebook",
        text: "Facebook",
        icon: "icnFacebook",
        link: "https://www.facebook.com/sharer/sharer.php?u={URL}&hashtag=%23brainster"
    },
    {
        id: "twitter",
        text: "Twitter",
        icon: "icnTwitter",
        link: "https://twitter.com/intent/tweet?url={URL}&hashtags=brainster,career,кариера"
    },
    {
        id: "email",
        text: "Email",
        icon: "icnEmail",
        link: "mailto:?subject=Brainster NEXT - Едукација од иднината&body=Здраво,%0D%0A%0D%0Aприклучи се на следниот линк {URL} и учествувај во трката за iPhone 13.%0D%0A%0D%0AТвој,%0D%0ABrainster NEXT!"
    }
]

//component
const PrelaunchStatus = (props) => {
    const { t } = useTranslation();
    const { signedUser, setSignedUser } = useContext(GlobalContext);
    const [showPopup, setShowPopup] = useState(false);
    const [checkpoint, setCheckpoint] = useState(0);
    const [sharerLink, setSharerLink] = useState('');
    const [showRules, setShowRules] = useState();
    const [photo, setPhoto] = useState(null);

    const photos = [photo1, photo2, photo3, photo4]

    useEffect(() => {
        if (props.student) {
            API.axios.get(API.createApiRoute("studentStatus", { STUDENT: props.student }))
                .then(response => {
                    setSignedUser(response.data)
                })
                .catch(error => console.log(error))
        }
    }, [props.student, setSignedUser]);

    useEffect(() => {
        //first sort the checkpoints in deceding order by value (so we don't have to keep that manually sorted)
        //then find the biggest checkpoint has (student can have c0, c10 and c30 if he has 35 invites, so c30 should be returned)
        let available_checkpoints = { c0r: 0, c10r: 10, c30r: 30 };
        let url_checkpoint = Object
            .keys(available_checkpoints)
            .sort(function (a, b) { return available_checkpoints[b] - available_checkpoints[a] })
            .find(checkpoint => available_checkpoints[checkpoint] <= signedUser.verified_referrals)
        let sharerLink = API.createRouteFromParts(process.env.REACT_APP_DOMAIN_URL, "api/invite", url_checkpoint, signedUser.guid);
        signedUser.guid && setSharerLink(sharerLink)
    }, [signedUser.guid, signedUser.verified_referrals]);

    useEffect(() => {
        photos && setPhoto(photos[Math.floor(Math.random() * 3)])
    }, [photos]);

    useEffect(() => {
        if (signedUser.checkpoints) {
            if (signedUser.verified_referrals >= 100) {
                signedUser.checkpoints.indexOf("100referrals") === -1 && setShowPopup(signedUser.verified_referrals)
                setCheckpoint(100);
            } else if (signedUser.verified_referrals >= 30) {
                signedUser.checkpoints.indexOf("30referrals") === -1 && setShowPopup(signedUser.verified_referrals);
                setCheckpoint(30);
            } else if (signedUser.verified_referrals >= 10) {
                signedUser.checkpoints.indexOf("10referrals") === -1 && setShowPopup(signedUser.verified_referrals);
                setCheckpoint(10);
            } else {
                signedUser.checkpoints.indexOf("0referrals") === -1 && setShowPopup(signedUser.verified_referrals || true);
                setCheckpoint(0);
            }
        }

    }, [signedUser.checkpoints, signedUser.verified_referrals, signedUser]);

    const closePopup = (checkpoint) => {
        API.axios.post(API.createApiRoute("checkpoint").replace("{ID}", signedUser.student), { checkpoint: checkpoint.toString() + "referrals" })
            .then(() => setShowPopup(false))
            .catch(error => console.log(error))
    }

    const setPrizePhoto = () => {
        if (signedUser.verified_referrals >= 100) {
            return prize_4_colored
        } else if (signedUser.verified_referrals >= 30) {
            return prize_3_colored
        } else if (signedUser.verified_referrals >= 10) {
            return prize_2_colored
        } else if (signedUser.verified_referrals >= 0) {
            return prize_1_colored
        }
    }

    const openShareModal = (socialMediaUrl, sharerLink) => {
        window.open(socialMediaUrl.replace("{URL}", sharerLink), 'Share', 'width=600,height=400');
    }
    return (
        <PrelaunchStatusWrapper verified={signedUser.verified}>
            {signedUser.verified && showPopup && <PrelaunchPopup
                onClick={() => closePopup(checkpoint)}
                greet={t("prelaunch.refer_a_friend.popup.heading")}
                description={
                    signedUser.verified_referrals === 0
                        ? t("prelaunch.refer_a_friend.popup.description_step1").replace("{COURSES}", 90)
                        : signedUser.verified_referrals >= 100 ? t("prelaunch.refer_a_friend.popup.step4").replace("{INVITED_FRIENDS}", signedUser.verified_referrals)
                            : signedUser.verified_referrals >= 30 ? t("prelaunch.refer_a_friend.popup.description_step3").replace("{INVITED_FRIENDS}", signedUser.verified_referrals).replace("{REMAINING_FRIENDS}", 100 - signedUser.verified_referrals)
                                : signedUser.verified_referrals >= 10 && t("prelaunch.refer_a_friend.popup.description_step2").replace("{INVITED_FRIENDS}", signedUser.verified_referrals).replace("{REMAINING_FRIENDS}", 30 - signedUser.verified_referrals)
                }
                checkpoint={checkpoint}
                verified_referrals={signedUser.verified_referrals}
                prizePhoto={() => setPrizePhoto()}
                onShareJoy={() => openShareModal(socialMedia.find(item => item.id === 'facebook').link, sharerLink)}
            />}
            {showRules && <PrelaunchRules takeMeBackAction={() => setShowRules()} />}
            {!showRules && <>
                <nav className="prelaunch-navbar">
                    <Wrapper>
                        <ul>
                            <li className="navbar-item">
                                <Icon icon="brainsterNextLogo" />
                            </li>
                            <li className="navbar-item" onClick={() => setShowRules(true)}>
                                <CardCaption15>{t("prelaunch.rules.link")}</CardCaption15>
                            </li>
                        </ul>
                    </Wrapper>
                </nav>
                <main>
                    <div className="prelaunch-status-share-container">
                        <img src={photo} alt="friends" />
                        <div className="prelaunch-status-share">

                            <SpecHeadingSmall>
                                {t("prelaunch.refer_a_friend.heading")}
                                <img src={photo} alt="logo white" />
                            </SpecHeadingSmall>
                            <div className="sharing-input-and-buttons-container">

                                <CopyLinkInput
                                    defaultValue={sharerLink}
                                    readOnly={true}
                                    buttonText={t("general.copy_link")}
                                />
                                <Caption9 className="or-share-via">{t("prelaunch.refer_a_friend.share_via")}</Caption9>
                                <div className="prelaunch-sharing-container">
                                    {socialMedia.map((icon, index) => (
                                        <SocialMediaShareButton icon={icon.icon} text={icon.text} key={index} onClick={() => openShareModal(icon.link, sharerLink)} />
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="prelaunch-progress-progress-container">
                        <ProgressWrapper>
                            <PrelaunchProgress />
                        </ProgressWrapper>
                    </div>
                </main>
            </>}
        </PrelaunchStatusWrapper >

    );
}

export default withTheme(PrelaunchStatus);