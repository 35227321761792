import React from "react";
import { useTranslation } from "react-i18next";

//npm imports
import styled, { withTheme } from "styled-components";

//component imports
import Icon from "../Icon/Icon";
import { Caption16, Text13 } from "../Text/Text";

//styled-components
const UpdateMessageWrapper = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  .error-message-container {
    width: 100%;
    padding: 18px 0 18px 19px;
    display: flex;
    background-color: #fde2df;
    border-radius: 7px;
    display: ${(props) => (props.error ? "flex" : "none")};
    .error-icon-container {
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.danger};
      border-radius: 13px;
      margin-right: 14px;
    }
    & > div {
      &:last-child {
        width: 60%;
        margin-top: 3px;
        .error-title {
          text-transform: none;
          color: ${(props) => props.theme.danger};
          font-weight: 700;
        }
      }
    }
  }
  /*----------------------------------------------------------------------------------------- */
  /* Info message container style is added for the temporary changes in Brainster+ (prelaunch) */
  .info-message-container {
    width: 100%;
    padding: 18px;
    display: flex;
    background-color: #cff4fc;
    border-radius: 7px;
    display: ${(props) => (props.info ? "flex" : "none")};
    margin-bottom: 15px;
    p {
      margin-bottom: 5px;
    }
    .info-icon-container {
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #7f90db;
      border-radius: 13px;
      margin-right: 14px;
    }
    & > div {
      &:last-child {
        width: 100%;
        margin-top: 3px;
        .info-title {
          text-transform: none;
          color: #b5a77e;
          font-weight: 700;
        }
      }
    }
  }
  /*----------------------------------------------------------------------------------------- */

  .success-message-container {
    width: 100%;
    padding: 18px 0 18px 19px;
    display: flex;
    background-color: #eaf8f0;
    border-radius: 7px;
    margin-top: 41px;
    display: ${(props) => (props.success ? "flex" : "none")};
    .succes-icon-container {
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.success};
      border-radius: 13px;
      margin-right: 14px;
    }
    & > div {
      &:last-child {
        width: 60%;
        margin-top: 3px;
        .changed-title {
          text-transform: none;
          color: ${(props) => props.theme.success};
          font-weight: 700;
        }
      }
    }
  }
`;

//component
const UpdateMessage = ({ show, theme }) => {
  //components must take show prop as an object with either success property or error property

  const { t } = useTranslation();
  return (
    <UpdateMessageWrapper
      show={show.success || show.error || show.info}
      error={show.error}
      success={show.success}
      info={show.info}
    >
      <div className="error-message-container">
        <div className="error-icon-container">
          <Icon icon={"icnX"} pathStroke={theme.red} width={14} height={14} />
        </div>
        <div>
          <Caption16 className="error-title">{`${t("errors.error")
            .charAt(0)
            .toUpperCase()}${t("errors.error").slice(1)}`}</Caption16>
          <Text13>
            {show.error ? show.error[0] : t("errors.error_occured")}
          </Text13>
        </div>
      </div>
      <div className="success-message-container">
        <div className="succes-icon-container">
          <Icon
            icon={"icnChecked"}
            pathFill={theme.white}
            width={14}
            height={10}
          />
        </div>
        <div>
          <Caption16 className="changed-title">{`${t("messages.success")
            .charAt(0)
            .toUpperCase()}${t("messages.success").slice(1)}`}</Caption16>
          <Text13>
            {show.success ? show.success : t("messages.update_successful")}
          </Text13>
        </div>
      </div>
      {/* Info message container is added for the temporary changes in Brainster+ */}
      <div className="info-message-container">
        <div className="info-icon-container">
          <Icon
            icon={"icnChecked"}
            pathFill={theme.white}
            width={14}
            height={10}
          />
        </div>
        <div>
          <Caption16 className="changed-title">{`${t("messages.info")
            .charAt(0)
            .toUpperCase()}${t("messages.info").slice(1)}`}</Caption16>
          <div
            dangerouslySetInnerHTML={{
              __html: t("messages.account_exists_row_one"),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t("messages.account_exists_row_two"),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t("messages.account_exists_row_three"),
            }}
          />
        </div>
      </div>
    </UpdateMessageWrapper>
  );
};

export default withTheme(UpdateMessage);
