import React from 'react';

//imports
import styled from 'styled-components';
import { Text13 } from '../../Text/Text';
import { Link } from '@reach/router';
import { API } from '../../../Consts/Api';
import Icon from '../../Icon/Icon';

//styled-components
const AccordionContentItemContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px 0;
    transition: 0.02s;
    ${Text13}{
        width: 90%;
    }
    padding-right: ${props => props.theme.spacer * 2}px;
  
`
const AccordionItemIconContainer = styled.div`
    margin: 5px 20px 5px 28px;
    display: flex;
    align-items: center;
`

//component
const AccordionContentItem = (props) => {
    return (
        <Link to={props.lesson && props.learningPathId ? API.createRoute("learningModeWithPath", { LEARNINGPATH_ID: props.learningPathId, COURSE_ID: props.courseId, LESSON_ID: props.lesson.id }) : props.lesson && API.createRoute("learningMode", { COURSE_ID: props.courseId, LESSON_ID: props.lesson.id })}>
            <AccordionContentItemContainer showMore={props.showMore}>
                <AccordionItemIconContainer className="accordion-item-icon-container">
                    {props.lesson &&
                        <Icon className="accordion-item-icon" icon={props.lesson && parseInt(props.lesson.is_completed) > 0 ? "icnLessonFull" : "icnLessonEmpty"} />
                    }
                    {props.quizz &&
                        <Icon icon={props.lesson && parseInt(props.lesson.is_complete) > 0 ? "icnTestFull" : "icnTestEmpty"} />
                    }
                    {props.code &&
                        <Icon icon={props.code && parseInt(props.code.is_complete) > 0 ? "icnCodeFull" : "icnCodeEmpty"} />
                    }
                </AccordionItemIconContainer>
                <Text13 className="accordion-lesson-title">{props.lesson.title && props.lesson.title}</Text13>
            </AccordionContentItemContainer>
        </Link>
    );
}

export default AccordionContentItem;