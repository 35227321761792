import React from 'react';

//imports
import styled from 'styled-components';
import empty_state_image from '../../../../../Static/empty-state-my-courses/empty-state-history.png';
import { SpecHeadingSmall, Text13 } from '../../../../../Components/Text/Text';
import ButtonBasic from '../../../../../Components/ButtonBasic/ButtonBasic';
import HistoryCard from '../../../../../Components/HistoryCard/HistoryCard';
import Icon from '../../../../../Components/Icon/Icon';
import { useTranslation } from 'react-i18next';

//styled-components
const WatchHistoryContainer = styled.div`
    width: 100%;
    height: ${props => props.spread ? "100%" : "0%"};
    overflow: hidden;
    position: relative;
    &>.cards-container{
        max-height: 718px;
        overflow-y: scroll;
        padding-top: 80px;
        padding-left: ${props => props.spread ? "48px" : "0px"};
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        max-width: 1005px;
    }
    @media (max-width: 500px){
        &>div{
            padding: ${props => props.spread ? "66px 22px 0px 16px" : "0px"};
        }
        width: 100%;
        max-height: 585px;
        overflow-y: unset;

        .swiper-container {
            padding-left: 10px;
            padding-bottom: 15px;
        }
        .cards-container{
            padding-left: ${props => props.spread ? "20px" : "0px"};
            padding-right: ${props => props.spread ? "20px" : "0px"};
        }
    }
`

//component
const WatchHistory = (props) => {
    const { t } = useTranslation();
    return (
        <WatchHistoryContainer div className="hide-scrollbar" spread={props.spread}>
            {/* if there are finished courses or learning paths, map the array and display a HistoryCard for every item */}
            {props.finished && props.finished.length > 0
                ? <React.Fragment>
                    <div className="courses-state-info">
                        <div className="completed-info">
                            <Icon icon="icnStar7Filled" />
                            <Text13>{t("my_courses.navigation.completed")}  &nbsp;<span>{props.finished.length}</span></Text13>
                        </div>
                    </div>
                    <div className="hide-scrollbar cards-container">
                        {props.finished.map((card, index) => {
                            return (
                                <HistoryCard key={index} cardImage={card.image} cardTitle={card.title} cardSubtitle={card.subtitle} cardTime={card.duration} cardStudentNumber={card.total_students} cardCategory={card.category.name} />
                            )
                        })}
                    </div>
                </React.Fragment>
                : <div className={"empty-state-container hide-scrollbar"}>
                    <img src={empty_state_image} alt={"no content"} />
                    <SpecHeadingSmall>{t("my_courses.navigation.no_courses.heading")} {t("general.completed_plural")} {t("general.courses")}</SpecHeadingSmall>
                    <Text13 className="empty-state-message">{t("my_courses.navigation.no_courses.description.1")} {t("general.course")}. <br /> {t("my_courses.navigation.no_courses.description.2")}!</Text13>
                    <a href="/catalogue">
                        <ButtonBasic className="bla" text={t("my_courses.navigation.no_courses.go_to_catalogue")} />
                    </a>
                </div>
            }
        </WatchHistoryContainer>
    );
}

export default WatchHistory;