const theme = {

    white: '#ffffff',
    beige: '#FFEED8',
    yellow: '#FED445',
    pink: '#FFA9C4',
    red: '#F43C28',
    coralRed: "#ED5050",
    dark_blue: "#484BFF",
    blue: '#234BDB',
    lightBlue: "#e9edfb",
    violet: "#4B4BFF",
    green: '#206246',
    success: "#6FCF97",
    orange: "#F2994A",
    danger: "#FFA9C4",
    incorrect: "#FE3346",
    purple: "#454BFF",
    danger_select: "#F3F3F5",
    black: '#302F38',
    lightBlack: '#46415C',
    mediumBlack: '#3E3853',
    darkBlack: '#100824',
    gray0: "#F9F9F9",
    gray1: '#F1F1F1',
    gray2: '#EFEFEF',
    gray3: '#B9BBC4',
    gray4: '#E1E2E7',
    gray5: '#BDBDBD',
    gray6: "#5D6174",
    gray7: "#E6E7EB",
    gray8: "#C3C3D1",
    gray9: "#FCFCFC",
    spacer: 4,
    dropShadow1: '4px 4px 10px rgba(0, 0, 0, 0.1)',
    dropShadow2: '10px 10px 20px rgba(0, 0, 0, 0.1)',
    smallText: '10px',
    normalText: '14px',
    button15Text: '15px',
    text16: "16px",
    mediumText: '20px',
    largeText: '24px',
    smallestCaption: '10px',
    smallCaption: '13px',
    smallCaptionMobile: '9px',
    smallMediumCaption: '14px',
    smallMediumCaptionMobile: "9px",
    mediumCaption: '16px',
    largeCaption: '18px',
    heading1: '80px',
    heading2: '60px',
    heading3: '53px',
    heading4: '28px',
    heading5: '22px',
    heading6: '35px',
    heading20: '20px',
    mobileHeading20: '14px',
    specHeadingSmall: '40px',
    specHeadingSmaller: '32px',
    specHeadingSmallest: '22px',
    menuCapture: '20px',
    expo: 'cubic-bezier(0.19, 1, 0.22, 1)',
    none: 'none',
    ComputerScience: '#234BDB',
    DataScience: '#FED445',
    DigitalMarketing: '#F43C28',
    GraphicDesign: '#FFA9C4',
    facebookBlue: "",
    twitterBlue: "",
    googleRed: ""
}


export default theme;