import React from 'react';
export const illustrationMobile =
<svg width="382" height="413" viewBox="0 0 382 413" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.29221 208.791C24.1135 218.298 40.1564 240.816 40.1564 267.081C40.1564 293.458 23.9764 316.056 1 325.491" stroke="#4B4BFF" stroke-miterlimit="10"/>
<path d="M81.2409 96.4893L80.8318 97.4243L79.6045 97.5997V97.3659" fill="#234BDB"/>
<path d="M68.1504 90.2348C68.1504 94.4426 71.2478 98.0076 75.5726 99.3518L75.5141 99.2934C74.6375 97.8907 74.7544 96.2544 75.7479 95.6115C76.7999 94.9686 78.3194 95.5531 79.196 96.8972C79.2544 96.9557 79.3129 97.0726 79.3713 97.131C79.4298 97.2479 79.4882 97.3063 79.6051 97.3648L81.183 96.605C81.183 96.4297 81.183 96.2544 81.0662 96.079L80.774 95.5531C80.4817 95.0271 80.5986 94.3258 81.1246 93.9751L81.5921 93.6245C82.0012 93.3322 82.235 92.9231 82.235 92.4556C82.235 92.1634 82.1181 91.8712 81.9428 91.6374C81.5921 91.1699 81.7675 90.4686 82.2935 90.1764L85.0987 88.8322C86.5013 88.1309 88.2546 88.6568 89.0143 90.001L90.3001 92.1634H90.3585C90.5338 91.5205 90.5923 90.8777 90.5923 90.2348C90.5923 85.5594 86.7351 81.6438 81.5921 80.7671C80.8908 80.6502 80.1311 80.5918 79.3129 80.5918C76.4492 80.5918 73.7609 81.5269 71.7738 83.0464C69.6115 84.7997 68.1504 87.3711 68.1504 90.2348Z" fill="#FFA9C4"/>
<path d="M182.404 158.613L209.638 168.723C210.983 169.191 212.385 169.425 213.729 169.425C215.483 157.21 212.268 146.866 205.08 144.294C197.775 141.723 188.658 147.859 182.404 158.613Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M213.672 169.425C212.327 169.425 210.925 169.191 209.581 168.724L182.346 158.613C180.71 161.36 179.308 164.399 178.139 167.73C175.158 176.204 174.515 184.503 175.859 190.99L196.49 200.341" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M157.801 108.353L174.983 99.0606C173.463 96.606 173.405 94.5605 173.989 92.9826C169.373 88.3072 164.405 86.2032 160.723 87.9565C155.755 90.2942 154.762 98.8268 157.801 108.353Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M157.801 108.352C158.385 110.222 159.145 112.151 160.08 114.079C165.632 125.709 174.924 132.781 180.827 129.976C182.23 129.333 183.282 128.164 184.041 126.586V113.787H184.801V113.729C181.821 113.495 180.301 109.871 180.184 108.352C180.067 106.832 179.775 104.728 176.795 101.456C176.502 101.163 176.269 100.813 176.035 100.521C175.976 100.462 175.918 100.404 175.918 100.345C175.859 100.228 175.801 100.17 175.743 100.053C175.509 99.7024 175.275 99.3518 175.041 99.0596L157.801 108.352Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M242.075 148.619C244.238 148.152 246.458 148.035 248.738 148.269L260.777 149.438C260.952 149.438 261.127 149.496 261.244 149.554H276.848C273.693 139.444 267.03 132.489 259.316 132.489C251.894 132.548 245.348 139.093 242.075 148.619Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M222.028 211.854C224.191 213.198 231.73 205.309 240.204 193.153C242.016 190.523 243.944 187.717 245.814 184.737C246.048 184.386 246.224 184.094 246.457 183.743C257.269 166.561 263.932 151.249 261.243 149.555C261.126 149.438 260.951 149.438 260.776 149.438C257.854 149.321 249.555 158.555 240.671 171.763C239.386 173.633 238.1 175.62 236.814 177.665C226.002 194.906 219.398 210.218 222.028 211.854Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M300.459 193.093C301.686 193.619 303.557 192.333 305.836 189.645C310.511 184.21 316.823 173.048 322.55 159.606C330.148 141.781 334.122 126.001 332.369 121.56C332.135 120.975 331.843 120.625 331.434 120.449C331.434 120.449 331.434 120.449 331.375 120.449C331.083 120.332 330.791 120.332 330.44 120.391C329.797 120.508 328.979 120.975 328.102 121.794C323.369 125.885 315.946 138.391 309.284 153.995C304.55 165.158 301.16 175.561 299.816 182.807C298.706 188.593 298.823 192.392 300.459 193.093Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M59.5 159.781C59.5 163.346 63.8832 166.443 70.0196 167.612L67.4482 152.534C62.7143 153.995 59.5 156.684 59.5 159.781Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M76.8575 168.139L88.6628 154.756C85.7407 152.71 81.0069 151.366 75.6886 151.366C72.7081 151.366 69.8444 151.775 67.4482 152.535L70.0197 167.613" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M75.513 99.2351L75.5714 99.2935C76.4481 100.638 77.9676 101.164 79.0195 100.521C79.1364 100.462 79.1948 100.404 79.3117 100.287L88.5456 106.365C91.8768 108.586 96.0263 105.079 94.4483 101.397L92.9872 98.0078L95.6172 96.8389C95.9678 96.6636 96.0263 96.1376 95.6756 95.9623L90.8249 92.8064L90.4158 92.1636L89.1301 90.0012C88.3119 88.657 86.617 88.131 85.2144 88.8323L82.4092 90.1765C81.8832 90.4103 81.6494 91.1116 82.0585 91.6376C82.2339 91.8713 82.3507 92.1636 82.3507 92.4558C82.3507 92.9233 82.117 93.3324 81.7079 93.6246L81.2403 93.9753C80.7144 94.3259 80.539 95.0272 80.8897 95.5532L81.1819 96.0792C81.2988 96.2545 81.2988 96.4299 81.2988 96.6052C81.2988 96.8974 81.1234 97.1896 80.8897 97.3649L80.7728 97.4234C80.4221 97.6571 80.013 97.6571 79.6624 97.3649C79.5455 97.3065 79.4871 97.1896 79.4286 97.1312C79.3702 97.0727 79.3117 96.9558 79.2533 96.8974C78.3767 95.4948 76.7987 94.9104 75.8052 95.6117C74.7532 96.1961 74.6363 97.8909 75.513 99.2351Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M85.2159 94.6182L87.8458 91.9883C88.1964 91.6377 88.1964 91.1117 87.8458 90.761C87.4951 90.4104 86.9691 90.4104 86.6185 90.761L83.9886 93.3909C83.6379 93.7416 83.6379 94.2676 83.9886 94.6182C84.2808 94.9104 84.8652 94.9104 85.2159 94.6182Z" fill="white"/>
<path d="M87.9033 95.3193C88.3229 95.3193 88.6631 94.9792 88.6631 94.5596C88.6631 94.14 88.3229 93.7998 87.9033 93.7998C87.4837 93.7998 87.1436 94.14 87.1436 94.5596C87.1436 94.9792 87.4837 95.3193 87.9033 95.3193Z" fill="white"/>
<path d="M59.5 159.431C59.5 159.431 61.5455 131.203 72.2989 117.586C81.7665 105.664 93.2797 123.021 94.1563 148.502" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M71.1895 80.5342C71.1895 81.4108 71.4232 82.2874 71.8908 82.9887C73.8778 81.4692 76.5077 80.5342 79.4298 80.5342C80.1896 80.5342 80.9493 80.5926 81.7091 80.7095C81.7091 80.651 81.7091 80.5926 81.7091 80.5342C81.7091 77.8458 79.3714 75.625 76.4493 75.625C73.5271 75.625 71.1895 77.7874 71.1895 80.5342Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M79.1953 100.346V110.865C79.1953 112.911 80.8901 114.606 82.9356 114.606C84.9811 114.606 86.6759 112.911 86.6759 110.865V105.196" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M86.3848 100.111C86.3848 100.111 87.6705 103.092 90.8264 101.981" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M87.3772 99.7617L85.2148 100.463" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M92.8711 98.0654L91.3516 98.7083L94.0399 100.637L92.8711 98.0654Z" fill="#302F38" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M86.7345 110.047L79.2539 100.346L86.7345 105.255V110.047Z" fill="white"/>
<path d="M75.5724 99.2935H50.7344V24.0781H92.696C92.696 24.0781 96.0272 40.968 91.644 48.7408C87.2609 56.5137 82.7608 60.6631 87.3777 68.2606C90.6505 72.2931 96.1441 78.7802 90.0661 87.1375" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M107.073 124.716L126.067 111.625" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M126.066 111.625L159.437 88.7158" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M184.392 202.795L105.378 167.32" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M104.501 189.237L124.547 175.971" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M76.8584 168.139L71.1895 174.509L72.4167 178.834C73.7609 183.451 76.5662 187.191 80.0727 189.704" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M203.619 143.943L132.553 129.742C121.099 127.463 109.293 131.437 101.579 140.145L88.6631 154.755" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M110.813 174.51L124.547 175.971" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M70.0195 167.613L71.1884 174.509" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M86.7344 111.625C86.7344 111.625 98.0138 111.333 108.417 134.359" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M86.7344 110.923C86.7344 110.923 102.163 102.975 114.728 119.455" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M179.717 130.385L165.866 136.404" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M174.923 99.0595C175.099 99.4102 175.332 99.7608 175.625 100.053C175.683 100.17 175.742 100.228 175.8 100.345C175.858 100.404 175.917 100.462 175.917 100.521C176.151 100.813 176.384 101.105 176.677 101.456C179.657 104.787 179.949 106.832 180.066 108.352C180.183 109.871 181.703 113.553 184.683 113.729C184.8 113.729 184.917 113.729 185.034 113.787C187.664 114.313 187.43 119.105 192.807 120.157C194.443 120.449 196.43 120.508 198.593 120.099C199.411 119.923 200.287 119.69 201.106 119.397C205.606 117.761 209.989 113.787 211.45 106.073C212.794 98.7673 210.865 94.0919 208.937 91.4036C207.476 89.3581 206.015 88.4814 206.015 88.4814H180.417C178.021 88.4814 175.742 89.6503 174.456 91.6958C174.222 92.0464 174.047 92.4555 173.871 92.9231C173.404 94.5595 173.462 96.6049 174.923 99.0595Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M259.315 132.547H174.749" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M175.1 99.0596H189.652" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M179.717 105.956H198.652" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M183.808 113.553H203.035" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M175.859 190.989C177.087 196.892 180.009 201.275 184.334 202.795C188.132 204.139 192.399 203.087 196.49 200.34L175.859 190.989Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M196.548 200.341L222.029 211.854" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M242.074 148.619C237.515 149.613 233.308 152.067 230.21 155.749L222.145 165.392C219.983 167.905 216.885 169.366 213.729 169.425C213.729 169.425 197.775 184.853 204.671 203.964" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M203.679 165.742C203.679 165.742 187.724 181.171 194.62 200.282" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M331.375 120.45L323.719 119.983C311.855 119.223 300.751 125.768 295.666 136.463L292.686 142.717C290.699 146.924 286.432 149.613 281.815 149.613H276.906" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M239.796 193.152H300.751" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M274.218 149.438C274.218 149.438 257.503 167.905 274.218 193.152" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M288.128 147.801C288.128 147.801 270.127 167.905 286.842 193.152" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M59.6172 192.977C59.6172 195.081 77.4421 196.775 99.4164 196.775C121.391 196.775 139.216 195.081 139.216 192.977C139.216 191.048 124.079 189.47 104.559 189.236L103.215 190.113C95.793 195.022 86.676 194.379 80.1305 189.704C67.8576 190.347 59.6172 191.574 59.6172 192.977Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M187.489 35.1239C203.853 35.1239 217.061 25.9484 217.061 9.64298V3.38965" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M158.794 16.8311H154.762V20.8636H158.794V16.8311Z" fill="white"/>
<path d="M186.496 63.2936C188.723 63.2936 190.529 61.4881 190.529 59.261C190.529 57.0339 188.723 55.2285 186.496 55.2285C184.269 55.2285 182.464 57.0339 182.464 59.261C182.464 61.4881 184.269 63.2936 186.496 63.2936Z" fill="white"/>
<path d="M195.613 69.0205H177.379V88.5403H195.613V69.0205Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M177.379 69.0206V65.5725C177.379 62.5919 174.925 60.1374 171.944 60.1374V54.7022C178.373 54.7022 183.574 49.5008 183.574 43.0722V35.124H186.496" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M195.613 69.0206V65.5725C195.613 62.5919 198.068 60.1374 201.048 60.1374V54.7022C194.62 54.7022 189.418 49.5008 189.418 43.0722V35.124H186.496" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M177.379 76.6768H195.613" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M158.678 18.877L186.496 35.1239V55.2281" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M189.418 37.1113L215.542 52.4232" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M217.587 50.4355H213.555V54.4681H217.587V50.4355Z" fill="white"/>
<path d="M218.932 0H214.899V4.03252H218.932V0Z" fill="white"/>
<path d="M155.99 64.2871H151.957V68.3196H155.99V64.2871Z" fill="white"/>
<path d="M183.574 35.124C167.21 35.124 154.002 44.2995 154.002 60.6049V66.2738" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M335.172 204.197C332.893 200.574 331.549 196.249 331.549 191.632C331.549 178.541 342.185 167.904 355.276 167.904C368.368 167.904 379.004 178.541 379.004 191.632C379.004 202.093 372.225 210.976 362.816 214.132L360.887 212.613L358.315 211.619L354.225 211.911L352.121 204.898L353.874 203.204L354.867 200.048L353.874 197.125L351.536 195.489L350.25 194.496L349.491 194.963L345.692 196.658L344.114 194.145L340.783 194.554L338.62 196.249L338.562 200.34L336.341 202.794L335.172 204.197Z" fill="#FED445"/>
<path d="M349.666 205.307C352.507 205.307 354.809 203.005 354.809 200.164C354.809 197.324 352.507 195.021 349.666 195.021C346.826 195.021 344.523 197.324 344.523 200.164C344.523 203.005 346.826 205.307 349.666 205.307Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M350.485 195.022L338.854 174.509" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M345.809 196.717L335.465 178.541" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M359.486 320.906C366.716 320.906 372.577 315.045 372.577 307.815C372.577 300.585 366.716 294.724 359.486 294.724C352.256 294.724 346.395 300.585 346.395 307.815C346.395 315.045 352.256 320.906 359.486 320.906Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M184.333 271.23C184.742 271.289 185.151 271.289 185.619 271.289H213.554C210.515 264.86 205.138 260.594 198.944 260.594C192.807 260.652 187.372 264.86 184.333 271.23Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M198.944 305.595C208.411 305.595 216.126 295.542 216.126 283.094C216.126 278.77 215.191 274.737 213.554 271.289H185.619C185.21 271.289 184.742 271.289 184.333 271.23" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M183.982 305.595C187.898 301.504 190.353 295.192 190.353 288.121C190.353 281.4 188.132 275.439 184.567 271.29C184.567 271.29 179.365 272.283 178.547 265.971C174.164 232.075 147.631 226.055 139.099 233.886" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M201.982 305.244C205.723 301.095 208.06 294.958 208.06 288.121C208.06 281.458 205.84 275.439 202.333 271.348" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M166.94 305.476C170.068 304.259 166.373 287.25 158.686 267.487C150.999 247.723 142.231 232.687 139.102 233.904C135.974 235.121 139.669 252.129 147.356 271.893C155.043 291.657 163.811 306.692 166.94 305.476Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M339.501 391.787C357.38 380.382 370.41 368.843 368.605 366.012C366.8 363.182 350.843 370.133 332.964 381.538C315.085 392.942 302.055 404.482 303.861 407.312C305.666 410.142 321.623 403.191 339.501 391.787Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M303.905 260.652C305.425 263.165 307.412 264.743 309.574 264.743C314.25 264.743 318.048 257.555 318.048 248.671C318.048 239.788 314.25 232.6 309.574 232.6C307.879 232.6 306.243 233.593 304.899 235.23C304.782 235.346 304.724 235.463 304.607 235.639C304.548 235.697 304.49 235.755 304.49 235.814C304.256 236.106 304.081 236.457 303.847 236.749C303.73 236.983 303.613 237.217 303.438 237.45C303.321 237.684 303.204 237.918 303.087 238.21C303.029 238.327 302.97 238.444 302.912 238.619C302.795 238.853 302.678 239.145 302.561 239.437C302.444 239.788 302.327 240.139 302.211 240.548C302.152 240.665 302.094 240.84 302.094 240.957C302.094 241.015 302.035 241.074 302.035 241.132" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M293.854 242.244C295.841 241.484 298.003 241.075 300.165 241.075H302.152C302.152 241.016 302.211 240.958 302.211 240.899C302.269 240.782 302.269 240.607 302.328 240.49C302.445 240.14 302.561 239.731 302.678 239.38C302.795 239.088 302.912 238.854 303.029 238.562C303.087 238.445 303.146 238.328 303.204 238.153C303.321 237.919 303.438 237.627 303.555 237.393C303.672 237.159 303.789 236.925 303.964 236.692C304.139 236.341 304.373 236.049 304.607 235.756C304.665 235.698 304.724 235.64 304.724 235.581C304.841 235.464 304.899 235.289 305.016 235.172C304.958 234.938 304.899 234.704 304.841 234.471H294.73" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M294.673 234.529H304.783C303.731 229.912 301.861 226.814 299.699 226.814C297.537 226.814 295.666 229.912 294.673 234.529Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M318.458 264.743C323.133 264.743 326.932 257.555 326.932 248.671C326.932 239.788 323.133 232.6 318.458 232.6" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M338.854 204.723H340.666L338.854 206.768V204.723Z" fill="white"/>
<path d="M344.523 194.379H341.952C340.082 194.379 338.504 195.898 338.504 197.827V200.866L334.237 205.132" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M335.815 205.133C336.692 206.419 337.744 207.587 338.854 208.698V205.133H335.815Z" fill="#FFA9C4"/>
<path d="M349.666 205.367L342.478 210.919C342.302 211.035 342.127 211.152 341.952 211.211C341.952 211.211 338.971 211.971 338.854 208.698V205.133H334.237" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M340.082 199.406L342.536 199.99C342.887 200.107 343.296 199.873 343.355 199.464C343.471 199.113 343.238 198.704 342.829 198.646L340.374 198.061C340.023 197.944 339.614 198.178 339.556 198.587C339.497 198.938 339.731 199.289 340.082 199.406Z" fill="white"/>
<path d="M341.075 201.158C341.301 201.158 341.484 200.975 341.484 200.749C341.484 200.523 341.301 200.34 341.075 200.34C340.849 200.34 340.666 200.523 340.666 200.749C340.666 200.975 340.849 201.158 341.075 201.158Z" fill="white"/>
<path d="M343.237 204.139C343.237 204.139 343.296 206.301 340.958 206.301" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M309.575 232.6H361.121" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M344.641 209.164L346.394 213.606C346.394 213.606 339.03 214.482 337.803 232.599" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M346.218 213.548C349.783 215.126 354.341 211.678 354.341 211.678L351.711 204.957" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M309.575 264.743H327.751" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M373.101 264.217C375.614 263.34 377.776 261.529 378.887 259.016C382.335 251.184 373.802 242.476 369.536 238.093C365.913 234.411 366.555 223.073 363.926 216.353C363.166 214.366 361.646 212.788 359.718 211.969C357.789 211.21 355.101 211.093 352.062 213.489C346.627 217.814 351.244 232.6 351.244 232.6" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M374.153 273.802C372.692 281.107 366.205 286.601 358.49 286.601" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M358.491 286.601L335.523 299.516" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M319.919 261.062C320.597 261.062 321.146 260.513 321.146 259.835C321.146 259.157 320.597 258.607 319.919 258.607C319.241 258.607 318.691 259.157 318.691 259.835C318.691 260.513 319.241 261.062 319.919 261.062Z" fill="white"/>
<path d="M293.854 242.243C292.451 242.769 291.107 243.529 289.938 244.406C288.36 245.574 286.958 247.035 286.022 249.022" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M317.113 241.133H302.094" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M315.244 260.652H198.943" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M273.866 228.275L270.126 221.847H218.813L241.255 260.652" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M280.354 239.438L280.821 240.197L282.341 242.827L283.977 245.632L285.964 249.022L292.685 260.652" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M289.88 244.464H298.88" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M282.049 237.217H289.88" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M280.821 240.197H293.503" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M282.34 242.828H292.45" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M286.314 248.438H303.672" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M287.659 251.944H309.575" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M356.037 347.03L338.913 321.14C335.465 320.965 331.783 321.374 328.16 322.543C321.088 324.764 315.478 329.147 312.264 334.29L325.472 357.375C326.115 358.485 326.465 359.654 326.641 360.823" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M338.972 321.14L356.095 347.03C358.433 342.822 359.193 338.146 357.79 333.705C355.452 326.283 347.913 321.607 338.972 321.14Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M347.68 334.29C344.349 334.173 340.784 334.582 337.219 335.692C329.563 338.089 323.602 342.998 320.621 348.783" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M356.037 347.205C352.414 347.03 348.498 347.556 344.641 348.724C336.284 351.296 329.914 356.556 326.699 362.809" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M309.75 347.79L294.029 296.01C292.159 289.99 294.789 283.445 300.282 280.347L327.809 264.743" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M355.16 328.738L322.725 280.406" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M335.524 299.516L331.783 283.094" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M358.491 286.601H380.699V329.03H355.394" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M356.037 347.03L368.602 366.024" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M326.64 360.765C327.108 364.213 325.588 367.719 322.549 369.765L312.556 376.369C302.387 383.09 298.647 396.298 303.848 407.343" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M305.251 226.814H335.816" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M346.218 213.373C346.218 213.373 334.178 212.321 332.191 226.815" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M166.684 305.595H297.069" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M309.984 348.666C312.087 355.387 318.516 359.887 326.464 360.998C326.289 359.829 325.938 358.66 325.295 357.55L312.146 334.523C309.458 338.965 308.522 343.991 309.984 348.666Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M268.666 298.172L232.314 312.958" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M258.087 287.478L242.834 323.653" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M243.067 287.419L257.853 323.77" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M232.372 297.939L268.548 313.193" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M285.73 235.055C286.782 234.704 287.951 234.529 289.062 234.529H294.672C295.724 229.912 297.594 226.814 299.757 226.814H305.25" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M273.867 228.275L280.354 239.438C280.822 238.561 281.406 237.801 281.991 237.217C282.341 236.866 282.692 236.633 283.043 236.34C283.861 235.756 284.737 235.288 285.673 234.996" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M327.75 264.743L374.153 273.86C374.387 272.808 374.445 271.756 374.445 270.645C374.445 268.366 373.978 266.204 373.101 264.217C372.049 264.567 370.997 264.743 369.829 264.743H327.75Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M315.126 260.886L313.256 263.165L310.1 264.86L307.295 264.1L303.964 260.886L306.185 260.477L314.776 260.652L315.126 260.886Z" fill="white"/>
<path d="M316.764 241.132V239.262L313.666 234.528L310.335 232.6L307.764 233.184L304.666 235.639L302.445 239.963V241.191L316.764 241.132Z" fill="white"/>
<path d="M241.056 55.4565C240.971 55.4036 240.971 55.3156 241.039 55.2627C241.55 54.8929 241.873 54.3469 241.873 53.5543C241.873 52.1805 240.954 51.159 239.151 51.159H235.255C235.119 51.159 235 51.2823 235 51.4232V59.5602C235 59.7011 235.119 59.8244 235.255 59.8244H239.525C241.226 59.8244 242.247 58.8205 242.247 57.3762C242.247 56.4428 241.788 55.8087 241.056 55.4565ZM237.143 53.3958C237.143 53.2725 237.246 53.1668 237.365 53.1668H239.032C239.474 53.1668 239.712 53.4486 239.712 53.8537C239.712 54.2588 239.491 54.5406 238.913 54.5406H237.365C237.246 54.5406 237.143 54.4349 237.143 54.3116V53.3958ZM239.287 57.8165H237.365C237.246 57.8165 237.143 57.7109 237.143 57.5876V56.5132C237.143 56.3899 237.246 56.2842 237.365 56.2842H239.304C239.882 56.2842 240.104 56.5837 240.104 57.024C240.087 57.4819 239.865 57.8165 239.287 57.8165Z" fill="white"/>
<path d="M249.409 57.3938C249.341 57.2882 249.358 57.1649 249.477 57.112C250.481 56.5484 250.94 55.4741 250.94 54.3293C250.94 52.6032 249.953 51.159 247.81 51.159H243.965C243.829 51.159 243.71 51.2823 243.71 51.4232V59.5602C243.71 59.7011 243.829 59.8244 243.965 59.8244H245.581C245.717 59.8244 245.836 59.7011 245.836 59.5602V57.7285C245.836 57.6052 245.938 57.4995 246.057 57.4995H246.721C246.857 57.4995 246.993 57.57 247.061 57.6756L248.371 59.5954C248.473 59.7363 248.626 59.8244 248.779 59.8244H250.821C250.923 59.8244 250.991 59.7011 250.923 59.5954L249.409 57.3938ZM247.691 55.4917H246.074C245.955 55.4917 245.853 55.386 245.853 55.2627V53.3958C245.853 53.2725 245.955 53.1668 246.074 53.1668H247.691C248.541 53.1668 248.864 53.66 248.864 54.3293C248.864 54.9457 248.541 55.4917 247.691 55.4917Z" fill="white"/>
<path d="M257.286 51.4056C257.235 51.2647 257.082 51.159 256.928 51.159H255.312C255.159 51.159 255.006 51.2647 254.955 51.4056L251.757 59.5778C251.706 59.6835 251.791 59.8244 251.91 59.8244H253.73C253.883 59.8244 254.019 59.7187 254.07 59.5778L254.377 58.7676C254.428 58.6443 254.53 58.5563 254.666 58.5563H257.541C257.677 58.5563 257.796 58.6443 257.83 58.7676L258.136 59.5778C258.187 59.7187 258.323 59.8244 258.477 59.8244H260.297C260.433 59.8244 260.501 59.6835 260.45 59.5778L257.286 51.4056ZM256.809 56.5484H255.397C255.295 56.5484 255.227 56.4428 255.261 56.3371L256.027 54.2236C256.044 54.1531 256.163 54.1531 256.18 54.2236L256.945 56.3371C256.997 56.4251 256.928 56.5484 256.809 56.5484Z" fill="white"/>
<path d="M263.631 51.159H261.998C261.862 51.159 261.743 51.2823 261.743 51.4232V59.5602C261.743 59.7011 261.862 59.8244 261.998 59.8244H263.631C263.767 59.8244 263.886 59.7011 263.886 59.5602V51.4232C263.886 51.2823 263.767 51.159 263.631 51.159Z" fill="white"/>
<path d="M273.141 51.159H271.525C271.389 51.159 271.269 51.2823 271.269 51.4232V55.7735C271.269 55.8439 271.184 55.8792 271.133 55.8263L267.697 51.3704C267.595 51.2295 267.442 51.159 267.272 51.159H265.979C265.843 51.159 265.724 51.2823 265.724 51.4232V59.5602C265.724 59.7011 265.843 59.8244 265.979 59.8244H267.595C267.731 59.8244 267.85 59.7011 267.85 59.5602V55.2099C267.85 55.1394 267.935 55.1042 267.986 55.157L271.44 59.5954C271.542 59.7363 271.695 59.8068 271.865 59.8068H273.158C273.294 59.8068 273.413 59.6835 273.413 59.5426V51.4232C273.396 51.2823 273.277 51.159 273.141 51.159Z" fill="white"/>
<path d="M278.635 54.3816C277.631 54.0294 277.257 53.8532 277.257 53.501C277.257 53.2192 277.546 52.9198 278.278 52.9198C278.992 52.9198 279.401 53.2544 279.52 53.6243C279.588 53.818 279.673 53.9237 279.877 53.9237H281.374C281.527 53.9237 281.663 53.7652 281.646 53.6067C281.544 52.0568 280.183 51 278.227 51C276.322 51 274.961 52.0391 274.961 53.6947C274.961 55.4912 276.713 56.1429 278.04 56.6008C279.043 56.953 279.469 57.182 279.469 57.5519C279.469 57.816 279.214 58.0802 278.414 58.0802C277.665 58.0802 277.223 57.816 277.07 57.5166C276.934 57.2877 276.883 57.2348 276.645 57.2348H275.199C275.046 57.2348 274.91 57.3933 274.927 57.5519C275.08 59.137 276.577 60 278.465 60C280.37 60 281.799 59.1194 281.799 57.4286C281.765 55.5088 279.792 54.8043 278.635 54.3816Z" fill="white"/>
<path d="M289.846 51.159H283.008C282.872 51.159 282.752 51.2823 282.752 51.4232V53.0435C282.752 53.1844 282.872 53.3077 283.008 53.3077H285.151C285.27 53.3077 285.372 53.4134 285.372 53.5367V59.5602C285.372 59.7011 285.491 59.8244 285.627 59.8244H287.261C287.397 59.8244 287.516 59.7011 287.516 59.5602V53.5367C287.516 53.4134 287.618 53.3077 287.737 53.3077H289.88C290.017 53.3077 290.136 53.1844 290.136 53.0435V51.4232C290.119 51.2823 289.999 51.159 289.846 51.159Z" fill="white"/>
<path d="M297.587 51.159H291.667C291.53 51.159 291.411 51.2823 291.411 51.4232V59.5602C291.411 59.7011 291.53 59.8244 291.667 59.8244H297.587C297.723 59.8244 297.842 59.7011 297.842 59.5602V57.9574C297.842 57.8165 297.723 57.6933 297.587 57.6933H293.776C293.657 57.6933 293.555 57.5876 293.555 57.4643V56.795C293.555 56.6717 293.657 56.566 293.776 56.566H296.821C296.957 56.566 297.076 56.4428 297.076 56.3019V54.8224C297.076 54.6815 296.957 54.5582 296.821 54.5582H293.776C293.657 54.5582 293.555 54.4525 293.555 54.3293V53.5367C293.555 53.4134 293.657 53.3077 293.776 53.3077H297.587C297.723 53.3077 297.842 53.1844 297.842 53.0435V51.4232C297.842 51.2823 297.723 51.159 297.587 51.159Z" fill="white"/>
<path d="M306.654 59.5954L305.123 57.3763C305.055 57.2706 305.072 57.1473 305.191 57.0945C306.195 56.5309 306.654 55.4565 306.654 54.3117C306.654 52.5856 305.667 51.1414 303.524 51.1414H299.696C299.56 51.1414 299.441 51.2647 299.441 51.4056V59.5426C299.441 59.6835 299.56 59.8068 299.696 59.8068H301.312C301.448 59.8068 301.568 59.6835 301.568 59.5426V57.7109C301.568 57.5876 301.67 57.4819 301.789 57.4819H302.452C302.588 57.4819 302.724 57.5524 302.792 57.6581L304.102 59.5778C304.204 59.7187 304.357 59.8068 304.511 59.8068H306.552C306.654 59.8244 306.722 59.7011 306.654 59.5954ZM303.405 55.4917H301.772C301.653 55.4917 301.551 55.386 301.551 55.2628V53.3958C301.551 53.2725 301.653 53.1669 301.772 53.1669H303.388C304.238 53.1669 304.562 53.66 304.562 54.3293C304.579 54.9457 304.255 55.4917 303.405 55.4917Z" fill="white"/>
<path d="M309.832 59.9375V51.0625H313.419C313.799 51.0625 314.146 51.1458 314.461 51.3125C314.777 51.4708 315.051 51.6875 315.286 51.9625C315.52 52.2292 315.702 52.5333 315.831 52.875C315.96 53.2083 316.025 53.55 316.025 53.9C316.025 54.3833 315.92 54.8458 315.71 55.2875C315.5 55.7208 315.205 56.075 314.825 56.35C314.445 56.6167 314.001 56.75 313.492 56.75H310.923V59.9375H309.832ZM310.923 55.75H313.431C313.73 55.75 313.989 55.6667 314.207 55.5C314.433 55.3333 314.607 55.1083 314.728 54.825C314.857 54.5417 314.922 54.2333 314.922 53.9C314.922 53.55 314.849 53.2375 314.704 52.9625C314.558 52.6792 314.369 52.4583 314.134 52.3C313.9 52.1417 313.642 52.0625 313.359 52.0625H310.923V55.75Z" fill="white"/>
<path d="M317.988 59.9375V51.0625H319.079V58.9375H323.878V59.9375H317.988Z" fill="white"/>
<path d="M328.289 51.0625H329.21L332.724 59.9375H331.56L330.543 57.3375H326.931L325.926 59.9375H324.762L328.289 51.0625ZM330.3 56.4625L328.749 52.3625L327.149 56.4625H330.3Z" fill="white"/>
<path d="M339.866 52.0625H336.897V59.9375H335.806V52.0625H332.837V51.0625H339.866V52.0625Z" fill="white"/>
<path d="M341.711 59.9375V51.0625H347.394V52.0625H342.802V55.025H346.667V55.9625H342.802V59.9375H341.711Z" fill="white"/>
<path d="M352.718 60C352.112 60 351.563 59.875 351.07 59.625C350.577 59.375 350.149 59.0375 349.785 58.6125C349.43 58.1792 349.155 57.6958 348.961 57.1625C348.767 56.6208 348.67 56.0667 348.67 55.5C348.67 54.9083 348.771 54.3458 348.973 53.8125C349.183 53.2708 349.47 52.7917 349.834 52.375C350.205 51.95 350.638 51.6167 351.13 51.375C351.623 51.125 352.16 51 352.742 51C353.348 51 353.897 51.1292 354.39 51.3875C354.883 51.6458 355.307 51.9917 355.663 52.425C356.018 52.8583 356.293 53.3417 356.487 53.875C356.681 54.4083 356.777 54.9542 356.777 55.5125C356.777 56.1042 356.676 56.6708 356.474 57.2125C356.273 57.7458 355.986 58.225 355.614 58.65C355.251 59.0667 354.822 59.3958 354.33 59.6375C353.837 59.8792 353.3 60 352.718 60ZM349.773 55.5C349.773 55.95 349.842 56.3875 349.979 56.8125C350.125 57.2292 350.327 57.6 350.585 57.925C350.844 58.25 351.155 58.5083 351.518 58.7C351.882 58.8917 352.286 58.9875 352.73 58.9875C353.191 58.9875 353.603 58.8875 353.966 58.6875C354.33 58.4875 354.637 58.2208 354.887 57.8875C355.146 57.5542 355.339 57.1792 355.469 56.7625C355.606 56.3458 355.675 55.925 355.675 55.5C355.675 55.05 355.602 54.6167 355.457 54.2C355.319 53.7833 355.117 53.4125 354.851 53.0875C354.592 52.7542 354.281 52.4958 353.918 52.3125C353.562 52.1208 353.166 52.025 352.73 52.025C352.27 52.025 351.858 52.125 351.494 52.325C351.13 52.5167 350.819 52.7792 350.561 53.1125C350.31 53.4458 350.117 53.8208 349.979 54.2375C349.842 54.6458 349.773 55.0667 349.773 55.5Z" fill="white"/>
<path d="M358.899 59.9375V51.0625H362.583C362.962 51.0625 363.31 51.1458 363.625 51.3125C363.94 51.4708 364.214 51.6875 364.449 51.9625C364.683 52.2292 364.865 52.5333 364.994 52.875C365.123 53.2083 365.188 53.55 365.188 53.9C365.188 54.3083 365.111 54.6958 364.958 55.0625C364.812 55.4292 364.602 55.7417 364.328 56C364.061 56.2583 363.746 56.4375 363.382 56.5375L365.467 59.9375H364.231L362.267 56.75H359.989V59.9375H358.899ZM359.989 55.75H362.595C362.894 55.75 363.152 55.6667 363.37 55.5C363.596 55.325 363.77 55.0958 363.891 54.8125C364.021 54.5292 364.085 54.225 364.085 53.9C364.085 53.5667 364.012 53.2625 363.867 52.9875C363.722 52.7042 363.532 52.4792 363.298 52.3125C363.063 52.1458 362.805 52.0625 362.522 52.0625H359.989V55.75Z" fill="white"/>
<path d="M374.909 59.9375V53.1625L372.074 58.375H371.407L368.56 53.1625V59.9375H367.469V51.0625H368.62L371.734 56.825L374.861 51.0625H376V59.9375H374.909Z" fill="white"/>
</svg>


