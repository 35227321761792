import React from 'react';

// imports
import loader from '../../Static/Loader.gif'

//main component
const Loader = () => {

    return (
        <div className='loader-container'>
            <img src={loader} alt="loader" />
        </div>
    )
}

export default Loader;