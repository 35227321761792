import React from 'react';
import { useTranslation } from 'react-i18next';


//npm imports
import styled from 'styled-components';

//component imports
import Wrapper from '../Wrapper/Wrapper';
import { Caption12, Heading53, Text13 } from '../Text/Text';
import Icon from '../Icon/Icon';
import ButtonBasic from '../ButtonBasic/ButtonBasic';

//other imports
import { API } from '../../Consts/Api';
import background from '../../Static/backgrounds/software-engineering-cta-background.svg';
import background_mobile from '../../Static/backgrounds/software-engineering-cta-background-mobile.png';
import hand_photo from '../../Static/Photos/SoftwareEngineeringCtaHandImage.png'

//styled-components
const StyledLargeCtaBlock = styled.div`
    width: 100%;
    background-color: ${props => props.theme.blue};
    padding: 80px 0;
    background-image: url(${background});
    background-position: 115% top;
    background-repeat: no-repeat;
    background-size: auto;
    position: relative;
    overflow: hidden;
    &>img{
        position: absolute;
        top: 100px;
        right: 8%;
        z-index: 0;
    }
    ${Wrapper}{
        z-index: 1;
        position: relative;
        align-items: flex-start;
        &>p, &>h3, .bullet-points-container{
            width: 70%;
            color: ${props => props.theme.white};
        }
        &>h3{
            margin: 24px 0 22px 0;
        }
        .bullet-points-container{
            display: grid;
            grid-template-columns: 22% 22% 55%;
            margin-bottom: 80px;
            p{
                color: ${props => props.theme.white};
            }
            .point-container{
                display: flex;
                    svg{
                        margin-right: 14px;
                    }
                    .point-content{
                        width: 83%;
                    }
            }
            .point-container:not(:last-child){
                .point-content{

                    &>p:last-child{
                        width: 70%;
                    }
                }
                
            }
        }
        &>a{
            width: 70%;
            button{
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
    @media (max-width: 992px){
        background-image: url(${background_mobile});
        background-position: right top;
        
        &>img{
            width: 250px;
            right: 3%;
        }
        ${Wrapper}{
            &>h3{
                font-size: 42px;
                line-height: 48px;
            }
            .bullet-points-container{
                grid-template-columns: 24% 24% 50%;
                gap: 8px;
                .point-container{
                    svg{
                        margin-right: 8px;
                    }
                }
            }
        } 
    }
    @media (max-width: 768px){
        width: 100%;
        background-color: ${props => props.theme.blue};
        padding: 80px 0;
        &>img{
            width: 182px;
            right: -30px;
        }
        ${Wrapper}{
            align-items: flex-start;
            &>p{
                width: 47%;
            }
            &>h3{
                font-size: 20px;
                line-height: 23px;
                margin: 15px 0 46px 0;
            }
            .bullet-points-container{
                width: 76%;
                display: flex;
                flex-direction: column;
                gap: 35px;
                margin-bottom: 80px;
                p{
                    font-size: 12px;
                    line-height: 15px;
                    color: ${props => props.theme.white};
                }
                .point-container{
                    width: 100%;
                    display: flex;
                        svg{
                            margin-right: 14px;
                        }
                        .point-content{
                            width: 83%;
                        }
                }
            }
            &>a{
                width: 100%;
                button{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 18px 0;
                }
            }
    }
`

//main component
const LargeCtaBlock = (props) => {
    const { t } = useTranslation();
    const bullet_points = [
        {
            icon: "icnCalendarPink",
            title: t(`course_index.cta_block.${props.category}.bullet_points.0.title`),
            content: t(`course_index.cta_block.${props.category}.bullet_points.0.content`)
        },
        {
            icon: "icnCommentPink",
            title: t(`course_index.cta_block.${props.category}.bullet_points.1.title`),
            content: t(`course_index.cta_block.${props.category}.bullet_points.1.content`)
        },
        {
            icon: "icnGraduationHatPink",
            title: t(`course_index.cta_block.${props.category}.bullet_points.2.title`),
            content: t(`course_index.cta_block.${props.category}.bullet_points.2.content`)
        },
    ]
    return (
        <StyledLargeCtaBlock>
            <Wrapper>
                <Caption12>{t(`course_index.cta_block.${props.category}.pre_header`)}</Caption12>
                <Heading53>{t(`course_index.cta_block.${props.category}.header`)}</Heading53>
                <div className="bullet-points-container">

                    {bullet_points.map((point, index) => (
                        <div className="point-container" key={index}>
                            <Icon icon={point.icon} />
                            <div className="point-content">
                                <Text13>{point.title}</Text13>
                                <Text13 fontWeight={800}>{point.content}</Text13>
                            </div>
                        </div>
                    ))}
                </div>
                <a href={API.externalLinks("nextLanding")} target="_blank" rel="noopener noreferrer">
                    <ButtonBasic text={t(`course_index.cta_block.${props.category}.cta`)} />
                </a>
            </Wrapper>
            <img src={hand_photo} alt="hand_photo" />
        </StyledLargeCtaBlock>
    )
}

export default LargeCtaBlock;