import React, { useState, useRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

// Imported Components
import { Caption12, Text10 } from '../Text/Text';
import Icon from '../Icon/Icon';

// Styled Components
const InputWrapper = styled.div`
    width: 100%;
    .input-label{
        color: ${props => props.textColor ? props.textColor : "unset"};
        margin-bottom:  ${props => props.theme.spacer}px;
        letter-spacing: 1px;
    }
    .helper-text {
        margin-top: ${props => props.helperText && "10px"};
    }
    ${Caption12}{
        span{
            font-size: 22px;
        }
    }
    @media (max-width: 500px){
        ${Caption12}{
            span{
                font-size: 14px;
            }
        } 
    }
`;

const InputContainer = styled.aside`
    width: 100%;
    position: relative;
    .close, .reveal-pass {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translate(50%, -50%);
        transition: 0.2s ease;
        &:hover {
            fill: ${props => props.theme.lightBlack};
        }
    }

    .close {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }

    .reveal-pass {
        fill: ${props => props.theme.black};
        &:hover {
            /* fill: ${props => lighten(0.1, props.theme.black)}; */
        }
    }
`;

const Input = styled.input`
    width: 100%;
    height: 60px;
    border-radius: 8px;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 0px 16px;
    border: 1px solid ${props => props.error ? props.theme.red : props.borderColor ? props.borderColor : props.theme.gray3};
    font-family: ${props => props.type === 'number' || props.type === 'phone' ? 'Manrope' : 'Raleway'};
    ${props => props.focusColor ? css`
        &:focus, &:focus-visible{
            border-color: ${props => props.theme.blue} ;
            outline: ${props => props.theme.blue};
            -moz-outline: ${props => props.theme.blue};
            box-shadow: none;
        }
    ` : css`
        &:focus{
            outline: none;
            -moz-outline: none;
            box-shadow: none;
        }
    `}
    &:hover{
     
        border: 1px solid ${props => props.theme.blue};
    }
    @media (max-width: 768px){
        height: 55px;
        font-size: 14px;
    }
`;

// Component
const InputField = forwardRef(({ type, inputLabel, helperText, placeholder, onChange, defaultValue, value, theme, name, error, disabled, color, focusColor, className, required, autocomplete }, ref) => {
    const input = useRef(null);
    const close = useRef();
    const revealPass = useRef();
    const [inputType, setInputType] = useState(false);

    const toggleClose = e => {
        if (typeof onChange === 'function') {
            onChange(e);
        }
        //if type is not "password" when text is typed in the input field 
        //an X icon will appear on the right with functionality to close suggestions and delete value
        //Don't remove type !== password condition otherwise app will fail
        if (type === "text" && typeof type === 'string') {
            if (e.target.value.length > 0) {
                close.current.style.visibility = 'visible';
                close.current.style.opacity = 1;
                close.current.style.pointerEvents = 'auto';
            }
        }
    };

    const clearField = () => {
        input.current.value = '';
        input.current.focus();
        close.current.style.visibility = 'hidden';
        close.current.style.opacity = 0;
        close.current.style.pointerEvents = 'none';
    };

    const revealPassword = () => {
        setInputType(!inputType);
        if (!inputType) {
            input.current.type = 'text';
        } else {
            input.current.type = 'password';
        }
    }

    return (
        <InputWrapper ref={ref} textColor={color} className={className} helperText={helperText} >
            <Caption12 className="input-label" >{inputLabel}{required && <span>*</span>}</Caption12>
            <InputContainer >
                <Input autoComplete={autocomplete} focusColor={focusColor} error={error} borderColor={color} placeholder={placeholder} value={value} defaultValue={defaultValue} disabled={disabled} type={type} name={name && name} ref={input} onChange={toggleClose} />
                {
                    type === 'text' ? <Icon icon="icnClose" className="close" ref={close} onClick={clearField} />
                        : type === 'password' ?   !inputType ? <svg onClick={revealPassword} className="reveal-pass" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 6.99987C2.40372 2.81261 6.8632 0 11.9673 0C17.0715 0 21.531 2.81261 23.9347 6.99987C21.531 11.1871 17.0715 13.9997 11.9673 13.9997C6.8632 13.9997 2.40372 11.1871 0 6.99987Z" fill="#302F38"/>
                        <ellipse cx="11.8758" cy="7.11872" rx="4.65705" ry="4.74567" fill="white"/>
                        <ellipse cx="11.8753" cy="7.11889" rx="2.79423" ry="2.8474" fill="#302F38"/>
                        </svg>
                         : <svg onClick={revealPassword} className="reveal-pass" width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M0 7.99987C2.40372 3.81261 6.8632 1 11.9673 1C17.0715 1 21.531 3.81261 23.9347 7.99987C21.531 12.1871 17.0715 14.9997 11.9673 14.9997C6.8632 14.9997 2.40372 12.1871 0 7.99987Z" fill="#302F38"/>
                         <ellipse cx="11.8758" cy="8.11872" rx="4.65705" ry="4.74567" fill="white"/>
                         <ellipse cx="11.8753" cy="8.11889" rx="2.79423" ry="2.8474" fill="#302F38"/>
                         <path d="M1.73633 14.5752L22.7363 1.5752" stroke="#302F38" stroke-width="2" stroke-linecap="round"/>
                         </svg>
                          : null
                }

            </InputContainer>
            <Text10 className="helper-text">{helperText}</Text10>
        </InputWrapper>
    )
})

export default InputField;

