import React from 'react';
export const icnHourglassGradient =
    <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6667 29.1663C16.225 29.1663 17.5 27.8913 17.5 26.333L17.4858 21.828C17.4858 21.0772 17.1883 20.3688 16.6642 19.8305L11.8333 14.9997L16.6642 10.1405C17.1883 9.61634 17.4858 8.89384 17.4858 8.14301L17.5 3.66634C17.5 2.10801 16.225 0.833008 14.6667 0.833008H3.33333C1.775 0.833008 0.5 2.10801 0.5 3.66634V8.14301C0.5 8.89384 0.7975 9.61634 1.32167 10.1547L6.16667 14.9997L1.33583 19.8163C0.7975 20.3547 0.5 21.0772 0.5 21.828V26.333C0.5 27.8913 1.775 29.1663 3.33333 29.1663H14.6667ZM3.33333 8.04384V5.08301C3.33333 4.30384 3.97083 3.66634 4.75 3.66634H13.25C14.0292 3.66634 14.6667 4.30384 14.6667 5.08301V8.04384C14.6667 8.42634 14.5108 8.78051 14.2558 9.04967L9 14.2913L3.74417 9.03551C3.48917 8.78051 3.33333 8.41218 3.33333 8.04384Z" fill="url(#paint0_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="9" y1="0.833008" x2="9" y2="29.1663" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F9F9F9" />
                <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
            </linearGradient>
        </defs>
    </svg>