import React from 'react';

//imports
import styled from 'styled-components';
import MyCourseCard from '../../../../../Components/MyCourseCard/MyCourseCard';
import { API } from '../../../../../Consts/Api';
import empty_state_image from '../../../../../Static/empty-state-my-courses/empty-state-courses.png';
import { SpecHeadingSmall, Text13 } from '../../../../../Components/Text/Text';
import ButtonBasic from '../../../../../Components/ButtonBasic/ButtonBasic';
import Icon from '../../../../../Components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import Skeleton from '../../../../../Components/Skeleton/Skeleton';


//styled-components
const UnfinishedCoursesContainer = styled.div`
    width: 100%;
    height: ${props => props.spread ? "100%" : "0%"};
    max-height: 718px;
    margin-bottom: ${props => props.spread ? "78px" : "0px"};
    overflow: hidden;
    position: relative;
    &>div{
        padding-top: ${props => props.unfinishedCoursesLength !== "0" ? "80px" : "0px"};
        padding-left: ${props => props.unfinishedCoursesLength === "0" ? "0px" : props.spread ? "28px" : "0px"};
        padding-bottom: 20px;
        max-height: 718px;
        overflow-y: scroll;
        display: flex;
        flex-flow: row wrap;
    }
    .my-course-card-container {
        width: 22%;
        margin-left: 27px;
        margin-bottom: 27px;
    }
    .my-courses-carousel-container{
        width: 100%;
    }
    
    .go-to-catalogue-button:hover{
            cursor: pointer;
    }
    .skeleton-cards-container{
        padding-top: ${props => props.theme.spacer * 19}px;
        &>div{
            
        }
    }
    @media (max-width: 1295px){
        &>div{
            .my-course-card-container{
                margin-left: 17px;
                margin-bottom: 17px;
            }
        }
    }
    @media (max-width: 1141px){
        &>div{
            .my-course-card-container{
                width: 31%;
                margin-left: 10px;
                margin-bottom: 10px;
            }
        }
    }
    @media (max-width: 500px){
        width: 100%;
        max-height: 585px;
        overflow-y: unset;
        &>div{
            padding: ${props => props.spread ? props.theme.spacer * 19 + "px 0px 0px 0px" : "0px"};
            .my-course-card-container {
                width: 47%;
                margin-left: 6px;
                margin-bottom: 6px;
            }
        }

        .cards-container{
            padding-left: ${props => props.spread ? "20px" : "0px"};
            padding-right: ${props => props.spread ? "20px" : "0px"};
        }

        .swiper-container {
            padding-left: 42px;
            padding-bottom: 150px;
            .swiper-slide{
                margin-right: 17px!important;
            }
        }
    }
`

//component
const UnfinishedCourses = (props) => {
    const { t } = useTranslation();

    return (
        <UnfinishedCoursesContainer spread={props.spread} unfinishedCoursesLength={props.unfinishedCourses.length || "0"}>
            {/* if there is unfinished courses, map the array and display a card for every unfinished course, if not display empty-state content*/}
            {props.unfinishedCourses && props.unfinishedCourses.length > 0
                ? <React.Fragment>
                    <div className="courses-state-info">
                        <div className="current-info">
                            <Icon icon="icnStar7Stroked" />
                            <Text13>{t("my_courses.navigation.current")} &nbsp;<span>{props.unfinishedCourses.length}</span></Text13>
                        </div>
                        <div className="completed-info">
                            <Icon icon="icnStar7Filled" />
                            <Text13>{t("my_courses.navigation.completed")} &nbsp;<span>{props.completed}</span></Text13>
                        </div>
                    </div>
                    <div className="hide-scrollbar cards-container">
                        {props.unfinishedCourses.map((course, index) => {
                            return (
                                <a href={API.createRoute("getCourse", { COURSE_ID: course.id })} key={index} className="my-course-card-container">
                                    <MyCourseCard course={course} />
                                </a>
                            )
                        })}
                        <a href="/catalogue" className="my-course-card-container">
                            <MyCourseCard />
                        </a>

                    </div>
                </React.Fragment>
                : props.unfinishedCourses && props.unfinishedCourses.length === 0
                    ? <div className={"empty-state-container hide-scrollbar"}>
                        <img src={empty_state_image} alt={"no content"} />
                        <SpecHeadingSmall>{t("my_courses.navigation.no_courses.heading")} {t("general.active_plural")} {t("general.courses")}</SpecHeadingSmall>
                        <Text13 className="empty-state-message">{t("my_courses.navigation.no_courses.description.1")} {t("general.course")}. <br /> {t("my_courses.navigation.no_courses.description.2")}!</Text13>
                        <a href="/catalogue">
                            <ButtonBasic className="bla" text={t("my_courses.navigation.no_courses.go_to_catalogue")} />
                        </a>
                    </div>
                    : <React.Fragment>
                        <div className="courses-state-info">
                            <div className="current-info">
                                <Text13><Skeleton /></Text13>
                            </div>
                            <div className="completed-info">
                                <Text13><Skeleton /></Text13>
                            </div>
                        </div>
                        <div className="hide-scrollbar cards-container skeleton-cards-container">
                            {Array(6).fill().map((element, index) => (
                                <div key={index} className="my-course-card-container">
                                    <MyCourseCard course />
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
            }

        </UnfinishedCoursesContainer >
    );
}

export default UnfinishedCourses;