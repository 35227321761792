import React, { useState, useRef, useEffect, useCallback } from 'react';

//npm imports
import styled, { withTheme, keyframes } from 'styled-components';
import Editor from '@monaco-editor/react';

//component imports
import { ControlButtonText12, ControlButtonText14 } from '../Text/Text';
import Icon from '../Icon/Icon';
import { Caption16, Text13 } from '../Text/Text';
import Theme from '../../Consts/Theme';
import { API } from '../../Consts/Api';
import UpdateMessage from '../UpdateMessage/UpdateMessage';
import { useTranslation } from 'react-i18next';

//animations
const buttonsSlideIn = keyframes`
    from{
        transform: translate(-135%, 0%);
    }
    to{
        transform: translate(0%, 0%);
    }
`

const buttonsSlideOut = keyframes`
    from{
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(-135%, 0%);
    }
`

const backToEditorSlideIn = keyframes`
    from{
        transform: translate(100%, -89px);
        opacity: 0;
    }
    to{
        transform: translate(0%, -89px);
        opacity: 1;
    }
`

const backToEditorSlideOut = keyframes`
    from{
        transform: translate(0%, -89px);
        opacity: 1;
    }
    to{
        transform: translate(100%, -89px);
        opacity: 0;
    }
`

const hintSlideIn = keyframes`
    from{
        transform: translate(150%, -101%);
    }
    to{
        transform: translate(0%, -101%);
    }
`

const hintSlideOut = keyframes`
    from{
        transform: translate(0%, -100%);
    }
    to{
        transform: translate(150%, -100%);
    }
`

const messageAnimationOne = keyframes`
    0%{
        opacity: 1;
    }
    75%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
`

const consoleSlideIn = keyframes`
    from{
        bottom: -220px;
    }
    to{
        bottom: 0px;
    }
`

const consoleSlideOut = keyframes`
    from{
        bottom: 0px;
    }
    to{
        bottom: -220px;
    }
`

//styled-components
const CodeEditorWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;

    .status-indicator {
        border: 1px solid white;
        border-radius: 10px;
        background: white;
        margin-left: -3px;
        margin-top: 8px;
        margin-right: 10px;
        width: 6px;
        height: 6px;
        align-self: flex-start;
    }
    .status-indicator.success {
        border-color: green;
        background: green;
    }
    .status-indicator.error {
        border-color: red;
        background: red;
    }
    .error-indicator{
        background-color: red;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
    }
    .editor-container{
        width: 50%;
        height: 100%;
        background-color: ${props => props.theme.lightBlack};

        .tabs-container {
            background-color: ${props => props.theme.darkBlack};
            padding-left: 126px;
            display: flex;

            button {
                padding-right: ${props => props.errors !== null && props.errors.length > 0 ? "15px" : "23px"}!important;
                padding: 7px 15px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border: none;
                background-color: transparent;
                display: flex;
                align-items: center;
                color: ${props => props.theme.lightBlack};
                font-size: 10px;
                font-weight: 600;

                span{
                    margin-left: 3px;
                    align-self: flex-start;
                }

                .success-indicator{
                    background-color: green;
                }

                &:hover{
                    cursor: pointer;
                }
            }

            .active{
                background-color: ${props => props.theme.lightBlack};
                color: ${props => props.theme.white};
            }
        }

        &>div:not(.editor-controls-container, .tabs-container){
            width: 100%;
            padding-top: 20px;
            padding-left: 115px;
            height: ${props => props.multiModel ? "80%" : "85%"};
            overflow: hidden;

            .hint-container{
                width: 100%;
                height: 101%;
                padding-top: 10px;
                background-color: ${props => props.theme.lightBlack};
                animation: ${props => props.showHint ? hintSlideIn : hintSlideOut} .3s ${props => props.theme.expo}     ;

                p{
                    color: ${props => props.theme.gray3};
                    -webkit-user-select: text;  
                    -moz-user-select: text;     
                    -ms-user-select: text;      
                    user-select: text;          
                }
            }
            .message-container{
                width: 79%;
                transform: translate(5%, -600px);
                opacity: 1;
                animation: ${props => props.errors !== null && messageAnimationOne} 4s ${props => props.theme.expo} forwards;

                &>div >div{
                    background-color: ${props => props.theme.mediumBlack};
                    p{
                        color: ${props => props.theme.white};
                    }
                    .changed-title{
                        color: ${props => props.theme.success};
                    }

                    .error-title{
                        color: ${props => props.theme.danger};
                    }
                }

                .success-message-container{
                    margin-top: 0px;
                }
            }
        }
        .editor-controls-container{
            width: 100%;
            height: 15%;
            background-color: ${props => props.theme.lightBlack};
            padding-left: 140px;
            padding-right: 15px;
            // overflow: hidden;
            position: relative;

            .wHyG2{
                display: flex;
                border-top: 1px solid ${props => props.theme.darkBlack};
            }

            .control-buttons-container{
                width: 100%;
                padding: 24px 0;
                justify-content: space-between;
                animation: ${props => props.showHint ? buttonsSlideOut : buttonsSlideIn} .3s ${props => props.theme.expo} forwards;
                &>div{
                    display: flex;
                    align-items: center;
                    .starter-or-solution{
                        float: right;
                        position: relative;
                        .status-popover{
                            display: none;
                            background-color: ${props => props.theme.yellow};
                            padding: 15px 5px;
                            border-radius: 5px;
                            position: absolute;
                            bottom: 25px;
                            right: 5px;
                            min-width: 150px;
                            p {
                                color: ${props => props.theme.lightBlack};
                            }
                        }
                        &:hover{
                            cursor: pointer;
                            .status-popover{
                                display: block;
                            }
                        }
                    }
                }
            }
            
            .back-to-editor-button-container{
                width: 100%;
                padding: 34px 0;
                justify-content: flex-end;
                animation: ${props => props.showHint ? backToEditorSlideIn : backToEditorSlideOut} .3s ${props => props.theme.expo} forwards;

                button{
                    border: none;
                    background: transparent;
                    padding: 0px;
                    p{
                        display: flex;
                        align-items: center;
                        color: ${props => props.theme.darkBlack};
                    }
                    svg{
                        margin-left: 11px;
                        transform: rotate(180deg);
                        path{
                            stroke: ${props => props.theme.darkBlack};
                        }
                    }
                    &:hover{
                        cursor: pointer;
                        p{
                            color: ${props => props.theme.gray3};
                        }
                        svg{
                            path{
                                stroke: ${props => props.theme.gray3};
                            }
                        }
                    }
                }
            }
        }
    }
    .browser-container{
        width: 50%;
        height: 100%;
        font-family: initial;
        position: relative;
        overflow: hidden;
        border-top: 1px solid ${props => props.theme.lightBlack};
        border-bottom: 1px solid ${props => props.theme.lightBlack};
        h1,h2,h3,h4,h5{
            font-family: initial!important;
        }

        .console-container{
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            animation: ${props => props.showConsole ? consoleSlideIn : consoleSlideOut} .3s ${props => props.theme.expo} forwards;
            .console-button-container{
                display: flex;
                justify-content: center;
                button{
                    min-width: ${props => props.showConsole ? "69px" : "106px"};
                    padding: ${props => props.showConsole ? "12px 26px 7px 26px" : "12px 26px"};
                    border: none;
                    background-color: ${props => props.showConsole ? props.theme.lightBlack : props.theme.gray3};
                    color: ${props => props.theme.gray6};
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                    display: flex;
                    justify-content: ${props => props.showConsole ? "center" : "flex-start"};
                    svg{
                        path{
                            fill: ${props => props.theme.white};
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .console{
                width: 100%;
                height: 220px;
                background-color: ${props => props.theme.lightBlack};
                padding: 15px 0 0 25px;
                p{
                    color: ${props => props.theme.white};
                    span{
                       color: ${props => props.theme.red};
                    }
                }
            }
        }
    }
    .mobile-screen-container{
        display: none;
    }

    @media (max-width: 1141px){
        flex-direction: column;
        .editor-container, .browser-container    {
            width: 100%;
            height: 50%;
        }
        .editor-container{
            overflow: hidden;
        }
        .browser-container{
            padding-left: 125px;
            background-color: ${props => props.theme.lightBlack};
            iframe{
                background-color: ${props => props.theme.white};
            }
        }
    }

    @media (max-width: 500px){
        height: unset;

        .editor-container, .browser-container{
            display: none;
        }

        .mobile-screen-container{
            width: 80%;
            height: 100%;
            margin: 0 auto;
            padding: 27px 40px 35px 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: ${props => props.theme.lightBlack};
            border-radius: 10px;

            .mobile-icon-container{
                width: 42px;
                height: 42px;
                margin-bottom: 15px;
                background-color: ${props => props.theme.darkBlack};
                border-radius: 50%;
                display: flex;
                place-content: center center;
                align-items: center;
            }

            .mobile-screen-heading{
                margin-bottom: 30px;
                font-weight: 700;
                text-transform: none;
                color: ${props => props.theme.darkBlack};
            }

            .mobile-screen-description{
                color: ${props => props.theme.white};
                text-align: center;
            }
        }
    }
`

const ControlButtonWrapper = styled.button`
    height: 40px;
    padding: 10px 15px 12px 15px;
    margin-right: 9px;
    background-color: ${props => props.active ? props.theme.darkBlack : "transparent"};
    border: 1px solid ${props => props.theme.darkBlack};
    border-radius: 20px;
    display: flex;
    align-items: center;

    p{
        font-weight: 500;
        margin-right: 16px;
        color: ${props => props.theme.white};
    }

    svg{
        margin-top: 2px;
    }

    &:hover{
        cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    }
`

const HelpButtonWrapper = styled.div`
    height: 18px;
    display: flex;
    align-items: center;
    
    &:nth-child(1){
        padding-right: 14px;
        // border-right: 1px solid ${props => props.theme.darkBlack};
    }

    &:nth-child(2){
        padding-left: 14px;
    }

    &:hover{
        cursor: pointer;

        p{
            color: ${props => props.theme.gray3};
        }

        svg{
            path{
                fill: ${props => props.theme.gray3};
            }
        }
    }

    p{
        margin-right: 5px;
        font-weight: 500;
        color: ${props => props.theme.darkBlack};
    }

`

//control button component
const ControlButton = withTheme((props) => {
    return (
        <ControlButtonWrapper disabled={props.disabled} onClick={props.onClick} active={props.active}>
            <ControlButtonText14>{props.text}</ControlButtonText14>
            <Icon icon={props.icon} width={16} pathFill={props.theme.white} />
        </ControlButtonWrapper>
    )
})

//help button component
const HelpButton = withTheme((props) => {
    return (
        <HelpButtonWrapper>
            <ControlButtonText12 onClick={props.onClick}>{props.text}</ControlButtonText12>
            <Icon icon={props.icon} width={18} />
        </HelpButtonWrapper>
    )
})

//component
//defaultValue should always be an array even if it has only one item
const CodeEditor = ({ technologies, hint, codeChallengeId, addSolvedCodeChallenge, updateSolutionInfoForCodeChallenge, theme }) => {
    const { t } = useTranslation();

    //a boolean to determine wether or not to display hint screen
    const [showHint, setShowHint] = useState(false);

    //array containing strings to indicate errors in language tabs
    const [errors, setErrors] = useState(null);

    //expects string that defines active tab in documents bar on top
    const [activeTab, setActiveTab] = useState();

    //array of strings defining languages for editor like "html", "css", "javascript"
    const [selectedTechnology, setSelectedTechnology] = useState([]);

    //used to show indicator to the student whether the challenge is completely solved or not.
    //this is important in lessons with multiple challenges where the user doesn't know
    //which are already successfully solved and which are not or have errors.
    const [codeChallengeStatus, setCodeChallengeStatus] = useState(null);

    //hook to store starter files for reset function
    // const [baseEditorValue, setBaseEditorValue] = useState({
    //     html: "",
    //     css: "",
    //     javascript: ""
    // });

    //hook to store values that are displayed on the result browser
    const [editorValue, setEditorValue] = useState({
        html: "",
        css: "",
        javascript: ""
    });

    //hook to store new values with changes made in the code editor
    const [liveEditorValue, setLiveEditorValue] = useState({
        html: "",
        css: "",
        javascript: ""
    });

    //keep syntax errors in marker hooks bellow
    const [cssMarkers, setCssMarkers] = useState([]);
    const [jsMarkers, setJsMarkers] = useState([]);

    const editorRef = useRef(null);


    //hook to hold boolean determining wether to display console or not
    const [showConsole, setShowConsole] = useState(false);

    //Source document to display in <iframe/>
    const sourceDocument = editorValue.html
        .replace('<link href="style.css" rel="stylesheet" />', `<style>${editorValue.css}</style>`)
        .replace('<link rel="stylesheet" href="style.css" />', `<style>${editorValue.css}</style>`)
        .replace("<link rel='stylesheet' href='style.css' />", `<style>${editorValue.css}</style>`)
        .replace("<link href='style.css' rel='stylesheet' />", `<style>${editorValue.css}</style>`);

    //when the user types something in the editor, reset errors in order to remove indicators on tabs and message
    useEffect(() => {
        liveEditorValue !== editorValue && setErrors(null)
    }, [liveEditorValue, editorValue]);




    const getFormattedTechnologiesObject = useCallback(() => {
        let html = '';
        let css = '';
        let javascript = '';
        technologies.forEach((technology, index) => {
            if (technology.language === "html") {
                html = technology.starter
                if (technology.solution_info && technology.solution_info.html) {
                    html = technology.solution_info.html
                    setCodeChallengeStatus(technology.solution_info.html_correct)
                }
            }
            if (technology.language === "css") {
                css = technology.starter
                if (technology.solution_info && technology.solution_info.css) {
                    css = technology.solution_info.css
                    setCodeChallengeStatus(technology.solution_info.css_correct)
                }
            }
            if (technology.language === "javascript") {
                javascript = technology.starter
                if (technology.solution_info && technology.solution_info.javascript) {
                    javascript = technology.solution_info.javascript
                    setCodeChallengeStatus(technology.solution_info.javascripta_correct)
                }
            }
        })

        return { html, css, javascript }
    }, [technologies])
    //On mount, if defaultValue prop is an array(it should always be),
    //then iterate defaultValue and store the values in the defined hooks,
    //then set hooks accordingly
    useEffect(() => {
        if (technologies.length) {
            let technologiesObject = getFormattedTechnologiesObject();
            setEditorValue(technologiesObject)
            setLiveEditorValue(technologiesObject)
            setSelectedTechnology(technologies[0])
            setActiveTab(technologies[0].name)
        }
    }, [codeChallengeId, getFormattedTechnologiesObject, technologies]);

    //handler function needed for mounting the editor npm component
    //sets the editor to be instance in editorRef.current
    //creates and sets custom theme
    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;

        //change background color of editor to the light dark from projects' theme
        monaco.editor.defineTheme('vs-next', {
            base: 'vs-dark',
            inherit: true,
            rules: [{ background: Theme.lightBlack }],
            colors: {
                'editor.background': Theme.lightBlack,
                'editor.lineHighlightBackground': Theme.lightBlack,
            }
        });
        monaco.editor.setTheme('vs-next');
    }

    //saves value of written html, css, javascript inside liveEditorValue hook accordingly
    const saveValue = () => {
        selectedTechnology.language === "html" && setLiveEditorValue({ ...liveEditorValue, html: editorRef.current.getValue() });
        if (selectedTechnology.language === "css") {
            setLiveEditorValue({ ...liveEditorValue, css: editorRef.current.getValue() });
            setCssMarkers([]);
        }
        if (selectedTechnology.language === "javascript") {
            setLiveEditorValue({ ...liveEditorValue, javascript: editorRef.current.getValue() });
            setJsMarkers([]);
        }
    }

    //validates the written code in backend according to the provided regex and returns response
    const checkSolution = (value) => {
        setEditorValue(value);
        let postBody = {
            id: codeChallengeId,
            html: value.html,
            css: value.css,
            javascript: value.javascript
        }
        let errors = [];
        if (cssMarkers.length === 0 && jsMarkers.length === 0) {
            API.axios.post(API.createApiRoute("checkCode"), postBody)
                .then(response => {
                    technologies.forEach((technology, index) => {

                        (response.data.data.solution_info[`${technology.language}_correct`] === 0) && errors.push(technology.language);

                    })

                    updateSolutionInfoForCodeChallenge(codeChallengeId, response.data.data.solution_info);
                    if (errors.length > 0) {
                        setErrors(errors);
                        setCodeChallengeStatus(false);
                    } else {
                        setErrors([]);
                        addSolvedCodeChallenge(codeChallengeId)
                        setCodeChallengeStatus(true);
                    }
                })
                .catch(error => console.log(error))
        } else {
            cssMarkers.length > 0 && cssMarkers.forEach((marker, index) => {
                errors.push(marker.source)
            })
            jsMarkers.length > 0 && jsMarkers.forEach((marker, index) => {
                errors.push("javascript")
            })
            setErrors(errors)
        }
    }

    //resets the displayed result back to the starter files result
    const resetValue = () => {
        setEditorValue(getFormattedTechnologiesObject());
        setCodeChallengeStatus(null)
        technologies.forEach((technology, index) => {
            if (technology.language === selectedTechnology.language) {
                editorRef.current.setValue(technology.starter)
            }
        })
    }

    //validate syntax errors and set them in arrays cssMarkers and jsMarkers (HTML validation is impossible atm)
    function handleEditorValidation(markers) {
        // model markers
        let cssMarkers = [];
        let jsMarkers = [];
        markers.forEach(marker => {
            marker.source === "css" ? cssMarkers.push(marker) : jsMarkers.push(marker)
        });
        cssMarkers.length > 0 && setCssMarkers(cssMarkers);
        jsMarkers.length > 0 && setJsMarkers(jsMarkers);
    }
    return (
        <CodeEditorWrapper errors={errors} multiModel={technologies.length > 1} showHint={showHint} showConsole={showConsole}>
            <div className="editor-container">
                <div className="tabs-container">
                    {/* if inside the defaultValue array prop there are more than one items, 
                    itterate it and create a tab button to shift between tabs of languages */}
                    {technologies && technologies.length > 1
                        && technologies.map((technology, index) =>
                            technology.language
                            && <button
                                key={index}
                                onClick={() => [setSelectedTechnology(technology), setActiveTab(technology.name)]}
                                className={activeTab === technology.name ? "active" : ""}
                            >
                                {technology.name}
                                {/* if errors is an array and it's length is bigger than 0 then render span for every button which is going to have red or 
                                green background depending on wether errors array include an item that matches the language of the tab that the button 
                                represents by assigning appropriate className */}
                                {errors !== null && errors.length > 0
                                    && <span className={errors.includes(technology.language.toString()) ? "error-indicator" : "success-indicator"}></span>}
                            </button>
                        )
                    }
                </div>
                <div>

                    <Editor
                        height="100%"
                        defaultLanguage={selectedTechnology.language}
                        value={liveEditorValue[selectedTechnology.language]}
                        path={`${selectedTechnology.name}-${codeChallengeId}`}
                        onMount={handleEditorDidMount}
                        onChange={saveValue}
                        onValidate={handleEditorValidation}
                        validate={true}
                        theme="vs-dark"
                        allowComments={false}
                    />
                    <div className="hint-container">
                        <Text13 dangerouslySetInnerHTML={{ __html: hint }}></Text13>
                    </div>
                    <div className="message-container">
                        <UpdateMessage
                            show={errors !== null && errors.length > 0 ? { error: [`${t("errors.errors_in")} ${errors.toString()}.`] } : errors !== null && { success: t("messages.code_challenge_success") }}
                        />
                    </div>
                </div>
                <div className="editor-controls-container">
                    <div className="control-buttons-container wHyG2">
                        <div>
                            <ControlButton
                                disabled={JSON.stringify(liveEditorValue) === JSON.stringify(editorValue)}
                                active={true}
                                text={`${t("learning_mode.code_editor.run").charAt(0).toUpperCase()}${t("learning_mode.code_editor.run").slice(1)}`}
                                icon="arrowRightSmall"
                                onClick={() => checkSolution(liveEditorValue)}
                            />
                            <ControlButton
                                active={false}
                                text={`${t("learning_mode.code_editor.reset").charAt(0).toUpperCase()}${t("learning_mode.code_editor.reset").slice(1)}`}
                                icon="icnReset"
                                onClick={() => resetValue()}
                            />
                        </div>
                        <div>
                            <div className="starter-or-solution">
                                <Icon icon="icnEditorText" />
                                <div className="status-popover">
                                    <Text13>{
                                        codeChallengeStatus === null
                                            ? t("learning_mode.code_editor.status_messages.starter_code")
                                            : (codeChallengeStatus)
                                                ? t("learning_mode.code_editor.status_messages.your_code_correct")
                                                : t("learning_mode.code_editor.status_messages.your_code_incorrect")
                                    }.</Text13>
                                </div>
                            </div>
                            <div className={`status-indicator ${codeChallengeStatus === null ? "" : (codeChallengeStatus) ? "success" : "error"}`} ></div>
                            <HelpButton
                                active={false}
                                text={`${t("learning_mode.code_editor.hint").charAt(0).toUpperCase()}${t("learning_mode.code_editor.hint").slice(1)}`}
                                icon="icnBell"
                                onClick={() => setShowHint(true)}
                            />
                        </div>
                    </div>
                    <div className="back-to-editor-button-container wHyG2">
                        <button onClick={() => setShowHint(false)}><ControlButtonText12>{`${t("learning_mode.code_editor.back_to_editor").charAt(0).toUpperCase()}${t("learning_mode.code_editor.back_to_editor").slice(1)}`}<Icon icon={"icnArrowLeft"} height={14} width={9} pathStroke={theme.darkBlack} pathStrokeWidth="2.5" /></ControlButtonText12></button>
                    </div>
                </div>
            </div>
            <div className="browser-container">
                <iframe
                    srcDoc={sourceDocument}
                    title="output"
                    sandbox="allow-scripts"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                />
                <div className="console-container">
                    <div className="console-button-container">
                        <button onClick={() => setShowConsole(!showConsole)}>{showConsole ? <Icon icon="icnX" pathFill={theme.gray6} width={12} height={12} /> : t("learning_mode.code_editor.console")}{cssMarkers.length > 0 || jsMarkers.length > 0 ? <span className="error-indicator"></span> : ""}</button>
                    </div>
                    <div className="console">
                        {cssMarkers.length > 0 && cssMarkers.map((marker, index) => (
                            <Text13 key={index}>css : <span>{marker.message}</span> at <span>{marker.startLineNumber}:{marker.startColumn}</span> </Text13>
                        ))}
                        {jsMarkers.length > 0 && jsMarkers.map((marker, index) => (
                            <Text13 key={index}>javascript : <span>{marker.message}</span> at <span>{marker.startLineNumber}:{marker.startColumn}</span> </Text13>
                        ))}
                    </div>
                </div>

            </div>
            <div className="mobile-screen-container">
                <div className="mobile-icon-container">
                    <Icon
                        icon="icnMobile"
                    />
                </div>
                <Caption16 className="mobile-screen-heading">{t("learning_mode.code_editor.mobile_preview.header")}</Caption16>
                <Text13 className="mobile-screen-description">{t("learning_mode.code_editor.mobile_preview.description")}</Text13>
            </div>
        </CodeEditorWrapper>

    );
}

export default withTheme(CodeEditor);
