import React from 'react';

//npm imports
import styled from 'styled-components';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

//component imports
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import Icon from '../../../../Components/Icon/Icon';
import { withTheme } from 'styled-components';
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';
import { Heading35, Text13, Text16 } from '../../../../Components/Text/Text';

//other imports
import { API } from '../../../../Consts/Api';
//import hero_animation from '../../../../Static/Gifs/homepage-hero-animation.gif'
import hero_image from '../../../../Static/Photos/Homepage/HeroImage.png'
import hero_background from '../../../../Static/backgrounds/homepage-hero-background.svg'

//styled-components
const StyledHomepageHeader = styled.header`
    width: 100%;
    padding-top: 30px;
    padding-bottom: 111px;
    background-color: ${props => props.theme.gray7};
    background-image: url(${hero_background});
    background-size: cover;
    background-repeat: norepeat;
    ${Wrapper}{
        nav{
            width: 100%;
            display: flex;
            justify-content: space-between;
            ul{
                display: flex;
                align-items: center;
                &>li:first-child{
                    margin-right: 24px;
                }
                &>li>a>button{
                    padding: 14px 30px;
                    >span{
                        font-size: 16px;
                    }
                }
                &>li>a{
                    font-family: 'Manrope', sans-serif;
                }
            }
        }
        .header-container{
            margin-top: 180px;
        }
        section{
            margin-top: 112px;
            display: flex;
            flex-direction: column;
            align-items: center;
            ${Heading35}{
                margin: 0 auto;
                width: 65%;
                text-align: center;
            }
            img{
                margin-top: 100px;
                width: 80%;
            }
            .bullet-points{
                display: flex;
                margin-bottom: 70px;
                .bullet-point{
                    display: flex;
                    flex-direction: column;
                    margin: 0px 50px;
                    p{
                        width: 200;
                        line-height: 20px;
                        margin: 14px 0;
                    }
                }
            }
        }
    }
    @media (max-width: 768px){
        padding-bottom: 77px;
        ${Wrapper}{
            nav{
    
            }
            .header-container{
                margin-top: 80px;
                h4{
                    font-size: 26px;
                    line-height: 30px;
                    width: 80%;
                    text-align: left;
                    align-self: flex-start;
                    margin: 0;
                    width: 100%;
                }
                img{
                    width: 100%;
                    margin-top: 50px;
                }
            }
            section{
                .bullet-points{
                    
                    flex-direction: column;
                    .bullet-point{
                        width: 50%;
                        margin: 0;
                    }
                }
            }
        }
    }
    @media (max-width: 500px){
        ${Wrapper}{
            nav{
                &>a{
                    width: 125px;
                    p{
                        width: 100%;
                        margin-right: 0;
                        svg{
                            width: 100%;
                        }
                    }
                }
                &>ul{
                    li:first-child{
                        margin-right: 0px;
                    }
                    li{
                        a{
                            p, button > span{
                                font-size: 12px;
                                line-height: 17px;
                            }
                            button {
                                padding: 14px 22px;
                            }
                        }
                    }
                    li:first-child{

                        a{
                            margin-right: 20px;
                        }
                    }
                }
            }
            section{
                .bullet-points{
                    .bullet-point{
                        width: 90%;
                        margin: 0;
                    }
                }
            }
        }
    }
`

const NavLink = styled(Link)`
    text-decoration: none;
    color: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover{
        path, circle{
            fill: ${props => props.theme.blue};
        }
        &>div > svg.catalogue-link > path{
            fill: none;
        }
    }
    .active > circle, .active > path{
        fill: ${props => props.theme.blue};
    }
    @media (max-width: 768px) {
    }
`

const Logo = styled.p`
    margin-right: 48px;
    font-size: 20px;
    text-transform: uppercase;
    color: #161616;
    display: flex;
    align-items: center;
    span {
        font-weight: bold
    }
    img {
        width: 144px;
    }
`

//main component
const HomepageHeader = (props) => {
    const { t } = useTranslation();

    const bulletPoints = [
        {
            title: t("homepage.hero.bullet_points.0.title"),
            text: t("homepage.hero.bullet_points.0.text")
        },
        {
            title: t("homepage.hero.bullet_points.1.title"),
            text: t("homepage.hero.bullet_points.1.text")
        },
        {
            title: t("homepage.hero.bullet_points.2.title"),
            text: t("homepage.hero.bullet_points.2.text")
        },
    ]

    return (
        <StyledHomepageHeader>
            <Wrapper>
                <nav>
                    <NavLink className="logo" to='/'><Logo><Icon icon="brainsterNextLogo" pathFill={props.theme.black} /></Logo></NavLink>
                    <ul>
                        <li>
                            <NavLink to={API.createRoute("signin")}><ButtonBasic text={t("general.start_learning")} /></NavLink>
                        </li>
                    </ul>
                </nav>
                <section className='header-container'>
                    <Heading35>{t("homepage.hero.header")}</Heading35>
                    <img src={hero_image} alt="Next Platform Hero" />
                </section>
                <section>
                    <div className="bullet-points">
                        {bulletPoints.map((point, index) => (
                            <div className="bullet-point" key={index}>
                                <Icon icon="icnStarBullet" />
                                <Text16 fontWeight={700}>{point.title}</Text16>
                                <Text13>{point.text}</Text13>
                            </div>
                        ))}
                    </div>
                    <NavLink to={API.createRoute("signin")}><ButtonBasic text={t("general.start_now")} /></NavLink>
                </section>
            </Wrapper>
        </StyledHomepageHeader>
    )
}

export default withTheme(HomepageHeader);