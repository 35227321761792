import React from "react";

//npm imports
import styled from "styled-components";

//component imports
import { Text13 } from "../Text/Text";

//styled-components
const ChooseButtonWrapper = styled.div`
  width: 100%;
  border: 1px solid
    ${(props) => (props.active ? props.theme.blue : props.theme.gray3)};
  border-radius: 7px;
  padding: 23px;
  display: flex;
  align-items: center;
  transition: border 0.08s linear;
  &:hover {
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.blue};
  }
  p {
    letter-spacing: 1px;
  }

  @media (max-width: 500px) {
    ${Text13} {
      font-size: 14px !important;
      line-height: 1.2;
    }
  }
`;

//component
const ChooseButton = ({ checkCircle, onClick, text, active, id }) => {
  return (
    <ChooseButtonWrapper onClick={() => onClick(id)} active={active}>
      {checkCircle && (
        <div className={active ? "check-circle active" : "check-circle"}></div>
      )}
      <Text13 fontWeight={500} fontFamily="Raleway">
        {text}
      </Text13>
    </ChooseButtonWrapper>
  );
};

export default ChooseButton;
