import React from 'react';

const NotFoundPage = () => {
    return ( 
        <div>
            NOT FOUND
        </div>
     );
}
 
export default NotFoundPage;