import React from "react";

//imports
import styled, { css, withTheme } from "styled-components";
import { prop, switchProp } from "styled-tools";
import { lighten } from "polished";

// Styled components
const ButtonContainer = styled.button`
  padding: 18px 35px;
  border-radius: 120px;
  font-size: ${(props) => props.theme.mediumText};
  border: ${(props) =>
    props.disabled && props.variation === "quaternary"
      ? "1px solid " + props.theme.gray3
      : "none"};
  color: white;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  transition: 0.2s ${(props) => props.theme.expo};
  position: relative;
  background: ${switchProp(prop("variation", "primary"), {
    primary: prop("theme.black"),
    formBtn: prop("theme.black"),
    secondary: prop("theme.blue"),
    terniary: prop("theme.yellow"),
    quaternary: prop("theme.none!important"),
  })};
  ${(props) =>
    props.disabled
      ? css`
          cursor: not-allowed;
          background: ${(props) =>
            props.variation === "quaternary"
              ? props.theme.none
              : props.theme.gray2};
          color: ${(props) =>
            props.variation === "quaternary" && props.theme.gray6};
        `
      : css`
          cursor: pointer;
        `};

  span {
    display: inline-block;
    font-family: "Raleway", sans-serif;
  }
  &:hover {
    background: ${switchProp(prop("variation", "primary"), {
      formBtn: "#234BDB",
      primary: lighten(0.05, "#302F38"),
      secondary: lighten(0.05, "#234BDB"),
    })};
    /* // ${(props) =>
      props.disabled &&
      css`
        //     background: ${(props) => props.theme.gray2};
        //
      `}; */
  
  }
  @media (max-width: 1000px) {
    padding: 10px 40px;
  }
  @media (max-width: 425px) {
    padding: 10px 35px;
    span {
      font-size: 16px;
    }
  }
`;

//component
const ButtonBasic = ({
  text,
  variation,
  disabled,
  theme,
  type,
  onClick,
  iconTransform,
  spanTransform,
  className,
  hidden,
}) => {
  return (
    <ButtonContainer
      className={className}
      tabIndex="0"
      hidden={hidden}
      disabled={disabled}
      variation={variation}
      type={type}
      onClick={onClick}
    >
      <span>{text}</span>
    </ButtonContainer>
  );
};

export default withTheme(ButtonBasic);
