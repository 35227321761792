import React, { useEffect, useState } from 'react';
import { API } from '../Consts/Api';


const Dashboard = () => {
    const [data, setData] = useState('Hello frontend');
    useEffect(() => {
        API.axios.get(API.createApiRoute("dashboard"))
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => console.log(error));
    }, [])
    return (
        <div>
            {data}
        </div>
    )
}

export default Dashboard;