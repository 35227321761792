import React, { useEffect, useState, forwardRef } from 'react';

//imports
import styled, { css } from 'styled-components';

//styled-components
const PopoverBubble = styled.div`
    width: 100%;
    border: 1px solid ${props => props.theme.gray3};
    border-radius: 13px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${props => props.pointerPosition === "top" && css`
        margin-top: 14px;
    `}
    a, button {
        width: 160px;
        padding: 18px 0;
        display: flex;
        justify-content: space-between;
        p,button{
            color: ${props => props.theme.gray3};
        }
        :hover {
            p, button{
                color: ${props => props.theme.blue};
            }
            img {
                fill: ${props => props.theme.blue};
            }
            cursor: pointer;
        }
    }

    a {
        border-bottom: 1px solid ${props => props.theme.gray3};
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
    }

    img {
        width: 12.53px;
    }

    &:before{
        content: '';
        display: block;
        position: absolute;
        z-index: 99;
        ${props => props.pointerPosition === "top" ? css`
            top: -13px;
        ` : props.pointerPosition === "bottom" && css`
            top: ${props => props.top - 1}px;
        `}
        left: ${props => props.card === "learning-path-card" ? props.left - 50 : 25}px;
        width: 0;
        height: 0;
        border:  14px solid transparent;
        ${props => props.pointerPosition === "bottom" ? css`
            border-top-color: #C4C4C4;
        ` : props.pointerPosition === "top" && css`
            border-bottom-color: #C4C4C4;
        `}
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        z-index: 99;
        ${props => props.pointerPosition === "top" ? css`
            top: -10px;
            ` : props.pointerPosition === "bottom" && css`
            top: ${props => props.top - 2}px;
        `}
        left: ${props => props.card === "learning-path-card" ? props.left - 49 : 26}px;
        width: 0;
        height: 0;
        border:  13px solid transparent;
        ${props => props.pointerPosition === "bottom" ? css`
            border-top-color: white;
        ` : props.pointerPosition === "top" && css`
            border-bottom-color: white;
        `}
    }

    // @media (max-width: 500px){
    //     &:before{
    //         left: ${props => props.card === "learning-path-card" && props.left - 25}px;
    //     }
    //     &:after{
    //         left: ${props => props.card === "learning-path-card" && props.left - 24}px;
    //     }
    // }
`

//component
const Popover = forwardRef((props, ref) => {
    //keep ref's current height value in this hook in order to position the pointer
    const [top, setTop] = useState()

    //keep ref's current width value in this hook in order to position the pointer if necessary
    const [left, setLeft] = useState();

    //if the pointer's position is set to bottom, get the ref's current height and set it inside top hook
    //if props.card is learning-path-card, set left hook to ref.current.width
    useEffect(() => {
        props.pointerPosition === "bottom" && setTop(ref.current.getBoundingClientRect().height);
        props.card === "learning-path-card" && setLeft(ref.current.getBoundingClientRect().width);
    }, [props.card, props.pointerPosition, ref])

    return (

        <PopoverBubble ref={ref && ref} pointerPosition={props.pointerPosition} top={top} left={left} card={props.card}>
            {props.children}
        </PopoverBubble>
    );
})

export default Popover;