import React, { useEffect, useState, useRef, useContext } from 'react';

//imports
import styled from 'styled-components';
import { Text13, Heading60, Heading28 } from '../../Components/Text/Text';
import { API } from '../../Consts/Api';
import { navigate, useLocation } from '@reach/router';
import ZenMode from '../../Components/ZenMode/ZenMode';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';
import LessonArticle from './Blocks/LessonArticle';
import LessonQuiz from './Blocks/LessonQuiz';
import LessonController from './Blocks/LessonController';
import Icon from '../../Components/Icon/Icon';
import SectionComplete from './Blocks/SectionComplete';
import CodeEditor from '../../Components/CodeEditor/CodeEditor';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../Context/GlobalContext';
import Loader from '../../Components/Loader/Loader';

//styled-components
const LearningModePageWrapper = styled(PageWrapper)`
    background-color: ${props => props.theme.gray0};
    padding-bottom: 76px;
    figure, img{
        max-width: 100%;
    }
`

const LearningModeTitleWrapper = styled.div`
    width: 100%;
    max-width: 750px;
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.gray0};
    padding-top: ${props => props.theme.spacer * 20}px;
    position: relative;
    transition: padding 0.2s ease;
    h1,h2,h3,h4,h5,h6,p,span,blockquote{
        text-align: center;
    }
    margin-bottom: ${props => props.theme.spacer * 10}px;
    img{
        max-width: 100%;
    }
    @media (max-width: 1441px){
        padding-left: 150px;
        padding-right: 25px;
    }
    @media (max-width: 500px){
        padding: ${props => props.collapse ? "55px 43px 0px 59px " : "55px 43px 0px 109px "};
        justify-content: flex-start;
        &>h2{
            font-size: 35px;
            line-height: 40px;
        }
        &>h4{
            font-size: 20px;
        }
    }
`

const LessonArticleContainer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.gray0};
    p, strong{
        color: ${props => props.theme.lightBlack};
    }
    &.code-challenge-text-container{
        background-color: ${props => props.theme.gray4};
        .code-challenge-title{
            width: 100%;
            max-width: 1141px;
            margin: 0 auto;
            display: flex;
            margin-top: 80px;
            p{
                margin-left: 20px;
                font-weight: bold;
            }
        }
    }
    @media (max-width: 1441px){
        &.code-challenge-text-container{
            .code-challenge-title{
                padding-left: 140px;
                margin-bottom: -25px;
            }
        }
    }
    @media (max-width: 500px){
        &.code-challenge-text-container{
            .code-challenge-title{
                font-size: 14px;
                line-height: 21px;
                padding-left: ${props => props.collapse ? "59px" : "109px"};
                transition: padding .2s ease;
            }
        }
    }
`

const LessonQuizContainer = styled.div`
    width: 100%;
    background-color: #F3F3F5;

    @media (max-width: 500px){ 
        border-top-left-radius: 80px;
        border-top-right-radius: 80px;
    }
`

const LessonEditorContainer = styled.div`
    width: 100%;
    height: 64vh;
    @media (max-width: 1141px){
        height: 90vh;
    }
    @media (max-width: 500px){
        height: unset;
    }
`

const DimmedDiv = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    opacity: ${props => props.myPathActive ? "0.5" : "0"};
    transition: opacity 0.2s ease;
    visibility: ${props => props.myPathActive ? "visible" : "hidden"};
`

const ZenModeTutorial = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(48,47,56,0.9);
    z-index: 2;
    display: none;
    justify-content: center;
    align-items: center;
    &>div{
        width: 41%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        &>svg {
            transform: rotate(180deg);
        }
        
        &>p {
            color: white;
            margin: 20px 0 30px 0;
        }
        
        &>button {
            background: transparent;
            border: 1px solid white;
            border-radius: 26px;
            padding: 16px 35px;
            color: white;
            width: max-content;
        }
    }
    @media (max-width: 500px){
        display: ${props => props.zenModeTutorial ? "flex" : "none"};
    }
`

//component
const LearningMode = (props) => {
    const { t } = useTranslation();

    const { setBodyUnscrollable } = useContext(GlobalContext);

    //property to change when collapsing ZenMode on mount after timeout
    const [collapse, setCollapse] = useState(false);
    //value for showing or hiding myPath content in Zen Mode
    const [myPathActive, setMyPathActive] = useState(false);
    //value for showing the tutorial on Zen Mode
    const [zenModeTutorial, setZenModeTutorial] = useState();

    //keep current course in this hook
    const [course, setCourse] = useState({});

    //keep all lessons and current lesson's index number in this hook array for the LessonControler component
    const [allLessons, setAllLessons] = useState([]);
    const [currentLessonIndex, setCurrentLessonIndex] = useState(null);

    const [currentLesson, setCurrentLesson] = useState();

    const [allQuestions, setAllQuestions] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [unansweredQuestions, setUnansweredQuestions] = useState([]);

    const [allCodeChallenges, setAllCodeChallenges] = useState([]);
    const [solvedCodeChallenges, setSolvedCodeChallenges] = useState([]);

    //display value for PopupAlert component
    const [popupAlert, setPopupAlert] = useState(false);

    //decides whether to show the section complete screen after each lesson is completed
    const [sectionComplete, setSectionComplete] = useState(false);
    const [nextSectionId, setNextSectionId] = useState(false);

    const isMounted = useRef(false);

    const location = useLocation();

    //get course from backend and keep it in state
    useEffect(() => {
        API.axios.get(API.createApiRoute("getCourse", { COURSE_ID: props.courseId }))
            .then((response) => setCourse(response.data.data))
            .catch((error) => console.log(error));
        if (!localStorage.getItem("showZenModeTutorial")) {
            setZenModeTutorial(true);
        }
    }, [props.courseId])

    useEffect(() => {
        if (isMounted.current) {
            //iterrate through section and extract all lessons
            let allLessons = [];
            course.sections.forEach(section => {
                allLessons.push(...section.lessons)
            })
            setAllLessons(allLessons);
        } else {
            isMounted.current = true;
        }
    }, [course]);

    //whenever allLessons change, find the current one (according to the props.lessonId value)
    useEffect(() => {
        if (allLessons && allLessons.length) {
            allLessons.forEach((lesson, index) => {
                if (parseInt(lesson.id) === parseInt(props.lessonId)) {
                    setCurrentLessonIndex(index)
                    setCurrentLesson(lesson)
                }
            })
        }
    }, [allLessons, props.lessonId])

    //itterate trough questions and set array of questions ids inside allQuestions hook
    //also itterate through codeChallenges and set array of codeChallenge ids inside allCodeChallenges
    useEffect(() => {
        let questionIds = [];
        let alreadyAnsweredQuestions = answeredQuestions;//[]
        let codeChallengeIds = [];
        let alreadySolvedCodeChallenges = [];
        setUnansweredQuestions([]);

        if (currentLesson && currentLesson.materials) {
            currentLesson.materials.forEach((material, index) => {
                if (material.type === 'quiz') {
                    material.quiz.questions.forEach((question, i) => {
                        questionIds.push(question.id);
                        if (question.answer_info) {
                            if (!isQuestionAlreadyAnswered(question.id)) {
                                alreadyAnsweredQuestions.push({
                                    question_id: question.id,
                                    answer_id: question.answer_info.student_answer,
                                    correct_answer_id: question.answer_info.correct_answer,
                                    explanation: question.answer_info.explanation
                                });
                            }
                        }
                    })
                }
                if (material.type === 'code-challenge') {
                    codeChallengeIds.push(material.code_challenge.id);
                    if (material.code_challenge.solution_info) {
                        alreadySolvedCodeChallenges.push(material.code_challenge.id);
                    }
                }
            })

        }
        setAnsweredQuestions(alreadyAnsweredQuestions)
        setAllQuestions(questionIds);
        setSolvedCodeChallenges(alreadySolvedCodeChallenges);
        setAllCodeChallenges(codeChallengeIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLesson])

    //Timeout to collapse ZenMode 2.5s after mount
    useEffect(() => {
        const timer = setTimeout(() => {
            setCollapse(true);
        }, 1500);
        return () => clearTimeout(timer);
    }, [])

    //whenever location.pathname changes check if sectionComplete is set to true, 
    //if it is and SectionComplete is shown, set it to false and don't show SectionComplete anymore
    useEffect(() => {
        sectionComplete && setSectionComplete(false)
        !collapse && setCollapse(true)
        // eslint-disable-next-line
    }, [location.pathname])

    useEffect(() => {
        setBodyUnscrollable(sectionComplete)
    }, [sectionComplete, setBodyUnscrollable])

    useEffect(() => {
        setBodyUnscrollable(myPathActive)
    }, [myPathActive, setBodyUnscrollable])

    // function that receives a question_id and checks if it already belongs in the array of answered questions
    // since answered questions are stored in an array of objects with format  [{question_id, answer_id, correct_answer_id, explanation}, ...]
    // this function first extract only the question_ids in separate array and then checks if the provided parameter value is present
    const isQuestionAlreadyAnswered = (question_id) => {
        return [...new Set(answeredQuestions.map(question => question.question_id))].includes(question_id);
    }

    //this function is passed to the code editor and it updates the solution info 
    //every time the student checks his code. This way, we can keep the correct
    //editor value without the need to make separate ajax call on each lesson
    const updateSolutionInfoForCodeChallenge = (codeChallengeId, solutionInfo) => {
        course.sections.forEach(section => {
            section.lessons.forEach(lesson => {
                lesson.materials.forEach((material, i) => {
                    if (material.type === 'code-challenge') {
                        if (material.code_challenge.id === codeChallengeId) {
                            material.code_challenge.solution_info = solutionInfo
                        }
                    }
                })
            })
        })
    }

    //When the user clicks back
    //If he is on the first lesson (index = 0), take him to the course index page
    //Otherwise show him the previous lesson from the allLessons array. Don't make another Ajax call,
    //since you already have all the lessons
    const backAction = () => {
        let url;
        //if there is no previous lessons, go to course index page and
        //if there is a learning path id in the location pathname, include it in the locaton pathname to go back to the course index page
        //if not don't include it
        if (props.learningPathId) {
            url = API.createRoute("getCourseWithPath", { LEARNINGPATH_ID: props.learningPathId, COURSE_ID: props.courseId });
        } else {
            url = API.createRoute("getCourse", { COURSE_ID: props.courseId });
        }
        let previousLessonIndex = currentLessonIndex - 1;
        if (previousLessonIndex >= 0) {
            setCurrentLessonIndex(previousLessonIndex);
            setCurrentLesson(allLessons[previousLessonIndex])
            //if there is a previous lesson check to see
            //if there is a learning path id in the location pathname, include it in the locaton pathname to go to previous lesson
            //if not don't include it
            if (props.learningPathId) {
                url = API.createRoute("learningModeWithPath", { LEARNINGPATH_ID: props.learningPathId, COURSE_ID: props.courseId, LESSON_ID: parseInt(allLessons[previousLessonIndex].id) });
            } else {
                url = API.createRoute("learningMode", { COURSE_ID: props.courseId, LESSON_ID: parseInt(allLessons[previousLessonIndex].id) });
            }
        }
        navigate(url);
    }

    //when the user clicks next

    //if it does, check if are all answered
    //if they are check if it is the last questions
    const nextAction = () => {
        let url;
        //check if he has all questions answered
        //If the number of questions is equal to number of answered questions
        //answeredQuestions is array in state and eachtime the user answers a questions
        //the question id is pushed in the answredQuestions array
        //new Set is used to eliminate possible duplicates from answeredQuestsion array
        if (allQuestions.length === 0 || allQuestions.every(questionId => isQuestionAlreadyAnswered(questionId))) {
            //check if it's the last lesson, if so, set url to well done course page with courseId
            let nextLessonIndex = currentLessonIndex + 1;
            if (nextLessonIndex === allLessons.length) {
                //when there are no more lessons left check if there is learning path id in the location path name and include it accordingly when navigating to well done course page
                if (props.learningPathId) {
                    url = API.createRoute("learningModeWellDoneWithLearningPath", { LEARNINGPATH_ID: props.learningPathId, COURSE_ID: props.courseId });
                } else {
                    url = API.createRoute("learningModeWellDone", { COURSE_ID: props.courseId });
                }
            } else {
                //if he is not on the last lesson and not on a section well done page, 
                //before showing the next lesson check if the current lesson is last lesson 
                //from its section. If it is, show a 'section well done' page first
                if (!sectionComplete && allLessons[nextLessonIndex].section_id && allLessons[nextLessonIndex].section_id !== allLessons[currentLessonIndex].section_id) {
                    setNextSectionId(allLessons[nextLessonIndex].section_id)
                    setSectionComplete(true);
                } else {
                    //if he is one the section well done page and the timer timeouts, clear the setSectionComplete flag
                    if (sectionComplete) {
                        setSectionComplete(false);
                    }
                    //if he is not on the last lesson from course or section, show the next lesson from allLessons array
                    //don't make antoher ajax call, you already have the lessons in the allLessons array
                    setCurrentLessonIndex(nextLessonIndex);
                    setCurrentLesson(allLessons[nextLessonIndex])
                    //to go to next lesson check if there is learning path id in the location path name and include it accordingly
                    if (props.learningPathId) {
                        url = API.createRoute("learningModeWithPath", { LEARNINGPATH_ID: props.learningPathId, COURSE_ID: props.courseId, LESSON_ID: allLessons[nextLessonIndex].id });
                    } else {
                        url = API.createRoute("learningMode", { COURSE_ID: props.courseId, LESSON_ID: parseInt(allLessons[nextLessonIndex].id) });
                    }
                }
            }

            //if lesson has code challenges and not all are solved, we don't need to update progress
            if (solvedCodeChallenges.length !== allCodeChallenges.length) {
                navigate(url);
            } else { //else if it doesn't have code challenges or all are solved, we update progress
                API.axios.get(API.createRoute("completeLesson", { LESSON_ID: props.lessonId }))
                    .then(res => navigate(url))
                    .catch(error => {
                        navigate(url);
                    }
                    )
            }
        } else {
            //if he hasn't answered all questions, setUnansweredQuestions to an array of ids of all unanswered questions
            //also setPopupAlert to true in order to display the popup alert
            let unansweredQuestions = [];
            allQuestions.forEach((question_id, index) => {
                if (!isQuestionAlreadyAnswered(question_id)) {
                    unansweredQuestions.push(question_id);
                }
                setUnansweredQuestions(unansweredQuestions);
                setPopupAlert(true)
            })
        }
    }

    const addAnsweredQuestion = (question_id, answer_id, correct_answer_id, explanation) => {
        setAnsweredQuestions(answeredQuestions => { return [...answeredQuestions, { question_id, answer_id, correct_answer_id, explanation }] })
    }

    const addSolvedCodeChallenge = (code_challenge_id) => {
        setSolvedCodeChallenges(solvedCodeChallenges => { return [...solvedCodeChallenges, code_challenge_id] })
    }

    const closeZenMode = () => {
        setZenModeTutorial(false);
        localStorage.setItem("showZenModeTutorial", "true");
    }

    return (
        currentLesson?.id
            ? <LearningModePageWrapper>
                {sectionComplete
                    && <SectionComplete
                        course={course}
                        nextSectionId={nextSectionId}
                        nextAction={nextAction}
                        sectionComplete={sectionComplete}
                        learningPathId={props.learningPathId && props.learningPathId}
                    />
                }
                <ZenModeTutorial zenModeTutorial={zenModeTutorial}>
                    <div>
                        <Icon icon="icnArrowLong" pathFill={"white"} />
                        <Text13>Hey, we care for your experience and focus during the learning process. To help you with that, the navigation is in a state of Zen Mode and will be hidden right here. To access it, just tap on it.</Text13>
                        <button onClick={() => closeZenMode()}>
                            GOT IT
                        </button>
                    </div>
                </ZenModeTutorial>
                <ZenMode currentLessonIndex={currentLessonIndex} lessons={allLessons.length} collapse={collapse} setCollapse={setCollapse} course={course} lessonId={parseInt(props.lessonId)} myPathActive={myPathActive} setMyPathActive={setMyPathActive} learningPathId={props.learningPathId && props.learningPathId} />
                <LearningModeTitleWrapper collapse={collapse}>
                    {currentLesson && <Heading60>{currentLesson.title}</Heading60>}
                    {currentLesson && <Heading28>{currentLesson.subtitle}</Heading28>}
                </LearningModeTitleWrapper>
                {/* map lesson's materials and if article return lesson if quiz return quiz */}
                {currentLesson && currentLesson.materials.map((material, i) => {
                    if (material.type === 'article') {

                        return (
                            <LessonArticleContainer className={material.article.is_code_challenge && "code-challenge-text-container"} key={i} collapse={collapse}>
                                {material.article.is_code_challenge
                                    && <div className="code-challenge-title">
                                        <Icon icon="icnCodeChallenge" />
                                        <Text13>{t("learning_mode.article.code_challenge")}</Text13>
                                    </div>
                                }
                                <LessonArticle collapse={collapse} lessonContent={material.article.content} studyField={course?.category?.study_field?.code} />
                            </LessonArticleContainer>
                        )

                    }
                    if (material.type === 'quiz') {
                        return (
                            <LessonQuizContainer key={i} collapse={collapse}>

                                <LessonQuiz
                                    key={i}
                                    quizContent={material.quiz}
                                    collapse={collapse}
                                    addAnsweredQuestion={addAnsweredQuestion}
                                    unansweredQuestions={unansweredQuestions}
                                    answeredQuestions={answeredQuestions}
                                />
                            </LessonQuizContainer>
                        )

                    }
                    if (material.type === "code-challenge") {
                        let technologies = [];
                        material.code_challenge.html_starter && technologies.push({ name: "index.html", language: "html", starter: material.code_challenge.html_starter, solution_info: material.code_challenge.solution_info })
                        material.code_challenge.css_starter && technologies.push({ name: "style.css", language: "css", starter: material.code_challenge.css_starter, solution_info: material.code_challenge.solution_info })
                        material.code_challenge.javascript_starter && technologies.push({ name: "main.js", language: "javascript", starter: material.code_challenge.javascript_starter, solution_info: material.code_challenge.solution_info })
                        return (

                            <LessonEditorContainer key={i}>
                                <CodeEditor
                                    codeChallengeId={material.code_challenge.id}
                                    technologies={technologies} id={material.code_challenge.id}
                                    hint={material.code_challenge.hint}
                                    addSolvedCodeChallenge={(code_challenge_id) => addSolvedCodeChallenge(code_challenge_id)}
                                    updateSolutionInfoForCodeChallenge={updateSolutionInfoForCodeChallenge}
                                />
                            </LessonEditorContainer>
                        )
                    }
                    return null
                }
                )}
                <LessonController
                    allLessons={allLessons}
                    currentLessonIndex={currentLessonIndex}
                    nextAction={nextAction}
                    backAction={backAction}
                    moveElements={collapse}
                    popupAlert={popupAlert}
                    setPopupAlert={() => setPopupAlert()}
                />
                <DimmedDiv myPathActive={myPathActive} />
            </LearningModePageWrapper>
            : <Loader />
    );
}

export default LearningMode;
