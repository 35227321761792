import React from 'react';

//npm imports
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

//component imports
import { Heading20 } from '../Text/Text';

//styled-components
const AddLearningPathCardContainer = styled.div`
    width: 100%;
    max-width: 850px;
    height: 199px;
    border: 1px solid ${props => props.theme.gray7};
    border-radius: 10px;
    display: flex;
    background-color: white;
    &:hover{
        cursor: pointer;
        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.1);
    }
    .add-more-content-container{
        display: flex;
        .plus {
            width: 46px;
            height: 46px;
            margin: auto 69px;
            background-color: ${props => props.theme.blue};
            clip-path: polygon(0 49%, 49% 49%, 49% 0, 51% 0, 51% 49%, 100% 49%, 100% 51%, 51% 51%, 51% 100%, 49% 100%, 49% 51%, 0 51%);
        }
        .add-learning-path-title {
            margin: auto 0px;
            h5{
                color: ${props => props.theme.gray3};
                text-align: center;
            }
        }
    }
    @media (max-width: 500px){
        height: 103px;
        overflow: unset;
        .add-more-content-container{
            .plus{
                margin: auto 25px;
            }
            .add-learning-path-title{
                h5{
                    font-size: 14px;
                }
            }
        }
    }
    `

//component
const AddLearningPathCard = (props) => {
    const { t } = useTranslation();
    return (
        <AddLearningPathCardContainer>
            <div className="add-more-content-container">
                <div className="plus"></div>
                <div className="add-learning-path-title">
                    <Heading20>{t("my_courses.navigation.add_learning_path")}</Heading20>
                </div>
            </div>
        </AddLearningPathCardContainer>

    );
}

export default AddLearningPathCard;