import styled from 'styled-components';

const PageWrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default PageWrapper;