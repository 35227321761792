import React from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import { Text10, Text13 } from '../Text/Text';

//styled-components
const TestimonialCardWrapper = styled.div`
    width: 100%;
    height: 341px;
    figure{
        width: 100%;
        height: 58%;
        background-color: red;
    }
    .card-content-container{
        width: 100%;
        position: relative;
        padding: 15px;
        .dimmed-div{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: ${props => props.theme.white};
            opacity: 0.8;
            z-index: 0;
        }
        &>p:first-of-type{
            margin-bottom: 15px;
        }
        p{
            position: relative;
            z-index: 1;
        }
    }
`

//component
const TestimonialCard = ({ cardImage, cardTitle, cardDescription }) => {
    return (
        <TestimonialCardWrapper>
            <figure></figure>
            <div className="card-content-container">
                <div className="dimmed-div"></div>
                <Text13 fontWeight="800">{cardTitle}</Text13>
                <Text10>{cardDescription}</Text10>
            </div>
        </TestimonialCardWrapper>
    );
}

export default TestimonialCard;