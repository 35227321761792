import React from 'react';

//imports
import styled, { keyframes, css, withTheme } from 'styled-components';


// Imported components
import { Text10, Caption9, Heading20, CardCaption11 } from '../Text/Text';
import ButtonSmall from '../ButtonSmall/ButtonSmall';
import Skeleton from '../Skeleton/Skeleton';
import Icon from '../Icon/Icon';

//animation
const cardCtaInitial = keyframes`
    from{
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(-110%, 0%);
    }
`

const cardCta = keyframes`
    from{
        transform: translate(-110%, 0%);
    }
    to{
        transform: translate(0%, 0%);
    }
`

const cardInfoInitial = keyframes`
    from{
        transform: translate(110%, 0%);
    }
    to{
        transform: translate(0%, 0%);
    }
`

const cardInfo = keyframes`
    from{
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(110%, 0%);
    }
`

// Styled components
const LearningPathCardWrapper = styled.article`
    min-height: ${props => props.cardMinHeight ? props.cardMinHeight + "px" : "unset"};
    background: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.gray7};
    display: flex;
    border-radius: 7px;
    :hover {
        box-shadow: ${props => props.theme.dropShadow1};
    }
    p, h5{
        width: 100%;
        height: 15px;
    }
    @media (max-width: 768px){
        min-height: unset;
        height: 142px;
        h5{
            font-size: 16px;
        }
    }

    :hover {
        cursor: ${props => props.cardCommingSoon && props.cardCommingSoon > 0 ? "not-allowed" : "pointer"};
        ${props => props.cardTitle && css`
            .card-cta{
                animation: ${cardCta} 0.4s linear forwards;
                animation-timing-function: ${props => props.theme.expo};
                opacity: 1;
            }

            .card-info{
                animation: ${cardInfo} 0.4s linear forwards;
                animation-timing-function: ${props => props.theme.expo};
            }
        `}
    }
`;

const CardBadge = styled.div`
    width: 24px;
    height: 24px;
    background: ${props => props.theme.lightBlack};
`;

const Image = styled.figure`
    width: ${props => props.cardImgWidth ? props.cardImgWidth + "px" : "46%"};
    height: 241px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${props => props.theme.gray7};
    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: center;
        margin-bottom: -5px;
    }
    span{
        min-width: 241px;
    }
    @media (max-width: 500px){
        height: 141px;
    }
`;

const Content = styled.aside`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 38px 30px 0 20px;
    .card-category {
        margin-bottom: ${props => props.theme.spacer * 3 + 'px'};
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;
        letter-spacing: 1px;
        color: ${props => props.theme.gray6};
    }
    p{
        display: flex;
        align-items: center;
    }
    @media (max-width: 768px){
        padding: ${props => props.theme.spacer * 4 + 'px'};
    }

    @media (max-width: 500px){
        padding: ${props => props.theme.spacer * 2.5 + 'px ' + props.theme.spacer * 2.5 + 'px  0px ' + props.theme.spacer * 2.5 + 'px '} ;
        h5{
            font-size: 14px;
            line-height: 17px;
        }
        .card-category {
            display: none;
            margin-bottom: 8px;
            font-size: 9px;
            letter-spacing: 0.5px;
        }
    }
`;

const ContentTop = styled.div`
    flex: 1;
`;

const ProgressWrapper = styled.div`
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: ${props => props.theme.gray2};
`;

const Progress = styled.div`
    height: inherit;
    background: black;
    width: ${props => props.width ? props.width + '%' : "0%"};
`;


const CardFooter = styled.div`
    width: 100%;
    height: 60px;
    position: relative;
    overflow: hidden;
    .footer-item {
        position: absolute;
        left: 0;
    }
    @media (max-width: 500px){
        height: 38px;
    }
    `

const CardInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;  
    height: 60px;
    bottom: 0;
    animation: ${cardInfoInitial} + " 0.3s linear forwards";
    animation-duration-function: ${props => props.theme.expo};
    
    p{
        svg{

            margin-right: ${props => props.theme.spacer}px;
        }
        color: ${props => props.theme.gray6};
    }
    @media (max-width: 500px){
        height: 38px;
        
        p{
            font-size: 6px;
        }
    }
    `

const CardCTA = styled.div`
    width: 100%;
    height: 60px;
    border-top: 1px solid ${props => props.theme.danger_select};
    top: 0;
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: ${cardCtaInitial} 0.3s linear forwards;
    animation-duration-function: ${props => props.theme.expo};
    ${CardCaption11}{
        font-weight: 500;
    }
    p{
        width: 70%;
        font-size: 13px;
        letter-spacing: 1px;
    }
    @media (max-width: 500px){
        height: 38px;
        
        p{
            font-size: 9px;
        }
    }
`

// ProgressComponent
const LearningPathProgress = ({ width }) => (
    <ProgressWrapper>
        <Progress width={width} />
    </ProgressWrapper>
);

// Component
const LearningPathCard = ({ cardImage, cardTitle, cardCategory, cardCourseNumber, theme, cardBadge, cardImgWidth, cardMinHeight, cardProgress, isMobile, cardCommingSoon }) => {
    return (
        <LearningPathCardWrapper cardTitle={cardTitle} cardImgWidth={cardImgWidth} cardMinHeight={cardMinHeight} cardCommingSoon={cardCommingSoon}>
            <Image>
                {
                    cardBadge ? <CardBadge>B</CardBadge> : null
                }
                {cardImage ? <img src={cardImage} alt={cardTitle} /> : <Skeleton />}
                <LearningPathProgress width={cardProgress} />
            </Image>
            <Content>
                <ContentTop>
                    <Text10 className="card-category">{cardCategory && cardCategory}</Text10>
                    <Heading20>{cardTitle ? cardTitle : <Skeleton />}</Heading20>
                </ContentTop>
                <CardFooter>
                    {cardTitle
                        && <CardCTA className="footer-item card-cta">
                            <CardCaption11>
                                {cardCommingSoon && cardCommingSoon > 0 ? "НАСКОРО" : cardProgress ? "ПРОДОЛЖИ" : "ЗАПОЧНИ"}
                            </CardCaption11>
                            <ButtonSmall width={isMobile ? 23 : 40} height={isMobile ? 23 : 40} />
                        </CardCTA>
                    }
                    <CardInfo className="footer-item card-info">
                        <div>

                            <Caption9>
                                {!cardTitle ? <Skeleton /> : cardCourseNumber && <><Icon icon="icnCourse" width="9" height="11" pathFill={theme.gray6} /> {cardCourseNumber + " курсеви"}</>}
                            </Caption9>

                        </div>
                        {/* {cardStudentsNumber && !isNaN(cardStudentsNumber) ?
                            <div>
                                <Caption9>
                                    <Icon icon={"icnUser"} circle={true} />
                                    {cardStudentsNumber + " студенти"}
                                </Caption9>
                            </div>
                            : <div></div>
                        } */}
                    </CardInfo>
                </CardFooter>
            </Content>
        </LearningPathCardWrapper>
    )
}

export default withTheme(LearningPathCard);