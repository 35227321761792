import React from 'react';
import styled from 'styled-components';


// Styled Components
const InputErrorContainer = styled.div`
    display: ${props => props.error ? "block" : "none"}
`

const InputError = styled.p`
    width: 100%;
    color: #F43C28;
    font-size: 12px;
`

// Component
const InputFieldError = ({ error }) => {


    return (
        <InputErrorContainer error={error}>
            <InputError>{error}</InputError>
        </InputErrorContainer>
    )
}

export default InputFieldError;