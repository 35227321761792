import React, { useState, useEffect, useRef } from 'react';

//npm imports
import styled, { keyframes, withTheme } from 'styled-components';
import image from '../../Static/course-image.svg';

//component imports
import { CardMoreOptions } from '../../Consts/PopoverMoreOptions';
import { Text10, Heading20, CardCaption11 } from '../Text/Text';
import GradientProgress from '../GradientProgress/GradientProgress';
import Icon from '../Icon/Icon';
import ButtonSmall from '../ButtonSmall/ButtonSmall';
import Popover from '../Popover/Popover';

//custom hooks
import useResetCourseProgress from '../../Hooks/useResetProgress/useResetProgress';
import { useTranslation } from 'react-i18next';
import Skeleton from '../Skeleton/Skeleton';


//animations
const pointsSlideIn = keyframes`
0%{
    transform: translate(-150%, 0%);
    opacity: 1;
}
100%{
    transform: translate(32%, 0%);
    opacity: 1;
}
`

const pointsSlideOut = keyframes`
0%{
    transform: translate(-32%, 0%);
    opacity: 1;
}
51%{
    opacity: 1;
}
52%{
    transform: translate(550%, 0%);
    opacity: 0;
}
100%{
    transform: translate(-150%, 0%);
    opacity: 0;
}
`

const ctaSlideIn = keyframes`
    0%{
        transform: translate(-150%, 0%);
        opacity: 1;
    }
    100%{
        transform: translate(-10%, 0%);
        opacity: 1;
    }
`

const ctaSlideOut = keyframes`
    0%{
        transform: translate(-0%, 0%);
        opacity: 1;
    }
    51%{
        opacity: 1;
    }
    52%{
        transform: translate(510%, 0%);
        opacity: 0;
    }
    100%{
        transform: translate(-110%, 0%);
        opacity: 0;
    }
`


//styled-components
const MyCourseCardContainer = styled.div`
    width: 100%;
    border: 1px solid ${props => props.theme.danger_select};
    border-radius: 10px;
    background-color: white;
    padding: 16px 20px 20px 20px;
    &>div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .my-course-image-and-more-options-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .my-course-image-container {
            width: 100px;
            height: 100px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${props => props.theme.gray7};
            img{
                height: 100%;
                margin: auto auto;
            }
            @media (max-width: 500px){
                display: none;
            }
        }
        .more-options-container {
            width: 10%;
            justify-content: center;
            padding: 2px 0px 16px 10px;
            &>div{
                width: 100%;
                margin: 0 auto;
                position: relative;
                &:hover{
                    .popover-container{
                        height: ${props => props.popoverScrollHeight}px;
                    }
                }
                &>div{
                    width: 5px;
                    height: 5px;
                    margin: 1.5px 0px;
                    border-radius: 50%;
                    background-color: ${props => props.theme.gray4};
                }
            }
            @media (max-width: 500px){
                padding: 0px 0px 12px 10px;
            }
        }
        @media (max-width: 500px){
            justify-content: flex-end;
        }
    }

    
    .my-course-content-container {
        flex-direction: column;
        align-items: center;
        @media (max-width: 500px){
            max-height: 122px;
        }
    }
    
    .my-course-title-container {
        min-height: 72px;
        margin-top: 40px;
        width: 100%;
        ${Heading20}{
            min-height: 72px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
        }
        @media (max-width: 1141px){
            margin-top: 13px;
        }
        @media (max-width: 500px){
            margin-top: 0px;
            h5{
                min-height: 34px;
                font-size: 14px;
                line-height: 17px;
                padding: 0px;
            }
        }
    }
    .my-course-progress-container{
        width: 100%;
        &>div{
            width: 100%;
            .course-progress-indicator{
                width: 48px;
                height: 26px;
                margin-top: -5px;
                h4{
                    font-size: 14px;
                }
            }
        }
    }

    
    .continue-learning-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        overflow: hidden;
        p{
            margin-right: 15px;
        }
        &>.points-container{
            display: flex;
            align-items: center;
            animation: ${pointsSlideIn} 0.3s linear forwards;
            animation-timing-function: ${props => props.theme.expo};
            &>p{
                margin-left: 3px;
            }
        }
        &>.continue-container{
            display: flex;
            align-items: center;
            animation: ${ctaSlideOut} 0.5s linear forwards;
            animation-timing-function: ${props => props.theme.expo};
        }
        @media (max-width: 1141px){
            margin-top: 0px;
        }
        @media (max-width: 500px){
            display: none;
        }
    }


    .popover-container{
        width: 136px;
        height: 0px;
        overflow: hidden;
        position: absolute;
        top: 20px;
        left: -100px;
        transition: height .2s;
        z-index: 9999999999999;
        @media (max-width: 500px){
            left: unset;
            right: -38px;
            top: 17px;
        }
    }

    .add-more-content-container {
        flex-direction: column;
        justify-content: center;
        .plus {
            width: 46px;
            height: 46px;
            background-color: ${props => props.theme.blue};
            clip-path: polygon(0 49%, 49% 49%, 49% 0, 51% 0, 51% 49%, 100% 49%, 100% 51%, 51% 51%, 51% 100%, 49% 100%, 49% 51%, 0 51%);
        }
        .add-course-title {
            padding: 0 21px;
            h5{
                color: ${props => props.theme.gray3};
                text-align: center;
                padding-bottom: 30px;
                margin-top: 65px;
            }
        }
        @media (max-width: 500px){
            padding: 21px 0 0 0;
            .plus{
                width: 27px;
                height: 27px;
                background-color: ${props => props.theme.black};
            }
            .add-course-title{
                h5{
                    font-size: 14px;
                    line-height: 17px;
                    margin-top: 16px;
                    padding-bottom: 0px;
                }
            }
        }
    }

    &.no-course-content, .add-more-content-container {
        height: 100%;
    }

    &:hover{
        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        .continue-learning-container > .continue-container{
            animation: ${ctaSlideIn} .3s linear forwards;
            animation-timing-function: ${props => props.theme.expo};
        }
        .continue-learning-container > .points-container{
            animation: ${pointsSlideOut} .5s linear forwards;
            animation-timing-function: ${props => props.theme.expo};
        }
    }
    @media (max-width: 500px){
        padding: 11px 11px 29px 11px;
    }
`

const OptionContainer = styled.div`
    width: 80%;
    padding: 13px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>p{
        color: ${props => props.theme.gray3};
    }

    &>div{
        width: 105px;
        border: 1px solid ${props => props.theme.gray3};
    }

    &:hover{
        cursor: pointer;
        p{
            color: ${props => props.theme.blue};
        }
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.gray3};
    }
`

//option component
export const Option = withTheme((props) => {
    //keep the hover state in this hook, needed in order to change fill of Icon
    const [hover, setHover] = useState(false);

    return (
        <OptionContainer onClick={props.onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Icon icon={props.icon} fill={hover ? props.theme.blue : props.theme.gray3} width={props.width} height={props.height} />
            <Text10>{props.text}</Text10>
        </OptionContainer>
    )
})

//component
const MyCourseCard = (props) => {
    const { t } = useTranslation();
    const popoverRef = useRef();

    //keep inital scroll height of popovers in these hooks
    const [popoverScrollHeight, setPopoverScrollHeight] = useState();

    const [color, setColor] = useState();

    const [courseProgress, resetCourseProgress] = useResetCourseProgress();

    //on component mount, setColor to appropriate theme color for given study field
    useEffect(() => {
        let name = props.course && props.course.category?.study_field?.name?.replace(" ", "");
        setColor(props.theme[name]);
        popoverRef.current && setPopoverScrollHeight(parseInt(popoverRef.current.getBoundingClientRect().height) + 15);
    }, [props.course, props.theme])
    //set progres to the progress of course from database
    useEffect(() => {
        props.course && resetCourseProgress(null, props.course.progress);
    }, [props.course, resetCourseProgress])

    return (
        <React.Fragment>
            {props.course
                ? <MyCourseCardContainer popoverScrollHeight={popoverScrollHeight}>
                    <div className="my-course-image-and-more-options-container">
                        <div className="my-course-image-container">
                            {props.course?.image ? <img src={props.course ? props.course.image : image} alt="course" /> : <Skeleton />}
                        </div>
                        {props.course.title &&
                            <div className="more-options-container">
                                <div onClick={(e) => e.preventDefault()}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <section className="popover-container">
                                        <Popover ref={popoverRef} pointerPosition="top" card="learning-path-card">
                                            {CardMoreOptions.map((option, index) => {
                                                return (
                                                    <Option onClick={() => index === 0 && resetCourseProgress(props.course.id)} key={index} text={option.text} icon={option.icon} width={option.width} height={option.height} />
                                                )
                                            })}
                                        </Popover>
                                    </section>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="my-course-content-container">
                        <div className="my-course-title-container">
                            <Heading20>{props.course?.title || <Skeleton />}</Heading20>
                        </div>
                        <div className="my-course-progress-container">
                            {courseProgress ? <GradientProgress progress={courseProgress} color={color} /> : <div style={{margin: "15px 0 7px 0"}}><Skeleton /></div>}
                        </div>
                        <div className="continue-learning-container">
                            <div className="points-container">
                                {props.course?.points ? <><Icon icon="icnPoints" width={9} height={9} pathStrokeWidth={1.25} /><Text10>{props.course.points}</Text10></>
                                    : <Skeleton />
                                }
                            </div>
                            {props.course.title
                                ? <div className="continue-container">
                                    <CardCaption11>ПРОДОЛЖИ</CardCaption11>
                                    <ButtonSmall width={34} height={34} />
                                </div>
                                : <Skeleton />}

                        </div>
                    </div>
                </MyCourseCardContainer>
                :
                <MyCourseCardContainer className="no-course-content">
                    <div className="add-more-content-container">
                        <div className="plus"></div>
                        <div className="add-course-title">
                            <Heading20>{t("my_courses.navigation.add_course")}</Heading20>
                        </div>
                    </div>
                </MyCourseCardContainer>

            }
        </React.Fragment>
    );
}

export default withTheme(MyCourseCard);