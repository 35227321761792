import React, { useRef, useState, useEffect } from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import { Heading20, Heading35, Text13 } from '../Text/Text';

//other imports

//styled components
const QAAccordionWrapper = styled.div`
    width: 100%;
    align-items: center;
    margin: 16px 0;
    .icon-container{
        h4{
            margin: auto 0;
        }
    }
    .accordion-question{
        width: 100%;
        padding: 15px 53px;
        background-color: ${props => props.theme.white};
        border-radius: 10px;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        .icon-container{
            h4{
                color: ${props => props.theme.gray8};
            }
        }
        .question-container{
            margin-left: 40px;
        }
    }
    .accordion-answer{
        width: 100%;
        padding: ${props => props.contentMaxHeight ? "42px 28px 60px 53px" : "0px 28px 0px 53px"};
        margin-top: -10px;
        background-color: ${props => props.theme.gray9};
        border-radius: 10px;
        z-index: 0;
        position: relative;
        display: flex;
        max-height: ${props => props.contentMaxHeight ? props.contentMaxHeight : 0}px!important;
        transition: max-height .2s linear, padding .2s linear;
        overflow: hidden;
        .icon-container{
            h4{
                color: ${props => props.theme.blue};
            }
        }
        .answer-container{
            margin-left: 40px;
        }
    }
    @media (max-width: 500px){
        .accordion-question{
            .icon-container{
                
            }
            .question-container{
                h5{
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
        .accordion-answer{
            .icon-container{
                
            }
            .answer-container{
                p{
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
`

//component
const QAAccordion = ({ question, answer, showContent, id, onClick }) => {
    const contentRef = useRef();
    const [isContentShown, setIsContentShown] = useState(true);
    const [contentMaxHeight, setContentMaxHeight] = useState();

    useEffect(() => {
        isContentShown ? setContentMaxHeight(contentRef.current.scrollHeight + 102) : setContentMaxHeight(0);
    }, [isContentShown])

    useEffect(() => {
        showContent === id ? setIsContentShown(true) : setIsContentShown(false);
    }, [showContent, id])

    return (
        <QAAccordionWrapper onClick={() => showContent !== id ? onClick(id) : isContentShown ? [setContentMaxHeight(0), setIsContentShown(false), onClick(id)] : [setContentMaxHeight(contentRef.current.scrollHeight + 102), onClick(id)]} contentMaxHeight={contentMaxHeight}>
            <div className="accordion-question">
                <div className="icon-container">
                    <Heading35 fontFamily="Ubuntu" fontWeight={700}>Q</Heading35>
                </div>
                <div className="question-container">
                    <Heading20 fontFamily="Ubuntu" fontWeight={700}>{question}</Heading20>
                </div>
            </div>
            <div ref={contentRef} className="accordion-answer">
                <div className="icon-container">
                    <Heading35 fontFamily="Ubuntu" fontWeight={700}>A</Heading35>
                </div>
                <div className="answer-container">
                    <Text13 fontFamily="Ubuntu" fontWeight={400}>{answer}</Text13>
                </div>
            </div>
        </QAAccordionWrapper>
    );
}

export default QAAccordion;