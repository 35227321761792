import { useLocation } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

//npm imports
import styled, { withTheme } from 'styled-components';
import { API } from '../../Consts/Api';
import Icon from '../Icon/Icon';

//component imports
import { Text13 } from '../Text/Text';
import Wrapper from '../Wrapper/Wrapper';

//styled-components
const FooterSimpleWrapper = styled.div`
    width: 100%;
    padding: 57px 0;
    display: ${props => props.isComponentVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.darkBlack};
    ${Text13}{
        color: ${props => props.theme.danger_select};
    }
    &>div{
        flex-direction: row;
        justify-content: space-between;
        .brainster-original{
            
        }
        .follow-us{
            display:flex;
            align-items: center;
            svg{
                margin-left: 15px;
            }
        }
    }
    @media (max-width: 500px){
        height: unset;
        margin-top: ${process.env.REACT_APP_STATUS === "prelaunch" ? "60px" : "0px"};
        padding: 20px 0;
        margin-bottom: ${process.env.REACT_APP_STATUS === "prelaunch" ? "0px" : "60px"};
        &>div{
            flex-direction: column-reverse;
            align-items: center;
            .follow-us{
                margin-bottom: 15px;
            }
        }
    }
`

const socialMedia = [
    {
        icon: "icnFacebookCircled",
        link: API.externalLinks("brainsterNextFacebookPage")
    },
    {
        icon: "icnLinkedInCircled",
        link: API.externalLinks("brainsterNextLinkedInProfile")
    },
    {
        icon: "icnInstagramCircled",
        link: API.externalLinks("brainsterNextInstagramProfile")
    },
    // {
    //     icon: "icnYoutubeCircled",
    //     link: "https://www.youtube.com/brainsterco"
    // }
]

//component
const FooterSimple = (props) => {
    const { t } = useTranslation();
    const [isFooterVisible, setIsFooterVisible] = useState();
    const location = useLocation();
    useEffect(() => {
        props.except && setIsFooterVisible(!props.except.some(exceptRoute => (new RegExp(`^${exceptRoute}/?$`)).test(location.pathname)))
    }, [props.except, location.pathname]);
    return (
        <FooterSimpleWrapper isComponentVisible={isFooterVisible}>
            <Wrapper>

                <div className="brainster-original">
                    <Text13>{t("footer.simple.brainster_original")}</Text13>
                </div>
                <div className="follow-us">
                    <Text13>{t("footer.simple.follow_us")}</Text13>
                    {socialMedia.map((network, index) => {
                        return (
                            <a key={index} href={network.link} target="_blank" rel="noopener noreferrer" className="social-network-container">
                                <Icon icon={network.icon} fill={props.theme.danger_select} />
                            </a>
                        )
                    })}
                </div>
            </Wrapper>
        </FooterSimpleWrapper>

    );
}

export default withTheme(FooterSimple);