import React, { useState, useRef, useEffect, useCallback } from 'react';

//imports
import styled, { withTheme } from 'styled-components';
import { Text24, Caption12 } from '../Text/Text';
import Icon from '../Icon/Icon';
import AccordionContentItem from './Subcomponents/AccordionContentItem';
import { useTranslation } from 'react-i18next';

//styled-components
const AccordionWrapper = styled.div`
    width: 100%;
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    display: flex;
    flex-direction: column;    
`

const AccordionButton = styled.button`
    width: 94%;
    padding: ${props => props.theme.spacer * 6}px 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    :hover {
        cursor: pointer;
    }
`

export const AccordionTitleAndLessonInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    &>div{
        width: 80%;
        display: flex;
        ${Caption12}:first-child{
            align-self: flex-start;
            margin-right: ${props => props.theme.spacer * 2}px;
            font-weight: 600;
            line-height: ${props => props.theme.spacer * 6}px;
            font-size: ${props => props.theme.normalText};
        }
    }
    &>${Caption12}{
        &>span{
            margin-right: 5px;
            margin-left: 36px;
        }
    }
    ${Text24}:not(:first-child){
        width: 95%;
        text-align: left;
    }
    &>p{
        text-align: left;
        margin-top: ${props => props.theme.spacer * 2}px;
    }
}

    @media (max-width: 768px){
        flex-direction: column;
        align-items: flex-start;
        .lessons-and-quizes {
            margin-left: ${props => props.theme.spacer * 6}px;
            font-size: 11px;
        }
        .lesson-title {
            font-size: 14px;
        }
        &>div{
            width: 95%;
            ${Caption12}:first-child{
                line-height: 21px;
            }
            ${Text24}:not(:first-child){
                line-height: 21px;
                margin-right: 0;
                width: 85%;
            }
        }
        &>${Caption12}{
            margin-left: 0px!important;
        }
        &>p.lessons-and-quizes{
            margin-left: ${props => props.theme.spacer * 8}px;
            margin-top: ${props => props.theme.spacer}px;
        }
    }
`

const AccordionContentContainer = styled.div`
    width: 97%;
    max-height: ${props => props.maxHeight ? props.maxHeight + 40 : 0}px!important; 
    transition: max-height 0.35s ease;
    display: flex;
    overflow: hidden;
    margin-left: 36px;
    @media (max-width: 768px){
        margin-left: 0px!important;
    }
    `

export const AccordionContent = styled.div`
    width: 52%;
    background: #F3F3F5;
    padding: 20px 0;
    border-radius: 10px;
    margin-bottom: ${props => props.theme.spacer * 8}px;

    @media (max-width: 768px){
        width: 100%;
        margin-left: ${props => props.theme.spacer * 8}px;
        padding: 12px 0;
    }
`

const AccordionArrowContainer = styled.div`
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate( 50px, -50%) ${props => props.showMore ? "rotate(90deg)" : "rotate(-90deg)"};
    transition: 0.35s;
`

//components
const Accordion = (props) => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState();
    const [contentMaxHeight, setContentMaxHeight] = useState(0)

    //accordion content refference use to toggle show and hide of the content
    const contentRef = useRef(null);

    //function to toggle show and hide of accordion content
    const toggleAccordion = useCallback(() => {
        setContentMaxHeight(showMore ? contentRef.current.scrollHeight + 20 : 0);
    }, [showMore, contentRef])

    // //check if there is a true value for props.showMore and if so, setShowMore to be true so that this accordion item would be opened upon mount
    useEffect(() => {
        if (props.showMore === true) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }
    }, [props.showMore])

    useEffect(() => {
        toggleAccordion();
    }, [showMore, toggleAccordion])

    return (
        <AccordionWrapper>
            <AccordionButton onClick={(e) => { setShowMore(!showMore); e.stopPropagation() }}>
                <AccordionTitleAndLessonInfoContainer>
                    <div>
                        <Caption12>{props.index && props.index <= 9 && 0}{props.index && props.index === 0 ? 1 : props.index + 1}.    </Caption12>
                        <Text24 fontFamily="Raleway" className="lesson-title" fontWeight={600}>{props.section && props.section.title}</Text24>
                    </div>
                    <Caption12 className="lessons-and-quizes">
                        <span>{props?.section?.lessons?.length > 0 && props.section.lessons.length}</span>
                        {props?.section?.lessons?.length > 1 ? t("general.lessons").toUpperCase() : props?.section?.lessons?.length > 0 && t("general.lesson").toUpperCase()}
                        <span>{props?.section?.quiz?.length > 0 && + props.section.quiz.length}</span>
                        {props?.section?.quiz?.length > 1 ? t("general.quizes").toUpperCase() : props?.section?.quiz?.length > 0 && t("general.quiz").toUpperCase()}
                    </Caption12>
                </AccordionTitleAndLessonInfoContainer>
                <AccordionArrowContainer showMore={showMore}>
                    <Icon
                        icon={"icnArrowLeft"}
                        width={15} height={20}
                        pathStroke={showMore ? props.theme.blue : props.theme.gray3}
                        pathStrokeWidth="2"
                    />
                </AccordionArrowContainer>
            </AccordionButton>
            <AccordionContentContainer className="accordion-content-container" ref={contentRef} maxHeight={contentMaxHeight}>
                <AccordionContent className="accordion-content">
                    {props.section && (props.section.lessons && props.section.lessons.map((lesson, i) => (
                        <AccordionContentItem key={i} lesson={lesson} courseId={props.courseId} learningPathId={props.learningPathId && props.learningPathId} />
                    )
                    ))}
                    {props.section && (props.section.quiz && props.section.quiz.map((quiz, i) => (
                        <AccordionContentItem key={1 + i} quiz={quiz} courseId={props.courseId} learningPathId={props.learningPathId && props.learningPathId} />
                    )))}
                    {props.section && (props.section.code && props.section.code.map((code, i) => (
                        <AccordionContentItem key={1 + i} code={code} courseId={props.courseId} learningPathId={props.learningPathId && props.learningPathId} />
                    )))}
                </AccordionContent>
            </AccordionContentContainer>
        </AccordionWrapper>
    );
}

export default withTheme(Accordion);