import React from "react";

// imports
import styled from "styled-components";
import { CardCaption11 } from "../Text/Text";
import { useTranslation } from "react-i18next";

const CaptionWrapper = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 3px 14px;
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};

  p {
    display: flex;
    align-self: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
`;

const CaptionInfo = (props) => {
  const { borderRadius, border } = props;
  const { t } = useTranslation();

  return (
    <CaptionWrapper borderRadius={borderRadius} border={border}>
      {" "}
      <CardCaption11>{t("catalogue.featured_courses.new_content")}</CardCaption11>
    </CaptionWrapper>
  );
};

export default CaptionInfo;
