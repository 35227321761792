import React from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import { Heading35, SpecHeading, Text13 } from '../../../../Components/Text/Text';
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';

//other imports
import { icnHourglassGradient } from '../../../../Static/icnHourglassGradient';
import { icnUsersGradient } from '../../../../Static/icnUsersGradient'
import { icnStarFolderGradient } from '../../../../Static/icnStarFolderGradient';

//styled-components
const PremiumCourseInfoWrapper = styled.div`
    width: 100%;
    background-color: ${props => props.theme.darkBlack};
    display: flex;
    padding-bottom: 12px;
    h1, h2, h3, h4, h5, h6, p{
        color: ${props => props.theme.white};
    }
    &>div{
        width: 50%;
    }
    .typical-week-container{
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .top-stray-shape{
            position: absolute;
            width: 24%;
            height: 12vw;
            background-color: ${props => props.theme.black};
            border-bottom-right-radius: 50%;
            top: -6vw;
            left: 0;
        }
        .typical-week-info{
            width: 569px;
            margin-top: 80px;
            align-self: flex-end;
            ul{
                display: flex;
                justify-content: space-between;
                margin-top:55px;
                li{
                    border-top: 2px solid ${props => props.theme.white};
                    padding-top: 18px;
                    width: 24%;
                    .numeric-content-header{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        h1{
                            line-height: 60px;
                        }
                        svg{
                            margin-top: 3px;
                        }
                    }
                    p{
                        margin-top: 45px;
                        font-weight: 800;
                    }
                }
            }
        }
        .typical-week-photos{
            width: 100%;
            margin-top: 64px;
            background-color: ${props => props.theme.purple};
            .top-photos{
                width: 100%;
                display: flex;
                .top-left-quadrant{
                    width: 50%;
                    padding-right: 5px;
                    padding-bottom: 5px;
                    background-color: ${props => props.theme.darkBlack};
                    border-bottom-right-radius: 10%;
                    .top-left-quadrant-top-section{
                        display: flex;
                        margin-bottom: 10px;
                        &>div:first-child{
                            width: 50%;
                            height: 12vw;
                            background-color: ${props => props.theme.purple};
                            border-top-right-radius: 50%;
                            border-bottom-left-radius: 50%;
                            margin-right: 5px;
                        }
                        &>div:last-child{
                            width: 50%;
                            height: 12vw;
                            background-color: red;
                            border-top-left-radius: 50%;
                            border-top-right-radius: 50%;
                            border-bottom-left-radius: 50%;
                            margin-left: 5px;
                        }
                    }
                    .top-left-quadrant-bottom-section{
                        display: flex;
                        &>div:first-child{
                            width: 50%;
                            height: 12vw;
                            background-color: red;
                            border-bottom-left-radius: 50%;
                            margin-right: 5px;
                        }
                        &>div:last-child{
                            width: 50%;
                            height: 12vw;
                            background-color: ${props => props.theme.black};
                            border-bottom-right-radius: 50%;
                            margin-left: 5px;
                        }
                    }
                }
                .top-right-quadrant{
                    width: 50%;
                    padding-left: 5px;
                    padding-bottom: 5px;
                    background-color: ${props => props.theme.darkBlack};
                    border-bottom-left-radius: 10%;
                    .top-right-quadrant-top-section{
                        display: flex;
                        margin-bottom: 10px;
                        &>div:first-child{
                            width: 50%;
                            height: 12vw;
                            background-color: ${props => props.theme.white};
                            border-top-right-radius: 50%;
                            margin-right: 5px;
                        }
                        &>div:last-child{
                            width: 50%;
                            height: 12vw;
                            background-color: red;
                            border-radius: 50%;
                            margin-left: 5px;
                        }
                    }
                    .top-right-quadrant-bottom-section{
                        display: flex;
                        &>div:first-child{
                            width: 50%;
                            height: 12vw;
                            background-color: red;
                            border-bottom-left-radius: 50%;
                            margin-right: 5px;
                        }
                        &>div:last-child{
                            width: 50%;
                            height: 12vw;
                            background-color: ${props => props.theme.purple};
                            border-top-left-radius: 50%;
                            border-bottom-right-radius: 50%;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .bottom-photos{
                width: 100%;
                display: flex;
                .bottom-left-quadrant{
                    width: 50%;
                    padding-right: 5px;
                    padding-top: 5px;
                    background-color: ${props => props.theme.darkBlack};
                    border-top-right-radius: 10%;
                    .bottom-left-quadrant-top-section{
                        width: 100%;
                        height: 12vw;
                        margin-bottom: 10px;
                    }
                    .bottom-left-quadrant-bottom-section{
                        &>div:first-child{
                            width: 50%;
                            height: 12vw;
                            background-color: ${props => props.theme.black};
                            margin-right: 5px;
                            border-bottom-right-radius: 50%;
                        }
                    }
                }
                .bottom-right-quadrant{
                    width: 50%;
                    padding-left: 5px;
                    padding-top: 5px;
                    background-color: ${props => props.theme.darkBlack};
                    border-top-left-radius: 10%;
                    .bottom-right-item{
                        width: 100%;
                        height: 24vw;
                        background-color: red;
                        border-top-left-radius: 25%;
                        border-bottom-right-radius: 25%;
                    }
                }
            }
        }
        .typical-week-photos-mobile{
            display: none;
        }
    }

    .mobile-middle-shapes-and-photos{
        display: none;
    }
    .who-this-course-is-for-container{
        padding-left: 11%;
        .top-half-star-container{
            width: 100%;
            height: 74px;
            background-color: ${props => props.theme.purple};
            display: flex;
            &>div{
                height: 100%;
                background-color: ${props => props.theme.darkBlack};
            }
            .top-shape-left-item{
                width: 34%;
                border-top-right-radius: 37px;
            }
            .top-shape-right-item{
                width: 66%;
                border-top-left-radius: 37px;

            }
        }
        .top-photos-container{
            width: 100%;
            display: flex;
            margin-top: 31px;
            .top-photos-left-section{
                width: 34%;
                margin-right: 5px;
                .top-photos-left-section-top-item{
                    width: 100%;
                    height: 12.5vw;
                    margin-bottom: 10px;
                    background-color: ${props => props.theme.purple};
                    border-top-right-radius: 50%;
                    border-bottom-left-radius: 50%;
                }
                .top-photos-left-section-bottom-item{
                    width: 100%;
                    height: 12.5vw;
                    margin-top: 10px;
                    border-bottom-left-radius: 50%;
                    background-color: red;
                }
            }
            .top-photos-right-section{
                width: 66%;
                .top-photos-right-section-item{
                    width: calc(100% - 5px);
                    height: 100%;
                    margin-left: 5px;
                    background-color: red;
                    border-top-left-radius: 25%;
                    border-bottom-right-radius: 25%;
                }
            }
        }
        .who-this-course-is-for-info-container{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .who-this-course-is-for-info{
                margin-top: 120px;
                width: 45%;
                ul{
                    margin-top: 50px;
                    margin-left: -10px;
                    li{
                        display: flex;
                        margin-bottom: 30px;
                        .clipped{
                            width: 20px;
                            height: 20px;
                            background-color: ${props => props.theme.purple};
                            clip-path: url(#clipped-star);
                            -webkit-clip-path: url(#clipped-star);
                            margin-top: -3px;
                        }
                        p{
                            font-weight: 800;
                            margin-left: 15px;
                        }
                    }
                }
                button{
                    margin-left: 23px;
                    margin-top: 60px;
                }
            }
            .who-this-course-is-for-photos-container{
                width: 32%;
                &>div{
                    width: 100%;
                    height: 12vw;
                }
                .top-photo{
                    background-color: red;
                    border-top-left-radius: 50%;
                    border-top-right-radius: 50%;
                    border-bottom-left-radius: 50%;
                    margin-top: 10px; 
                }
                .shape-in-between{
                    background-color: ${props => props.theme.purple};
                    border-top-right-radius: 50%;
                    border-bottom-left-radius: 50%;
                    margin-top: 200px;
                }
                .bottom-photo{
                    background-color: red;
                    border-bottom-left-radius: 50%;
                    margin-top: 10px;
                }
            }
        }
    }
    .mobile-bottom-half-star-container{
        display: none;
    }
    @media (max-width: 1266px){
        .typical-week-container{
            .typical-week-info{
                width: 90%;
            }
        }
    }
    @media (max-width: 500px){
        flex-direction: column;
        &>div{
            width: 100%;
        }
        .typical-week-container{
            flex-direction: row;
            .top-stray-shape{
                display: none;
            }
            .typical-week-info{
                width: 64%;
                margin-left: 0px;
                margin-top: 67px;
                h4{
                    padding-left: 20px;
                    padding-right: 34px;
                    font-size: 28px;
                    line-height: 33px;
                    margin-right: -50px;
                }
                ul{
                    padding-left: 20px;
                    padding-right: 34px;
                    margin-bottom: 76px;
                    flex-direction: column;
                    li{
                        width: 72%;
                        margin-bottom: 84px;
                        p{
                            width: 70%;
                        }
                    }
                }
                
            }
            .typical-week-photos{
                display: none;
            }
            .typical-week-photos-mobile{
                display: block;
                width: 36%;
                .typical-week-photos-mobile-photo1{
                    width: 97.8vw;
                    height: 97.6vw;
                    background-color: red;
                    border-top-left-radius: 25%;
                    border-bottom-right-radius: 25%;
                    margin-top: 116px;
                }
                .typical-week-photos-mobile-photo2{
                    width: 47.4vw;
                    height: 47.4vw;
                    background-color: ${props => props.theme.purple};
                    border-bottom-left-radius: 50%;
                    border-top-right-radius: 50%;
                    margin-top: 10px;
                }
                .typical-week-photos-mobile-photo3{
                    width: 47.4vw;
                    height: 47.4vw;
                    border-bottom-left-radius: 50%;
                    background-color: red;
                    margin-top: 10px;
                }
                .typical-week-photos-mobile-photo4{
                    width: 47.4vw;
                    height: 47.4vw;
                    border-bottom-right-radius: 50%;
                    background-color: ${props => props.theme.black};
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        .mobile-middle-shapes-and-photos{
            width: 100%;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            .mobile-middle-left-shapes-and-photos{
                width: 64%;
                margin-left: -36%;
                .mobile-middle-left-top-shapes-and-photos{
                    width:94.8vw;
                    display: flex;
                    .left-photo{
                        background-color: red;
                        width: 47.4vw;
                        height: 47.4vw;
                        border-top-right-radius: 50%;
                        margin-right: 5px;
                    }
                    .right-photo{
                        background-color: ${props => props.theme.white};
                        width: 47.4vw;
                        height: 47.4vw;
                        border-top-right-radius: 50%;
                        margin-left: 5px;
                    }
                }
                .mobile-middle-left-bottom-shapes-and-photos{
                    display: flex;
                    width: 94.8vw;
                    margin-top: 10px;
                    background-color: ${props => props.theme.purple};
                    .left-photo-container{
                        maring-left: -5px;
                        border-bottom-right-radius: 37px;
                        background-color: ${props => props.theme.darkBlack};
                        .left-photo{
                            background-color: ${props => props.theme.black};
                            border-bottom-right-radius: 50%;
                            width: 47.4vw;
                            height: 47.4vw;
                            margin-right: 5px;
                        }
                    }
                    .right-photo-container{
                        border-bottom-left-radius: 37px;
                        background-color: ${props => props.theme.darkBlack};
                        .right-photo{
                            background-color: red;
                            border-bottom-left-radius: 50%;
                            width: 47.4vw;
                            height: 47.4vw;
                            margin-left: 5px;
                        }
                    }
                }
                .mobile-middle-half-star-container{
                    width: 94.8vw;
                    height: 74px;
                    background-color: ${props => props.theme.purple};
                    display: flex;
                    margin-left: 5px;
                    .mobile-middle-half-star-left-item{
                        width: 100%;
                        height: 100%;
                        background-color: ${props => props.theme.darkBlack};
                        border-top-right-radius: 37px;
                    }
                    .mobile-middle-half-star-right-item{
                        width: 100%;
                        height: 100%;
                        background-color: ${props => props.theme.darkBlack};
                        border-top-left-radius: 37px;
                    }
                }
            }
            .mobile-middle-right-shapes-and-photos{
                width: 36%;
                .mobile-middle-right-top-photo{
                    width: 47.4vw;
                    height: 47.4vw;
                    border-radius: 50%;
                    background-color: red;
                }
                .mobile-middle-right-bottom-photo{
                    width: 47.4vw;
                    height: 47.4vw;
                    margin-top: 10px;
                    background-color: ${props => props.theme.purple};
                    border-top-left-radius: 50%;
                    border-bottom-right-radius: 50%;
                }
            }
        }
        .who-this-course-is-for-container{
            padding-left: 20px;
            .top-shape-container, .top-half-star-container, .top-photos-container{
                display: none;
            }
            .who-this-course-is-for-info-container{
                .who-this-course-is-for-info {
                    width: 59.7vw;
                    margin-top: 27px;
                    ul{
                        margin-top: 30px;
                        width: 75%;
                        li{
                            margin-bottom: 29px;
                            p{
                                font-weight: 500;
                            }
                        }
                    }
                    button{
                        width: 60%;
                        display: flex;
                        justify-content: center;
                    }
                }
                .who-this-course-is-for-photos-container{
                    width: 36vw;
                    overflow: hidden;
                    margin-top: -64px;
                    .top-photo{
                        display: none;
                    }
                    .shape-in-between{
                        margin-top: 0px;
                        width: 47.4vw;
                        height: 47.4vw;
                        background-color: ${props => props.theme.white};
                        border-top-left-radius: 50%;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 50%;
                        border-bottom-left-radius: 0;
                    }
                    .bottom-photo{
                        width: 47.4vw;
                        height: 47.4vw;
                    }
                }
            }
        }
        .mobile-bottom-half-star-container{
            width: 100%;
            height: 74px;
            background-color: ${props => props.theme.purple};
            display: flex;
            .mobile-bottom-half-star-left-item{
                width: 64%;
                height: 100%;
                background-color: ${props => props.theme.darkBlack};
                border-bottom-right-radius: 37px;
            }
            .mobile-bottom-half-star-right-item{
                width: 36%;
                height: 100%;
                background-color: ${props => props.theme.darkBlack};
                border-bottom-left-radius: 37px;
            }
        }
    }
`

const clippedStar =
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>

            <clipPath id="clipped-star">
                <path d="M20 10C14.5161 9.95392 10.0922 5.48387 10 0C9.95392 5.48387 5.48387 9.95392 0 10C5.48387 10.0461 9.95392 14.5161 10 20C10.0922 14.5161 14.5161 10.0922 20 10Z" fill="#234BDB" />
            </clipPath>
        </defs>
    </svg>

//component
const PremiumCourseInfo = (props) => {
    return (
        <PremiumCourseInfoWrapper>
            <div className="typical-week-container">
                <div className="top-stray-shape"></div>
                <div className="typical-week-info">
                    <Heading35>Една Типична<br /> Недела на Курсот</Heading35>
                    <ul>
                        <li className="premium-course-content-item">
                            <div className="numeric-content-header">
                                {icnHourglassGradient}
                                <SpecHeading>4</SpecHeading>
                            </div>
                            <Text13>Снимени сесии по 1 час</Text13>
                        </li>
                        <li className="premium-course-content-item">
                            <div className="numeric-content-header">
                                {icnUsersGradient}
                                <SpecHeading>1</SpecHeading>
                            </div>
                            <Text13>Консултативна сесија во времетраење од 2 часа</Text13>
                        </li>
                        <li className="premium-course-content-item">
                            <div className="numeric-content-header">
                                {icnStarFolderGradient}
                                <SpecHeading>1</SpecHeading>
                            </div>
                            <Text13>Изработка на проект со сесија во живо во времетраење од 4 часа</Text13>
                        </li>
                    </ul>

                </div>
                <div className="typical-week-photos">
                    <div className="top-photos">
                        <div className="top-left-quadrant">
                            <div className="top-left-quadrant-top-section">
                                <div></div>
                                <div></div>
                            </div>
                            <div className="top-left-quadrant-bottom-section">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="top-right-quadrant">
                            <div className="top-right-quadrant-top-section">
                                <div></div>
                                <div></div>
                            </div>
                            <div className="top-right-quadrant-bottom-section">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-photos">
                        <div className="bottom-left-quadrant">
                            <div className="bottom-left-quadrant-top-section"></div>
                            <div className="bottom-left-quadrant-bottom-section">
                                <div></div>
                            </div>
                        </div>
                        <div className="bottom-right-quadrant">
                            <div className="bottom-right-item"></div>
                        </div>
                    </div>
                </div>
                <div className="typical-week-photos-mobile">
                    <div className="typical-week-photos-mobile-photo1"></div>
                    <div className="typical-week-photos-mobile-photo2"></div>
                    <div className="typical-week-photos-mobile-photo3"></div>
                    <div className="typical-week-photos-mobile-photo4"></div>
                </div>
            </div>
            <div className="mobile-middle-shapes-and-photos">

                <div className="mobile-middle-left-shapes-and-photos">
                    <div className="mobile-middle-left-top-shapes-and-photos">
                        <div className="left-photo-container">
                            <div className="left-photo"></div>
                        </div>
                        <div className="right-photo-container">
                            <div className="right-photo"></div>
                        </div>
                    </div>
                    <div className="mobile-middle-left-bottom-shapes-and-photos">
                        <div className="left-photo-container">
                            <div className="left-photo"></div>
                        </div>
                        <div className="right-photo-container">
                            <div className="right-photo"></div>
                        </div>

                    </div>
                    <div className="mobile-middle-half-star-container">
                        <div className="mobile-middle-half-star-left-item"></div>
                        <div className="mobile-middle-half-star-right-item"></div>
                    </div>
                </div>
                <div className="mobile-middle-right-shapes-and-photos">
                    <div className="mobile-middle-right-top-photo"></div>
                    <div className="mobile-middle-right-bottom-photo"></div>
                </div>
            </div>

            <div className="who-this-course-is-for-container">
                <div className="top-half-star-container">
                    <div className="top-shape-left-item"></div>
                    <div className="top-shape-right-item"></div>
                </div>
                <div className="top-photos-container">
                    <div className="top-photos-left-section">
                        <div className="top-photos-left-section-top-item"></div>
                        <div className="top-photos-left-section-bottom-item"></div>
                    </div>
                    <div className="top-photos-right-section">
                        <div className="top-photos-right-section-item"></div>
                    </div>
                </div>
                <div className="who-this-course-is-for-info-container">
                    <div className="who-this-course-is-for-info">

                        <Heading35>За Кого е<br /> Овој Курс</Heading35>
                        <ul>
                            {props.courseInfo.map((item, index) => (

                                <li key={index}>
                                    <div className="clipped">
                                        {clippedStar}
                                    </div>
                                    <Text13>{item}</Text13>
                                </li>
                            ))}
                        </ul>
                        <ButtonBasic variation="primary" text="CTA" />
                    </div>
                    <div className="who-this-course-is-for-photos-container">
                        <div className="top-photo"></div>
                        <div className="shape-in-between"></div>
                        <div className="bottom-photo"></div>
                    </div>
                </div>
            </div>
            <div className="mobile-bottom-half-star-container">
                <div className="mobile-bottom-half-star-left-item"></div>
                <div className="mobile-bottom-half-star-right-item"></div>
            </div>
        </PremiumCourseInfoWrapper>
    );
}

export default PremiumCourseInfo;