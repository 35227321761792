import React from 'react';

//imports
import styled from 'styled-components';
import { Caption16 } from '../../../../Components/Text/Text';
import Accordion from '../../../../Components/Accordion/Accordion';
import { useTranslation } from 'react-i18next';

const CourseContentContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    @media (max-width: 768px){
        flex-direction: column;
    }
`

const CourseContentTitleContainer = styled.div`
    width: 30%;
    p{
        margin-top: -9px;
        color: #BDBDBD;
    }
    @media (max-width: 768px){
        width: 100%;
        margin-top: 0px;
        margin-bottom: 25px;
    }
`

const CourseContentItemsContainer = styled.div`
    width: 70%;
    @media (max-width: 768px){
        width: 100%;
    }
`

const CourseContent = (props) => {
    const { t } = useTranslation();
    return (
        <CourseContentContainer>
            <CourseContentTitleContainer>
                <Caption16>{t("course_index.content")}</Caption16>
            </CourseContentTitleContainer>
            <CourseContentItemsContainer>
                {props.currentCourse && props.currentCourse.sections.map((section, i) => {
                    return (
                        <Accordion key={i} index={i} section={section} courseId={props.courseId} />
                    )
                })}
            </CourseContentItemsContainer>
        </CourseContentContainer>
    );
}

export default CourseContent;