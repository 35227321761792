import styled from 'styled-components';
import Wrapper from '../Wrapper/Wrapper';
import background from '../../Static/illustrations/CtaBlockSoftwareEngineering/background.svg';

export const CtaBlockQuiz = styled(Wrapper)`
    margin-top: 50px;
    max-width: 1151px;
    margin-bottom: ${props => props.bottom ? "100px" : "0px"};
    .children-container{
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        .heading-container{
            width: 45%;
            height: 100%;
            margin-top: 50px;
            margin-left: 40px;
            h3,p {
                color: ${props => props.theme.white};
            }
            p{
                margin-top: 10px;
            }
        }
        img{
            width: 35%;
            margin-top: -25px;
            margin-left: 65px;
        }
    }
    @media (max-width: 1200px){
        .children-container{
            .heading-container{
                
                h3{
                    font-size: 32px;
                    line-height: 38px;
                }
                p{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            img{
                width: 38%;
                margin-top: -20px;
                margin-left: 65px;
            }
        }
    }
    @media (max-width: 1070px){
        .children-container{
            img{
                width: 38%;
                margin-top: -15px;
                margin-left: 45px;
            }
        }
    }
    @media (max-width: 1000px){
        .children-container{
            img{
                width: 40%;
                margin-top: -10px;
            }
        }
    }
    @media (max-width: 950px){
        .children-container{
            img{
                width: 42%;
            }
        }
    }
    @media (max-width: 900px){
        .children-container{
            .heading-container{
                h3 {
                    font-size: 26px;
                    ling-height: 32px;
                }
                p{
                    font-size: 13px;
                    line-height: 18px;
                }
            }
            img{
                width: 44%;
                margin-left: 25px;
            }
        }
    }
    @media (max-width: 850px){
        .children-container{
            img{
                width: 46%;
                margin-top: -5px;
                margin-left: 0px;
            }
        }
    }
    @media (max-width: 500px){
        margin-top: 25px;
        margin-bottom: 60px;
        .children-container{
            .heading-container{
                width: 33%;
                margin-top: 21px;
                margin-left: 22px;
                h3{
                    font-size: 19px;
                    line-height: 22px;
                }
                p{
                    font-size: 10px;
                    line-height: 12px;
                }
            }
            img{
                width: 60%;
                margin-top: 45px;
                margin-left: 20px;
                margin-right: -40px;
            }
        }
    }
    @media (max-width: 420px){
        .children-container{
            img{
                margin-top: 65px;
            }
        }
    }
`

export const CtaBlockCommunity = styled(Wrapper)`
max-width: 1151px;
margin-top: 40px;
margin-bottom: 40px;
padding: 0;
.children-container{
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    .heading-container{
        width: 50%;
        height: 100%;
        h3{
            margin-top: 50px;
            margin-left: 40px;
            color: ${props => props.theme.white};
        }
    }
    .community-illustration{
        width: 50%;
        height: 100%;
        position: relative;
        .purple-clipped-rectangle{
            position: absolute;
            top: 0;
            left: -20px;
            visibility: hidden;
            transition: transform .2s linear;
        }
        .black-circle{
            width: 92px;
            height: 92px;
            background-color: ${props => props.theme.black};
            position: absolute;
            top: 149px;
            left: 107px;
            border-radius: 50%;
        }
        .scribbled-leaf{
            position: absolute;
            top: -20px;
            left: 239px;
        }
        .yellow-slice{
            position: absolute;
            top: 61px;
            left: 200px;
        }
    }
}
@media(max-width: 500px) {
    .children-container{
        .heading-container{
            width: 55%;
            h3{
                margin-top: 21px;
                margin-left: 22px;
                font-size: 19px;
                line-height: 22px;
            }
        }
        .community-illustration{
            width: 45%;
            .purple-clipped-rectangle{
                width: 70px;
                height: 70px;
                top: 50px;
            }
            .black-circle{
                width: 56px;
                height: 56px;
                left: 50px;
            }
            .scribbled-leaf{
                width: 60px;
                top: -11px;
                left: 77px;
            }
            .yellow-slice{
                width: 24px;
                top: 135px;
                left: 96px;
            }
        }
    }
}

`


export const StyledSoftwareEngineeringCta = styled.div`
    width: 90%;
    max-width: 1151px;
    margin: 40px auto;
    padding: 0;
    .children-container{
        width: 100%;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        .heading-container{
            width: 59%;
            height: 100%;
            &>p{
                margin-top: 40px;
                margin-left: 40px;
                color: ${props => props.theme.white};
            }
            h3{
                margin-top: 12px;
                margin-left: 40px;
                color: ${props => props.theme.white};
            }
        }
        .software-engineering-illustration{
            width: 41%;
            height: 100%;
            position: relative;
            &>svg{
                position: absolute;
            }
            .brackets{
                top: -30px;
                right: 30%;
                &>path{
                    transition: fill .25s linear;
                }
            }
            .quarterCircleYellow{
                top: 75px;
                left: 75px;
                transition: transform .25s linear;
            }
            .blackCircle{
                left: 285px;
                top: 150px;
            }
            .silverLeaf{
                left: 180px;
                top: 150px;
                transition: transform .25s linear;
                path{
                    transition: fill .25s linear;
                }
            }
        }
    }
    .shapes-container{
        .black-shape-container{
            width: 59%;
        }
        .gray-shape-container{
            width: 41%;
            .gray-shape-item{
                background-image: url('${background}');
                background-size: 250%;
                background-position: right;
            }
        }
    }
    @media(max-width: 500px) {
        .children-container{
            .heading-container{
                width: 55%;
                p{
                    margin-top: 15px;
                    margin-left: 15px;
                }
                h3{
                    margin-top: 8px;
                    margin-left: 15px;
                    font-size: 19px;
                    line-height: 22px;
                }
            }
            .software-engineering-illustration{
                width: 45%;
                background-image: url(${background});
                .brackets{
                    width: 90px;
                    right: 10px;
                }
                .quarterCircleYellow{
                    width: 30px;
                    left: 50px
                }
                .blackCircle{
                    width: 30px;
                    left: 100px;
                }
                .silverLeaf{
                    width: 65px;
                    left: 30px;
                    top: 130px;
                }
            }
        }
    }
    
`
