import React, { useState, useEffect, useRef, useCallback, memo } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components/macro';
import Icon from '../Icon/Icon';

//component imports
import { Caption12, Text13 } from '../Text/Text';

//styled-components
const StyledAccordion = styled.div`
    width: 100%;
    border-radius: 10px;
    background-color: ${props => props.theme.white};
    button {
        width: 100%;
        padding: 40px 0;
        background-color: transparent;
        border: none;
        header{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: ${props => props.contentMaxHeight ? "25px" : "0px"};
            transition: margin-bottom .2s linear;
            &>div{
                display: flex;
                align-items: center;
                .item-icon{
                    width: calc(100% - 44px);
                    text-align: left;
                }
                p{

                }
            }
            .accordion-basic-arrow{
                transform: ${props => props.showMore && "rotate(180deg)"};
                transition: transform .2s linear;
                path{
                    stroke: ${props => props.showMore && props.theme.blue};
                }
            }
            
        }
        &:hover{
            cursor: pointer;
        }
    }
    section{
        width: 100%;
        max-height: ${props => props.contentMaxHeight}px;
        padding: ${props => props.contentMaxHeight ? "30px 0 30px 42px" : "0 0 0 42px"};
        overflow: hidden;
        transition: max-height .1s linear, padding .2s linear;
        p{
            text-align: left;
        }
    }
    padding: 0px;
    button{
        padding: 29px 22px 22px 22px;
        header{
            margin-bottom: ${props => props.contentMaxHeight ? "10px" : "0px"};
            &>div{
                .item-icon{
                    width: 25px;
                    height: 28px;
                    margin-right: 16px;
                }
                p{
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }
    section{
        p{
            font-size: 16px;
            line-height: 20px;
        }
    }
    @media (max-width: 586px){
    }
`

//main component
const AccordionBasic = memo(({ header, content, propShowMore, theme, icon }) => {
    const [showMore, setShowMore] = useState();
    const [contentMaxHeight, setContentMaxHeight] = useState(0)

    //accordion content refference use to toggle show and hide of the content
    const contentRef = useRef(null);

    //function to toggle show and hide of accordion content
    const toggleAccordion = useCallback(() => {
        setContentMaxHeight(showMore ? contentRef.current.scrollHeight + 600 : 0);
    }, [showMore, contentRef])

    // //check if there is a true value for props.showMore and if so, setShowMore to be true so that this accordion item would be opened upon mount
    useEffect(() => {
        if (propShowMore === true) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }
    }, [propShowMore])

    useEffect(() => {
        toggleAccordion();
    }, [showMore, toggleAccordion])
    return (
        <StyledAccordion contentMaxHeight={contentMaxHeight} showMore={showMore}>
            <button onClick={(e) => setShowMore(!showMore)}>
                <header>
                    <div>
                        <Icon icon={icon} className="item-icon" pathFill={theme.black} />
                        <Caption12>{header}</Caption12>
                    </div>
                    <Icon className="accordion-basic-arrow" icon="icnArrowSmallest" />
                </header>
                <section ref={contentRef}>
                    <Text13 color={theme.darkGray}>
                        {content}
                    </Text13>
                </section>
            </button>
        </StyledAccordion>
    )
})

export default withTheme(AccordionBasic);