import React, { useContext, useEffect, useState } from 'react';

//npm imports
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

//component imports
import { Heading20, Text10, Text13 } from '../Text/Text';
import ButtonBasic from '../ButtonBasic/ButtonBasic';

//other imports
import catalogue_gif from '../../Static/giffs/getting_started/catalogue.gif';
import courses_gif from '../../Static/giffs/getting_started/courses.gif';
import learning_mode_gif from '../../Static/giffs/getting_started/learning_mode.gif'
import my_courses_gif from '../../Static/giffs/getting_started/my-courses.gif';
import { GlobalContext } from '../../Context/GlobalContext';
import { useLocation } from '@reach/router';

//other imports

//styled-components
const GettingStartedWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    display: ${props => !props.isComponentVisible ? "none" : props.isGettingStartedSeen === true ? "none" : props.isGettingStartedSeen === false || props.isComponentVisible ? "block" : "none"};
    .dimmed-background{
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.black};
        opacity: 0.8;
    }
    .popup-container{
        width: 70%;
        max-width: 1141px;
        height: 73%;
        background-color: ${props => props.theme.white};
        border-radius: 80px;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        .popup-content-container{
            width: 100%;
            height: 74%;
            position: relative;
            .introduction-container{
                position: absolute;
                top: 0;
                width: 70%;
                height: 100%;
                transition: left .5s ease-in-out .2s;
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                align-items: center;
                h5{
                    margin-top: 55px;
                }
                p{
                    margin-top: 22px;
                    text-align: center;
                    font-weight: 500;
                }
                img{
                    width: 100%;
                    margin-top: 22px;
                    margin-bottom: -50%;
                    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.1);
                }
            }
            .catalogue-introduction-container{
                left: ${props => props.seen === 0 ? "15%" : "-101%"};
            }
            .courses-introduction-container{
                left: ${props => props.seen < 1 ? "101%" : props.seen === 1 ? "15%" : "-101%"};
            }
            .learning-mode-introduction-container{
                left: ${props => props.seen < 2 ? "101%" : props.seen === 2 ? "15%" : "-101%"};
            }
            .my-courses-introduction-container{
                left: ${props => props.seen < 3 ? "101%" : props.seen === 3 ? "15%" : "-101%"};
            }
            .bottom-shade{
                width: 100%;
                height: 75%;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 19.06%, #FFFFFF 48.23%, #FFFFFF 91.46%);
                position: absolute;
                top: 63%;
                left: 0;
            }
        }
        .popup-buttons-container{
            width: 100%;
            height: 26%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .progress-container{
                width: 165px;
                height: 4px;
                margin-top: 26px;
                background-color: ${props => props.theme.gray7};
                z-index: 9;
                .progress-bar{
                    width: ${props => (props.seen / 3) * 100}%;
                    height: 100%;
                    background-color: ${props => props.theme.black};
                    transition: width .5s ease-in-out .2s;
                }
            }
            &>p{
                &:hover{
                    cursor: pointer;
                    color: ${props => props.theme.blue};
                }
                margin-top: 34px;
                color: ${props => props.theme.gray3};
                z-index: 9;
            }
        }
    }
    @media (max-width: 500px){
        .dimmed-background{

        }
        .popup-container{
            width: 92%;
            border-radius: 50px;
            .popup-content-container{
                height: 67%;
                .introduction-container{
                    width: 80%;
                    h5{
                        font-size: 20px;
                        line-height: 23px;
                        margin-top: 72px;
                    }
                    p{
                        font-size: 14px;
                        line-height: 20px;
                        margin-top: 20px;
                    }
                    img{
                        margin-top: 25px;
                    }
                }
                .catalogue-introduction-container{
                    left: ${props => props.seen === 0 ? "10%" : "-101%"};
                }
                .courses-introduction-container{
                    left: ${props => props.seen < 1 ? "101%" : props.seen === 1 ? "10%" : "-101%"};
                }
                .learning-mode-introduction-container{
                    left: ${props => props.seen < 2 ? "101%" : props.seen === 2 ? "10%" : "-101%"};
                }
                .my-courses-introduction-container{
                    left: ${props => props.seen < 3 ? "101%" : props.seen === 3 ? "10%" : "-101%"};
                }
                .bottom-shade{
                    top: 59%;
                }
            }
            .popup-buttons-container{
                height: 33%;
                width: 92%;
                button{
                    width: 90%;
                    height: 50px;
                    justify-content: center;
                }
                .progress-container{
                    margin-top: 53px;
                }
                &>p{
                    margin-top: 40px;
                }
            }
        }
    }
`

//component
const GettingStarted = (props) => {
    const { t } = useTranslation();
    const [seenIntroductions, setSeenIntroductions] = useState(0);
    const { isGettingStartedSeen, setIsGettingStartedSeen } = useContext(GlobalContext);
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const location = useLocation();

    const gettingStartedIsSeen = () => {
        localStorage.setItem("show_walkthrough", true)
        setIsGettingStartedSeen(true)
    }

    const next = (seenIntroductions, callBack) => {
        if (seenIntroductions < 3) {
            callBack(seenIntroductions + 1)
        } else {
            gettingStartedIsSeen();
        }
    }

    useEffect(() => {

        props.except && setIsComponentVisible(!props.except.some(exceptRoute => (new RegExp(`^${exceptRoute}/?$`)).test(location.pathname)));
    }, [props.except, location.pathname]);
    return (

        <GettingStartedWrapper seen={seenIntroductions} isComponentVisible={isComponentVisible} isGettingStartedSeen={isGettingStartedSeen}>
            <div className="dimmed-background"></div>
            <div className="popup-container">
                <div className="popup-content-container">
                    <div className="catalogue-introduction-container introduction-container">
                        <Heading20>{t("getting_started.heading")}</Heading20>
                        <Text13>{t("getting_started.catalogue")}</Text13>
                        <img src={catalogue_gif} alt="animated catalogue example" />
                    </div>
                    <div className="courses-introduction-container introduction-container">
                        <Heading20>{t("getting_started.heading")}</Heading20>
                        <Text13>{t("getting_started.courses")}</Text13>
                        <img src={courses_gif} alt="animated courses example" />
                    </div>
                    <div className="learning-mode-introduction-container introduction-container">
                        <Heading20>{t("getting_started.heading")}</Heading20>
                        <Text13>{t("getting_started.learning_mode")}</Text13>
                        <img src={learning_mode_gif} alt="animated learning mode example" />
                    </div>
                    <div className="my-courses-introduction-container introduction-container">
                        <Heading20>{t("getting_started.heading")}</Heading20>
                        <Text13>{t("getting_started.my_courses")}</Text13>
                        <img src={my_courses_gif} alt="animated my courses example" />
                    </div>
                    <div className="bottom-shade"></div>
                </div>
                <div className="popup-buttons-container">
                    <ButtonBasic text={seenIntroductions === 3 ? t("general.start_learning") : t("getting_started.next")} onClick={(callBack) => next(seenIntroductions, setSeenIntroductions)} />
                    <div className="progress-container">
                        <div className="progress-bar"></div>
                    </div>
                    <Text10 onClick={gettingStartedIsSeen}>{t("getting_started.skip_introduction")}</Text10>
                </div>
            </div>
        </GettingStartedWrapper>
    );
}

export default GettingStarted;