import React from 'react';

//npm imports
import styled, { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

//component imports 
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import { Text13, Heading35 } from '../../../../Components/Text/Text';

//other imports
import career_quiz_background from '../../../../Static/backgrounds/career-quiz-background.png'
import career_quiz_background_mobile from '../../../../Static/backgrounds/career-quiz-background-mobile.png'

//styled-components
const StyledCareerQuiz = styled.div`
    width: 100%;
    padding: 115px 0px 109px 0px;
    background-color: ${props => props.theme.black};
    background-image: url(${career_quiz_background});
    background-size: contain;
    background-position: right  top;
    background-repeat: no-repeat;
    ${Wrapper}{
        align-items: flex-start;
        
    }
    section{
        width: 45%;
        ${Heading35}{
            margin-bottom: 40px;
        }
        a{
            button{
                margin-top: 66px;
                background-color: ${props => props.theme.purple};
                &:hover{
                    background-color: ${props => props.theme.purple};
                    opacity: 0.8;
                }
            }
        }
    }

    @media (max-width: 980px){
        padding-top: 306px;
        background-image: url(${career_quiz_background_mobile});
        section{
            width: 100%;
            ${Heading35}{
                width: 65%;
            }
            p{
                width: 80%;
            }
            a{
                width: 100%;
                button{
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }
    @media (max-width: 500px){
        section{
            ${Heading35}{
                font-size: 28px;
                line-height: 31px;
            }
            p{
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 24px;
            }
        }
    }
`

//main component
const CareerQuiz = (props) => {
    const { t } = useTranslation()

    return (
        <StyledCareerQuiz>
            <Wrapper>
                <section>
                    <Heading35 color={props.theme.white}>{t("homepage.career_quiz.header")}</Heading35>
                    <Text13 color={props.theme.white}>{t("homepage.career_quiz.description_part1")}</Text13>
                    <Text13 color={props.theme.white}>{t("homepage.career_quiz.description_part2")}</Text13>
                    <a href="https://careers.brainster.co/quiz" target="_blank" rel="noopener noreferrer"><ButtonBasic text={t("homepage.career_quiz.cta")} /></a>
                </section>
            </Wrapper>
        </StyledCareerQuiz>
    )
}

export default withTheme(CareerQuiz);