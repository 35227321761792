import React, { useContext, useState } from 'react';

//npm imports
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

//component imports
import { SpecHeadingSmall, SpecHeading, Caption16, Text13 } from '../../../Components/Text/Text';
import InputField from '../../../Components/InputField/InputField';
import ButtonBasic from '../../../Components/ButtonBasic/ButtonBasic';

//other imports
import mobile_hand from '../../../Static/prelaunch-photos/mobile_hand.png';
import mobile_hand_mobile from '../../../Static/prelaunch-photos/mobile_hand_mobile.png';
import form_photo from '../../../Static/prelaunch-photos/Register_Image.png';
import { API } from '../../../Consts/Api';
import { GlobalContext } from '../../../Context/GlobalContext';
import Icon from '../../../Components/Icon/Icon';

//animations
const blueContainerSlideIn = keyframes`
from{
    left: 100%;
}
to{
    left: 0%;
}
`

const blueContainerSlideInMobile = keyframes`
from{
    z-index: 4;
    top: 100%;
}
to{
    z-index: 4;
    top: 0%;
}
`

const yellowContainerSlideIn = keyframes`
from{
    z-index: 2;
    left: 86%;
}
to{
    z-index: 2;
    left: 0%;
}
`

const containerSlideIn = keyframes`
from{
    z-index: 1;
    left: 86%;
}
to{
    z-index: 1;
    left: 0%;
}
`

const blueSpacerShowUp = keyframes`
from{
    opacity: 0;
}
to{
    opacity: 1;
}
`

const blackContainerSlideInMobile = keyframes`
from{
    top: 86%;
}
to{
    top: 0%;
}

`

const yellowContainerSlideInMobile = keyframes`
    from{
        z-index: 2;
        top: 86%;
    }
    to{
        z-index: 2;
        top: 0;
    }
`

const blueCirclingShow = keyframes`
0%{
    opacity: 0;
    }
    99%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`

const pinkUnderlineDissapear = keyframes`
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
`


//styled-components
const PrelaunchRegisterWrapper = styled.div`
    height: 100vh;
    position: relative;
    overflow: hidden;
    .logo{
        position: absolute;
        top: 100px;
        left: 120px;
        z-index: 6;
    }
    .red-container{
        width: 100vw;
        height: 100%;
        background-color: ${props => props.theme.incorrect};
        position: absolute;
        top: 0;
        left: 0%;
        z-index: 0;
    }
    .black-container{
        width: 86%;
        height: 100%;
        background-color: ${props => props.theme.lightBlack};
        position: absolute;
        top: 0;
        left: 86%;
        animation: ${containerSlideIn} .86s 1s ${props => props.theme.expo} forwards;
    }
    .yellow-container{
        width: 86%;
        height: 100%;
        background-color: ${props => props.theme.yellow};
        position: absolute;
        top: 0;
        left: 86%;
        animation: ${yellowContainerSlideIn} .86s 2s ${props => props.theme.expo} forwards;
    }
    .blue-spacer{
        width: 14%;
        height: 100%;
        background-color: ${props => props.theme.blue};
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        animation: ${blueSpacerShowUp} .1s 2.1s linear forwards;
    }
    .phone-hand-container{
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0%;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .future-of-learning-copy-container{
            width: 30%;
            margin-bottom: 105px;
            margin-left: 120px;
            position: relative;
            br{
                display: none;
            }
            h1{
                font-size: 70px;
                line-height: 80px;
                color: ${props => props.theme.white};
            }
            .pink-underline, .blue-circling{
                position: absolute;
            }
            .pink-underline{
                width: 458px;
                height: 1px;
                border: 1px solid ${props => props.theme.pink};
                top: 68px;
                left: 0;
                z-index: -1;
                transform: rotate(-0.8deg);
                animation: ${pinkUnderlineDissapear} .01s .9s ${props => props.theme.expo} forwards;
            }
            .blue-circling{
                width: 268px;
                height: 56px;
                border: 2px solid ${props => props.theme.blue};
                border-radius: 50%;
                transform: rotate(-5deg);
                bottom: 13px;
                left: -27px;
                opacity: 0;
                animation: ${blueCirclingShow} .01s 2.1s ${props => props.theme.expo} forwards;
            }
        }
        img{
            height: 100%;
        }
        .mobile-hand-mobile{
            display: none;
        }
    }
    .blue-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: ${props => props.theme.blue};
        height: 100%;
        display: flex;
        flex-direction: row;
        z-index: 3;
        animation: ${blueContainerSlideIn} 1s 3s ${props => props.theme.expo} forwards;
        padding: 0 60px;
        .prelaunch-register-headline{
            width: 48%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin: 0;
            img{
                width: 120%;
                margin-left: -20px;
                margin-right: -80px;
                z-index: 99;
            }
            .mobile-hand-mobile{
                display: none;
            }
        }
        .prelaunch-register-form{
            width: 52%;
            height: 100%;
            background-color: ${props => props.theme.white};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 100px;
            overflow-y: scroll;
                ::-webkit-scrollbar {
                    width: 0;  /* Remove scrollbar space */
                    background: transparent;  /* Optional: just make scrollbar invisible */
                }
                /* Optional: show position indicator in red */
                ::-webkit-scrollbar-thumb {
                    background: #FF0000;
                }
            .error{
                color: ${props => props.theme.incorrect};
                margin-bottom: 10px;
            }
            h3{
                margin-bottom: 40px;
            }
            ${Caption16}{
                margin-bottom: 23px;
            }
            h3,p{
                text-align: center;
            }
        }
        div{
            margin-top: 48px;
            margin-bottom: 20px;
            p.input-label{
                text-align: left;
            }
        }
        .submit-button{
            align-self: flex-end;
        }
    }
    @media (max-width: 1400px){
        .phone-hand-container{
            .mobile-hand{
                margin-right: -150px;
            }
        }
    }
    @media (max-width: 1200px){
        .blue-container{
            .prelaunch-register-headline{
                img{
                    width: 105%;
                    margin-right: -40px;
                }
            }
            .prelaunch-register-form{
                padding: 0 30px;
            }
        }
        .phone-hand-container{
            .mobile-hand{
                margin-right: -250px;
            }
        }
    }
    @media (max-width: 500px){
        .logo{
            width: 100px;
            top: 35px;
            left: 39px;
        }
        .black-container{
            width: 100%;
            height: 86%;
            left: 0;
            top: 86%;
            animation: ${blackContainerSlideInMobile} .86s 0.8s ${props => props.theme.expo} forwards;
        }
        .yellow-container{
            height: 86%;
            width: 100%;
            left: 0;
            top: 86%;
            animation: ${yellowContainerSlideInMobile} 1s 1.66s ${props => props.theme.expo} forwards;
        }
        .blue-spacer{
            width: 100%;
            height: 14%;
            top: 86%;
            left: 0;
            animation: ${blueSpacerShowUp} .1s 1.8s linear forwards;
        }
        .phone-hand-container{
            width: 100%;
            flex-direction: column;
            .future-of-learning-copy-container{
                width: 100%;
                margin-top: 115px;
                padding-left: 39px;
                h1{
                    width: 90%;
                    font-size: 40px;
                    line-height: 46px;
                }
                .pink-underline{
                    width: 260px;
                    top: 41px;
                    left: 39px;
                }
                .blue-circling{
                    width: 147px;
                    height: 37px;
                    left: 21px;
                    top: 97px;
                }
            }
            img{
                width: 66%;
                height: unset;
            }
            .mobile-hand{
                display: none;
            }
            .mobile-hand-mobile{
                display: block;
                margin-top: -100px;
            }
        }
        .blue-container{
            width: 100%;
            flex-direction: column;
            left: 0;
            top: 100%;
            animation: ${blueContainerSlideInMobile} 1s 2.66s ${props => props.theme.expo} forwards;
            padding: 0;
            .prelaunch-register-headline{
                width: 100%;
                height: 39%;
                img{
                    width: 90%;
                    margin-right: 20px;
                    margin-bottom: -80px;
                    z-index: -1;
                }
            }
            .prelaunch-register-form{
                width: 100%;
                height: 61%;
                padding: 50px 33px 0 33px;
                border-top-left-radius: 55px;
                border-top-right-radius: 55px;
                justify-content: flex-start;
                h3{
                    font-size: 25px;
                    line-height: 29px;
                    margin-bottom: 30px;
                }
                &>p{
                    font-size: 12px;
                    line-height: 20px;
                    margin-bottom: 30px;
                }
                &>div{
                    margin-top: 0;
                }
                button{
                    margin-bottom: 60px;
                }
            }
        }
    }
`

//component
const PrelaunchRegister = (props) => {
    const { t } = useTranslation();
    const { signedUser, setSignedUser } = useContext(GlobalContext);
    const [errors, setErrors] = useState({})

    const handleSubmit = (e) => {
        e.preventDefault();
        API.axios.post(API.createApiRoute("apply"), signedUser)
            .then(response => {
                setSignedUser(response.data);
                navigate(API.createRoute("applicationStatus", { STUDENT: response.data.student }));
            })
            .catch(error => {
                if (error.response) {

                    error.response.data.errors.email && setErrors({ email: error.response.data.errors.email });
                    error.response.data.errors.additional && setErrors({ additional: error.response.data.errors.additional[0] });
                } else {
                    console.log(error)
                }
            });
    }
    return (
        <PrelaunchRegisterWrapper>
            <Icon icon="brainsterNextLogo" className="logo" />
            <div className="phone-hand-container">
                <div className="future-of-learning-copy-container">
                    <SpecHeading>{t("prelaunch.register.future_of_learning")}</SpecHeading>
                    <br />
                    <SpecHeading>{t("prelaunch.register.today")}</SpecHeading>
                    <div className="pink-underline"></div>
                    <div className="blue-circling"></div>
                </div>
                <img src={mobile_hand} alt="mobile next" className="mobile-hand" />
                <img src={mobile_hand_mobile} alt="mobile next" className="mobile-hand-mobile" />
            </div>
            <div className="blue-container">

                <div className="prelaunch-register-headline">
                    <img src={form_photo} alt="friends" />
                </div>
                <form className="prelaunch-register-form" onSubmit={(e) => handleSubmit(e)}>
                    <SpecHeadingSmall>{t("prelaunch.register.heading")}</SpecHeadingSmall>
                    <Caption16>{t("prelaunch.register.description1")}</Caption16>
                    <Caption16>{t("prelaunch.register.description2")}</Caption16>
                    <InputField
                        name="email"
                        type="email"
                        autocomplete="email"
                        autofill="email"
                        required={true}
                        inputLabel={t('general.email')}
                        onChange={(e) => { setErrors({}); setSignedUser({ ...signedUser, email: e.target.value }) }}
                    />
                    {errors.additional && <Text13 className="error">*{errors.additional}</Text13>}
                    {errors.email && <Text13 className="error">*{errors.email}</Text13>}
                    <ButtonBasic
                        type="submit"
                        text={t("general.step_in")}
                        variation="primary"
                        className="submit-button"
                    />
                </form>
            </div>
            <div className="red-container"></div>
            <div className="yellow-container"></div>
            <div className="blue-spacer"></div>
            <div className="black-container"></div>
        </PrelaunchRegisterWrapper>

    );
}

export default PrelaunchRegister;