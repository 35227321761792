import React, { useEffect, useState } from 'react';

//imports
import styled, { css } from 'styled-components';
import { Text28 } from '../Text/Text';
import { prop, switchProp } from "styled-tools";
import Icon from '../Icon/Icon';

//styled-components
const AnswerButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 30px;
    border-radius: 55px;
    margin-bottom: 18px;
    background: ${props => (props.notSelected || props.variation === "danger" || props.variation === "dangerSelect") ? "none" : "white"};
    box-shadow: ${props => props.variation === "success" ? `0px 1px 4px 0px ` + props.theme.success : ((props.selected && props.variation === "primary") && `0px 1px 4px 0px ` + props.theme.blue)};
    p {
        color: ${switchProp(prop("variation", "primary"), {
  primary: prop('theme.blue'),
  success: prop('theme.success'),
  danger: prop('theme.gray3'),
  dangerSelect: prop('theme.gray3')
})};
    }
    border: 2px solid${switchProp(prop("variation", "primary"), {
  primary: prop('theme.blue'),
  success: prop('theme.success'),
  danger: prop('theme.red'),
  dangerSelect: prop('theme.danger.select')
})};
    &:hover{ ${props => props.disabled ? css`
      cursor: not-allowed;
    ` : css`
      cursor: pointer;
      background: white;
      box-shadow: ${props => props.variation !== "primary" ? "none" : (!props.selected && !props.notSelected) && `0px 1px 4px 0px ` + props.theme.blue}
    }`}
`

const Circle = styled.div`
width: 35px;
height: 35px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
border: 1px solid ${switchProp(prop("variation", "primary"), {
  primary: prop('theme.blue'),
  success: prop('theme.success'),
  danger: prop('theme.danger'),
  dangerSelect: prop('theme.danger')
})};
  background-color: ${switchProp(prop("variation", "primary"), {
  success: prop('theme.success')
})};
  .correct-answer {
      margin-top: 2px;
      margin-left: 1px;
  }
`

const SelectedCircle = styled.div`
  width: 19px;
  height 19px;
  background-color: ${props => props.theme.blue};
  border-radius: 45%;
`

//component
const AnswerButton = (props) => {
  const [notSelected, setNotSelected] = useState(true);
  const [variation, setVariation] = useState("primary");

  useEffect(() => {
    if (parseInt(props.clickedAnswer) !== 0 && parseInt(props.clickedAnswer) !== parseInt(props.answerId)) {
      setNotSelected(true);
    } else {
      setNotSelected(false);
    }
  }, [props.clickedAnswer, props.answerId])

  useEffect(() => {
    //if there is a correct answer id in props, and also answer id along with clicked answer id
    //then check if the correct answer id doesn't match this answers id but this answer is clicked

    //if so, set the variation to dangerSelect(this is selected but incorrect answer)

    //if not, then only check if correct answer id doesn't matches this answers id having in mind that the
    //condition of correct answer id is true, it means that there is a correct answer but it isn't
    //this one since it failed in the first condition. So if it isn't the correct answer and it is not clicked

    //then set variation to danger(this is neither correct nor selected answer)

    //if neither of before mentioned conditions are fulfilled this means this answer is correct answer,
    //since we don't have a variation for selected correct answer in design

    //then set variation to success(this is correct answer)

    //which will set a correct answer and all others would be danger variation (neigher selected nor correct)

    //if the first condition is not fulfilled
    //it means that there is no clicked answer nor there is feedback from backend about correct answer
    //then set variation to primary
    if (props.correctAnswer && props.answerId && props.clickedAnswer) {
      if (parseInt(props.correctAnswer) !== parseInt(props.answerId) && parseInt(props.clickedAnswer) === parseInt(props.answerId)) {
        setVariation("dangerSelect");
      } else if (parseInt(props.correctAnswer) !== parseInt(props.answerId)) {
        setVariation("danger");
      } else {
        setVariation("success");
      }
    } else {
      setVariation("primary");
    }
  }, [props.correctAnswer, props.answerId, props.clickedAnswer]);

  return (
    <AnswerButtonWrapper
      variation={variation}
      width={props.width}
      onClick={() => !props.disabled && props.handleButtonClick(props.answerId, props.questionId)}
      notSelected={notSelected}
      disabled={props.disabled}
      selected={parseInt(props.clickedAnswer) !== 0 && parseInt(props.clickedAnswer) === parseInt(props.answerId)}
    >
      <Text28>{props.answerOrder}</Text28>
      <Circle variation={variation}>
        {variation === "success" && <Icon className="correct-answer" icon="icnChecked" pathFill="white" width={17} height={17} />}
        {(variation === "danger" || variation === "dangerSelect") && <Icon icon="icnX" />}
        {(parseInt(props.clickedAnswer) !== 0 && parseInt(props.clickedAnswer) === parseInt(props.answerId)) && variation === "primary" && <SelectedCircle />}
      </Circle>
    </AnswerButtonWrapper>
  );
}

export default AnswerButton;
