import React, { useContext, Fragment } from 'react';

// imports
import styled, { withTheme } from 'styled-components'
import { SpecHeadingSmall, Text13, Caption12, Text24 } from '../../../Components/Text/Text';
import PageWrapper from '../../../Components/PageWrapper/PageWrapper';
import { AccordionTitleAndLessonInfoContainer, AccordionContent } from '../../../Components/Accordion/Accordion';
import ButtonBasic from '../../../Components/ButtonBasic/ButtonBasic';
import BackLink from '../../../Components/BackLink/BackLink';
import Timer from '../../../Components/Timer/Timer';
import { GlobalContext } from '../../../Context/GlobalContext';
import { useTranslation } from 'react-i18next';
import AccordionContentItem from '../../../Components/Accordion/Subcomponents/AccordionContentItem';
import { useMediaQuery } from 'react-responsive';
import Icon from '../../../Components/Icon/Icon';

//styled components
const SectionCompleteWrapper = styled(PageWrapper)`
    width: 100vw;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
    @media (max-width: 500px){
        background-color: ${props => props.theme.white};
        flex-direction: column;
    }
`

const DimmedDiv = styled.div`
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-color: ${props => props.theme.lightBlack};
    opacity: 0.8;
`

const SectionCompleteHeader = styled.div`
    width: 45%;
    height: 100vh;
    @media (max-width: 500px){
        display: none;
        width: 0px;
    height: 0px;
    }
`

const SectionCompleteContainer = styled.div`
    width: 45%;
    min-height: 100vh;
    height: max-content;
    padding: 0  ${props => props.theme.spacer * 16}px 0px ${props => props.theme.spacer * 16}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    background-color: white;
    text-align: left;
    
@media (max-width: 1041px){
    width: 70%;
}
    @media (max-width: 768px){
        padding: 0;
        width: 100%;
        height: 100vh;
        overflow-y: unset;
        background-color: transparent;
    }
`

const SectionCompleteTitleContainer = styled.div`
    &>h3 {
        margin-bottom: 15px;
    }
    @media (max-width: 768px){
        width: 100%;
        top: 0;
        padding: 60px 60px 100px 60px;
        z-index: 0;
        position: relative;
        &>h3 {
            font-size: 40px;
            line-height: 46.2px;
            text-align: center;
            color: white;
        }

        &>p {
            text-align: center;
            color: white;
        }
    }
`

const SectionCompleteCurve = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 500px){
        position: relative;
        z-index: 2;
        margin-top: -50px;
        padding: 0 ${props => props.theme.spacer * 7}px ${props => props.theme.spacer * 7}px ${props => props.theme.spacer * 7}px;
        background-color: white;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;

    }
`

// const OpacityBorder = styled.div`
//     position: relative;
//     margin-right: 10px;
//     height: 47px;
//     z-index: 22;
// `


const OpacityBorderContainer = styled.div`
    width: 100%;
    &>.top-opacity-border {
        background-image: linear-gradient(to bottom, rgba(255,255,255, 1), rgba(255,255,255, 1), rgba(255,255,255, 0.5), rgba(255,255,255, 0));
        margin-bottom: -45px;
    }
    &>.bottom-opacity-border {
        background-image: linear-gradient(to top, rgba(255,255,255, 1), rgba(255,255,255, 1), rgba(255,255,255, 0.5), rgba(255,255,255, 0));
        margin-top: -45px;
    }
`

const ScrollableSectionsContainer = styled.div`
    width: 100%;
    border-radius: 20px;
    margin-bottom: ${props => props.theme.spacer * 15}px;
    padding: ${props => props.theme.spacer * 9}px ${props => props.theme.spacer * 12}px ${props => props.theme.spacer * 9}px ${props => props.theme.spacer * 6}px;
    background-color: ${props => props.theme.gray0};
    .lessons-and-quizes, .accordion-content{
        margin-left: 35px;
    }
    .lesson-title{
        font-weight: 400;
    }
    .accordion-content{
        width: calc(100% - 35px);
        margin-top: ${props => props.theme.spacer * 8}px;
        margin-bottom: 0px;
        padding-right: ${props => props.theme.spacer * 5}px;
    }
    @media (max-height: 750px){
        margin-bottom: 10px;
    }
    @media (max-width: 768px){
        padding: ${props => props.theme.spacer * 6}px;
        .numeric-indicator{
            font-weight: 400!important;
        }
        .lesson-title{
            width: 100%!important;
            margin-bottom: ${props => props.theme.spacer * 3.5}px;
        }
        .accordion-content{
            margin-top: ${props => props.theme.spacer * 5}px;
        }
    }
`

const CountdownContainer = styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    background-color: ${props => props.theme.lightBlue};
    padding: ${props => props.theme.spacer * 1.5}px ${props => props.theme.spacer * 3.5}px;
    border-radius: 15px;
    img, svg{
        margin-right: ${props => props.theme.spacer * 1.5}px;
    }
    &>p {
        display: flex;
        font-size: 12px;
        color: ${props => props.theme.gray3};
        .seconds{
            color: ${props => props.theme.blue};
            margin-left: 0px;
        }
        .line{
            margin: 0 ${props => props.theme.spacer * 4}px;
            color: ${props => props.theme.gray3}!important;
        }
        &>span:first-child{
            color: ${props => props.theme.blue};
            margin-right:  ${props => props.theme.spacer}px;
        }
        .timer{
            display: flex;
            &:hover{
                cursor: pointer;
                color: ${props => props.theme.blue};
                svg{
                    path{
                        fill: ${props => props.theme.blue};
                    }
                }
            }
            svg {
                align-self: center;
            }
        }
    }
    @media (max-height: 750px){
        margin-bottom: 4px;
    }
    @media (max-height: 820px){
        margin-bottom: ${props => props.theme.spacer * 3}px;
    }
    @media (max-width: 768px){
        margin-top: ${props => props.theme.spacer * 16}px;
    }

`

const ButtonsContainer = styled.div`
    width: max-content;
    align-self: flex-end;
    &>button{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    @media (max-width: 500px){
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &>button {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        a{
            margin-top: ${props => props.theme.spacer * 10}px;
        }
    }
`

//component
const SectionComplete = (props) => {
    const { t } = useTranslation();
    const { signedUser } = useContext(GlobalContext);
    const isMobile = useMediaQuery({ maxWidth: 500 })

    return (
        <SectionCompleteWrapper sectionComplete={props.sectionComplete}>
            <DimmedDiv />
            <SectionCompleteHeader />
            <SectionCompleteContainer>
                <SectionCompleteTitleContainer>
                    <SpecHeadingSmall>{t('learning_mode.section_complete.bravo')} <br />{signedUser.name}</SpecHeadingSmall>
                    <Text13>{t('learning_mode.section_complete.great_job')}</Text13>
                    {/* <DimmedDiv /> */}
                </SectionCompleteTitleContainer>
                <SectionCompleteCurve>
                    <CountdownContainer>
                        <Icon icon="icnCountdown" />
                        {/* make the redirect function inside the if(secconds === 0) condition in useEffect cycle inside Timer component */}
                        <Text13><span>{t('learning_mode.section_complete.starting_in')} </span><Timer seconds={12} timeoutAction={props.nextAction} /></Text13>
                    </CountdownContainer>
                    {/* itterate sections of the course and call Accordion for each section , pass the index as key and pass the section object as section prop (section={section})*/}
                    <OpacityBorderContainer>
                        {/* {isMobile && <OpacityBorder className="top-opacity-border" />} */}

                        <ScrollableSectionsContainer isMobile={isMobile}>
                            {props.course.sections
                                && props.course.sections.map((section, i) => {
                                    if (props.nextSectionId === section.id) {
                                        return (
                                            <Fragment key={i}>
                                                <AccordionTitleAndLessonInfoContainer>
                                                    <div>
                                                        <Caption12 className="numeric-indicator">{i <= 9 && 0}{i && i === 0 ? 1 : i + 1}.    </Caption12>
                                                        <Text24 fontFamily="Raleway" className="lesson-title" fontWeight={600}>{section.title}</Text24>
                                                    </div>
                                                    <Caption12 className="lessons-and-quizes">
                                                        {(section.lessons && (section.lessons.length > 1 ? section.lessons.length + " " + t("general.lessons").toUpperCase() : section.lessons.length + " " + t("general.lesson").toUpperCase()))}
                                                        {(section.quiz && (section.quiz.length > 1 ? " / " + section.quiz.length + " " + t("general.quizes").toUpperCase() : " / " + section.quiz.length + " " + t("general.quiz").toUpperCase()))}
                                                    </Caption12>
                                                </AccordionTitleAndLessonInfoContainer>
                                                <AccordionContent className="accordion-content">
                                                    {section && (section.lessons && section.lessons.map((lesson, i) => (
                                                        <AccordionContentItem key={i} lesson={lesson} courseId={props.course.id} learningPathId={props.learningPathId && props.learningPathId} />
                                                    )
                                                    ))}
                                                    {section && (section.quiz && section.quiz.map((quiz, i) => (
                                                        <AccordionContentItem key={1 + i} quiz={quiz} courseId={props.course.id} learningPathId={props.learningPathId && props.learningPathId} />
                                                    )))}
                                                    {section && (section.code && section.code.map((code, i) => (
                                                        <AccordionContentItem key={1 + i} code={code} courseId={props.course.id} learningPathId={props.learningPathId && props.learningPathId} />
                                                    )))}
                                                </AccordionContent>
                                            </Fragment>
                                            // <Accordion key={i} index={i} section={section} showMore={section.id === props.nextSectionId} courseId={props.course.id} learningPathId={props.learningPathId && props.learningPathId} />
                                        )
                                    } else {
                                        return void 0;
                                    }
                                })

                            }
                        </ScrollableSectionsContainer>

                        {/* {isMobile && <OpacityBorder className="bottom-opacity-border" />} */}
                    </OpacityBorderContainer>

                    <ButtonsContainer>
                        {/* Wrap the Button component in a ButtonContainer (styled(Link)) component and set to prop to lead to next section */}
                        <ButtonBasic variation="primary" text={`${t('general.continue').charAt(0).toUpperCase()}${t('general.continue').slice(1)}`} onClick={props.nextAction} />
                        <BackLink url="/catalogue" text={t('general.back_to_my_courses')} fill={props.theme.blue} />
                    </ButtonsContainer>
                </SectionCompleteCurve>
            </SectionCompleteContainer>
        </SectionCompleteWrapper>
    );
}

export default withTheme(SectionComplete);