import React, { Component } from 'react';

//imports
import { Swiper, Slide } from 'react-dynamic-swiper';
import { Link } from "@reach/router";
import './styles/Carousel.css';
import styled from 'styled-components';

// components
import LearningPathCard from '../LearningPathCard/LearningPathCard';
import CourseCard from '../CourseCard/CourseCard';
import CourseCardFallback from '../CourseCardFallback/CourseCardFallback';
import MyCourseCard from '../MyCourseCard/MyCourseCard';
import TestimonialCard from '../TestimonialCard/TestimonialCard';

import image from '../../Static/course-image.svg';


// styled-components
const CardWrapper = styled.div`
    // grid-column-gap: ${props => props.theme.spacer * 8 + 'px'};
    // padding: ${props => !props.isMobile && props.renderCard === "LearningPathCard" ? "0 18px 30px 12px" : !props.isMobile && "0 18px 30px 0"};
    // &:last-child{
    //   padding: ${props => !props.isMobile && props.renderCard === "LearningPathCard" ? "0 30px 30px 12px" : !props.isMobile && "0 0 30px 0"};
    // }
    padding-bottom: 30px;
    @media (max-width: 500px){
      width: 100%;
    }
`;

// component implemented by Bojan Stojkovski
// additional info in component documentation @ "https://www.npmjs.com/package/react-dynamic-swiper#api"

//component
class Carousel extends Component {
  static defaultProps = {
    cardsPerSlide: 1,
    pagination: true,
    image: image,
    renderCard: "CourseCardFallback",
  }
  constructor({ cardData, cardsPerSlide, renderCard, navigation, pagination, image, columns, header, href, route, isMobile }) {
    super()
    this.state = {
      slideCount: 0,
      options: {
        navigation: false,
        pagination: true,
        paginationClickable: true,
        scrollBar: false,
        loop: true
      }
    }
    this.setUpCards = this.setUpCards.bind(this)
  }

  //set the number of slides in state depending on the length of the array received trough props
  componentDidMount() {
    let numberOfSlides = Math.ceil(this.props.cardData.length > 0 && this.props.cardsPerSlide && this.props.cardData.length / this.props.cardsPerSlide);
    this.setState({ slideCount: this.props.isMobile ? this.props.cardData.length : numberOfSlides });
    let pagination = this.props.isMobile ? false : this.props.pagination === false ? false : true;
    this.setState({ options: { navigation: this.props.navigation, pagination: pagination, paginationClickable: true, scrollBar: false, loop: this.props.loop === "false" ? false : true } })
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.cardData !== this.props.cardData) {
  //     let numberOfSlides = Math.ceil(this.props.cardData.length / this.props.cardsPerSlide);
  //     this.setState({ slideCount: this.props.isMobile ? this.props.cardData.length : numberOfSlides });
  //     let pagination = this.props.isMobile ? false : true;
  //     this.setState({ options: { navigation: this.props.navigation, pagination: pagination, paginationClickable: true, scrollBar: false, loop: this.props.loop === "false" ? false : true } })
  //   }
  // }

  setUpCards = () => {
    return this.props.isMobile && this.props.renderCard === "LearningPathCard" ? 1.3
      : this.props.renderCard === "LearningPathCard" ? 1
        : this.props.isMobile && this.props.renderCard === "CourseCard" ? 2.4
          : this.props.renderCard === "CourseCard" ? 1
            : this.props.isSmallLaptop && this.props.renderCard === "CourseCard" ? 3
              : this.props.isMobile && this.props.renderCard === "MyCourseCard" ? 1.7
                : this.props.isMobile && this.props.renderCard === "TestimonialCard" && 1.7;
  }

  render() {
    let offset = 0;

    // define which component to render trough a string in props from this const
    const componentMapping = {
      LearningPathCard,
      CourseCard,
      MyCourseCard,
      TestimonialCard,
      CourseCardFallback
    }

    const RenderComponent = componentMapping[this.props.renderCard];
    return (
      <div className="swiper">
        <Swiper
          swiperOptions={{
            slidesPerView: this.setUpCards(),
            scrollbarHide: false,
            grabCursor: true,
            freeMode: false,
            spaceBetween: 10
          }}
          {...this.state.options}
        >
          {/* making new array from an integer to map and create number of slides based on that integer (slideCount) */}
          {(new Array(this.state.slideCount).fill(null).map((_, i) => {
            let cardsToRender = this.props.cardData.length > 0 && this.props.cardData.slice(this.props.isMobile ? i : offset, this.props.isMobile ? i + 1 : offset + this.props.cardsPerSlide);
            offset = offset + this.props.cardsPerSlide;
            return (
              <Slide
                key={i}
                style={!this.props.isMobile ? { display: "grid", gridGap: !this.props.isMobile && "19px", gridTemplateColumns: this.props.gridTemplateColumns ? this.props.gridTemplateColumns : this.props.renderCard === "LearningPathCard" ? "1fr 1fr" : this.props.cardsPerSlide === 5 ? "1fr 1fr 1fr 1fr 1fr" : this.props.cardsPerSlide === 3 ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr" } : {}}
              >
                {cardsToRender.map((card, index) => {
                  return (

                    <CardWrapper key={index} isMobile={this.props.isMobile} renderCard={this.props.renderCard}>
                      {card.coming_soon && card.coming_soon !== 0
                        ? <RenderComponent

                          cardComingSoon={card.coming_soon && card.coming_soon !== 0}
                          id={card.id && card.id}
                          cardImage={card.image ? card.image : this.props.image}
                          cardTitle={card.title && card.title}
                          cardSubtitle={card.subtitle && card.subtitle}
                          cardDescription={card.description && card.description}
                          cardCategory={this.props.header ? this.props.header : card.category && card.category.name}
                          cardPoints={card.points && card.points}
                          cardStudentsNumber={this.props.renderCard !== "LearningPathCard" && card.total_students && card.total_students}
                          cardTime={card.duration && card.duration}
                          cardProgress={card.progress && card.progress}
                          cardCourseNumber={card.courses && card.courses.length}
                          cardImgWidth={170}
                          cardMinHeight={160}
                          cardLocation={this.props.cardLocation && this.props.cardLocation}
                          isMobile={this.props.isMobile}
                          course={card}
                          cardExclusive={this.props.exclusive}
                        />
                        : this.props.renderCard !== "TestimonialCard"
                          ? <Link to={`/${this.props.route}/${card.id ? card.id.toString() : this.props.href}`} style={{ textDecoration: "none", color: "unset" }}>
                            <RenderComponent
                              id={card.id && card.id}
                              cardImage={card.image ? card.image : this.props.image}
                              cardTitle={card.title && card.title}
                              cardSubtitle={card.subtitle && card.subtitle}
                              cardDescription={card.description && card.description}
                              cardCategory={this.props.header ? this.props.header : card.category && card.category.name}
                              cardPoints={card.points && card.points}
                              cardStudentsNumber={this.props.renderCard !== "LearningPathCard" && card.total_students && card.total_students}
                              cardTime={card.duration && card.duration}
                              cardProgress={card.progress && card.progress}
                              cardCourseNumber={card.courses && card.courses.length}
                              cardImgWidth={170}
                              cardMinHeight={160}
                              cardLocation={this.props.cardLocation && this.props.cardLocation}
                              isMobile={this.props.isMobile}
                              course={card}
                              cardExclusive={this.props.exclusive}
                            />
                          </Link>
                          : <RenderComponent
                            id={card.id && card.id}
                            cardImage={card.image ? card.image : this.props.image}
                            cardTitle={card.title && card.title}
                            cardSubtitle={card.subtitle && card.subtitle}
                            cardDescription={card.description && card.description}
                            cardCategory={this.props.header ? this.props.header : card.category && card.category.name}
                            cardPoints={card.points && card.points}
                            cardStudentsNumber={this.props.renderCard !== "LearningPathCard" && card.total_students && card.total_students}
                            cardTime={card.duration && card.duration}
                            cardProgress={card.progress && card.progress}
                            cardCourseNumber={card.courses && card.courses.length}
                            cardImgWidth={170}
                            cardMinHeight={160}
                            cardLocation={this.props.cardLocation && this.props.cardLocation}
                            isMobile={this.props.isMobile}
                            course={card}
                            cardExclusive={this.props.exclusive}
                          />
                      }
                    </CardWrapper>
                  )
                })}
              </Slide>
            )
          }))}
        </Swiper>
      </div>
    )
  }
}

export default Carousel;