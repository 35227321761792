import React from 'react';

//imports
import styled, { withTheme } from 'styled-components';
import { Caption12 } from '../Text/Text';
import Icon from '../Icon/Icon';

//styled-components
const SidebarItemContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    p{ 
        width: 125px;
        color: ${props => props.active ? props.theme.blue : "black"};
    }
    &:hover {
        cursor: pointer;
        .inactive-icon{
            display: none;
        }
        p{
            color: ${props => props.theme.blue};
        }
    }
    .icon{
        z-index: 9999;
        display: none;
        position: absolute;
        transition: opacity 1s;
    }
    .icon0{
        right: 14px;
        bottom: 33px;
    }
    
    .icon1{
        right: 13px;
        bottom: 32px;
    }
    
    .icon2{
        right: 13px;
        bottom: 34px;
    }
    
    
    
    @media (max-width: 500px){
        p{
            display: none;
        }
        &:hover{
            .active-icon{
                display: block;
            }
        }
        .active-icon{
            display: block;
            opacity: 1;
        }
        .inactive-icon{
            display: none;
            opacity: 0;
        }
    }
`

// const Icon = styled.img`
//     display: none;
//     width: ${props => props.width}px;
//     height: ${props => props.height}px;

// `

const SidebarIndicatorContainer = styled.div`
width: 40px;
display: flex;
justify-content: flex-end;
&>div{
    display: flex;
    flex-direction: column;
}
`

const SidebarTopShapeContainer = styled.div`
width: ${props => props.active ? "25px" : "0px"};
    transition: width 0.1s;
    overflow: hidden;
    height: 20px;
    align-self: flex-end;
    background-color: white;
    &>div {
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.danger_select};
        border-bottom-right-radius: 100%;
    }
`

const SidebarMiddleShapeContainer = styled.div`
    width: ${props => props.active ? "40px" : "0px"};
    transition: width 0.3s;
    align-self: flex-end;
    overflow: hidden;
    height: 40px;
    background-color: white;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &>div{
        width: 10px;
        height: 10px;
        background-color: ${props => props.theme.blue};
        border-radius: 50%;
    }
    @media (max-width: 500px){
        &>div{
            display: none;
        }
        &>img{
            display: block;
        }
    }
`

const SidebarBottomShapeContainer = styled.div`
    width: ${props => props.active ? "25px" : "0px"};
    transition: width 0.1s;
    height: 20px;
    align-self: flex-end;
    background-color: white;
    &>div {
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.danger_select};
        border-top-right-radius: 100%;
    }
`

//component
const SidebarItem = ({ active, onClick, argument_1, height, width, icon, text, index, theme }) => {
    return (
        <SidebarItemContainer className="cont" active={active} onClick={() => onClick(argument_1 && argument_1)}>
            <div>
                <Icon className={`${!active ? "active-icon" : "inactive-icon"} icon icon${index}`} icon={icon} pathFill={theme.gray3} width={width} height={height} />
            </div>
            <Caption12 >{text}</Caption12>
            <SidebarIndicatorContainer className="ind">
                <div>
                    <SidebarTopShapeContainer active={active}>
                        <div></div>
                    </SidebarTopShapeContainer>
                    <SidebarMiddleShapeContainer active={active}>
                        <Icon className={active ? "active-icon icon icon" + index : "inactive-icon icon icon" + index} icon={icon} width={width} height={height} />
                        <div></div>
                    </SidebarMiddleShapeContainer>
                    <SidebarBottomShapeContainer active={active}>
                        <div></div>
                    </SidebarBottomShapeContainer>
                </div>
            </SidebarIndicatorContainer>
        </SidebarItemContainer>
    );
}

export default withTheme(SidebarItem);