import React, { useState, useEffect, useContext } from 'react';

//npm imports
import styled from 'styled-components';
import { navigate } from "@reach/router";

//component imports
import { API } from '../../Consts/Api';
import { GlobalContext } from '../../Context/GlobalContext';
import { Text20 } from '../../Components/Text/Text';

//styled-components
const EmailVerificationWrapper = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

//component
const EmailVerification = (props) => {
    const [error, setError] = useState('');
    const { signedUser, setSignedUser } = useContext(GlobalContext);
    useEffect(() => {
        API.axios.get(API.createApiRoute("verifyEmail", { TOKEN: props.token }))
            .then((response) => {

                if (response.status === 200) {
                    //store signed user data in global context
                    localStorage.setItem('user', JSON.stringify(response.data))
                    setSignedUser(response.data)
                }
                if (process.env.REACT_APP_STATUS === "prelaunch") {
                    navigate(API.createRoute("applicationStatus").replace("{STUDENT}", signedUser.student))
                } else {
                    navigate(API.createRoute("catalogue"));
                }
            })
            .catch((error) => {
                setError(error.message);
                if (process.env.REACT_APP_STATUS === "prelaunch") {
                    navigate(API.createRoute("applicationStatus").replace("{STUDENT}", signedUser.student))
                } else {
                    navigate(API.createRoute("catalogue"));
                }
            })
    }, [props.token, setSignedUser, signedUser.student]);
    return (
        <EmailVerificationWrapper>
            <Text20>
                {error}
            </Text20>
        </EmailVerificationWrapper>

    );
}

export default EmailVerification;