import React from 'react';

//npm imports
import styled, { keyframes } from 'styled-components';

//component imports
import { Heading28 } from '../Text/Text';

//animation
const progressAnimation = (progress) => keyframes`
    from{
        width: 0%;
    }
    to{
        width: ${progress}%;
    }
`

//styled-components
const CardProgressbarContainer = styled.div`
    width: 90%;
    height: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: ${props => props.theme.danger_select};
    border-radius: 8px;
    display: flex;
    @media (max-width: 500px){
        width: 100%;
        height: 12px;
        margin: 11px 0;
    }
`

const CardProgressBar = styled.div`
    height: 16px;
    border-radius: 8px;
    background: linear-gradient(to right, ${props => props.theme.gray3}, ${props => props.background} 40% , ${props => props.background} 100%);
    animation: ${props => progressAnimation(props.progress)} 1.5s ease-in-out forwards;
    animation-delay: 0.4s;
    animation-timing-function: ${props => props.theme.expo};
    @media (max-width: 500px){
        height: 12px;
        border-radius: 6px;
    }
`

const CardProgressIndicator = styled.div`
    width: 82px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 22px;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.1);
    margin-top: -15px;
    margin-left: ${props => props.progress > 70 ? "-46px" : props.progress > 50 ? "-40px" : props.progress > 35 ? "-30px" : props.progress > 20 ? "-15px" : "-8px"};
    h4{
        width: max-content;
        color: ${props => props.color};
    }
    @media (max-width: 500px){
        width: 52px;
        height: 34px;
        margin-top: -11px;
        margin-left: -5px;
        h4{
            font-size:  20px;
            line-height: 23px;
        }
    }
`


//card-progress component
const CardProgress = (props) => {
    return (
        <CardProgressbarContainer>
            <CardProgressBar progress={props.progress} background={props.color}>
            </CardProgressBar>
            <CardProgressIndicator className="course-progress-indicator" color={props.color} progress={props.progress}>
                <Heading28>{props.progress}%</Heading28>
            </CardProgressIndicator>
        </CardProgressbarContainer>
    )
}

export default CardProgress;