import React from 'react';

//npm imports
import styled, { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

//component imports
import { SpecHeadingSmaller, Caption16 } from '../../../Components/Text/Text';
import Wrapper from '../../../Components/Wrapper/Wrapper';
import Icon from '../../../Components/Icon/Icon';
import PrelaunchAccordion from '../../../Components/PrelaunchAccordion/PrelaunchAccordion';

//other imports
import step_1_prize from "../../../Static/prelaunch-prizes/step_1_rules.png";
import step_2_prize from "../../../Static/prelaunch-prizes/step_2_rules.png";
import step_3_prize from "../../../Static/prelaunch-prizes/step_3_rules.png";
import step_4_prize from "../../../Static/prelaunch-prizes/step_4_rules.png";

//styled-components
const PrelaunchRulesWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: ${props => props.theme.black};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
        background: #FF0000;
    }
    .rules-navigation{
        width: 100%;
        padding: 42px 0;
        ul{
            width: 100%;
            display: flex;
            justify-content: space-between;
            li{
                display: flex;
                align-items: center;
                .arrow-icon{
                    transform: rotate(180deg);
                }
                p{
                    margin-left: 20px;
                    color: ${props => props.theme.white};
                }

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    .rules-container{
        margin-top: 74px;
        &>div{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            h3{
                align-self: flex-start;
                width: 30%;
                color: ${props => props.theme.white};
            }
            .rules-content-container{
                width: 65%;
            }
        }
    }
    @media (max-width: 500px){
        .rules-navigation{
            ul{
                li{
                    p{
                        font-size: 13px;
                        margin-left: 5px;
                    }
                }
            }
        }
        .rules-container{
            &>div{
                flex-direction: column;
                h3{
                    width: 100%;
                    margin-bottom: 70px;
                }
                .rules-content-container{
                    width: 100%;
                }
            }
        }
    }
`

const rules = [
    {
        heading: "prelaunch.rules.points.right_to_participate.heading",
        content: "prelaunch.rules.points.right_to_participate.content"
    },
    {
        heading: "prelaunch.rules.points.prizes.heading",
        content: [
            {
                text: "prelaunch.rules.points.prizes.step1",
                image: step_1_prize
            },
            {
                text: "prelaunch.rules.points.prizes.step2",
                image: step_2_prize
            }, {
                text: "prelaunch.rules.points.prizes.step3",
                image: step_3_prize
            }, {
                text: "prelaunch.rules.points.prizes.step4",
                image: step_4_prize
            }
        ]
    },
    {
        heading: "prelaunch.rules.points.duration.heading",
        content: "prelaunch.rules.points.duration.content"
    }
]

//component
const PrelaunchRules = (props) => {
    const { t } = useTranslation();
    return (
        <PrelaunchRulesWrapper>
            <nav className="rules-navigation">
                <Wrapper>
                    <ul>
                        <li onClick={() => props.takeMeBackAction(false)}>
                            <Icon icon="brainsterNextLogo" />
                        </li>
                        <li onClick={() => props.takeMeBackAction(false)}>
                            <Icon icon="arrowRight" pathFill={props.theme.white} className="arrow-icon" />
                            <Caption16>{t("general.take_me_back")}</Caption16>
                        </li>
                    </ul>
                </Wrapper>
            </nav>
            <main className="rules-container">
                <Wrapper>
                    <SpecHeadingSmaller>{t("prelaunch.rules.heading")}</SpecHeadingSmaller>
                    <div className="rules-content-container">
                        {rules && rules.map((rule, index) =>
                            <PrelaunchAccordion key={index} heading={rule.heading} description={rule.content} />)
                        }
                        {/* <PrelaunchAccordion heading="Kako da dobijam nagrada" description="Our referral page had more enigmatic design and copy. A picture of a wooly mammoth was coupled with the words: “Shaving is evolving. Don’t leave your friends behind.” Again, we wanted people to feel that something big was happening to which they had front row seat and the opportunity to invite friends to join them. Our first customers were insiders and we wanted to make them feel like insiders." /> */}
                    </div>
                </Wrapper>
            </main>
        </PrelaunchRulesWrapper>

    );
}

export default withTheme(PrelaunchRules);