import React from 'react';

import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../Consts/GlobalStyle';
import theme from '../../Consts/Theme';
// import Icon from '../Icon/Icon';
import loader from '../../Static/Loader.gif';




const LayoutTestWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px;
    background-color: red;
    img{
        
    }
`


const LayoutTest = () => {
    // const { t } = useTranslation();

    return (

        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <LayoutTestWrapper>
                <img src={loader} alt="" />
            </LayoutTestWrapper>

        </ThemeProvider>
    );
}

export default LayoutTest;