import React, { useState, useEffect } from 'react';

//imports
import styled from 'styled-components';
import { Text10 } from '../Text/Text';

//styled-components
const PasswordStrengthIndicatorContainer = styled.div`
    width: 100%;
    display: flex;

    .indicators-container{
        width: 90%;
        height: 9px;
        display: flex;
        align-items: center;
        .indicator{
            width: 24%;
            margin-right: 2px;
            height: 6px;
            background-color: ${props => props.theme.gray3};
            transition: background-color 0.1s linear 0.1s;
        }
        .indicator-1{
            background-color: ${props => props.passwordStrength === 0 ? props.theme.incorrect : props.passwordStrength === 1 ? props.theme.orange : props.passwordStrength === 2 ? props.theme.yellow : props.passwordStrength >= 3 && props.theme.success}
        }
        .indicator-2{
            background-color: ${props => props.passwordStrength === 1 ? props.theme.orange : props.passwordStrength === 2 ? props.theme.yellow : props.passwordStrength >= 3 && props.theme.success}
        }
        .indicator-3{
            background-color: ${props => props.passwordStrength === 2 ? props.theme.yellow : props.passwordStrength >= 3 && props.theme.success};
        }
        .indicator-4{
            background-color: ${props => props.passwordStrength >= 3 && props.theme.success}
        }
    }
    .strength-message-container {
        p{
            color: ${props => props.passwordStrength === 0 ? props.theme.incorrect : props.passwordStrength === 1 ? props.theme.orange : props.passwordStrength === 2 ? props.theme.yellow : props.passwordStrength >= 3 && props.theme.success};
        }
    }
`

//component
const PasswordStrengthIndicator = ({ password }) => {
    //hook bellow keeps password strength index on a scale from 0 to 4
    const [passwordStrength, setPasswordStrength] = useState(0);

    //this hook keeps array of messages to display on index from 0 to 4 according to passwordStrength
    const passwordStrengthMessage = ["weak", "weak", "normal", "good", "great"];

    //RegExp to validate if password (string) contains at least 6 characters
    const passwordLength = new RegExp(".{6}");

    //RegExp to validate if password (string) contans at least 2 uppercase letters
    const passwordHas2UppercaseLettersRegExp = new RegExp("(?=.*[A-Z].*[A-Z])");

    //RegExp to validate if password (string) contains at least 1 uppercase letters
    const passwordHas1UppercaseLetterRegExp = new RegExp("(?=.*[A-Z])");

    //RegExp to validate if password (string) contains at least 2 special characters
    const passwordHas2SpecialCharactersRegExp = new RegExp("(?=.*[!@#$&*()<,.?[]].*[!@#$&*()<,.?[]])");

    //RegExp to validate if password (string) contains at least 1 special character
    const passwordHas1SpecialCharacterRegExp = new RegExp("(?=.*[!@#$&*()<,.?[]])");

    //RegExp to validate if password (string) contains at least 2 digits
    const passwordHas2NumbersRegExp = new RegExp("(?=.*[0-9].*[0-9])");

    //RegExp to validate if password (string) contains at least 1 digit
    const passwordHas1NumberRegExp = new RegExp("(?=.*[0-9])");

    useEffect(() => {
        let passwordStrength = 1;

        //validate password string in the defined RegExps
        let passwordHas2UppercaseLetters = passwordHas2UppercaseLettersRegExp.test(password);
        let passwordHas1UppercaseLetter = passwordHas1UppercaseLetterRegExp.test(password);
        let passwordHas2SpecialCharacters = passwordHas2SpecialCharactersRegExp.test(password);
        let passwordHas1SpecialCharacter = passwordHas1SpecialCharacterRegExp.test(password);
        let passwordHas2Numbers = passwordHas2NumbersRegExp.test(password);
        let passwordHas1Number = passwordHas1NumberRegExp.test(password);

        //only if the password contains at least 6 characters do we test it's strength further
        if (passwordLength.test(password)) {
            //then again if the password contains at least 2 of each digits, special characters and uppercase letters we score it with an index of 4
            if (passwordHas2Numbers && passwordHas2SpecialCharacters && passwordHas2UppercaseLetters) {
                passwordStrength = 4;
            } else if (
                //if it's not 4 in strength, then we see if it has at least 1 of each combined with 2 of 1
                //(exp: at least 2 digits + 1 uppercase letter + 1 special character), 
                //then we score password with an index of 3
                (passwordHas2Numbers && passwordHas1SpecialCharacter && passwordHas1UppercaseLetter)
                || (passwordHas2SpecialCharacters && passwordHas1Number && passwordHas1UppercaseLetter)
                || (passwordHas2UppercaseLetters && passwordHas1Number && passwordHas1SpecialCharacter)
            ) {
                passwordStrength = 3
            } else if (
                (passwordHas1SpecialCharacter && passwordHas1UppercaseLetter)
                || (passwordHas1SpecialCharacter && passwordHas1Number)
                || (passwordHas1UppercaseLetter && passwordHas1Number)
            ) {
                passwordStrength = 2
            } else if (passwordHas1SpecialCharacter || passwordHas1UppercaseLetter || passwordHas1Number) {
                passwordStrength = 1
            }
        }
        setPasswordStrength(passwordStrength)
    }, [password,
        passwordHas2UppercaseLettersRegExp,
        passwordHas1UppercaseLetterRegExp,
        passwordHas2SpecialCharactersRegExp,
        passwordHas1SpecialCharacterRegExp,
        passwordHas2NumbersRegExp,
        passwordHas1NumberRegExp,
        passwordLength
    ]);
    return (
        <PasswordStrengthIndicatorContainer passwordStrength={password.split("").length >= 6 && passwordStrength - 1}>
            <div className="indicators-container">
                <div className="indicator indicator-1"></div>
                <div className="indicator indicator-2"></div>
                <div className="indicator indicator-3"></div>
                <div className="indicator indicator-4"></div>
            </div>
            <div className="strength-message-container">
                <Text10>{password.split("").length >= 6 && passwordStrengthMessage[passwordStrength]}</Text10>
            </div>
        </PasswordStrengthIndicatorContainer>
    );
}

export default PasswordStrengthIndicator;