import React, { useState, useEffect, useRef } from 'react';

//imports
import styled from 'styled-components';
import { useMatch } from '@reach/router';
import LearningPathCard from '../../../Components/LearningPathCard/LearningPathCard';
import { API } from '../../../Consts/Api';

//styled-components
const LearningPathCoursesWrapper = styled.div`
    width: 55%;
    max-height: 750px;
    overflow-y: ${props => props.scroll ? "scroll" : "unset"};
    padding-left: 10px;
    @media (max-width: 768px){
        width: 100%;
        max-height: unset;
        overflow-y: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`

const LearningPathCardsContainer = styled.div`
    width: 100%;
   
`

const CourseWrapper = styled.div`
    padding: 10px 0 10px 10px;
    @media (max-width: 500px){
        padding: 10px;
    }
`

//components
const LearningPathCourses = (props) => {
    const containerRef = useRef()
    const [containerHeight, setContainerHeight] = useState();

    //reach router hook, used to get learning path id from location and use it to navigate to course route including the learning path id
    const match = useMatch('/learning-path/:learningPathId')

    //on change of props.courses, setContainerHeight to the height of the containerRef.current element. Used in accordion transition styles.
    useEffect(() => {
        setContainerHeight(containerRef.current.clientHeight)
    }, [props.courses]);
    return (
        <LearningPathCoursesWrapper ref={containerRef} scroll={containerHeight === 750 && true}>
            <LearningPathCardsContainer>
                {props.courses.map((course, i) => {
                    return (
                        <CourseWrapper key={i}>
                            {course.comming_soon && course.comming_soon !== 0
                                ? <LearningPathCard
                                    cardCommingSoon={course.comming_soon && course.comming_soon !== 0}
                                    cardTitle={course.title}
                                    cardCategory={course.category}
                                    cardStudentsNumber={course.total_students}
                                    cardImage={course.image}
                                    cardProgress={course.progress}
                                />
                                : <a href={API.createRoute("getCourseWithPath", { LEARNINGPATH_ID: match.learningPathId, COURSE_ID: course.id })}>
                                    <LearningPathCard
                                        cardTitle={course.title}
                                        cardCategory={course.category}
                                        cardStudentsNumber={course.total_students}
                                        cardImage={course.image}
                                        cardProgress={course.progress}
                                    />
                                </a>
                            }

                        </CourseWrapper>
                    )
                })}
            </LearningPathCardsContainer>
        </LearningPathCoursesWrapper>
    );
}

export default LearningPathCourses;