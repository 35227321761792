import React, { useState, useEffect } from 'react';

//imports
import styled from 'styled-components';
import AnswerButton from '../../../Components/AnswerButton/AnswerButton';
// import ButtonBasic from '../../../Components/ButtonBasic/ButtonBasic';
import { API } from '../../../Consts/Api';
import { Text16, Text10 } from '../../../Components/Text/Text';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Components/Icon/Icon';
import { withTheme } from 'styled-components';

//styled-components
const QuizAnswersWrapper = styled.div`
    min-width: 246px;
    padding-left: 10%;
    flex-basis: 0;
    flex-grow: 1;
    align-self: center;
    height: 100%;
    &>div{
        min-width: 246px;
        width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    button {
        :focus {
            outline: none;
        }
    }
    @media (max-width: 500px){
        padding-left: 0px;
        width: 100%;
        &>div {
            width: 100%;
        }
    }
`

const QuizAnswerButtonsContainer = styled.div`
    width: 200px;
    align-self: flex-end;
    @media (max-width: 500px){
        width: 100%;
        margin-bottom: 30px;
    }
`

const QuizResultDescription = styled.div`
    display: ${props => props.show ? "flex" : "none"};
    flex-direction: column;
    
    background-color: ${props => props.correct ? "#E4F3EA" : "#FDE2DF"};
    width: 100%;
    min-width: 246px;
    padding: 18px 19px;
    border-radius: 7px;
    .title-container{
        display: flex;
        .icon-container{
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 13px;
            margin-right: 14px;
            background-color: ${props => props.correct ? props.theme.success : props.theme.danger};
            .icnCorrect{
                display: ${props => props.correct ? "block" : "none"};
            }
            .icnIncorrect{
                display: ${props => props.correct ? "none" : "block"};
            }
        }

        ${Text16}{
            color: ${props => props.correct ? props.theme.success : props.theme.danger};
        }
    }
    &>${Text10}{
        width: calc(100% - 40px);
        align-self: flex-end;
    }
`

// const CheckButtonContainer = styled.div`
//     align-self: flex-end;
//     margin-top: 34px;
//     &>button {
//         margin: 0px;
//         &>span{
//             font-size: 16px!important;
//         }
//     }
//     @media (max-width: 500px){

//         margin-top: 14px;
//         &>button {
//             margin: 0px;
//             border-radius: 25px;
//             padding: 15px 50px;
//             &>span{
//                 font-size: 14px!important;
//             }
//         }
//     }
// `

//component
const QuizAnswers = (props) => {
    const { t } = useTranslation();
    // const [checkDisabled, setCheckDisabled] = useState(true);
    const [disableAnswers, setDisableAnswers] = useState(false);
    const [questionId, setQuestionId] = useState(0)
    const [clickedAnswer, setClickedAnswer] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState();
    const [explanation, setExplanation] = useState();

    useEffect(() => {
        if (props.questionId !== questionId) {
            setClickedAnswer(undefined)
            setCorrectAnswer(undefined)
            setDisableAnswers(false)
        }
        if (props.answerInfo) {
            setClickedAnswer(props.answerInfo.student_answer)
            setCorrectAnswer(props.answerInfo.correct_answer)
            setExplanation(props.answerInfo.explanation);
            setDisableAnswers(true)
            // setCheckDisabled(true);
        }

        props.answeredQuestions.forEach(answeredQuestion => {
            if (answeredQuestion.question_id === props.questionId) {
                setClickedAnswer(answeredQuestion.answer_id)
                setCorrectAnswer(answeredQuestion.correct_answer_id)
                setExplanation(answeredQuestion.explanation);
                setDisableAnswers(true)
                // setCheckDisabled(true);
            }
        })
    }, [props.answerInfo, props.answeredQuestions, props.questionId, questionId]);

    const checkAnswer = (question_id, answer_id) => {
        API.axios.post(API.createApiRoute("checkAnswer"), {
            "question_id": question_id,
            "answer_id": answer_id
        })
            .then(response => {
                setCorrectAnswer(response.data.data.correct_answer);
                setExplanation(response.data.data.explanation);
                props.addAnsweredQuestion(question_id, answer_id, response.data.data.correct_answer, response.data.data.explanation);
                setDisableAnswers(true);
                // setCheckDisabled(true);
            })
            .catch(err => {
                console.log("Ooops...");
            })
    }

    const handleButtonClick = (currentAnswer, question_id) => {
        // setClickedAnswer(currentAnswer);
        setQuestionId(parseInt(question_id))
        // setCheckDisabled(false);
        checkAnswer(question_id, currentAnswer)
    }

    return (
        <QuizAnswersWrapper>
            <div>

                <QuizAnswerButtonsContainer>
                    {props.answers.map((answer, i) => {
                        return (
                            <AnswerButton
                                key={"answer-button " + i}
                                mobileWidth="100"
                                width={"200px"}
                                answerOrder={props.answerLetters[i]}
                                questionId={props.questionId}
                                answerId={answer.id}
                                clickedAnswer={clickedAnswer}
                                correctAnswer={correctAnswer}
                                disabled={disableAnswers}
                                handleButtonClick={handleButtonClick}
                                setCorrectAnswer={setCorrectAnswer}
                                setDisableAnswers={setDisableAnswers}
                            />
                        )
                    })}
                </QuizAnswerButtonsContainer>
                <QuizResultDescription show={correctAnswer > -1} correct={correctAnswer === clickedAnswer}>
                    {correctAnswer > -1 &&
                        <>
                            <div className="title-container">
                                <div className="icon-container">
                                    <Icon className="icnCorrect" icon={"icnChecked"} pathFill={props.theme.white} width={14} height={10} />
                                    <Icon className="icnIncorrect" icon={"icnX"} pathFill={props.theme.red} width={14} height={14} />
                                </div>
                                <Text16 fontWeight={700}>
                                    {correctAnswer !== clickedAnswer ? t("learning_mode.quiz.incorrect") : t("learning_mode.quiz.correct")}
                                </Text16>
                            </div>
                            <Text10>
                                {explanation}
                            </Text10>
                        </>
                    }
                </QuizResultDescription>
                {/* Check Button was deprecated just before initial launch, left it here just in case, very expensive to write it again */}
                {/* <CheckButtonContainer>
                    <ButtonBasic
                        disabled={checkDisabled}
                        hidden={disableAnswers}
                        variation={checkDisabled ? "quaternary" : "terniary"}
                        text={`${t("learning_mode.quiz.check")}`}
                        onClick={() => checkAnswer(questionId, clickedAnswer)}
                    />
                </CheckButtonContainer> */}
            </div>
        </QuizAnswersWrapper>

    );
};

export default withTheme(QuizAnswers);