import React, { useContext } from 'react';

//npm imports
import { ThemeProvider } from 'styled-components';
import { Router, LocationProvider } from '@reach/router';
import { useTranslation } from 'react-i18next';

//component imports
import GlobalStyle from './Consts/GlobalStyle';
import { GlobalContext } from './Context/GlobalContext';
import LayoutTest from './Components/LayoutTest/LayoutTest';
import Catalogue from './Pages/Catalogue/Catalogue';
import Course from './Pages/Course/Course';
import SignIn from './Pages/SignIn/SignIn';
import Register from './Pages/Register/Register';
import LearningPath from './Pages/LearningPath/LearningPath';
import Navbar from './Components/Navbar/Navbar';
import FooterSimple from './Components/FooterSimple/FooterSimple';
import Dashboard from './Components/Dashboard'
import HomePage from './Pages/Homepage/Homepage';
import LearningMode from './Pages/LearningMode/LearningMode';
import WellDoneRate from './Pages/WellDone/CourseWellDone/WellDoneRate';
import WhatsNext from './Pages/WellDone/CourseWellDone/WhatsNext';
import RecoverPassword from './Pages/ForgotPassword/RecoverPassword';
import ResetPassword from './Pages/ForgotPassword/ResetPassword';
import MyCourses from './Pages/MyCourses/MyCourses';
import MyProfile from './Pages/MyProfile/MyProfile';
import GlobalEvents from './Components/GlobalEvents';
import ReferAFriend from './Pages/ReferAFriend/ReferAFriend';
import VerificationMessage from './Components/VerificationMessage/VerificationMessage';
import EmailVerification from './Pages/EmailVerification/EmailVerification';
import PrelaunchRegister from './Pages/Prelaunch/PrelaunchRegister/PrelaunchRegister.jsx'
import PrelaunchStatus from './Pages/Prelaunch/PrelaunchStatus/PrelaunchStatus.jsx';
import RegisterSteps from './Pages/RegisterSteps/RegisterSteps';

//other imports
import theme from './Consts/Theme';
import pointer_image from './Static/pointer_image.png';
import GettingStarted from './Components/GettingStarted/GettingStarted';
import PremiumCourse from './Pages/PremiumCourse/PremiumCourse';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';

// import Footer from './Components/Footer/Footer'

const App = () => {
  const { t } = useTranslation();
  const { isBodyScrollable, appConfig } = useContext(GlobalContext)
  return (
    <LocationProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle isBodyScrollable={isBodyScrollable} />
        <GlobalEvents />
        <VerificationMessage except={process.env.REACT_APP_STATUS === "prelaunch" ? ['/apply'] : ['/', '/register(/steps)?', '/register(.*)?', '/signin', '/learning-mode(.*)', '/student/recover(/confirm)?', '/student/reset(.*)?', '/raf/(.*)', '/verify/token', '/refer', "/apply"]}>
          {process.env.REACT_APP_STATUS === "prelaunch"
            ? <><img className="pointer-image" src={pointer_image} alt="pointer hand" />
              {t("prelaunch.verification_message")} </>
            : <> {t("verification.fail")}</>
          }

        </VerificationMessage>
        <GettingStarted except={['/', '/register(/steps)?', '/register(.*)?', '/signin', '/learning-mode(.*)', '/student/recover(/confirm)?', '/student/reset(/.*)?', '/refer-a-friend', "/apply(/status|.*)?", "/rules"]} />
        <Navbar
          except={['/', '/register(/steps)?', '/register(.*)?', '/signin', '/learning-mode(.*)', '/student/recover(/confirm)?', '/student/reset(/.*)?', '/refer-a-friend', "/apply(/status|.*)?", "/rules"]}
        />
        {/* 
        The primary flag prevents from the sticky scroll position
        read here: https://reach.tech/router/api/Router
       */}
        <Router primary={true}>
          <NotFoundPage default />
          <PrelaunchRegister path="/apply" />
          <PrelaunchRegister path="/apply/:userToken" />
          <PrelaunchStatus path="/apply/status/:student" />
          {process.env.REACT_APP_STATUS !== "prelaunch" &&
            <>
              <HomePage path="/" />
              <Catalogue path="/catalogue" />
              <Dashboard path="/dashboard" />

              <SignIn path="/signin" />

              <Register path='/register' />
              <Register path="/register/:referredBy" />
              <RegisterSteps path='/register/steps' />

              <Course path="catalogue/courses/:courseId" />
              <Course path="catalogue/learning-path/courses/:courseId" />
              <Course path="/learning-path/:learningPathId/courses/:courseId" />
              <Course path="/courses/:courseId" />

              <PremiumCourse path="/premium/:courseId" />
              {appConfig?.learning_paths && appConfig.learning_paths === "1" && <LearningPath path="catalogue/learning-path/:learningPathId" />}
              {appConfig?.learning_paths && appConfig.learning_paths === "1" && <LearningPath path="/learning-path/:learningPathId" />}

              <LearningMode path="/learning-mode/:learningPathId/course/:courseId/lesson/:lessonId" />
              <LearningMode path="/learning-mode/:courseId/lesson/:lessonId" />
              <WellDoneRate path="/learning-mode/:courseId/well-done/course" />
              <WellDoneRate path="/learning-mode/:learningPathId/:courseId/well-done/course" />
              <WhatsNext path="/learning-mode/:courseId/well-done/next" />
              <WhatsNext path="/learning-mode/:learningPathId/:courseId/well-done/next" />

              <MyCourses path="/my-courses" />

              <MyProfile path="/student/profile" />

              <LayoutTest path="/layout" />

              <RecoverPassword path="/student/recover/" />
              <RecoverPassword path="/student/recover/confirm" />
              <ResetPassword path="/student/reset/:reset_token" />

              <ReferAFriend path="/refer" />

              <EmailVerification path="/verify/:token" />
            </>
          }
        </Router>
        <FooterSimple except={['/', '/signin', '/register', '/register(.*)?', '/register/steps', '/learning-mode(.*)', '/apply']} />
        {/* <Footer except={['/signin', '/register', '/learning-mode', '/apply', '/catalogue']} /> */}
      </ThemeProvider>
    </LocationProvider>
  );
}

export default App;
