import React from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import { Caption12, Heading20 } from '../Text/Text';

//other imports

import course_image from '../../Static/course-image.svg';
import Icon from '../Icon/Icon';

//styled-components
const ProjectCardWrapper = styled.div`
    width: 100%;
    height: 434px;
    background-color: ${props => props.theme.white};
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    figure{
        width: 100%;
        height: 55%;
        background-image: url(${props => props.course_image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .card-details{
        width: 100%;
        height: 45%;
        padding: 20px 26px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h1, h2,h3,h4,h5,h6,p{
            color: ${props => props.theme.black};
        }
        .card-content{
            p{
                font-size: 11px;
                line-height: 11px;
                font-weight: 500;
                margin-bottom: 10px;
                margin-left: -3px;
                width: max-content;
                padding: 9px 13px;
                border: 1px solid ${props => props.theme.gray3};
                border-radius: 14px;
            }
            h5{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
        }
        .card-footer{
            width: 100%;
            overflow: hidden;
            svg{
                transform: translate(-80%, 0);
                transition: transform .1s linear;
                path{
                    fill: ${props => props.theme.gray3};
                }
            }
        }
    }
    &:hover{
        cursor: pointer;
        .card-details{
           .card-content{
               p{
                   border-color: ${props => props.theme.blue};
               }
           } 
            .card-footer{
                svg{
                    transform: translate(-45%, 0);
                    path{
                        fill: ${props => props.theme.blue};
                    }
                }
            }
        }
    }

    @media (max-width: 500px){
        .card-details{
            .card-content{
                h5{
                    font-size: 20px;
                    line-height: 23px;
                }
            }
        }
    }
`


const ProjectCard = ({ week_order, title }) => {
    return (

        <ProjectCardWrapper course_image={course_image}>
            <figure></figure>
            <div className="card-details">
                <div className="card-content">
                    <Caption12>Недела #{week_order}</Caption12>
                    <Heading20>{title}</Heading20>
                </div>
                <div className="card-footer">
                    <Icon icon="icnArrowLong" />
                </div>
            </div>
        </ProjectCardWrapper>
    );
}



export default ProjectCard;