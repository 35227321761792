import React from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import { Caption16, Caption12, Heading35, Text13, Text16, } from '../../../../Components/Text/Text';

//other imports
import instructorBoko from '../../../../Static/premium-courses/instructors/bokica.png';
import instructorTamara from '../../../../Static/premium-courses/instructors/tamara.png';

//styled-components
const PremiumOurInstructorsWrapper = styled.div`
    width: 100%;
    padding: 80px 0;
    background-color: ${props => props.theme.danger_select};
    position: relative;
    overflow: hidden;
    ${Caption16}{
        letter-spacing: 3px;
        font-weight: 600;
    }

    .content-container{
        width: 100%;
        max-width: 950px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        &>h4{
            text-align: center;
            margin-top: 30px;
        }
        .instructor-container{
            width: 100%;
            margin-top: 90px;
            display: flex;
            &>div:first-child{
                margin-right: 70px;
            }
            .instructor-details{
                width: 45%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .instructor-name{
                    font-weight: 700;
                }
                .profession-caption{
                    color: ${props => props.theme.gray3};
                    margin-top: 5px;
                }
                .instructor-description{
                    margin-top: 40px;
                }
            }
            .image{
                width: 50%;
                max-width: 500px;
                border-radius: 30px;
            }
            
        }
    }
    .background-item{
        position: absolute;
    }
    .circle-gradient-container{
        top: 50%;
        left: 0%;
    }
    .single-star-gradient-container{
        top: 15%;
        left: 10%;
    }
    .stars-gradient-container{
        right: 0;
        bottom: 10%;
    }
    @media (max-width: 980px){
        .content-container{
            width: 90%;
        }
    }
    @media (max-width: 500px){
        padding: 66px 0;
        ${Caption16}{
            font-size: 12px;
            line-height: 20px;
        }
        .content-container{
            width: 95%;
            margin: 0 auto;
            align-items: flex-start;
            h4{
                text-align: left;
                font-size: 28px;
                line-height: 33px;
                margin-bottom: 80px;
            }
                                   
            .instructor-container{
                margin-top: 0px;
                         flex-direction: column;
                &:first-of-type{
                                                                    margin-bottom: 80px;
                }
                &>div:first-child{
                    margin-right: 0px;
                }
                .instructor-details{
                    width: 100%;
                    order: 2;
                    margin-left: 0!important;
                    .instructor-name{
                        margin-top: 0px;
                    }
                    .instructor-description{
                        margin-top: 36px;
                    }
                }
                .image{
                    order: 1;
                    width: 100%;
                    margin-bottom: 36px;
                }
            }
        }
        .background-item{
            display: none;
        }
    }
`

const backgroundCircleGradient =

    <svg width="171" height="413" viewBox="0 0 171 413" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b)">
            <path d="M28.6955 402.203C136.706 366.823 195.584 250.581 160.203 142.57C124.823 34.5598 8.58101 -24.3183 -99.4296 11.0625C-207.44 46.4433 -266.318 162.685 -230.937 270.696C-195.557 378.706 -79.3151 437.584 28.6955 402.203Z" fill="url(#paint0_linear)" />
        </g>
        <defs>
            <filter id="filter0_b" x="-245.218" y="-3.21826" width="419.703" height="419.702" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
            </filter>
            <linearGradient id="paint0_linear" x1="-99.4295" y1="11.0624" x2="28.6956" y2="402.203" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>

const backgroundSingleStarGradient =
    <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61 30.5C44.2742 30.3594 30.7811 16.7258 30.5 0C30.3594 16.7258 16.7258 30.3594 0 30.5C16.7258 30.6406 30.3594 44.2742 30.5 61C30.7811 44.2742 44.2742 30.7811 61 30.5Z" fill="url(#paint0_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="30.5" y1="0" x2="30.5" y2="61" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F9F9F9" />
                <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
            </linearGradient>
        </defs>
    </svg>

const backgroundStarsGradient =
    <svg width="212" height="263" viewBox="0 0 212 263" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b)">
            <path d="M180.5 86L187.095 141.346L214.367 92.7367L199.28 146.393L243.079 111.921L208.607 155.72L262.263 140.633L213.654 167.905L269 174.5L213.654 181.095L262.263 208.367L208.607 193.28L243.079 237.079L199.28 202.607L214.367 256.263L187.095 207.654L180.5 263L173.905 207.654L146.633 256.263L161.72 202.607L117.921 237.079L152.393 193.28L98.7367 208.367L147.346 181.095L92 174.5L147.346 167.905L98.7367 140.633L152.393 155.72L117.921 111.921L161.72 146.393L146.633 92.7367L173.905 141.346L180.5 86Z" fill="#F9F9F9" />
            <path d="M51 0L54.8004 31.894L70.5169 3.88214L61.8227 34.8027L87.0624 14.9376L67.1973 40.1773L98.1179 31.4831L70.106 47.1996L102 51L70.106 54.8004L98.1179 70.5169L67.1973 61.8227L87.0624 87.0624L61.8227 67.1973L70.5169 98.1179L54.8004 70.106L51 102L47.1996 70.106L31.4831 98.1179L40.1773 67.1973L14.9376 87.0624L34.8027 61.8227L3.88214 70.5169L31.894 54.8004L0 51L31.894 47.1996L3.88214 31.4831L34.8027 40.1773L14.9376 14.9376L40.1773 34.8027L31.4831 3.88214L47.1996 31.894L51 0Z" fill="#F9F9F9" />
        </g>
        <defs>
            <filter id="filter0_b" x="-4" y="-4" width="277" height="271" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
            </filter>
        </defs>
    </svg>


const instructorsMapping = {
    instructorBoko,
    instructorTamara
}

//component
const PremiumOurInstructors = (props) => {
    return (
        <PremiumOurInstructorsWrapper instructor1={props.instructor1} instructor2={props.instructor2}>
            <div className="circle-gradient-container background-item">
                {backgroundCircleGradient}
            </div>
            <div className="single-star-gradient-container background-item">
                {backgroundSingleStarGradient}
            </div>
            <div className="stars-gradient-container background-item">
                {backgroundStarsGradient}
            </div>
            <div className="content-container">
                <Caption16>нашите инструктори</Caption16>
                <Heading35>Достапни, стручни и отворени за нови идеи и предизвици</Heading35>
                {props.instructors && props.instructors.map((instructor, index) => {
                    if (index % 2 === 0) {

                        return <div key={index} className="instructor-container">
                            <img src={instructorsMapping[instructor.image]} className="image" alt="instructor" />
                            <div style={{ marginLeft: "70px" }} className="instructor-details">
                                <Text16 className={"instructor-name"}>{instructor.name}</Text16>
                                <Caption12 className="profession-caption">{instructor.profession}</Caption12>
                                <Text13 className="instructor-description">{instructor.description}</Text13>
                            </div>
                        </div>
                    } else {
                        return <div key={index} className="instructor-container">
                            <div className="instructor-details">
                                <Text16 className={"instructor-name"}>{instructor.name}</Text16>
                                <Caption12 className="profession-caption">{instructor.profession}</Caption12>
                                <Text13 className="instructor-description">{instructor.description}</Text13>
                            </div>
                            <img src={instructorsMapping[instructor.image]} className="image" alt="instructor" />
                        </div>
                    }

                })}
            </div>
        </PremiumOurInstructorsWrapper>

    );
}

export default PremiumOurInstructors;