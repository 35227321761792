import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

//npm imports
import styled from 'styled-components/macro';

//other imports
import { API } from '../../Consts/Api';
import { GlobalContext } from '../../Context/GlobalContext';

//styled-components
const StyledAllRulesPopup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: ${props => !props.showAllRulesPopup ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    z-index: 9;
    .dimmed-div{
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.lightBlack};
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .popup-container{
        width: 90vw;
        max-width: 900px;
        height: 85vh;
        padding: 80px 40px 40px 56px;
        background-color: ${props => props.theme.white};
        z-index: 3;
        border-radius: 16px;
        position: relative;
        .popup-content{
            max-height: 100%;
            overflow-y: scroll;
            padding-right: 32px;    
            .privacy-policy-container{
                margin-bottom: 70px;
            }
            .privacy-policy-container, .rules-container{
                li{
                    position: relative;
                    margin-left: 24px;
                    &::after{
                        content: "";
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: ${props => props.theme.black};
                        position: absolute;
                        top: 5px;
                        left: -18px;
                        z-index: 1000;
                    }
                }
                    
                p{
                    margin-bottom: 12px;
                }
            }
        }

        .x-button-container{
            width: 18px;
            height: 18px;
            position: absolute;
            top: 40px;
            right: 30px;
            &:hover{
                cursor: pointer;
            }
            &>div{
                width: 100%;
                height: 2px;
                background-color: ${props => props.theme.black};
                position: absolute;
                top: 0;
                left: 0;
            }
            &>div:first-child{
                transform: rotate(-45deg);
            }
            &>div:last-child{
                transform: rotate(45deg);
            }
        }
    }
    @media (max-width: 550px){
        .popup-container{
            width: 100vw;
            height: 100vh;
            border-radius: 0px;
        }
    }
`

//main component
const AllRulesPopup = ({ showAllRulesPopup, setShowAllRulesPopup }) => {
    const popupRef = useRef()
    const { setBodyUnscrollable, checkIfClickedOutside } = useContext(GlobalContext);
    const [popupContent, setPopupContent] = useState({});
    useEffect(() => {
        if (showAllRulesPopup.display) {
            setBodyUnscrollable(true);
        } else if (!showAllRulesPopup.display) {
            setBodyUnscrollable(false)
        }
        //eslint-disable-next-line
    }, [showAllRulesPopup])


    const handleKeys = useCallback((e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 27) {
            setShowAllRulesPopup({ ...showAllRulesPopup, display: false });
        }
        //eslint-disable-next-line
    }, [setShowAllRulesPopup])

    useEffect(() => {
        if (showAllRulesPopup.display) {
            window.addEventListener('keydown', handleKeys);
            API.axios.get(API.createApiRoute("legal"))
                .then(response => setPopupContent(response.data.data))
                .catch(error => console.error(error.response))
        } else if (!showAllRulesPopup.display) {
            window.removeEventListener('keydown', handleKeys);
        }

        return () => {
            window.removeEventListener('keydown', handleKeys);
        };
        //eslint-disable-next-line
    }, [showAllRulesPopup.display])

    function createMarkup(text) {
        return { __html: text };
    }

    return (
        <StyledAllRulesPopup showAllRulesPopup={showAllRulesPopup.display} onClick={(e) => checkIfClickedOutside(e, popupRef, () => setShowAllRulesPopup({ ...showAllRulesPopup, display: false }))}>
            <div className="dimmed-div"></div>
            <div ref={popupRef} className="popup-container">
                <div className="popup-content">
                    {showAllRulesPopup.privacy && popupContent.privacy_policy
                        && <div className="privacy-policy-container" dangerouslySetInnerHTML={createMarkup(popupContent.privacy_policy)}></div>
                    }
                    {popupContent.terms_and_conditions
                        && <div className="rules-container" dangerouslySetInnerHTML={createMarkup(popupContent.terms_and_conditions)}></div>
                    }
                </div>
                <div className="x-button-container" onClick={() => setShowAllRulesPopup(false)}>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </StyledAllRulesPopup>
    )
}

export default AllRulesPopup;