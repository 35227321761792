import React from 'react';

//imports
import styled, { withTheme } from 'styled-components';
import { API } from '../../../Consts/Api';
import { Link } from '@reach/router';
import { Caption12, Text13, Heading53 } from '../../../Components/Text/Text';
import Icon from '../../../Components/Icon/Icon';
import ButtonBasic from '../../../Components/ButtonBasic/ButtonBasic';
import BackLink from '../../../Components/BackLink/BackLink';
import { useTranslation } from 'react-i18next';

//styled-components
const LearningPathHeaderWrapper = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px){
        width: 100%;
        align-items: center;
        padding: 0 20px;
        margin: 0 auto;
    }

    @media (max-width: 500px){
        width: 100%;
        padding: 0 10px;
    }
`

const CategoryWrapper = styled.div`
    align-self: flex-start;
    display: none;
    @media (max-width: 500px){
        display: block;
    }
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    ${Caption12} {
        font-family: "Manrope", sans-serif;
    }
    .points{
        path{
            d: path('M19 9.99275C14.054 10.0653 10.0653 14.054 10.0073 19C9.94923 14.054 5.94601 10.0508 1 9.99275C5.94601 9.93473 9.93473 5.94601 10.0073 1C10.0653 5.94601 14.054 9.93473 19 9.99275Z');  
            stroke: ${props => props.theme.blue};
            fill: none;
        }
    }
    @media (max-width: 500px){
        display: none;
    }
`

const TitleWrapper = styled.div`
margin-top: 23px;
margin-bottom: 23px;
@media (max-width: 768px) {
    margin-top: 17px;
    margin-bottom: 32px;
    h3{
        font-size: 22px;
        line-height: 26px;
    }
    align-self: flex-start;
}
`

const DescriptionWrapper = styled.div`
margin: 0 0 43px 0;
align-self: flex-start;
@media (max-width: 500px){
    margin: 0 0 29px 0;
}
`

const ButtonsWrapper = styled.div`
margin: ${props => props.theme.spacer * 3}px 0;
display: flex;
flex-direction: column;
.learning-path-button{
    margin-bottom: 10px;
}
.learning-path-button-disabled{
    margin-bottom: 10px;
    background-color: ${props => props.theme.gray4}!important;
    display: flex;
    justify-content: center;
    span{
        text-align: center;
    }
}
@media (max-width: 500px){
    margin-bottom: 60px;
    align-items: center;
    .learning-path-button{
        margin-bottom: 25px;
    }
}
`

//component
const LearningPathHeader = (props) => {
    const { t } = useTranslation();
    return (
        <LearningPathHeaderWrapper>
            <CategoryWrapper>
                <Caption12>{props.category && props.category.name}</Caption12>
            </CategoryWrapper>
            <IconWrapper>
                <Icon fill="none" strokeWidth="1.5" className="points" icon="icnPoints" width={20} height={20} viewBox="0 0 20 20" />
                &nbsp;
                <Caption12>{props.points} {t("general.points")}</Caption12>
            </IconWrapper>
            <TitleWrapper>
                <Heading53>{props.learningPath && props.learningPath.title}</Heading53>
            </TitleWrapper>
            <DescriptionWrapper>
                <Text13>{props.learningPath && props.learningPath.description}</Text13>
            </DescriptionWrapper>
            <ButtonsWrapper>
                {props.firstCourseToLearnId !== undefined
                    ? <Link to={API.createApiRoute("getCourse", { COURSE_ID: props.firstCourseToLearnId })}>
                        <ButtonBasic
                            className="learning-path-button"
                            text={t("learning_path_index.watch_full_learning_path")}
                            variation="primary"
                            type="button"
                            spanTransform="largeSpan"
                            iconTransform="largeIcon"
                        />
                    </Link>
                    : <ButtonBasic
                        className="learning-path-button-disabled"
                        text={t("general.coming_soon").toUpperCase()}
                        variation="primary"
                        type="button"
                        spanTransform="largeSpan"
                        iconTransform="largeIcon"
                        disabled={true}
                    />
                }
                <BackLink url="/catalogue" text={t("general.back_to_my_courses")} fill={props.theme.blue} />
            </ButtonsWrapper>

        </LearningPathHeaderWrapper>
    );
}

export default withTheme(LearningPathHeader);