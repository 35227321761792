import React, { useRef } from 'react';
import styled, { keyframes, withTheme, css } from 'styled-components';

// Components
import { CardCaption11, Caption9, Text13, Heading20, CardCaption10 } from '../Text/Text';
import Icon from '../Icon/Icon';
import ButtonSmall from '../ButtonSmall/ButtonSmall';
import Skeleton from '../Skeleton/Skeleton';
import { useTranslation } from 'react-i18next';

//animation
const cardCtaInitial = keyframes`
    from{
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(-110%, 0%);
    }
`

const cardCta = keyframes`
    from{
        transform: translate(-110%, 0%);
    }
    to{
        transform: translate(0%, 0%);
    }
`

const cardInfoInitial = keyframes`
    from{
        transform: translate(110%, 0%);
    }
    to{
        transform: translate(0%, 0%);
    }
`

const cardInfo = keyframes`
    from{
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(110%, 0%);
    }
`

// Styled Components
const CourseCardWrapper = styled.article`
    background: ${props => props.theme.white};
    border-radius: 7px;
    border: 1px solid ${props => props.theme.gray7};
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    :hover {
        box-shadow: ${props => props.theme.dropShadow1};
        cursor: ${props => props.comingSoon ? "not-allowed" : "pointer"};
        ${props => props.cardTitle && css`
            .card-cta {
                animation: ${cardCta} 0.2s linear forwards;
                animation-duration-function: ${props => props.theme.expo};
            }
            .card-info{
                animation: ${cardInfo} 0.2s linear forwards;
                animation-duration-function: ${props => props.theme.expo};
            }
        `}
    }

    @media (max-width: 500px){
        width: 100%;;
        height: 214.57px;
    }
`;

const ImageContainer = styled.figure`
    width: 100%;
    height: ${props => props.cardLocation === "catalogue" ? "160px" : "185px"};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: ${props => props.theme.gray7};
    @media (max-width: 500px){
        // width: 137px;
        height: 81px;
    }
`;

const Image = styled.img`
    max-width: 100%;
`

const BrainsterExclusiveBadge = styled.div`
    border-radius: 20px;
    padding: 8px 20px;
    background-color: ${props => props.theme.black};
    position: absolute;
    top: 33px;
    left: -9px;
    display: ${props => props.exclusive ? "block" : "none"};
    p{
        color: white;
        font-weight: 600;
        font-size: 10px;
        line-height: 13px;
    }
    @media (max-width: 500px){
        padding: 3px 8px;   
        left: -5px;
        p{
            font-size: 6px;
            line-height: 9px;
        }
    }
`

const ProgressWrapper = styled.div`
    height: 3px;
    position: relative;
    background: ${props => props.theme.gray2};
`;

const Progress = styled.div`
    height: inherit;
    background: black;
    width: ${props => props.width ? props.width + '%' : "0%"};
`;

const CourseDetailsWrapper = styled.aside`
    min-height: 135px;
    padding: ${props => props.theme.spacer * 6 + 'px 18px 0px 18px'};
    ${props => props.marginBottom ? "" : "margin-bottom: 24px!important;"}
    overflow-y: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    @media (max-width: 500px){
        min-height: unset;
        padding: 16px 11px 0 11px;
    }
`;

const CourseDetails = styled.header`
    min-height: 135px;
    flex: 1;
    .card-category {
        font-size: 9px;
        color: ${props => props.theme.gray6};
        text-transform: uppercase;
        margin-bottom: 7px;
    }
    .title {
        margin-bottom: ${props => props.theme.spacer * 0.5 + 'px'};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    p{
        font-weight: 500;
        color: ${props => props.theme.black};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    @media(max-width: 500px){
        .card-category {
            display: none!important;
        }
        &>h5{
            font-size: 14px;
            line-height: 17px;
        }
        &>p{
            font-size: 10px;
            line-height: 11.74px;
        }
    }
`;

const CourseFooter = styled.footer`
    width: 100%;
    height: ${props => props.cardLocation === "catalogue" ? "59px" : "68px"};
    position: relative;
    overflow: hidden;
    .card-info, .card-cta {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    @media (max-width: 500px){
        height: 32px;
    }
`;

const CardCTA = styled.div`
    width: 100%;
    height: ${props => props.cardLocation === "catalogue" ? "59px" : "68px"};
    display: flex;
    justify-content: ${props => props.comingSoon ? "center" : "space-between"};
    padding: ${props => props.cardLocation === "catalogue" ? "0 21px" : "0 24px"};
    align-items: center;
    border-top: 1px solid #F6F6F6;
    animation: ${cardCtaInitial} 0.2s linear forwards;
    animation-duration-function: ${props => props.theme.expo};
    ${CardCaption11}{
        font-weight: 500;
    }
    p{
        letter-spacing: 1px;
        font-size: 13px;
    }
    &>button{
        display: ${props => props.comingSoon && "none"};
    }
    @media (max-width: 500px){
    height: 32px;
    padding: 0 13px;
        p{
            font-size: 8.5px;
        }
    }
`

const CardInfo = styled.div`
    width: 100%;
    height: ${props => props.cardLocation === "catalogue" ? "59px" : "68px"};
    padding: ${props => props.cardLocation === "catalogue" ? "0 21px" : "0 18px"};
    display: flex;
    align-items: center;
    justify-content: space-between;

    line-height: 10px;
    animation: ${cardInfoInitial} 0.2s linear forwards;
    animation-duration-function: ${props => props.theme.expo};
    p{
        color: ${props => props.theme.gray6}
    }
    @media (max-width: 500px){
        height: 32px;
        p{
            font-size: 6px;
            margin-top: 2px;
        }

        svg {
            width: 5px;
            height: 5px;
        }

        padding: 0 13px;
    }
`

const StudentsTimeWrapper = styled.div`
height: 7px;
    display: flex;
    align-items: center;
    span {
        margin: 0 ${props => props.theme.spacer * 2 + 'px'};
    }
`;

const TimeWrapper = styled(StudentsTimeWrapper)`
    border-right: 1px solid ${props => props.theme.gray6};
    padding-right: 7px;
    margin-right: 7px;
    p {
        margin-left: ${props => props.theme.spacer + 'px'};
    }
`;

const StudentsWrapper = styled(StudentsTimeWrapper)`
    p {
        margin-left: ${props => props.theme.spacer + 'px'};
    }
`;

const PointsWrapper = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-left: 2px;
        display: flex;
        align-items: center;
    }
`;



// Progress Component
const CourseProgress = ({ width }) => (
    <ProgressWrapper>
        <Progress width={width} />
    </ProgressWrapper>
);

// Main Component
const CourseCard = (props) => {
    const { t } = useTranslation();
    const card = useRef()
    return (
        <CourseCardWrapper cardTitle={props.cardTitle} ref={card} footer={props.cardTime || props.cardStudentsNumber || props.cardPoints} comingSoon={props.cardComingSoon}>
            <ImageContainer cardLocation={props.cardLocation}>
                {props.cardImage ? <Image src={props.cardImage} alt={props.cardTitle} /> : <Skeleton />}
            </ImageContainer>
            <BrainsterExclusiveBadge exclusive={props.cardExclusive}>
                <Text13>B</Text13>
            </BrainsterExclusiveBadge>
            <CourseProgress width={props.cardProgress} />
            <CourseDetailsWrapper marginBottom={props.cardTime || props.cardStudentsNumber || props.cardPoints}>
                <CourseDetails>
                    <Caption9 className="card-category">{props.cardCategory && props.cardCategory}</Caption9>
                    <Heading20 className="title">{props.cardTitle ? props.cardTitle : <Skeleton />}</Heading20>
                    <Text13 style={{ maxHeight: "100px", overflowY: "hidden" }}>{props.cardSubtitle}</Text13>
                </CourseDetails>
            </CourseDetailsWrapper>
            <CourseFooter cardLocation={props.cardLocation}>
                {props.cardTitle
                    && <CardCTA className="card-cta" cardLocation={props.cardLocation} comingSoon={props.cardComingSoon}>
                        <CardCaption11>
                            {props.cardComingSoon ? t("general.coming_soon") : props.cardProgress ? t("general.continue") : t("general.start")}
                        </CardCaption11>
                        <ButtonSmall width={props.isMobile ? 23 : props.cardLocation === "catalogue" ? 34 : 40} height={props.isMobile ? 23 : props.cardLocation === "catalogue" ? 34 : 40} />
                    </CardCTA>
                }
                <CardInfo className="card-info" cardLocation={props.cardLocation}>
                    <StudentsTimeWrapper>
                        {!props.cardTitle
                            ? <Skeleton />
                            : props.cardTime && <TimeWrapper>
                                <Icon icon="icnTime" width={10} height={10} fill="none" pathStroke={props.theme.gray6} />
                                <CardCaption10>{props.cardTime} min</CardCaption10>
                            </TimeWrapper>}
                        {!isNaN(props.cardStudentsNumber) ? <StudentsWrapper>
                            <Icon icon="icnUser" width={11} height={10} fill="none" circle_stroke={props.theme.gray6} circle={true} />
                            <CardCaption10>{props.cardStudentsNumber}</CardCaption10>
                        </StudentsWrapper>
                            : <div></div>
                        }
                    </StudentsTimeWrapper>
                    {props.cardPoints && <PointsWrapper>
                        <Icon icon={"icnPoints"} fill="none" stroke={props.theme.gray6} pathStrokeWidth={0.75} width={10} height={10} />
                        <CardCaption10>&nbsp;{props.cardPoints}</CardCaption10>
                    </PointsWrapper>}
                </CardInfo>
            </CourseFooter>
        </CourseCardWrapper>
    )
}

export default withTheme(CourseCard);