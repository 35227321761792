import React from 'react';

//imports
import styled from 'styled-components';
import MyLearningPathCard from '../../../../../Components/MyLearningPathCard/MyLearningPathCard';
import empty_state_image from '../../../../../Static/empty-state-my-courses/empty-state-paths.png';
import { SpecHeadingSmall, Text13 } from '../../../../../Components/Text/Text';
import ButtonBasic from '../../../../../Components/ButtonBasic/ButtonBasic';
import Icon from '../../../../../Components/Icon/Icon';
import Skeleton from '../../../../../Components/Skeleton/Skeleton';
import { useTranslation } from 'react-i18next';
import AddLearningPathCard from '../../../../../Components/AddLearningPathCard/AddLearningPathCard';

//styled-components
const UnfinishedLearningPathsContainer = styled.div`
    width: ${props => props.spread ? "100%" : "0%"};
    height: ${props => props.spread ? "100%" : "0%"};
    max-height: 718px;
    position: relative;
    &>.cards-container{
        padding-top: 80px;
        padding-left: ${props => props.spread ? "48px" : "0px"};
        padding-bottom: 20px;
        max-height: 718px;
        overflow-y: scroll;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 78px;
        @media (max-width: 500px){
            max-height: 585px;
        }
    }
    .my-learning-path-container {
        width: 100%;
        margin-right: 70px;
        margin-bottom: 28px;
    }
    @media (max-width: 500px){
        max-height: 585px;
        padding: "0px";
        &>div{
            padding: ${props => props.spread ? "76px 0px 0px 19px" : "0px"}
        }
        .my-learning-path-container {
            width: 100%;
            margin-right: 17px;
            margin-bottom: 19px;
        }
        .cards-container{
            justify-content: flex-end;
            padding-left: ${props => props.spread ? "20px" : "0px"};
            padding-right: ${props => props.spread ? "20px" : "0px"};
        }
    }
`

//component
const UnfinishedLearningPaths = (props) => {
    const { t } = useTranslation();

    return (
        <UnfinishedLearningPathsContainer spread={props.spread}>
            {/* if there is unfinished learning paths, map the array and display a card for every unfinished learning path, if not display empty-state content*/}
            {props.unfinishedLearningPaths && props.unfinishedLearningPaths.length > 0
                ? <React.Fragment>
                    <div className="courses-state-info">
                        <div className="current-info">
                            <Icon icon="icnStar7Stroked" />
                            <Text13>{t("my_courses.navigation.current")} &nbsp;<span>{props.unfinishedLearningPaths.length}</span></Text13>
                        </div>
                        <div className="completed-info">
                            <Icon icon="icnStar7Filled" />
                            <Text13>{t("my_courses.navigation.completed")} &nbsp;<span>{props.completed}</span></Text13>
                        </div>
                    </div>
                    <div className="hide-scrollbar cards-container">
                        {props.unfinishedLearningPaths.map((learning_path, index) => {
                            return (

                                <a href={"learning-path/" + learning_path.id} key={index} className="my-learning-path-container">
                                    <MyLearningPathCard learning_path={true} cardId={learning_path.id} cardImage={learning_path.image} cardTitle={learning_path.title} cardProgress={learning_path.progress} spread={props.spread} studyField={learning_path.category.study_field.name} />
                                </a>
                            )
                        })}
                        <a href="/catalogue" className="my-learning-path-container"><AddLearningPathCard /></a>
                    </div>
                </React.Fragment>
                : props.unfinishedLearningPaths && props.unfinishedLearningPaths.length === 0
                    ? <div className="empty-state-container hide-scrollbar">
                        <img src={empty_state_image} alt={"no content"} />
                        <SpecHeadingSmall>{t("my_courses.navigation.no_courses.heading")} {t("general.active_plural")}  <br /> {t("general.learning_paths")}</SpecHeadingSmall>
                        <Text13 className="empty-state-message">{t("my_courses.navigation.no_courses.description.1")} {t("general.learning_path")}. <br /> {t("my_courses.navigation.no_courses.description.2")}!</Text13>
                        <a href="/catalogue">
                            <ButtonBasic className="bla" text={t("my_courses.navigation.no_courses.go_to_catalogue")} />
                        </a>
                    </div>
                    :
                    <React.Fragment>
                        <div className="courses-state-info">
                            <div className="current-info">
                                <Text13><Skeleton /></Text13>
                            </div>
                            <div className="completed-info">
                                <Text13><Skeleton /></Text13>
                            </div>
                        </div>
                        <div className="hide-scrollbar cards-container">
                            {Array(6).fill().map((element, index) => (
                                <div key={index} className="my-learning-path-container">
                                    <MyLearningPathCard />
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
            }
        </UnfinishedLearningPathsContainer>
    );
}

export default UnfinishedLearningPaths;