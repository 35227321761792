import React from 'react';
import { useTranslation } from 'react-i18next';

//imports
import styled from 'styled-components';
import { Text10, ButtonText16 } from '../Text/Text';


//styled-components
const PopupAlertWrapper = styled.div`
    width: 270px;
    position: absolute;
    top: ${props => props.top ? props.top + "px" : "unset"};
    right: ${props => props.right ? props.right + "px" : "unset"};
    bottom: ${props => props.bottom ? props.bottom + "px" : "unset"};
    left: ${props => props.left ? props.left + "px" : "unset"};
    display: ${props => props.popup ? "flex" : "none"};
    flex-direction: column;
    z-index: 999;
`

const PropsAlertContainer = styled.div`
    width: 100%;
    height: 159px;
    border-radius: 13px;
    background-color: ${props => props.theme.yellow};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &>p {
        margin-bottom: 26px;
    }
    button {
        background: none;
        outline: none;
        border: 1px solid white;
        border-radius: 28px;
        padding: 20px 40px;
        :hover {
            cursor: pointer;
        }
    }
`

const PropsAlertTriangle = styled.div`
    width: 27px;
    height: 16px;
    margin-left: 149px;
    background-color: ${props => props.theme.yellow};
    clip-path: polygon(50% 100%, 0 0, 100% 0);
`

//component
const PopupAlert = ({ top, right, bottom, left, message, popup, onClick }) => {
    const { t } = useTranslation();
    return (
        <PopupAlertWrapper top={top} right={right} bottom={bottom} left={left} popup={popup}>
            <PropsAlertContainer>
                {message && <Text10>{message}</Text10>}
                <button onClick={onClick}><ButtonText16 color="white" fontWeight={500}>{t("general.ok")}</ButtonText16></button>
            </PropsAlertContainer>
            <PropsAlertTriangle />
        </PopupAlertWrapper>
    );
}

export default PopupAlert;