import React, { useEffect, useState } from 'react';

//imports
import styled from 'styled-components';
import { SpecHeading2, Heading28 } from '../../../Components/Text/Text';

import PageWrapper from '../../../Components/PageWrapper/PageWrapper';
import Wrapper from '../../../Components/Wrapper/Wrapper';
import BackLink from '../../../Components/BackLink/BackLink';
import { API } from '../../../Consts/Api';
import { useTranslation } from 'react-i18next';
import SliderBlock from '../../../Components/SliderBlock/SliderBlock';
import { useMediaQuery } from 'react-responsive';

//styled-components
const WhatsNextContainer = styled(PageWrapper)`
    background-color: ${props => props.theme.blue};
    .whats-next-backlink{
        margin-top: 75px;
        svg{
            display: none;
        }
        &:hover{
            p{
                color: white;
            }
        }
    }
    
`

const RecomendedForYouContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    &>h1, >h4{
        text-align: center;
        color: white;
    }
`

const CarouselWrapper = styled(Wrapper)`
    display: block;
    width: 100%;
    &>div {
        padding: 0;
    }
`

//component
const WhatsNext = () => {
    const { t } = useTranslation();
    const [nextCourses, setNextCourses] = useState();
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const isLaptop = useMediaQuery({ maxWidth: 1141 });
    useEffect(() => {
        API.axios.get(API.createApiRoute("recommendedCoursesGeneral"))
            .then((response) => {
                let coursesAll = [];
                response.data.data.forEach(course => {
                    coursesAll.push(course);
                })

                setNextCourses(coursesAll);
            }).catch(error => console.log(error));
    }, [])
    return (
        <WhatsNextContainer>
            <RecomendedForYouContainer>
                <SpecHeading2>{t("learning_mode.course_complete.whats_next.heading")}</SpecHeading2>
                <Heading28>{t("learning_mode.course_complete.whats_next.recomended")}</Heading28>
            </RecomendedForYouContainer>
            <CarouselWrapper>
                {nextCourses &&
                    <SliderBlock
                        cardData={nextCourses}
                        route="courses"
                        renderCard="CourseCard"
                        cardsPerSlide={isMobile ? 2 : isLaptop ? 3 : 4}
                        isLaptop={isLaptop}
                        isMobile={isMobile}
                        navigation={false}
                        gridTemplateColumns={isLaptop && "1fr 1fr 1fr"}
                    />
                }
            </CarouselWrapper>

            <BackLink className="whats-next-backlink" url="/my-courses" text={t("general.back_to_my_courses")} fill="white" />
        </WhatsNextContainer>
    );
}

export default WhatsNext;