import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';



//npm imports
import styled from 'styled-components';
import { withTheme } from 'styled-components';

//component imports
import AccordionBasic from '../../../../Components/AccordionBasic/AccordionBasic';
import Icon from '../../../../Components/Icon/Icon';
import { Heading28, Heading35, Text13, Text16 } from '../../../../Components/Text/Text';

//other imports
import learning_path_img from '../../../../Static/Photos/Homepage/LearningSystem/LearningPath.png'
import course_img from '../../../../Static/Photos/Homepage/LearningSystem/Course.png'
import code_editor_img from '../../../../Static/Photos/Homepage/LearningSystem/CodeEditor.png'
import lesson_img from '../../../../Static/Photos/Homepage/LearningSystem/Lesson.png'
import section_img from '../../../../Static/Photos/Homepage/LearningSystem/Section.png'
import quiz_img from '../../../../Static/Photos/Homepage/LearningSystem/Quiz.png'
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';
import { Link } from '@reach/router';
import { API } from '../../../../Consts/Api';

//styled-components
const StyledLearningSystem = styled.div`
    width: 100%;
    padding-top: 120px;
    background-color: ${props => props.theme.danger_select};
    display: flex;
    flex-direction: column;
    align-items: center;
    &>${Text13}{
        margin: 65px auto;
        width: 70%;
        max-width: 1065px;
    }
    nav{
        width: 100%;
        z-index: 1;
        position: relative;
        ul{
            width: 85%;
            max-width: 1065px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            li{
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                &>div{
                    width: 100%;
                    height: 80px;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    .shapes-container{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX( -50%);
                        display: flex;
                        transition: height .1s linear;
                        &>div{
                            height: 100%;
                            background-color: ${props => props.theme.white};
                            &>div{
                                width: 100%;
                                height: 100%;
                                background-color: ${props => props.theme.danger_select};
                            }
                        }
                        .tab-left-shape-container{
                            width: 27%;
                            div{
                                border-bottom-right-radius: 60%;
                            }
                        }
                        .tab-middle-shape-container{
                            width: 46%;
                            background-color: ${props => props.theme.danger_select};
                            div{
                                border-top-left-radius: 50%;
                                border-top-right-radius: 50%;
                                background-color: ${props => props.theme.white};
                            }
                        }
                        .tab-right-shape-container{
                            width: 27%;
                            div{
                                border-bottom-left-radius: 60%;
                            }
                        }
                    }
                    svg{
                        margin-top: 10px;
                        height: 100%;
                        width: 50px;
                        padding: 0 13px;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
                &>${Text13}{
                    width: 100%;
                    height: 69px;
                    text-align: center;
                    padding-top: 23px;
                    line-height: 13px;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .LearningPath{
                &>div{
                    .shapes-container{
                        height: ${props => props.currentTab === "LearningPath" ? "100%" : "0px"}
                    }
                }
            }
            .Course{
                &>div{
                    .shapes-container{
                        height: ${props => props.currentTab === "Course" ? "100%" : "0px"}
                    }
                }
            }
            .Section{
                &>div{
                    .shapes-container{
                        height: ${props => props.currentTab === "Section" ? "100%" : "0px"}
                    }
                }
            }
            .Lesson{
                &>div{
                    .shapes-container{
                        height: ${props => props.currentTab === "Lesson" ? "100%" : "0px"}
                    }
                }
            }
            .Quiz{
                &>div{
                    .shapes-container{
                        height: ${props => props.currentTab === "Quiz" ? "100%" : "0px"}
                    }
                }
            }
            .CodeEditor{
                &>div{
                    .shapes-container{
                        height: ${props => props.currentTab === "CodeEditor" ? "100%" : "0px"}
                    }
                }
            }
        }
    }
    &>section{
        width: 100%;
        padding: 120px 0 0 0;
        background-color: ${props => props.theme.white};
        border-top-left-radius: 96px;
        border-top-right-radius: 96px;
        margin-top: -69px;
        display: flex;
        justify-content: center;
        align-items: center;
        &>img{
            width: 32%;
            margin-right: 50px;
        }
        .tab-content-container{
            width: 26%;
            &>h4{
                margin-bottom: 50px;
            }
        }
    }
    .mobile-learning-system-content{
        display: none;
    }
    .cta-container{
        width: 100%;
        padding: 100px 0;
        background-color: ${props => props.theme.white};
        display: flex;
        flex-direction: column;
        align-items: center;
        &>${Text16}{
            width: 70%;
            text-align: center;
            margin-bottom: 30px;
        }
    }
    @media (max-width: 992px){
        padding-bottom: 70px;
        &>nav, &>section{
            display: none;
        }
        .mobile-learning-system-content{
            display: block;
            width: 90%;
            &>div{
                margin: 14px 0;
            }
        }

    .cta-container{
        background-color: ${props => props.theme.danger_select};
        padding: 40px 0 0 0;
        &>${Text16}{
            width: 90%;
        }
    }
    }

    @media (max-width: 600px){
        padding-top: 80px;
        padding-bottom: 74px;
        &>${Heading35}{
            font-size: 28px;
            line-height: 30px;
        }
        &>${Text13}{
            width: 90%;
            margin: 40px 0 60px 0;
            font-size: 14px;
            line-height: 20px;
        }
        &>${Heading35}, &>${Text13}{
            align-self: flex-start;
            margin-left: 5vw;
            margin-right: 5vw;
        }
    }
`

//main component
const LearningSystem = (props) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState({ name: "Course", index: 1, image: course_img });
    const tabs = [
        {
            icon: "icnCourseLandingTab",
            name: "LearningPath",
            image: learning_path_img
        },
        {
            icon: "icnMyPath",
            name: "Course",
            image: course_img
        },
        {
            icon: "icnLessonEmpty",
            name: "Section",
            image: lesson_img
        },
        {
            icon: "icnTestEmpty",
            name: "Lesson",
            image: section_img
        },
        {
            icon: "icnCommunity",
            name: "Quiz",
            image: quiz_img
        },
        {
            icon: "icnEditorEmpty",
            name: "CodeEditor",
            image: code_editor_img
        }
    ]
    return (
        <StyledLearningSystem currentTab={currentTab.name}>
            <Heading35>{t("homepage.learning_system.header")}</Heading35>
            <Text13>{t("homepage.learning_system.description")}</Text13>
            <nav>
                <ul>
                    {tabs.map((tab, index) => (
                        <li key={index} className={tab.name}>
                            <div>
                                <div className="shapes-container">
                                    <div className='tab-left-shape-container'>
                                        <div></div>
                                    </div>
                                    <div className='tab-middle-shape-container'>
                                        <div></div>
                                    </div>
                                    <div className='tab-right-shape-container'>
                                        <div></div>
                                    </div>
                                </div>
                                <Icon onClick={() => currentTab.name !== tab.name && setCurrentTab({ name: tab.name, index: index.toString(), image: tab.image })} icon={tab.icon} pathFill={currentTab.index && currentTab.index === index.toString() ? props.theme.blue : props.theme.black} width={30} height={30} />
                            </div>
                            <Text13 key={tab.name} onClick={() => currentTab !== tab.name && setCurrentTab({ name: tab.name, index: index, image: tab.image })}>{t(`homepage.learning_system.tabs.${index}.title`)}</Text13>
                        </li>
                    ))}
                </ul>
            </nav>
            <section>
                <img src={currentTab?.image} alt={currentTab.name} />
                <div className="tab-content-container">
                    <Heading28>{t(`homepage.learning_system.tabs.${currentTab.index}.title`)}</Heading28>
                    <Text13>{t(`homepage.learning_system.tabs.${currentTab.index}.content`)}</Text13>
                </div>
            </section>
            <div className='mobile-learning-system-content'>
                {tabs.map((tab, index) => (

                    <AccordionBasic key={index} icon={tab.icon} header={t(`homepage.learning_system.tabs.${index}.title`)} content={t(`homepage.learning_system.tabs.${index}.content`)} />
                ))}
            </div>
            <div className='cta-container'>
                <Text16 fontWeight={"bold"}>{t("homepage.learning_system.cta.tagline")}</Text16>
                <Link to={API.createRoute("register")}>
                    <ButtonBasic text={t("homepage.learning_system.cta.button")} />
                </Link>
            </div>
        </StyledLearningSystem>
    )
}

export default withTheme(LearningSystem);