import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

//npm imports
import styled, { withTheme } from 'styled-components';
import Icon from '../Icon/Icon';

//component imports
import { Text13, Text24 } from '../Text/Text';

//styled-components
const PrelaunchAccordionWrapper = styled.div`
    width: 100%;
    &:first-child{
        border-top: 1.5px solid ${props => props.theme.darkBlack};
    }
    border-bottom: 1.5px solid ${props => props.theme.darkBlack};
    padding: 33px 0;
    .prelaunch-accordion-button{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${props => props.showContent ? "0 0 20px 0" : "0"};
        transition: padding .2s ${props => props.theme.expo};
        p{
            color: ${props => props.theme.blue};
            font-weight: bold;
        }
        svg{
            transform: rotate(${props => props.showContent ? "90deg" : "-90deg"});
            transition: transform .2s linear;
        }
    }
    .prelaunch-accordion-content{
        width: 100%;
        height: ${props => props.showContent ? props.contentHeight + "px" : "0px"};
        overflow: hidden;
        transition: height .2s ${props => props.theme.expo};
        display: flex;
        flex-direction: column;
        p{
            color: ${props => props.theme.white};
            opacity: ${props => props.showContent ? "1" : "0"};
            transition: opacity .1s ease-out;
        }
        .prize-content{
            display: flex;
            padding: 21px 0;
            align-items: center;
            div{
                height: 170px;
                display: flex;
                align-items: center;     
            }
            p{
                width: 52%;
                height: unset;
                margin-left: 37px;
            }
        }
    }
    &:hover{
        cursor: pointer;
    }
    @media (max-width:500px){
        .prelaunch-accordion-content{
            .prize-content{
                
                flex-direction: column;
                div{
                    align-self: center;
                    width: 100%;
                    height: 200px;
                    justify-content: center;
                    img{
                        margin-bottom: 35px;
                    }
                }
                p{
                    width: 90%;
                    margin-left: 0;
                }
            }
        }
    }
`

//component
const PrelaunchAccordion = (props) => {
    const { t } = useTranslation();
    const [contentHeight, setContentHeight] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const contentRef = useRef(null);
    const prizeDescriptionRef = useRef([]);
    useEffect(() => {
        if (typeof props.description === "string") {
            let height = contentRef.current.scrollHeight;
            setContentHeight(height);
        } else if (prizeDescriptionRef.current) {
            let height = 0;
            prizeDescriptionRef.current.forEach(ref => {
                height = height += ref.scrollHeight;
            })
            setContentHeight(height)
        }
        //eslint-disable-next-line
    }, [contentRef, props.description, prizeDescriptionRef.current]);
    return (
        <PrelaunchAccordionWrapper onClick={() => setShowContent(!showContent)} showContent={showContent} contentHeight={contentHeight}>
            <div className="prelaunch-accordion-button">
                <Text24>{t(props.heading)}</Text24>
                <Icon icon="icnArrowLeft" pathStroke={showContent ? props.theme.blue : props.theme.darkBlack} height={20} pathStrokeWidth={2} />
            </div>
            <div ref={contentRef} className="prelaunch-accordion-content">
                {
                    typeof props.description !== "string"
                        ? props.description.map((step, index) => (
                            <div ref={ref => prizeDescriptionRef.current.push(ref)} className="prize-content" key={index}>
                                <div>
                                    <img src={step.image} alt={`prize${index + 1}`} />
                                </div>
                                <Text13>{t(step.text)}</Text13>
                            </div>
                        ))
                        : <Text13>{t(props.description)}</Text13>
                }
            </div>
        </PrelaunchAccordionWrapper>

    );
}

export default withTheme(PrelaunchAccordion);