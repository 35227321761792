import React from 'react';

//npm imports
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

//component imports
import { Caption16, Heading35 } from '../../../../Components/Text/Text';
import SliderBlock from '../../../../Components/SliderBlock/SliderBlock';
import TestimonialCard from '../../../../Components/TestimonialCard/TestimonialCard';
import CtaBlockApply from '../../../../Components/CtaBlockApply/CtaBlockApply';

//other imports
import communityBackground from '../../../../Static/backgrounds/join-our-community-background.svg';
import icnArrowLong from '../../../../Static/icn-arrow-long.svg';



//styled-components
const PremiumJoinCommunityWrapper = styled.div`
    width: 100%;
    min-height: 1400px;
    padding: 80px 0;
    background-color: ${props => props.theme.gray0};
    background-image: url(${communityBackground});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 33%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${Caption16}{
        letter-spacing: 3px;
        font-weight: 600;
    }
    .join-community-container{
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        .join-community-heading{
            width: 30%;
            h4{
                margin-top: 30px;
                color: ${props => props.theme.black};
            }
        }
        .card-container{
            width: 266px;
            position: absolute;
        }
        .card-1{
            top: 45%;
            left: 25%;
        }
        .card-2{
            top: 10%;
            left: 51%;
        }
        .card-3{
            top: 30%;
            left: 71%;
        }
    }
    .slider-container{
        display: none;
    }
    .cta-block-container{
        
    }
    @media (max-width: 1141px){
        .join-community-container{
            width: 95%;
            margin: 0 auto;
        }
    }
    @media (max-width: 500px){
        padding: 66px 0;
        background-image: none;
        min-height: unset;
        ${Caption16}{
            font-size: 12px;
            line-height: 20px;
        }
        .join-community-container{
            width: 90%;
            .join-community-heading{
                width: 100%;
                h4{
                    width: 85%;
                    margin-top: 24px;
                    font-size: 28px;
                    line-height: 33px;
                }
            }
            .card-container{
                display: none;
            }
        }
        .slider-container{
            display: block;
            &>div >div:first-of-type{
                display: none;
            }
            .swiper-container{
                padding-top: 30px;
                position: relative;
                .swiper-button-next, .swiper-button-prev{
                    position: absolute;
                    width: 50px;
                    top: 20px;
                    background-size: contain;
                }
                .swiper-button-next{
                    background-image: url(${icnArrowLong});
                    left: 60px;
                }
                .swiper-button-prev{ 
                    background-image: url(${icnArrowLong});
                    transform: rotate(180deg);
                    left: 0;
                }
            }
        }
    }
`

const cardData = [
    {
        title: "Bojan Stojkovski",
        description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
    },
    {
        title: "Bojan Stojkovski",
        description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
    },
    {
        title: "Bojan Stojkovski",
        description: "Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години"
    }
]




//component
const PremiumJoinCommunity = (props) => {

    const isMobile = useMediaQuery({ maxWidth: 500 });
    return (
        <PremiumJoinCommunityWrapper>
            <div className="join-community-container">
                <div className="join-community-heading">
                    <Caption16>стани дел од нашата заедница</Caption16>
                    <Heading35>Што е она што најмногу им се допадна на нашите студенти?</Heading35>
                </div>
                {props.testimonials.length > 0 && props.testimonials.map((testimonial, index) => (

                    <div key={index} className={`card-container card-${index + 1}`}>
                        <TestimonialCard cardImage={testimonial.image} cardTitle={testimonial.title} cardDescription="Модулот многу се изненадив колку точно ме опиша резултатот. Ќе беше многу подобро да постоеше овој квиз пред 5 години" />
                    </div>
                ))}
            </div>
            <div className="slider-container">

                <SliderBlock
                    cardData={cardData}
                    isMobile={isMobile}
                    renderCard="TestimonialCard"
                    cardsPerSlide={2}
                    gridTemplateColumns="1fr 1fr"
                />
            </div>
            <CtaBlockApply />
        </PremiumJoinCommunityWrapper>
    );
}

export default PremiumJoinCommunity;