import React, { useRef } from 'react';
import styled, { css, withTheme } from 'styled-components';

// Components
import Icon from '../Icon/Icon';
import { Text13 } from '../Text/Text';

// Styled components
const CheckboxContainer = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 2px solid ${props => props.theme.black};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.checked ? props.theme.black : "transparent"};
    transition: background 0.3s ${props => props.theme.expo};
    input {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
    .checkmark {
        transition: 0.5s ${props => props.theme.expo};
        transform-origin: center center;
        ${props => props.checked ? css`
            transform: scale(1);
        `: css`
            transform: scale(0);
        `};
    }
    @media (max-width: 500px){
        svg{
            width: 9px;
            height: 9px;
        }
    }
`;

const CheckboxLabel = styled.label`
cursor: pointer;
display: inline-flex;
align-items: center;
p {
    margin-left: 10px;
    .link{
        color: ${props => props.theme.blue};
        font-weight: bold;
    }
    }
`;


// Component
const Checkbox = ({ theme, checked, onClick, text, boldText, error, isFilterButton, className, link }) => {
    const checkmark = useRef(null);

    return (
        <CheckboxLabel onChange={onClick} isFilterButton={isFilterButton} className={className}>
            <CheckboxContainer checked={checked}>
                <input type="checkbox" value={checked} />
                <Icon icon="icnCheck" checked={checked} className="checkmark" width={15} height={15} pathFill={theme.white} ref={checkmark} />
            </CheckboxContainer>
            <Text13 color={error ? theme.textError : theme.lightBlack}>{typeof error === "string" ? error : text && text}{boldText && <strong>{boldText}</strong>}{link?.text && <span className='link' onClick={link.onClick}>{link.text}</span>}</Text13>
        </CheckboxLabel>
    )
}

export default withTheme(Checkbox);
