import React, { useEffect, useRef } from 'react';

//imports
import styled from 'styled-components';
import Wrapper from '../../../Components/Wrapper/Wrapper';
import QuizAnswers from '../Blocks/QuizAnswers';
import { Text10, Text13 } from '../../../Components/Text/Text';
import { useTranslation } from 'react-i18next';

//styled-components
const LessonQuizWrapper = styled.div`
display: flex;
transition: padding 0.2s ease;
@media (max-width: 1041px){
    padding-left: 140px;
    padding-right: 25px;
}

@media (max-width: 500px){
    font-size: 14px;
    padding: ${props => props.collapse ? "60px 43px 60px 59px" : "60px 43px 60px 109px"};
}
:nth-child(even){
    background-color: ${props => props.theme.gray0};
}

:nth-last-child {
    border-bottom: #E6E7EB;
}
`

const QuestionWrapper = styled(Wrapper)`
width: 100%;
max-width: 750px;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: stretch;
padding: 80px 0;

@media (max-width: 768px){
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 80px 0 0 0;
}

@media (max-width: 500px){
    padding-top: 0px;
}
`

const LessonQuizQuestionContainer = styled.div`
    flex-basis: 0;
    flex-grow: 1;
    padding: 25px 0;
    padding-right: 10%;
    border-right: 1px solid ${props => props.theme.gray4};
    &>p{
        color: ${props => props.theme.gray6};
    }
    @media (max-width: 500px){
        border-right: none;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
`

const LessonQuizTitleContainer = styled.div`
    padding: 0 0 20px 0 ;
    p{
        color: ${props => props.theme.gray3};
    }
    @media (max-width: 500px){
        padding: 0px 0px 20px 0px;
    }
`

const LessonQuizLeftAnswersContainer = styled.div`
    padding: 25px 0;
    p {
        margin: 15px 0;
        color: ${props => props.theme.gray6};
    }
    @media (max-width: 500px){
        padding: 25px 0 45px 0;
    }
`



//component
const LessonQuiz = (props) => {
    const { t, i18n } = useTranslation();
    //Alphabet array used to assign Letters to answers based on their index numbers
    const answerLettersABC = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    const answerLettersABV = ["А", "Б", "В", "Г", "Д", "Ѓ", "Е", "Ж", "З", "Ѕ", "И", "Ј", "К", "Л", "Љ", "М", "Н", "Њ", "О", "П", "Р", "С", "Т", "Ќ", "У", "Ф", "Х", "Ц", "Ч", "Џ", "Ш"];

    const answerLetters = i18n.language === "mk" ? answerLettersABV : answerLettersABC;


    //reference of first unanswered question if there is such
    const unansweredQuestionRef = useRef();

    //on change of props.unansweredQuestions, see if there is an unansweredQuestionRef.current and if so, scrollIntoView the question
    //see ref assignment on line 74 on LessonQuizWrapper component
    useEffect(() => {
        if (unansweredQuestionRef.current) {
            const y = unansweredQuestionRef.current.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, [props.unansweredQuestions])
    return (
        <>
            {props.quizContent && props.quizContent.questions.map((question, i) => {
                return (
                    // if the first unanswered question (represented in question id) from all unanswered questions (props.unansweredQuestions)
                    // is equal to this questions id, set it's ref to unansweredQuestionRef to use it inside the useEffect from line 58 and scrollIntoView given question
                    <LessonQuizWrapper ref={question.id === props.unansweredQuestions[0] ? unansweredQuestionRef : null} key={"question " + i} collapse={props.collapse}>
                        <QuestionWrapper>
                            <LessonQuizQuestionContainer>

                                <LessonQuizTitleContainer>
                                    <Text10>{`${t("learning_mode.quiz.question").charAt(0).toUpperCase()}${t("learning_mode.quiz.question").slice(1)}`} {i + 1}</Text10>
                                </LessonQuizTitleContainer>
                                <Text13>{question.text}</Text13>
                                <LessonQuizLeftAnswersContainer>
                                    {question.answers.map((answer, i) => (
                                        <Text13 key={"answer " + i}>{`${answerLetters[i]}) ${answer.text}`}</Text13>
                                    )
                                    )}
                                </LessonQuizLeftAnswersContainer>
                            </LessonQuizQuestionContainer>
                            <QuizAnswers
                                answers={question.answers}
                                answerInfo={question.answer_info}
                                answerLetters={answerLetters}
                                addAnsweredQuestion={props.addAnsweredQuestion}
                                answeredQuestions={props.answeredQuestions}
                                questionId={question.id}
                            />
                        </QuestionWrapper>
                    </LessonQuizWrapper>
                )
            })}
        </>
    );
}

export default LessonQuiz;