import React from 'react';

//npm imports
import styled, { keyframes, withTheme } from 'styled-components';

//component imports
import Icon from '../Icon/Icon';
import { useMediaQuery } from 'react-responsive';
import ClippedRectangleYellow from '../../Static/illustrations/CtaBlockCommunity/ClippedRectangleYellow.svg';
import StraightScribble from '../../Static/illustrations/CtaBlockCommunity/StraightScribble.svg';
import TwirlyScribble from '../../Static/illustrations/CtaBlockCommunity/TwirlyScribble.svg';

//animations

const blackArrowSlideOut = keyframes`
    0%{
        top: 0px;
        left: 0px;
    }
    100%{
        top: -281px;
        left: 145px;
    }
`

const whiteArrowSlideIn = keyframes`
    0%{
        top: 0px;
        left: -145px;
    }
    100%{
        top: 0px;
        left: 0px;
    }
`

//styled-components
const SCtaBlockWrapper = styled.a`
    width: 100%;
    max-width: 1151px;
    height: 215px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .shapes-container{
        width: 100%;
        height: 215px;
        display: flex;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        .black-shape-container{
            width: 50%;
            height: 100%;
            background-color: ${props => props.theme.gray7};
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            .black-shape-item{
                width: 100%;
                height: 100%;
                border-bottom-right-radius: 78px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                background-color: ${props => props.theme.black};
            }
        }
        .gray-shape-container{
            width: 50%;
            height: 100%;
            background-color: ${props => props.theme.black};
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            .gray-shape-item{
                width: 100%;
                height: 100%;
                border-top-left-radius: 78px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                background-color: ${props => props.theme.gray7};
                background-image: ${props => props.ctacommunity === "true" && `url(${ClippedRectangleYellow}), url(${TwirlyScribble}), url(${StraightScribble})`};
                background-repeat: no-repeat, no-repeat, no-repeat;
                background-position: -20px 0px, 40px 0px, right;
                background-size: auto, 37%, auto;
            }
        }
        .black-filler-item{
            width: 70%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -50%;
            transition: left .6s ${props => props.theme.expo};
            background-color: ${props => props.theme.black};
        }
    }
    .content-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .children-container{
            .quiz-illustration{
                transition: transform 0.1s linear;
            }
        }
        .arrow-container{
            width: 143px;
            height: 20px;
            overflow: hidden;
            float: right;
            // transform: translate(0%, 50%);
            margin-top: 100px;
            margin-right: 75px;
            position: relative;
            .black-arrow{
                position: absolute;
                top: 0;
                left: 0;
            }
            .white-arrow{
                position: absolute;
                top: 0px;
                left: -145px;
            }
        }
    }
    &:hover{
        cursor: pointer;
        .shapes-container{
            .gray-shape-container{
                .gray-shape-item{
    
                }
            }
            .black-filler-item{
                left: 35%;
            }
        }
        .content-container{
            .children-container{
                .quiz-illustration{
                    transform: scale(1.01);
                }
                .community-illustration{
                    .purple-clipped-rectangle{
                        visibility: visible;
                        transform: rotate(90deg);
                    }
                    .black-circle{
                        background-color: ${props => props.theme.pink};
                    }
                }
                .software-engineering-illustration{
                    .quarterCircleYellow{
                        transform: rotate(180deg);
                    }
                    .silverLeaf{
                        transform: rotate(-270deg);
                        path{

                            fill: ${props => props.theme.purple};
                        }
                    }
                    .brackets{
                        &>path{
                            fill: ${props => props.theme.purple};
                        }
                    }
                }
            }
            .arrow-container{
                .black-arrow{
                    animation: ${blackArrowSlideOut} .2s ${props => props.theme.expo} forwards;
                }
                .white-arrow{
                    animation: ${whiteArrowSlideIn} .4s ${props => props.theme.expo} .2s forwards;
                }
            }
        }
    }
    @media (max-width: 500px){
        height: 194px;
        .shapes-container{
            .black-shape-container{
                width: 55%;
                .black-shape-item{
                    border-bottom-right-radius: 45px;
                }
            }
            .gray-shape-container{
                width: 45%;
                .gray-shape-item{
                    border-top-left-radius: 45px;
                    background-size: 70px 70px, 116px 200px, 0px;
                    background-position: -20px 50px, 40px 0px, right;
                }
            }
            .black-filler-item{
            }
        }
        .content-container{
            .children-container{
                .quiz-illustration{
                    max-height: 150px;
                }
            }
            .arrow-container{
                width: 51px;
                float: none;
                margin-top: 171px;
                margin-right: 0px!important;
                margin-left: 21px;
            }
        }
    }
`

//component
const SCtaBlock = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 500 })
    return (
        <SCtaBlockWrapper href={props.href} target="_blank" ctacommunity={(props.href === "http://facebook.com").toString()}>
            <div className="shapes-container">
                <div className="black-shape-container">
                    <div className="black-shape-item"></div>
                </div>
                <div className="gray-shape-container">
                    <div className="gray-shape-item"></div>
                </div>
                <div className="black-filler-item"></div>
            </div>
            <div className="content-container">
                {props.children}
                <div className="arrow-container">
                    <Icon icon="icnArrowLong" pathStroke={isMobile && props.theme.white} pathFill={isMobile && props.theme.white} className="black-arrow" width={isMobile && 51} />
                    <Icon icon="icnArrowLong" pathStroke={isMobile && props.theme.white} pathFill={props.theme.white} className="white-arrow" width={isMobile && 51} />
                </div>
            </div>
        </SCtaBlockWrapper>
    );
}

export default withTheme(SCtaBlock);