import React, { useState } from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import { Heading35 } from '../../../../Components/Text/Text';
import QAAccordion from '../../../../Components/QAAccordion/QAAccordion';

//other imports

//styled components
const PremiumFAQWrapper = styled(Wrapper)`
    flex-direction: row;
    padding: 70px 0;
    align-items: flex-start;

    .accordions-container{
        width: 70%;
    }

    @media (max-width: 500px){
        flex-direction: column;
        h4{
            margin-bottom: 24px;
            font-size: 28px;
            line-height: 33px;
        }
        .accordions-container{
            width: 100%;
        }
    }
`

//component
const PremiumFAQ = (props) => {
    const [showAccordion, setShowAccordion] = useState(null)
    return (
        <PremiumFAQWrapper>
            <Heading35>Често поставувани прашања</Heading35>
            <div className="accordions-container">
                <QAAccordion onClick={setShowAccordion} showContent={showAccordion} id={1} question="Често поставувано прашање" answer="Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор " />
                <QAAccordion onClick={setShowAccordion} showContent={showAccordion} id={2} question="Често поставувано прашање" answer="Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор " />
                <QAAccordion onClick={setShowAccordion} showContent={showAccordion} id={3} question="Често поставувано прашање" answer="Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор Често даден одговор " />

            </div>
        </PremiumFAQWrapper>
    );
}

export default PremiumFAQ;