import axios from 'axios';
import { navigate } from '@reach/router';

let axiosSetup = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axiosSetup.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('user')
            navigate('/signin');
        }
        throw error;
    }
);

var createApiRoute = (route, data) => {
    let apiRoute = API.routes[route];
    if (typeof data !== typeof undefined) {
        for (let key in data) {
            apiRoute = apiRoute.replace(`{${key}}`, data[key]);
        }
    }

    return apiRoute;
}

var createRoute = (route, data) => {
    let apiRoute = API.appRoutes[route];
    if (typeof data !== typeof undefined) {
        for (let key in data) {
            apiRoute = apiRoute.replace(`{${key}}`, data[key]);
        }
    }

    return apiRoute;
}

//create url from parts, stripping the necessary slashes at the end of each part
var createRouteFromParts = (...parts) => {
    parts = parts.map(part => {
        return part && part.replace(/^\/+|\/+$/g, "");
    })

    let url = parts.join('/');

    return url;
}

const externalLinks = (key) => {
    let link = API.links[key];
    return link
}

axiosSetup.defaults.withCredentials = true;

export const API = {
    createApiRoute: createApiRoute,
    createRoute: createRoute,
    createRouteFromParts: createRouteFromParts,
    externalLinks: externalLinks,
    axios: axiosSetup,
    routes: {
        config: "/config",
        authenticated: "/authenticated",
        backToCourse: "/courses/{COURSE_ID}", //not used
        checkAnswer: "quiz/check-answer",
        checkCode: "code-challenge/check",
        csrf: "csrf-cookie",
        dashboard: "dashboard",
        editStudentInfo: "/student/edit/profile",
        editStudentLogin: "/student/edit/login",
        getCourse: "courses/{COURSE_ID}",
        getCourseInfo: "courses/{COURSE_ID}/info",
        //comment for below route backend doesn't have an api route like this. so its no longer used. it's changed with an appRoute
        getCourseWithPath: "{LEARNINGPATH_ID}/courses/{COURSE_ID}",
        getLearningPath: "learningpaths/{LEARNINGPATH_ID}",
        getLearningPathsAll: "learningpaths/all/",
        getStudyFieldsWithCourses: "studyfields/courses",
        popularCourses: "courses/popular",
        featuredCourses: "courses/featured",
        featuredLearningPaths: "learningpaths/featured",
        popularLearningPaths: "learningpaths/popular",
        recommendedCoursesByCourse: "courses/{COURSE_ID}/recommend",
        recommendedCoursesFromComputerScience: "/studyfields/computer-science/courses/recommend",
        recommendedCoursesFromDigitalMarketing: "/studyfields/digital-marketing/courses/recommend",
        recommendedCoursesGeneral: "/courses/recommend",
        recoverPassword: "/student/reset",
        recoverPasswordConfirm: "/student/reset/confirm",
        resetCourse: "/courses/{COURSE_ID}/reset-progress",
        resetPassword: "student/reset/{RESET_TOKEN}",
        resetProgress: 'reset/', //not sure if used
        getCourseFeedback: '/courses/{COURSE_ID}/feedback',
        setCourseFeedback: '/courses/{COURSE_ID}/feedback',
        studentLogin: "student/login",
        studentLogout: "student/logout",
        studentRegister: "student/register",
        unfinishedCourses: "student/courses",
        unfinishedLearningPaths: "student/learningpaths",
        watchHistory: "student/history",
        uploadProfileImage: "/student/edit/image",
        resendEmailVerificationLink: "/student/resend-verification",
        prelaunchResendEmailVerificationLink: "/prelaunch/student/resend-verification/{STUDENT}",
        verifyEmail: "/student/verify/{TOKEN}",
        apply: "/student/apply",
        studentStatus: "/student/apply/{STUDENT}",
        checkpoint: "/student/apply/{ID}/checkpoint",
        studentInfo: "/student/info",
        getLandingCourses: "/lp/studyfields/courses",
        legal: "/legal"
    },
    appRoutes: {
        completeLesson: "/lessons/{LESSON_ID}/complete", //same as startLesson
        getCourse: "/courses/{COURSE_ID}",
        getLearningPath: "/learning-path/{LEARNINGPATH_ID}",
        getCourseWithPath: "/learning-path/{LEARNINGPATH_ID}/courses/{COURSE_ID}",
        learningMode: '/learning-mode/{COURSE_ID}/lesson/{LESSON_ID}', //ok
        //learningModeBasic: "/learning-mode/", //ok
        learningModeWellDone: "/learning-mode/{COURSE_ID}/well-done/course",
        learningModeWellDoneWithLearningPath: "/learning-mode/{LEARNINGPATH_ID}/{COURSE_ID}/well-done/course",
        learningModeWhatsNext: "/learning-mode/{COURSE_ID}/well-done/next",
        learningModeWhatsNextWithLearningPath: "/learning-mode/{LEARNINGPATH_ID}/{COURSE_ID}/well-done/next",
        learningModeWithPath: '/learning-mode/{LEARNINGPATH_ID}/course/{COURSE_ID}/lesson/{LESSON_ID}', //ok
        startLesson: "/lessons/{LESSON_ID}/start", //not sure if this is for appRoutes or apiRoutes i put it here because its in navigate() CourseHeading.jsx 136 row
        studentRecover: "/student/recover",
        signin: "/signin",
        register: "/register",
        registerWithReferral: "register/{REFERRED_BY}",
        catalogue: "/catalogue",
        referAFriend: "/refer",
        apply: "/apply",
        applicationStatus: "/apply/status/{STUDENT}",
    },
    links: {
        nextLanding: "https://next.edu.mk/",
        quiz: "https://careers.brainster.co/quiz",
        mediaLanding: "https://media.next.edu.mk/",
        brainsterBlog: "https://blog.brainster.co/",
        brainsterNextFacebookPage: "https://www.facebook.com/brainsternext",
        brainsterNextInstagramProfile: "https://www.instagram.com/brainsternext/",
        brainsterNextLinkedInProfile: "https://www.linkedin.com/company/brainsternext/",
        brainsterYouTubeChannel: "https://www.youtube.com/channel/UCNtVHccQMoj5VV8HM1jv_uw",
        brainsterNextFacebookGroup: "https://www.facebook.com/groups/5787924641222882"
    },
    courseFeedback: { // not used
        helpful: 1,
        interesting: 2,
        brilliant: 3,
        confusing: 4
    },
    storage: process.env.REACT_APP_STORAGE_URL
};