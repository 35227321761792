import React, { useEffect, useState } from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import CourseHeading from '../Course/Blocks/CourseHeading/CourseHeading';
import PremiumCourseInfo from './Blocks/PremiumCourseInfo/PremiumCourseInfo';
import CourseMobileBackground from '../Course/Blocks/CourseMobileBackground/CourseMobileBackground';
import PremiumRealProjects from './Blocks/PremiumRealProjects/PremiumRealProjects';
import PremiumOurInstructors from './Blocks/PremiumOurInstructors/PremiumOurInstructors';
import PremiumClassroom from './Blocks/PremiumClassroom/PremiumClassroom';
import PremiumJoinCommunity from './Blocks/PremiumJoinCommunity/PremiumJoinCommunity';

//other imports
import course_image from '../../Static/premium-courses/hero-images/creative-engereering.png';
import PremiumFAQ from './Blocks/PremiumFAQ/PremiumFAQ';
import { PremiumCoursesArray } from '../../Consts/PremiumCoursesArray';
import PremiumTimeline from './Blocks/PremiumTimeline/PremiumTimeline';
import { Caption16 } from '../../Components/Text/Text';


//styled-components
const PremiumCourseWrapper = styled.div`
    ${Caption16}{
        letter-spacing: 15%;
    }
`

const heroMapping = {
    course_image,

}


//component
const PremiumCourse = (props) => {
    const [currentCourse, setCurrentCourse] = useState({});

    useEffect(() => {
        if (props.courseId) {
            var course = PremiumCoursesArray.filter(course => course.id === parseFloat(props.courseId))[0]
            setCurrentCourse(course)
        }
    }, [props.courseId]);

    return (
        <PremiumCourseWrapper>
            {currentCourse.id !== undefined
                && <>
                    <CourseMobileBackground image={heroMapping[currentCourse.course_heading.hero_image]} isExclusiveCourse={true} />
                    <CourseHeading
                        courseId={currentCourse.id}
                        lessonId={2}
                        image={heroMapping[currentCourse.course_heading.hero_image]}
                        courseTitle={currentCourse.course_heading.heading}
                        courseDescription={currentCourse.course_heading.description}
                        start={currentCourse.course_heading.technical_info.start_date}
                        duration={currentCourse.course_heading.technical_info.duration}
                        result={currentCourse.course_heading.technical_info.result}
                        isExclusiveCourse={true}
                    />
                    <PremiumCourseInfo courseInfo={currentCourse.course_info} />
                    <PremiumRealProjects realProjects={currentCourse.real_projects} />
                    <PremiumTimeline timeline={currentCourse.prep_programme_timeline} />
                    <PremiumOurInstructors instructors={currentCourse.your_instructors} />
                    <PremiumClassroom />
                    <PremiumJoinCommunity testimonials={currentCourse.community.testimonials} />
                    <PremiumFAQ />
                </>
            }
        </PremiumCourseWrapper>
    )
}

export default PremiumCourse;