import React, { useState, useEffect, useRef } from 'react';

//imports
import styled from 'styled-components';
import { Heading20, Text13, CardCaption9 } from '../Text/Text';
import Icon from '../Icon/Icon';
import image from '../../Static/course-image.svg';
import Popover from '../Popover/Popover';

//styled-components
const HistoryCardContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.danger_select}
    }
    .history-main-content-container{
        display: flex;  
    }
    .history-image-container{
        padding: 25px 25px 25px 0px;
        figure{
            width: 128px;
            height: 128px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img{
                height: 100%;
                background-color: ${props => props.theme.gray7};
            }
            
            @media (max-width: 500px){
                width: 80px;
                height: 100px;
            }
        }
        @media (max-width: 500px){
            padding: 0px 14px 0px 0px;
        }

    }
    .history-content-conatiner{
        padding: 25px 25px 25px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h5{
            margin-bottom: 8px;
        }
        @media (max-width: 500px){
            padding: 0px;
            h5{
                min-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
            .history-subtitle-container{
                margin-bottom: 8px;
                min-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
        }
    }

    .card-footer {
        &>div{
            display: flex;
            &>div{
                display: flex;
                align-items: center;
                p{
                    margin-left: 4px;
                }
            }
        }
        .card-category{
            padding-left: 11px;
            img{
                width: 10px;
            }
            @media (max-width: 500px){
                padding-left: 5px;
            }
        }
        .card-students{
            padding: 0 11px;
            border-left: 1px solid ${props => props.theme.gray3};
            border-right: 1px solid ${props => props.theme.gray3};
            @media (max-width: 500px){
                padding: 0 5px;
                p{
                    display: none;
                }
            }
        }

        .card-time {
            padding-right: 11px;
            .card-time-symbol{
                display: none;
            }
            @media (max-width: 500px){
                .card-time-symbol{
                    display: block;
                }
                .card-time-text{
                    display: none;
                }
                padding-right: 5px;
            }
        }
    }

    .more-options{
        display: none;
        padding: 25px 0;
        &>div{
            display: flex;
            position: relative;    
            @media (max-width: 500px){
                flex-direction: column;
                padding: 0px 0px 5px 10px;
            }
        }
        .dot{
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: ${props => props.theme.gray4};
            margin: 0 1.5px;
            @media (max-width: 500px){
                margin: 1.5px 0px;
            }
        }
        .popover-container{
            width: 136px;
            height: 0px;
            overflow: hidden;
            position: absolute;
            top: 2px;
            right: -25px;
            transition: height .2s;
            z-index: 9999999999999;
            &>div{
                :before{
                    left: 97px;
                }
                :after{
                    left: 98px;
                }
            }
            @media (max-width: 500px){
                top: 11px;
                right: -20px;
            }
        }
        &:hover{
            .popover-container{
                height: ${props => props.popoverScrollHeight + 2}px;
            }
        }
        @media (max-width: 500px){
            padding: 0px;
        }
    }
    @media (max-width: 500px){
        padding: 16px 0px 16px 0px;
    }
    
`

// const OptionContainer = styled.div`
//     width: 80%;
//     padding: 13px 0px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     &>p{
//         color: ${props => props.theme.gray3};
//     }

//     &>div{
//         width: 105px;
//         border: 1px solid ${props => props.theme.gray3};
//     }

//     .shrink{
//         width: 0px!important;
//         display: none;
//     }

//     &:hover{
//         cursor: pointer;
//         p{
//             color: ${props => props.theme.blue};
//         }
//     }

//     &:not(:last-child) {
//         border-bottom: 1px solid ${props => props.theme.gray3};
//     }

//     @media (max-width: 500px){
//         padding: 24px 0;
//     }
// `

//Option component
// const Option = () => {
//     const [hover, setHover] = useState(false);
//     return (
//         <OptionContainer onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
//             <Icon icon="icnRemove" />
//             <Text10>Remove</Text10>
//         </OptionContainer>
//     );
// }

//component
const HistoryCard = (props) => {
    const popoverRef = useRef();

    //keep inital scroll height of popovers in these hooks
    const [popoverScrollHeight, setPopoverScrollHeight] = useState();

    //on component mount, get popover's initial scrollheight and keep it in popoverScrollHeight hook
    useEffect(() => {
        popoverRef.current && setPopoverScrollHeight(parseInt(popoverRef.current.getBoundingClientRect().height) + 15);
    }, [])
    return (
        <HistoryCardContainer popoverScrollHeight={popoverScrollHeight}>
            <div className="history-main-content-container">
                <div className="history-image-container">
                    <figure>
                        <img src={props.cardImage ? props.cardImage : image} alt="course" />
                    </figure>
                </div>
                <div className="history-content-conatiner">
                    <div>
                        <div className="history-title-container">
                            <Heading20>{props.cardTitle ? props.cardTitle : "Наслов На Патека За Учење"}</Heading20>
                        </div>
                        <div className="history-subtitle-container">
                            <Text13>{props.cardSubtitle ? props.cardSubtitle : "Поднаслов на курс"}</Text13>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="card-info-container">
                            <div className="card-time">
                                <Icon icon="icnTime" />
                                <CardCaption9 className="card-time-text">{props.cardTime && props.cardTime}MIN</CardCaption9>
                                <CardCaption9 className="card-time-symbol">{props.cardTime && props.cardTime}'</CardCaption9>
                            </div>
                            <div className="card-students">
                                <Icon icon="icnUser" />
                                <CardCaption9>{props.cardStudentNumber} STUDENTS</CardCaption9>
                            </div>
                            <div className="card-category">
                                <Icon icon="icnCategory" />
                                <CardCaption9> {props.cardCategory && props.cardCategory}</CardCaption9>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="more-options">
                <div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <section className="popover-container">
                        <Popover ref={popoverRef} pointerPosition="top" card="learning-path-card">
                            {/* <Option /> */}
                        </Popover>
                    </section>
                </div>
            </div>
        </HistoryCardContainer>
    );
}

export default HistoryCard;