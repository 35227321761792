import React from 'react';

//npm imports
import styled, { keyframes } from 'styled-components';

//component imports
import Wrapper from '../Wrapper/Wrapper';
import ButtonBasic from '../ButtonBasic/ButtonBasic';
import { Heading35 } from '../Text/Text';

//rotate gear animation
const rotateGear = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`

//rotate red shape vertical animation
const rotateRedShapeVertical = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(-8deg);
    }
`

//styled-components
const CtaBlockApplyWrapper = styled(Wrapper)`
    width: 100%;
    max-width: 1141px;
    padding:0 9% 0 7%;
    margin: 0 auto;
    height: 216px;
    background-color: ${props => props.theme.blue};
    border-radius: 40px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h4{
        width: 67%;
        color: ${props => props.theme.white};
    }
    button{
        padding: 19px 46px;
        text-align: center;
        background-color: ${props => props.theme.white};
        color: ${props => props.theme.black};
        transition: color .2s linear, background-color .2s linear;
        z-index: 1;
    }
    button:hover{
        background-color: ${props => props.theme.white};
    }
    .block-item{
        position: absolute;
    }
    .horizontal{
        width: 83px;
        height: 45px;
        top: -22px;
        left: 9%;
    }
    .vertical{
        width: 23px;
        height: 42px;
        bottom: -28px;
        left: 59%;
    }
    .black-circle{
        width: 19px;
        height: 19px;
        background-color: ${props => props.theme.black};
        border-radius: 50%;
        top: 22px;
        left: 17%;
    }
    .black-gear{
        width: 65px;
        height: 65px;
        bottom: -20px;
        left: 60%;
    }

    .white-shape-left{
        top: 10%;
        left: 3%;
    }
    .white-shape-middle{
        top: 0;
        left: 50%;
    }
    .white-shape-right{
        top: 40%;
        right: 7%;
    }
    &:hover{
        cursor: pointer;
        .black-gear{
            animation: ${rotateGear} 5s linear forwards infinite;
        }
        .vertical{
            animation: ${rotateRedShapeVertical} .2s linear forwards infinite;
        }
        button{
            background-color: ${props => props.theme.black};
            color: ${props => props.theme.white};
        }
    }
    @media (max-width: 1141px) {
        width: 95%;
    }
    @media (max-width: 500px){
        width: 90%;
        height: 324px;
        flex-direction: column;
        padding: 85px 0;
        margin-top: 124px;
        h4{
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            width: 90%;
        }
        button{
            width: 200px;
            display: flex;
            justify-content: center;
        }
        .white-shape-left{
            z-index: 1;
            top: 7px;
            left: 20%;
            width: 59px;
            height: 54px;
            svg{
                width: 100%;
                height: 100%;
                path{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .black-circle{
            top: 10px;
            left: 25%;
            z-index: 2;
        }
        .black-gear{
            width: 43px;
            height: 43px;
            left: unset;
            right: 8%;
            bottom: -11px;
            z-index: 1;
            svg{
                width: 100%;
                height: 100%;
                path{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .vertical{
            left: unset;
            right: 17%;
            z-index: 1;
        }
        .white-shape-right{
            display: none;
        }
        .white-shape-middle{
            width: 91px;
            height: 84px;
            top: unset;
            bottom: 15px;
            left: unset;
            right: 9%;
            svg{
                width: 100%;
                height: 100%;
                path{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
`



//component
const CtaBlockApply = (props) => {
    return (
        <CtaBlockApplyWrapper>
            <Heading35>Дознај кога започнува следната група и започни нова авантура!</Heading35>
            <ButtonBasic text="Приклучи се" />
            <div className="cta-red-shape horizontal block-item">{redShapeHorizontal}</div>
            <div className="black-circle block-item"></div>
            <div className="black-gear block-item">{blackGear}</div>
            <div className="cta-red-shape vertical block-item">{redShapeVertical}</div>
            <div className="white-shape-left block-item">{whiteShapeLeft}</div>
            <div className="white-shape-middle block-item">{whiteShapeMiddle}</div>
            <div className="white-shape-right block-item">{whiteShapeRight}</div>
        </CtaBlockApplyWrapper>
    );
}

export default CtaBlockApply;

//red shape clip path
const redShapeHorizontal =
    <svg width="83" height="45" viewBox="0 0 83 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M41.3343 45L-0.000404358 45C-0.000406354 20.1395 18.4987 9.9221e-06 41.3343 7.74872e-06L82.6689 3.8147e-06C82.6689 24.8605 64.1698 45 41.3343 45Z" fill="#FF003A" />
    </svg>

//red shape vertical clip path
const redShapeVertical =
    <svg width="23" height="43" viewBox="0 0 23 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M23 21.1267L23 42.2533C10.2935 42.2533 -5.29321e-05 32.7982 -5.24219e-05 21.1267L-5.14984e-05 -1.34744e-06C12.7064 3.24113e-07 23 9.45512 23 21.1267Z" fill="#FF003A" />
    </svg>

//black gear clip path
const blackGear =
    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.5 0L37.0757 3.55458L42.5431 1.56619L45.7792 6.33901L51.603 6.11146L53.1829 11.6353L58.7931 13.1909L58.5619 18.9251L63.4093 22.1115L61.3899 27.4947L65 32L61.3899 36.5053L63.4093 41.8885L58.5619 45.0749L58.7931 50.8091L53.1829 52.3647L51.603 57.8885L45.7792 57.661L42.5431 62.4338L37.0757 60.4454L32.5 64L27.9243 60.4454L22.4569 62.4338L19.2208 57.661L13.397 57.8885L11.8171 52.3647L6.20695 50.8091L6.43806 45.0749L1.59066 41.8885L3.61012 36.5053L0 32L3.61012 27.4947L1.59066 22.1115L6.43806 18.9251L6.20695 13.1909L11.8171 11.6353L13.397 6.11146L19.2208 6.33901L22.4569 1.56619L27.9243 3.55458L32.5 0Z" fill="#100824" />
    </svg>

//white shape left
const whiteShapeLeft =
    <svg width="81" height="88" viewBox="0 0 81 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b)">
            <path d="M44 88C19.6995 88 -7.90954e-07 68.3005 -1.76665e-06 44L-1.91054e-06 40.4161C-2.80676e-06 18.0949 18.0949 -8.61091e-07 40.4161 -1.9233e-06C62.7373 -2.98551e-06 80.8323 18.0949 80.8323 40.4161L80.8323 88L44 88Z" fill="url(#paint0_radial)" />
        </g>
        <defs>
            <filter id="filter0_b" x="-8" y="-8" width="96.8323" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="4" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
            </filter>
            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.8323 88) rotate(-130.353) scale(115.475 114.306)">
                <stop stopColor="white" stopOpacity="0.3" />
                <stop offset="0.992861" stopColor="white" color="white" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>

const whiteShapeMiddle =
    <svg width="103" height="112" viewBox="0 0 103 112" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b)">
            <path d="M47 7.27009e-07C77.9279 3.25493e-07 103 25.0721 103 56L103 60.5C103 88.9427 79.9427 112 51.5 112C23.0573 112 9.74241e-07 88.9427 6.62692e-07 60.5L0 1.33718e-06L47 7.27009e-07Z" fill="url(#paint0_radial)" />
        </g>
        <defs>
            <filter id="filter0_b" x="-8" y="-8" width="119" height="128" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="4" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
            </filter>
            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(49.6132) scale(147.042 145.581)">
                <stop stopColor="white" stopOpacity="0.3" />
                <stop offset="0.992861" stopColor="white" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>

const whiteShapeRight =
    <svg width="81" height="88" viewBox="0 0 81 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b)">
            <path d="M44 88C19.6995 88 -7.90954e-07 68.3005 -1.76665e-06 44L-1.91054e-06 40.4161C-2.80676e-06 18.0949 18.0949 -8.61091e-07 40.4161 -1.9233e-06C62.7373 -2.98551e-06 80.8323 18.0949 80.8323 40.4161L80.8323 88L44 88Z" fill="url(#paint0_radial)" />
        </g>
        <defs>
            <filter id="filter0_b" x="-8" y="-8" width="96.8323" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="4" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
            </filter>
            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.8323 88) rotate(-130.353) scale(115.475 114.306)">
                <stop stopColor="white" stopOpacity="0.3" />
                <stop offset="0.992861" stopColor="white" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>