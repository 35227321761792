import React from 'react';

//imports
import styled from 'styled-components';
import Wrapper from '../../../Components/Wrapper/Wrapper';
import Icon from '../../../Components/Icon/Icon';
import { CardCaption15 } from '../../../Components/Text/Text';
import PopupAlert from '../../../Components/PopupAlert/PopupAlert';
import { useTranslation } from 'react-i18next';

//styled-components
const LessonControllerContainer = styled.div`
    width: 100%;
    padding: ${props => !props.moveElements ? '26px 0px 26px 70px' : '26px 0'};
    position: fixed;
    bottom: 0px;
    background-color: ${props => props.theme.lightBlack};
    transition: padding .2s ease;
    box-shadow: 1px -4px 6px rgba(0, 0, 0, 0.05);
`

const LessonControlerWrapper = styled(Wrapper)`
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    &>a {
        display: flex;
        align-items: center;
    }
    &>.next >svg {
        transform: rotate(180deg);
    }
    &>button {
        background: none;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        :hover {
            cursor: pointer;
        }
        .nextArrow {
            transform: rotate(180deg);
        }
    }
`

const LessonsLegend = styled(CardCaption15)`
    width: max-content;
    color: ${props => props.theme.gray5};
    letter-spacing: 1px;
`

const NavigateText = styled(CardCaption15)`
    color: ${props => props.theme.gray5};
    margin: 0 8px;
    letter-spacing: 1px;
`

const LessonControler = (props) => {
    const { t } = useTranslation();
    return (
        <LessonControllerContainer moveElements={props.moveElements}>
            <LessonControlerWrapper>
                <PopupAlert popup={props.popupAlert} right={-40} bottom={20} message={t("alerts.learning_mode.choose_answer")} onClick={() => props.setPopupAlert(!props.popupAlert)} />
                <button onClick={props.backAction}><Icon icon={"icnArrowLeft"} width={8} height={12} pathStrokeWidth={4} /><NavigateText>{t("general.back")}</NavigateText></button>
                <LessonsLegend>
                    {(props.currentLessonIndex + 1) + " / " + props.allLessons.length}
                </LessonsLegend>
                <button onClick={props.nextAction}><NavigateText>{t("general.next")}</NavigateText><Icon className="nextArrow" icon={"icnArrowLeft"} width={8} height={12} pathStrokeWidth={4} /></button>
            </LessonControlerWrapper>
        </LessonControllerContainer>
    );
}

export default LessonControler;