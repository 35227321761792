import React, { useState, useEffect } from 'react';

//npm imports
import styled, { keyframes, withTheme } from 'styled-components';

//component imports
import GradientProgress from '../GradientProgress/GradientProgress';
import { Text10, CardCaption11, Heading20 } from '../Text/Text';
import Icon from '../Icon/Icon';
import ButtonSmall from '../ButtonSmall/ButtonSmall';
import Skeleton from '../Skeleton/Skeleton';

//other imports
import image from '../../Static/course-image.svg';

//animations
const animationOne = keyframes`
    from{
        transform: translate(-50%, 0%);
    }
    to{
        transform: translate(0%, 0%);
    }
`

const animationTwo = keyframes`
    from{
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(-50%, 0%);
    }
`

//styled-components
const MyLearningPathCardContainer = styled.div`
    width: 100%;
    max-width: 850px;
    height: 100%;
    border: 1px solid ${props => props.theme.gray7};
    border-radius: 10px;
    display: flex;
    background-color: white;
    &:hover{
        cursor: pointer;
        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.1);
        .card-cta {
            animation: ${animationOne} 0.2s linear forwards;
            animation-duration-function: ${props => props.theme.expo};
        }
    }
    @media (max-width: 500px){
        padding: 0px 12px 16px 12px;
        overflow: unset;
    }
`

const LearningPathImageContainer = styled.div`
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    &>img, &>.image-skeleton-container{
        width: 110px;
        height: 110px;
        border-radius: 50%;
        box-shadow: 1px 0px 7px rgba(0,0,0,0.1);
        overflow: hidden;
    }
    &>img{
        background-color: ${props => props.theme.gray7};
    }
    @media (max-width: 1141px){
        width: 35%;
        &>img, &>.image-skeleton-container{
            width: 130px;
            height: 130px;
        }
    }
    @media (max-width: 500px){
        display: none;
    }
`

const LearningPathTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    h5{
        width: 70%;
    }
`

const LearningPathCardContent = styled.div`
    width: 80%;
    padding: 43px 20px 16px 0;

    @media (max-width: 1141px){
        width: 65%;
        padding: 23px 20px 9px 0;
    }

    @media (max-width: 500px){
        width: 100%;
        padding: 16px 0 0 0;
        h5{
            font-size: 14px;
            line-height: 17px;
        }
    }
`

const CardProgressConatiner = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`

const OptionContainer = styled.div`
    width: 80%;
    padding: 13px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>p{
        color: ${props => props.theme.gray3};
    }

    &>div{
        width: 105px;
        border: 1px solid ${props => props.theme.gray3};
    }

    &:hover{
        cursor: pointer;
        p{
            color: ${props => props.theme.blue};
        }
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.gray3};
    }
`

const KeepLearningContainer = styled.div`
    width: 100%;
    height: 35px;
    margin-top: 15px;
    overflow: hidden;
    @media (max-width: 500px){
        display: none;
    }
`

const KeepLearningCTA = styled.div`
    display: flex;
    align-items: center;
    animation: ${animationTwo} 0.2s linear forwards;
    animation-duration-function: ${props => props.theme.expo};
    p{
        margin-right: 22px;
    }
`


//option component
export const Option = withTheme((props) => {
    //keep the hover state in this hook, needed in order to change fill of Icon
    const [hover, setHover] = useState(false);

    return (
        <OptionContainer onClick={() => props.onClick()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Icon icon={props.icon} fill={hover ? props.theme.blue : props.theme.gray3} width={props.width} height={props.height} />
            <Text10>{props.text}</Text10>
        </OptionContainer>
    )
})

//components
const MyLearningPathCard = (props) => {
    const [color, setColor] = useState();

    //on component mount, setColor to appropriate theme color for given study field
    //get popover's initial scrollheight and keep it in this hook
    useEffect(() => {
        let name = props.studyField && props.studyField.replace(" ", "");
        setColor(props.theme[name]);
    }, [props.studyField, props.theme])


    return (
        <MyLearningPathCardContainer spread={props.spread}>
            <LearningPathImageContainer>
                {props.cardImage
                    ? <img src={props.cardImage ? props.cardImage : image} alt="course" />
                    : <div className="image-skeleton-container">
                        <Skeleton />
                    </div>
                }
            </LearningPathImageContainer>
            <LearningPathCardContent>
                <LearningPathTitleContainer>
                    <Heading20>{props.cardTitle ? props.cardTitle : <Skeleton />}</Heading20>
                </LearningPathTitleContainer>
                <CardProgressConatiner>
                    {props.cardTitle
                        ? <GradientProgress progress={props.cardProgress} color={color} />
                        : <Skeleton />
                    }

                </CardProgressConatiner>
                <KeepLearningContainer>
                    {props.cardTitle
                        &&
                        <KeepLearningCTA className="card-cta">
                            <CardCaption11>ПРОДОЛЖИ</CardCaption11>
                            <ButtonSmall width={34} height={34} />
                        </KeepLearningCTA>
                    }
                </KeepLearningContainer>
            </LearningPathCardContent>
        </MyLearningPathCardContainer>
    );
}

export default withTheme(MyLearningPathCard);