import React from 'react';

//imports
import styled, { withTheme } from 'styled-components';
import { Caption9 } from '../../Components/Text/Text';
import Icon from '../../Components/Icon/Icon';
import { useTranslation } from 'react-i18next';

//styled-components
const ProgressBarParrent = styled.div`
    width: ${props => props.width ? props.width : "100%"};
    display: flex;
    margin-bottom: 80px;
    margin-top: 80px;
    @media (max-width: 768px){
        margin-top: 0px;
        margin-bottom: 58px;
    }
`

const ProgressBarWrapper = styled.div`
    width: 95%;
`

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 23px;
    background-color: #F3F3F5;
    border-radius: 12px;
`

const Progress = styled.div`
    width: ${props => props.progress ? props.progress + "%" : "0%"};
    transition: width 0.5s ease;
    padding-right: ${props => props.progress > 2 ? "10px" : "0px   "};
    height: 23px;
    background-color: ${props => props.theme[props.studyField] || props.theme.blue};
    color: white;
    border-radius: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
        font-size: 12px;
    }
`

const ProgressLegendContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    @media (max-width: 500px){
        height: 7px;
    }
`

const ResetProgressContainer = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    outline: none;
    border: none;
    p.reset-progress {
        margin-left: 5px;
        font-size: 10px;
        text-transform: none;
        color: ${props => props.theme[props.studyField] || props.theme.blue};
    }

    &:hover {
        color: none;
        cursor: pointer;
    }
    @media(max-width: 500px){
        p.reset-progress{
            font-size: 9px;
        }
        svg{
            width: 10px;
        }
    }
`

const ProgressStarContainer = styled.div`
    width: 5%;
    display: flex;
    justify-content: center;
    margin-top: -3px;
    
`

//component
const ProgressBar = (props) => {
    const { t } = useTranslation();

    return (
        <ProgressBarParrent>
            <ProgressBarWrapper>
                <ProgressBarContainer >
                    <Progress progress={props.progress} studyField={props.studyField}><span>{props.progress > 2 && props.progress + "%"}</span></Progress>
                </ProgressBarContainer>
                <ProgressLegendContainer>
                    <div>
                        <Caption9>{t("course_index.course_progress")}</Caption9>
                    </div>
                    {props.progress > 0
                        && <ResetProgressContainer onClick={() => props.resetCourseProgress(props.resetId)} studyField={props.studyField}>
                            <Icon icon={"icnReset"} width={17} height={17} pathFill={props.theme[props.studyField] || props.theme.blue} />
                            <Caption9 className="reset-progress"> {t('course_index.reset_your_progress')}</Caption9>
                        </ResetProgressContainer>
                    }
                </ProgressLegendContainer>
            </ProgressBarWrapper>
            <ProgressStarContainer>
                <Icon icon={"icnStar"} pathFill={props.theme[props.studyField] || props.theme.blue} />
            </ProgressStarContainer>
        </ProgressBarParrent>
    );
}

export default withTheme(ProgressBar);