import styled from 'styled-components';

// Body Text

export const Text10 = styled.p`
  font-size: ${props => props.theme.smallText};
  line-height: 12px;
  color: ${props => props.color ? props.color : props.theme.lightBlack};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Text13 = styled.p`
  font-size: ${props => props.theme.normalText};
  color: ${props => props.error ? props.theme.red : props.color ? props.color : props.theme.lightBlack};
  line-height: 20px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
  @media (max-width: 500px){
    font-size: 10px;
    line-height: 12px;
  }
`;

export const Text16 = styled.p`
  font-size: ${props => props.theme.text16};
  color: ${props => props.error ? props.theme.red : props.color ? props.color : props.theme.lightBlack};
  line-height: 30px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Text20 = styled.p`
  font-size: ${props => props.theme.mediumText};
  color: ${props => props.color ? props.color : props.theme.lightBlack};
  line-height: 23px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Text24 = styled.p`
font-size: ${props => props.theme.largeText};
color: ${props => props.color ? props.color : props.theme.lightBlack};
line-height: 33px;
font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Text28 = styled.p`
font-size: ${props => props.theme.heading4};
color: ${props => props.color ? props.color : props.theme.lightBlack};
line-height: 33px;
font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

//Manrope Text
export const ManropeText16 = styled.p`
  font-size: ${props => props.color ? props.color : props.theme.text16};
  color: ${props => props.theme.lightBlack};
  line-height: 22px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`
// Captions

export const Caption9 = styled.p`
  font-size: ${props => props.theme.smallestCaption};
  color: ${props => props.color ? props.color : props.theme.lightBlack};
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Caption12 = styled.p`
  font-size: ${props => props.theme.smallCaption};
  color: ${props => props.color ? props.color : props.theme.lightBlack};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
  @media (max-width: 500px){
    font-size: ${props => props.theme.smallCaptionMobile};
    line-height: 12px;
  }
`;

export const Caption14 = styled.p`
  font-size: ${props => props.theme.smallMediumCaption};
  color: ${props => props.color ? props.color : props.theme.lightBlack};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 600};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
  @media (max-width: 500px){
    font-size: ${props => props.theme.smallMediumCaptionMobile};
    line-height: 12px;
  }
`;

export const Caption16 = styled.p`
  font-size: ${props => props.theme.mediumCaption};
  color: ${props => props.color ? props.color : props.theme.lightBlack};
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Caption18 = styled.p`
  font-size: ${props => props.theme.largeCaption};
  color: ${props => props.color ? props.color : props.theme.lightBlack};
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;


// Headings

export const Heading80 = styled.h1`
  font-size: ${props => props.theme.heading1};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 94px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Heading60 = styled.h2`
  font-size: ${props => props.theme.heading2};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 70px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Heading53 = styled.h3`
  font-size: ${props => props.theme.heading3};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 62px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Heading35 = styled.h4`
  font-size: ${props => props.theme.heading6};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 40px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Heading28 = styled.h4`
  font-size: ${props => props.theme.heading4};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 33px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Heading22 = styled.h5`
  font-size: ${props => props.theme.heading5};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 24px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
`;

export const Heading20 = styled.h5`
  font-size: ${props => props.theme.heading20};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 24px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  font-family: ${props => props.fontFamily ? props.fontFamily : "inherit"};
  @media (max-width: 500px){
    font-size: ${props => props.theme.mobileHeading20};
    line-height: 23px;
  }
`;

export const SpecHeading = styled.h1`
  font-size: ${props => props.theme.heading1};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 92px;
  font-family: 'YesevaOne';
`;

export const SpecHeading2 = styled.h1`
  font-size: ${props => props.theme.heading2};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 75px;
  font-family: 'YesevaOne';
`;
export const SpecHeadingSmall = styled.h3`
  font-size: ${props => props.theme.specHeadingSmall};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 46px;
  font-family: ${props => props.font ? props.font : 'YesevaOne'};
  font-weight: ${props => props.weight ? props.weight : 400};
`;

export const SpecHeadingSmaller = styled.h3`
  font-size: ${props => props.theme.specHeadingSmaller};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 37px;
  font-family: ${props => props.font ? props.font : 'YesevaOne'};
  font-weight: ${props => props.weight ? props.weight : 400};
`;


export const SpecHeadingSmallest = styled.h3`
  font-size: ${props => props.theme.specHeadingSmallest};
  color: ${props => props.color ? props.color : props.theme.black};
  line-height: 26px;
  font-family: ${props => props.font ? props.font : 'YesevaOne'};
  font-weight: ${props => props.weight ? props.weight : 400};
`;

// Menu Capture

export const MenuCaptureB = styled.h4`
  font-size: ${props => props.theme.menuCapture};
  color: ${props => props.color ? props.color : props.theme.black};
  text-transform: uppercase;
  line-height: 23px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
`;

export const MenuCapture = styled(MenuCaptureB)`
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
`;

//Card Captions
export const CardCaption9 = styled.p`
  font-size: ${props => props.theme.smallestCaption};
  color: ${props => props.color ? props.color : props.theme.black};
  font-family: 'Manrope';
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
  letter-spacing: 1px;
`;

export const CardCaption10 = styled.p`
  font-size: 10px;
  color: ${props => props.color ? props.color : props.theme.black};
  font-family: 'Manrope';
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
  letter-spacing: 1px;
`;

export const CardCaption11 = styled.p`
font-size: ${props => props.theme.smallCaption};
color: ${props => props.color ? props.color : props.theme.black};
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
  letter-spacing: 1px;
`

export const CardCaption15 = styled.p`
  font-size: ${props => props.theme.mediumCaption};
  color: ${props => props.color ? props.color : props.theme.black};
  font-family: 'Manrope';
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
  letter-spacing: 1px;
`;

//button texts
export const ButtonText16 = styled.p`
  font-size: ${props => props.theme.mediumCaption};
  color: ${props => props.color ? props.color : props.theme.black};
  font-family: 'Manrope';
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
  @media (max-width: 500px){
    font-size: 14px;
    line-height: 19px;
  }
`

export const ControlButtonText12 = styled.p`
  font-size: ${props => props.theme.normalText};
  color: ${props => props.color ? props.color : props.theme.black};
  font-family: 'Manrope';
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
`

export const ControlButtonText14 = styled.p`
  font-size: ${props => props.theme.normalText};
  color: ${props => props.color ? props.color : props.theme.black};
  font-family: 'Manrope';
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
`