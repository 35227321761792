import React, { useState, useCallback, useEffect } from 'react';

//npm imports
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';


//component imports
import ButtonBasic from '../ButtonBasic/ButtonBasic';

//styled-components
const PhotoCropperWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 9999999;
    align-items: center;
    .cropper-popup{
        height: fit-content;
        background-color: ${props => props.theme.white};
        border-radius: 15px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cropper-container{
            width: 500px;
            height: 500px;
            position: relative;
            border-radius: 15px;
            .reactEasyCrop_Container{
                border-radius: 15px;
                overflow: hidden;
            }
        }
        .zoom-container{
            width: 100%;
            input{
                width: 100%;
            }
        }
        .buttons-container{
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
    }
    .dimmed-background{
        width:100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: ${props => props.theme.lightBlack};
        opacity: 0.8;
    }
    @media (max-width: 500px){
        .cropper-popup{
            width: 95%;
            .cropper-container{
                width: 100%;
                height: 300px;
            }
        }
    }
`

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    return canvas.toDataURL();

    // As a blob
    // return new Promise((resolve) => {
    //     canvas.toBlob((file) => {
    //         resolve(URL.createObjectURL(file))
    //     }
    //         , 'image/png')
    // })
}


//component
const PhotoCropper = ({ photo, closeCropper, uploadImage, setNewImage }) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
    const [image, setImage] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                photo,
                croppedAreaPixels
            )
            let formData = new FormData();

            formData.append("file", croppedImage)

            uploadImage(formData);
            setNewImage(false)
        } catch (e) {
            console.error(e)
        }
    }, [photo, croppedAreaPixels, setNewImage, uploadImage])

    useEffect(() => {
        photo && setImage(photo);
    }, [photo]);

    return (
        <PhotoCropperWrapper>
            <div className="cropper-popup">
                <div className="dimmed-background"></div>
                <div className="cropper-container">

                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        minZoom={1}
                        maxZoom={3}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape="round"
                        zoomWithScroll={false}
                    />
                </div>
                <div className="zoom-container">
                    <input type="range" min={1} max={3} defaultValue={1} step={0.01} onChange={(e) => setZoom(e.target.value)} />
                </div>
                <div className="buttons-container">
                    <ButtonBasic onClick={() => showCroppedImage()} text={t("general.update")} variation="secondary" />
                    <ButtonBasic onClick={() => closeCropper(false)} text={t("general.close")} variation="primary" />
                </div>
            </div>
        </PhotoCropperWrapper>
    )
}

export default PhotoCropper;