import React, { useState, useEffect, useContext, useRef } from 'react';

//imports
import styled, { withTheme } from 'styled-components';
import { Link } from '@reach/router';
import { Caption9, Caption12, Heading20 } from '../Text/Text';
import { GlobalContext } from '../../Context/GlobalContext';
import { API } from '../../Consts/Api';
import { useMediaQuery } from 'react-responsive';
import Icon from '../Icon/Icon';
import Popover from '../Popover/Popover';
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';
import Accordion from '../Accordion/Accordion';
import { useTranslation } from 'react-i18next';



//styled-components
const ZenModeWrapper = styled.div`

    width: ${props => props.myPathActive ? "415px" : !props.collapse ? "122px" : "98px"};
    height: ${props => props.collapse ? "401px" : "100vh"};
    position: fixed;
    top: 50%;
    left: ${props => props.collapse ? "12px" : "0px"};
    transform: translate(0px, -50%);
    background-color: white;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: ${props => props.collapse ? "55px" : "0px"};
    transition: height .2s ease, border-radius 0.2s ease ${props => !props.collapse && ", width 0.1s ease , left 0.1s "};
    z-index: 9;
    display: flex;
    justify-content: flex-start;
    @media (max-width: 500px){
        width: ${props => props.myPathActive ? "322px" : props.collapse ? "89px" : "83px"};
        left: ${props => props.collapse ? '-78px' : '0'};
        transition: height 0.2s ease, border-radius 0.2s ease, left 0.2s ease, width 0.2s ease;
    }
`

const ZenModeNavigation = styled.div`
    width: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 24px 0;
    @media (max-width: 500px){
        width: 89px;
        padding: ${props => props.collapse ? "0 100px 0 0" : "60px 0 24px 0"};
        margin-left: 0px;
    }
`

const BrainsterLogoContainer = styled.div`
    height: ${props => props.collapse ? "0px" : "40px"};
    opacity: ${props => props.collapse ? "0" : "1"};
    overflow: hidden; 
    transition: height 0.1s ease 0.1s, opacity 0.05s ease 0.05s;
    display: flex;
    justify-content: center;

`

const ZenModeNav = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;

    &>li {
        padding: 22.5px 0;
        p{
            margin-top: 15px;
            font-size: 9px;
            letter-spacing: 0.5px;
            font-weight: 600;
        }
        @media (max-width: 500px){
            padding: 17.5px 0;
        }
    }
    
    &>li >a, >li >div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &>li:hover {
        background-color: ${props => props.theme.gray0};
        cursor: pointer;

    }

    &>li.my-path {
        background-color: ${props => props.myPathActive && props.theme.gray0};

    }
`

const IconLogo = styled(Icon)`
    width: ${props => props.width ? props.width + "px" : "24px"};
    height: ${props => props.height ? props.height + "px" : "26px"};
    @media (max-width: 500px){
        width: 32px;
        height: 32px;
    }
`

const ProfilePicContainer = styled.div`
    width: 100%;
    height: ${props => props.collapse ? "0px" : "40px"};
    opacity: ${props => props.collapse ? "0" : "1"};
    overflow: hidden; 
    transition: height 0.1s ease 0.1s, opacity 0.05s ease 0.05s;
    display: flex;
    justify-content: center;
    &>div{
        width: 38px;
        height: 38px;
        border-radius: 19px;
        background-image: url('${props => props.profileImage}');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    img {
        width: 38px;
        height: 38px;
        border-radius: 20px;
    }
    :hover {
        cursor: pointer;
    }
    `

const PopoverContainer = styled.div`
    width: 218px;
    height: ${props => props.dropdown ? "187px" : "0px"};
    transition: height 0.2s ease;
    overflow: hidden;
    position: absolute;
    left: 8px;
    bottom: 43px;
    
    .shrink {
        width: 0px!important;
    }

    &>div>a, >div>button {
        padding: 30px 0;
    }
    button{
        &:hover{
            path{
                fill: ${props => props.theme.blue};
            }
        }
    }
    a:hover{
        path{
            fill: ${props => props.theme.blue};
        }
        circle{
            fill: ${props => props.theme.blue};
        }
    }
`

const MyPathContainer = styled.div`
    width: ${props => props.myPathActive ? "305px" : "0"};
    max-height: 100%;
    transition: ${props => props.myPathActive && "width 0.1s ease"};
    padding: 60px 0 24px 0;
    overflow: hidden;
    @media (max-width: 500px){
        width: ${props => props.myPathActive ? "233px" : "0"};
        transition: width 0.2s ease;
    }
    `

const MyPathContent = styled.div`
    height: 100%;
    border-left: ${props => props.myPathActive && `1px solid #E6E7EB`};
    padding-top: 40px;
    @media (max-width: 500px){
        padding-top: 32px;
    }
`

const MyPathProgressContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const MyPathTitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -10px;
`

const MyPathSectionsContainer = styled.div`
    max-height: 90%;
    overflow-y: scroll;
    padding: 40px 30px;
    &>div >button >div p:first-child{
        line-height: 20px!important;
        font-weight: 400!important;
        margin-right: ${props => props.theme.spacer}px!important;
    }
    .lesson-title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        width: 75%;
    }

    &>div >button{
        padding: ${props => props.theme.spacer * 4}px 0;
    }

    .accordion-content-container{
        width: 100%;
        justify-content: flex-start;
        &>div{
            background: transparent;
            padding: 0px;
            .accordion-item-icon-container{
                display: none;
            }
        }
        .accordion-lesson-title {
            font-size: 10px;
            margin-left: 30px;
        }
        .accordion-lesson-title:hover{
            color: ${props => props.theme.blue};
        }
    }

    .accordion-content{
        width: 100%;
    }

    .accordion-item-icon-container {
        margin: 5px 12px 5px 0px;
        width: 10%;
    }

    .accordion-item-icon {
        width: 11px;
        height: 13px;
    }

    .lessons-and-quizes{
        display:none;
    }
    @media (max-width: 500px){
        .accordion-content-container{

            .accordion-lesson-title{
                margin-left: 0px;
            }
        }
    }
`

const MyPathCloseButtonContainer = styled.div`
    position: absolute;
    bottom: 24px;
    right: 30px;
    display: none;
    @media (max-width: 500px){
        display: ${props => props.myPathActive ? "block" : "none"};
    }
`

//component
const ZenMode = (props) => {
    const { t } = useTranslation();

    //value for showing or hiding dropdown on hover of profile picture
    const [dropdown, setDropdown] = useState(false);

    //holds section.id of current section
    const [currentSection, setCurrentSection] = useState();

    //const that contains value true or false wether viewport width is below 500px used later for css transitions
    const isMobile = useMediaQuery({ maxWidth: 500 });

    const { signedUser } = useContext(GlobalContext);

    //needed for the dropdown at profile picture
    const { logoutUser } = useContext(GlobalContext);

    const popoverRef = useRef();

    //listen on changes of props.collapse, props.course, props.lessonId and if props.collapse is true setDropdown and setMyPathActive 
    //to false in order to close them all when hovered out

    //if there is props.course itterate trough its sections and its section lessons, and compare every lesson's id to props.lessonId. 
    //if they match set the section.id inside currentSection to pass on the accordion inside myPath so that the section accordion that contains the current lesson is opened
    useEffect(() => {
        if (props.collapse) {
            setDropdown(false);
            props.setMyPathActive(false);
        }

        if (props.course && props.course.sections) {
            props.course.sections.forEach((section, i) => {
                section.lessons.forEach((lesson, i) => {
                    if (parseInt(lesson.id) === props.lessonId) {
                        setCurrentSection(section.id);
                    }
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.collapse, props.course, props.course.sections, props.lessonId, props.setMyPathActive]);
    return (
        <ZenModeWrapper
            collapse={props.collapse}
            myPathActive={props.myPathActive}
            onMouseOver={() => !isMobile && props.setCollapse(false)}
            onMouseLeave={() => props.setCollapse(true)}
            onClick={() => isMobile && [props.setCollapse(false), props.setMyPathActive(false)]}
        >

            <ZenModeNavigation collapse={props.collapse}>
                <BrainsterLogoContainer collapse={props.collapse}>
                    <Link to="/catalogue">
                        <IconLogo width={40} height={40} icon="brainsterLogo" className="logo" />
                    </Link>
                </BrainsterLogoContainer>

                <ZenModeNav collapse={props.collapse} myPathActive={props.myPathActive}>
                    <li className="my-path">
                        <div onClick={(e) => props.collapse ? e.stopPropagation() : [props.setMyPathActive(!props.myPathActive), e.stopPropagation()]}>
                            <Icon width={26.61} height={30} icon={"icnMyPath"} collapse={props.collapse} alt="my paths" />
                            <Caption9>{t("learning_mode.zen_mode.my_path")}</Caption9>
                        </div>
                    </li>
                    <li>
                        <Link to="/my-courses">
                            <Icon width={23.68} height={30} icon={"icnCourse"} pathFill={props.theme.black} />
                            <Caption9>{t("learning_mode.zen_mode.my_courses")}</Caption9>
                        </Link>
                    </li>
                    <li>
                        <a href={API.externalLinks("brainsterNextFacebookGroup")} target="_blank" rel="noopener noreferrer">
                            <Icon width={35.63} height={30} icon={"icnHelp"} collapse={props.collapse} alt="help" />
                            <Caption9>{t("learning_mode.zen_mode.help")}</Caption9>
                        </a>
                    </li>

                </ZenModeNav>
                <ProfilePicContainer collapse={props.collapse} onMouseEnter={() => setDropdown(true)} profileImage={API.storage + signedUser.profile_image} >
                    <div></div>
                </ProfilePicContainer>
                <PopoverContainer dropdown={dropdown} onMouseLeave={() => setDropdown(false)}>
                    <Popover ref={popoverRef} onMouseLeave={() => setDropdown(false)} pointerPosition={"bottom"}>
                        <Link to="/student/profile">
                            <div>

                                <Icon icon="icnMyProfile" pathFill={props.theme.gray4} width="12.5" height="15" />
                            </div>
                            <Caption12>
                                {t("general.my_profile")}
                            </Caption12>
                        </Link>
                        <button onClick={logoutUser} >
                            <div>
                                <Icon icon="icnSignOut" pathFill={props.theme.gray4} />
                            </div>
                            <Caption12>
                                {t("general.sign_out")}
                            </Caption12>
                        </button>
                    </Popover>
                </PopoverContainer>
            </ZenModeNavigation>
            <MyPathContainer myPathActive={props.myPathActive}>
                <MyPathContent myPathActive={props.myPathActive}>
                    <MyPathProgressContainer>
                        <CircularProgressBar
                            width={isMobile ? 80 : 100}
                            percentage={Math.floor(props.currentLessonIndex === 0 ? 0 : props.currentLessonIndex / props.lessons * 100)}
                            strokeWidth={12}
                            color={props.theme.blue}
                            trailColor={props.theme.danger_select}
                            dashboard={true}
                            rotation={0.75}
                            textSize={20}
                        />
                    </MyPathProgressContainer>
                    <MyPathTitleContainer>
                        <Heading20>{props.course && props.course.title}</Heading20>
                    </MyPathTitleContainer>
                    <MyPathSectionsContainer>
                        {props.course.sections && props.course.sections.map((section, i) => (
                            <Accordion key={i} index={i} section={section} showMore={currentSection === section.id} courseId={props.course.id} learningPathId={props.learningPathId && props.learningPathId} />
                        ))}
                    </MyPathSectionsContainer>
                    <MyPathCloseButtonContainer myPathActive={props.myPathActive}>
                        <Icon icon="icnArrowLeft" width={15} height={20} onClick={() => props.setMyPathActive(false)} />
                    </MyPathCloseButtonContainer>
                </MyPathContent>
            </MyPathContainer>
        </ZenModeWrapper>
    );
}

export default withTheme(ZenMode);