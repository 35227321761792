import React from 'react';

//imports
import styled from 'styled-components';
import Wrapper from '../Wrapper/Wrapper';
import illustration from '../../Static/illustrations/RAFblock/RAFblock.svg';
import { Text13, SpecHeadingSmall } from '../Text/Text';
import ButtonBasic from '../ButtonBasic/ButtonBasic';
import RafblockBackground from '../../Static/backgrounds/rafblock-background.svg';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import { API } from '../../Consts/Api';

//styled-components
const RafBlockContainer = styled.div`
    width: 100%;
    cursor: pointer;
    background-color: ${props => props.theme.blue};
    background-image: url(${RafblockBackground});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 52px 0;
    &>div{
        flex-direction: row;
        justify-content: space-between;
    }
    .raf-text-container{
        width: 41%;
    }
    
    .raf-text-container{
        h3, p{
            color: white;
        }
        h3{
            margin-bottom: 25px;
        }
        p{
            margin-bottom: 45px;
        }
        button{
            background-color: white;
            color: ${props => props.theme.black};
            font-weight: 800;
        }
        @media (max-width: 500px){
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            h3{
                margin-bottom: 15px; 
            }
            p{
                margin-bottom: 40px;
            }
            button {
                width: 100%;
                span{
                    margin: 0 auto;
                }
            }
        }
    }
    
    .raf-illustration-container{
        width: 37%;
        img{
            width: 345px;
        }

        @media (max-width: 500px){
            display: none;
        }
    }

    @media (max-width: 500px){
        padding: 45px 0 60px 0;
        background-image: none;
    }
`

//component
const RafBlock = (props) => {
    const { t } = useTranslation();
    return (
        <Link to={API.createRoute("referAFriend")}>
            <RafBlockContainer>
                <Wrapper>
                    <div className="raf-text-container">
                        <SpecHeadingSmall>{t("my_courses.refer_cta.heading")}</SpecHeadingSmall>
                        <Text13>{t("my_courses.refer_cta.description")}</Text13>
                        <ButtonBasic text={t("my_courses.refer_cta.button")} />
                    </div>
                    <div className="raf-illustration-container">
                        <img src={illustration} alt="refer illustration" />
                    </div>
                </Wrapper>
            </RafBlockContainer>
        </Link>
    );
}

export default RafBlock;