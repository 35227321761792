import React, { useState, useEffect } from 'react';

//imports
import styled from 'styled-components';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';
import Wrapper from '../../Components/Wrapper/Wrapper';
import LearningPathHeader from "./Blocks/LearningPathHeader";
import LearningPathCourses from './Blocks/LearningPathCourses';
import { API } from '../../Consts/Api';
import { Caption12 } from '../../Components/Text/Text';
import { useTranslation } from 'react-i18next';
import Icon from '../../Components/Icon/Icon';
import Loader from '../../Components/Loader/Loader';

//styled-components
const LearningPatchPageWrapper = styled(PageWrapper)`
    flex-direction: row;
    position: relative;
    .learningpath-background{
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        display: flex;
        z-index: 0;
        .learningpath-background-left-container{
            width: 90%;
            height: 100%;
            background-color: ${props => props.theme.danger_select};
            border-bottom-right-radius: 132px;
        }
        .learningpath-background-right-container{
            width: 10%;
            height: 100%;
            background-color: ${props => props.theme.danger_select};
            .learningpath-background-right-item{
                width: 100%;
                height: 100%;
                border-top-left-radius: 132px;
                background-color: ${props => props.theme.white};
            }
        }
    }
    @media (max-width: 500px){
        .learningpath-background{
            display: none;
        }
    }
`

const LearningPathTopBlockWrapper = styled(Wrapper)`
    width: 95%;
    max-width: 1141px;
    padding: 35px 0;
    z-index: 1;
    align-self: flex-start;
    .learning-path-info-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        .learningpath-mobile-background{
            display: none;
        }
    }
    main{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

    }
    ${Caption12} {
        font-family: "Manrope", sans-serif;
    }
    @media (max-width: 500px){
        padding: 0px!important;
        .learning-path-info-container{
            padding: 0 10px;
            flex-direction: column;
            position: relative;
            margin-bottom: 28px;
            .learningpath-mobile-background{
                display: flex;
                width: 45%;
                height: 54px;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 0;
                .learningpath-background-left-container{
                    width: 15%;
                    height: 100%;
                    background-color: ${props => props.theme.danger_select};

                    .learningpath-background-left-item{
                        width: 100%;
                        height: 100%;
                        background-color: ${props => props.theme.white};
                        border-top-right-radius: 23px;
                    }
                }
                .learningpath-background-right-container{
                    width: 85%;
                    height: 100%;
                    background-color: ${props => props.theme.danger_select};
                    border-bottom-left-radius: 23px;
                }
            }
        }
        main{

            flex-direction: column;
        }
        
        padding: ${props => props.theme.spacer * 2 + 'px'} 0px;
    }
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    z-index: 1;
    img{
        width: 11px;
    }
    p{
        margin-left: 3px;
    }
    @media (max-width: 500px){
        align-self: flex-end;
        margin-top: 16px;
    }
`

const CategoryWrapper = styled.div`
    align-self: flex-start;
    @media (max-width: 500px){
        align-self: flex-end;
        display: none;
    }
`

//component
const LearningPath = (props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [currentLearningPath, setCurrentLearningPath] = useState({});
    const [courses, setCourses] = useState([]);
    const [firstCourseToLearn, setFirstCourseToLearn] = useState({})
    const [points, setPoints] = useState(0);

    //get adequate learning path from database on component mount and set it inside currentLearningPath hook, set the courses inside courses hook
    useEffect(() => {
        API.axios.get(API.createApiRoute("getLearningPath", { LEARNINGPATH_ID: props.learningPathId }))
            .then((response) => {
                setCurrentLearningPath(response.data.data);
                setCourses(response.data.data.courses);
                let points = 0;
                response.data.data.courses.forEach((course, index) => {
                    if (course.points) {
                        points = points + course.points;
                    }
                })
                setPoints(points)
            })
            .catch((error) => console.log(error));
    }, [props.learningPathId])

    useEffect(() => {
        if (currentLearningPath.id && courses?.length > 0) {
            const timeout = setTimeout(() => {
                setIsLoading(false)
            }, 1700)
            return () => clearTimeout(timeout)
        }
    }, [courses, currentLearningPath])

    useEffect(() => {
        //availableCourses array stores all courses that are not comming soon
        let availableCourses = []
        for (let i = 0; i < courses.length; i++) {
            //we itterate courses and if course is not comming soon we push it in availableCourses array
            if (!courses[i].comming_soon || courses[i].comming_soon === 0) {
                availableCourses.push(courses[i])
            }
        }

        //then we check if there are any available courses in availableCoursesArray
        if (availableCourses?.length > 0) {
            //then we itterate availableCoursesArray
            for (let i = 0; i < availableCourses.length; i++) {
                //if we find a course with progress less than 100 (unfinished course, but started or not) 
                //we set it in the firstCourseToLearn state and we break
                if (courses[i].progress < 100) {
                    setFirstCourseToLearn(courses[i]);
                    break;
                } else {
                    //if all courses are finished, 
                    //then we set first course to learn to the first course of the availableCoursesArray 
                    //so that the student can go trough the learning path again from start
                    setFirstCourseToLearn(courses[0]);
                }
            }
        } else {
            //if there are no courses available in availableCoursesArray
            //we set firstCourseToLearn state to undefined meaning no courses are available in this path
            //this is used later in LearningPathHeader's JSX and checked if props.firstCourseToLearn is defined or not
            //based on that condition we render clickable button to start the learning path 
            //that would navigate to the first available course
            //or disabled button with the text COMMING SOON
            setFirstCourseToLearn(undefined)
        }
    }, [courses])
    return (
        isLoading
            ? <Loader />
            : <LearningPatchPageWrapper>
                <div className="learningpath-background">
                    <div className="learningpath-background-left-container"></div>
                    <div className="learningpath-background-right-container">
                        <div className="learningpath-background-right-item"></div>
                    </div>
                </div>
                <LearningPathTopBlockWrapper>
                    <div className="learning-path-info-container">
                        <div className="learningpath-mobile-background">
                            <div className="learningpath-background-left-container">
                                <div className="learningpath-background-left-item"></div>
                            </div>
                            <div className="learningpath-background-right-container"></div>
                        </div>
                        <CategoryWrapper>
                            <Caption12>{currentLearningPath.category && currentLearningPath.category.name}</Caption12>
                        </CategoryWrapper>
                        <IconWrapper>
                            <Icon icon="icnCourse" />
                            &nbsp;
                            <Caption12>{currentLearningPath.courses && currentLearningPath.courses.length} {t("general.courses")}</Caption12>
                        </IconWrapper>
                    </div>
                    <main>
                        <LearningPathHeader
                            learningPath={currentLearningPath !== {} && currentLearningPath}
                            category={currentLearningPath.category} courses={courses}
                            firstCourseToLearnId={firstCourseToLearn && firstCourseToLearn.id}
                            points={points}
                        />
                        <LearningPathCourses courses={courses} />
                    </main>
                </LearningPathTopBlockWrapper>

            </LearningPatchPageWrapper>
    );
}

export default LearningPath;