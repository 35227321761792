import { createGlobalStyle } from 'styled-components';
import '../Fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
    /* Reset styles */
    *:not(input) {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline; 
    }

    /* HTML5 display-role reset for older browsers */

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block; 
    }

    body {
    line-height: 1; 
    
}
.no-scroll{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: hidden;
}

ol, ul {
    list-style: none; 
}

blockquote, q {
    quotes: none; 
}

blockquote {
    &:before, &:after {
        content: '';
        content: none; 
    } 
}

q {
    &:before, &:after {
        content: '';
        content: none; 
    } 
}

table {
    border-collapse: collapse;
    border-spacing: 0; 
}

* {
    box-sizing: border-box;
}


/* Fonts */



body, html {
    background: white;
    line-height: 1.5;
    font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    /* scroll-behavior: smooth; */
    -webkit-tap-highlight-color: transparent;
    min-height: 100%;
    font-family: 'Raleway', sans-serif;
}

a {
    text-decoration: none;
	    outline: none;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }
	

    textarea {
        font-family: 'Raleway', sans-serif;
    }
    
    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 20px;
        background: #E6E7EB;
    }

    &::-webkit-scrollbar-track {
        background: #f7f7f7;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background: #E6E7EB;
        border-radius: 20px;
    }
	
    &::-webkit-scrollbar-thumb:hover {
        background: #E5E5E5;
        border-radius: 20px;
    }

    input {
        color: ${props => props.theme.lightBlack};
        font-family: 'Raleway', sans-serif;
    }

    ::placeholder {
        font-family: 'Raleway', sans-serif;
        color: ${props => props.theme.lightBlack};
    }

    @media screen and (max-width: 768px){
        .card {
            width: 50%;
        }
    }

    @media screen and (max-width: 425px){
        .card {
            width: 100%;
        }
    }

.scrolled-in-view {
    padding-top: 120px;
}

.block {
    width: 90%!important;
    max-width: 1141px;

}

.uppercase {
    text-transform: uppercase;
}

.flex{
    display: flex;
}

.align-items {
    align-items: center;
}

.lesson {
    background-color: #F9F9F9;
}

.quiz {
    background-color: #F3F3F5;
}

button {
    :focus, :visited, :active {
        outline: none;
        box-shadow: none;
    }
}


.loader-container{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.theme.black};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    img{
        width: 30%;
    }
}

@media (max-width: 500px){
    body{
        // padding-bottom: 60px;
    }
    .loader-container{
        img{
            width: 85%;
        }
    }
}
`
    ;

export default GlobalStyle;