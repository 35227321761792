import React, { useEffect, useState } from 'react';

//imports
import styled, { withTheme } from 'styled-components';
import { API } from '../../../../Consts/Api';
import { navigate, useMatch } from '@reach/router';
import { Heading53, Heading28, Text13 } from '../../../../Components/Text/Text';
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';
import { useTranslation } from 'react-i18next';
import CourseInfo from '../CourseInfo/CourseInfo';
import Icon from '../../../../Components/Icon/Icon';

const CourseHeadingContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
position: relative;
padding-bottom: 80px;
.course-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 77%;
    height: 100%;
    display: flex;
    z-index: 0;
    .course-background-left-container{
        width: 90%;
        height: 100%;
        background-color: ${props => props.theme.danger_select};
        border-bottom-right-radius: 132px;
    }
    .course-background-right-container{
        width: 10%;
        height: 100%;
        background-color: ${props => props.theme.danger_select};
        .course-background-right-item{
            width: 100%;
            height: 100%;
            border-top-left-radius: 132px;
            background-color: ${props => props.theme.white};
        }
    }
    .course-mobile-background{
        display: none;
    }
}
@media (max-width: 500px){
    flex-direction: column;
    margin-top: -70px;
    padding-top: 70px;
    padding-bottom: 0px;
    border-top-right-radius: 90px;
    background-color: ${props => props.theme.white};
    .course-background{
        display: none;
    }
}
`

const CourseHeadingWrapper = styled(Wrapper)`
&>.heading-container{
        width: 100%;
        display: flex;
        flex-direction: row;

    }
    &>ul.exclusive-course-info{
        align-self: flex-start;
        z-index: 999999;
        margin-top: 60px;
        margin-bottom: 20px;
        li{
            display: flex;
            .icon-container{
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
            }
            .info-container{
                display: flex;
                p{
                    font-weight: 500;
                    color: ${props => props.theme.black};
                }
                .course-info-bold{
                    color: ${props => props.theme.blue};
                    font-weight: 700;
                    margin-left: 5px;
                }
            }
        }
    }
    margin-botom:  70px ;
    @media (max-width: 768px){
        margin-bottom: 55px;
    }
    @media (max-width: 500px){
        margin-bottom: 0px;
    }
`

const CourseHeadingText = styled.div`
    width: 60%;
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; 

    @media (max-width: 768px){
        width: 100%;
        padding-right: 0px;
        h3 {
            font-size: 20px;
            line-height: 24px;
        }

        h4 {
            font-size: 13px;
            line-height: 16px;
        }

        p {
            font-size: 14px;
            line-height: 16px;
        }
    }

    @media (max-width: 500px){
        .header-btn {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 42px;
        }
    }
`

const CourseHeadingImgContainer = styled.div`
    width: 40%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @media (max-width: 768px){
        display: none;
    }
`

const CourseTitleContainer = styled.div`
    margin-bottom: 15px;
    @media (max-width: 768px){
        margin-bottom: 5px;
    }
`

const CourseSubtitleContainer = styled.div`
    margin-bottom: 35px;
    @media (max-width: 768px){
        margin-bottom: 15px;
    }
`

const CourseDescriptionContainer = styled.div`
    margin-bottom: 55px;
    @media (max-width: 768px){
        margin-bottom: 25px;
    }
`

const CourseHeadingImg = styled.img`
    width: 100%;
    margin-top: ${props => props.isExclusiveCourse && "-100px"};
`

const StartLearningBtnContainer = styled.div`
    margin-bottom: 80px;
`

const CourseHeading = (props) => {
    const { t } = useTranslation();
    const match = useMatch("/learning-path/:learningPathId/courses/:courseId");
    const [startLearningRoute, setStartLearningRoute] = useState("");

    //on match change, if match is not equal to null it means that course is opened from learning path and there is a learning path id in location,
    //if so, set startLearningRoute to API.routes.learningModeWIthPath and replace with id's accordingly
    //if not, set startLearningRoute to API.routes.learningMode
    useEffect(() => {
        if (match !== null) {
            // setStartLearningRoute(API.routes.learningModeWithPath.replace("{LEARNINGPATH_ID}", match.learningPathId).replace("{COURSE_ID}", props.courseId).replace("{LESSON_ID}", props.lessonId))
            setStartLearningRoute(API.createRoute('learningModeWithPath', { LEARNINGPATH_ID: match.learningPathId, COURSE_ID: props.courseId, LESSON_ID: props.lessonId }))
        } else {
            // setStartLearningRoute(API.routes.learningMode.replace("{COURSE_ID}", props.courseId).replace("{LESSON_ID}", props.lessonId))
            setStartLearningRoute(API.createRoute('learningMode', { COURSE_ID: props.courseId, LESSON_ID: props.lessonId }))
        }

    }, [match, props.courseId, props.lessonId])

    //used on click on ЗАПОЧНИ СО УЧЕЊЕ button to start learning, send a get request to API.routes.startLearning to record the start of course in backend,
    // then whether it responds with success or error, 
    const startLearning = (startLearningRoute) => {
        API.axios.get(API.createRoute("startLesson", { LESSON_ID: props.lessonId }))
            .then(res => navigate(startLearningRoute))
            .catch(error => { navigate(startLearningRoute); console.error("progress not set") });
    }


    return (
        <CourseHeadingContainer>
            <div className="course-background">
                <div className="course-background-left-container"></div>
                <div className="course-background-right-container">
                    <div className="course-background-right-item"></div>
                </div>
            </div>
            <CourseHeadingWrapper>
                {!props.isExclusiveCourse
                    && <CourseInfo
                        time={props.time}
                        students={props.students}
                        points={props.points}
                    />}
                {props.isExclusiveCourse
                    && <ul className="exclusive-course-info">
                        <li className="exclusive-course-info-item">
                            <div className="icon-container">
                                <Icon icon="icnCalendar" width={16} height={17} />
                            </div>
                            <div className="info-container">
                                <Text13>Почеток: </Text13>
                                <Text13 className="course-info-bold">{props.start}</Text13>
                            </div>
                        </li>
                        <li className="exclusive-course-info-item">
                            <div className="icon-container">
                                <Icon icon="icnTime" width={16} height={16} pathStroke={props.theme.gray3} pathStrokeWidth={1.2} />
                            </div>
                            <div className="info-container">
                                <Text13>Потребно време: </Text13>
                                <Text13 className="course-info-bold">{props.duration}</Text13>
                            </div>
                        </li>
                        <li className="exclusive-course-info-item">
                            <div className="icon-container">
                                <Icon icon="icnCategory" width={18} height={16} pathFill={props.theme.gray3} pathStroke={props.theme.gray3} pathStrokeWidth={0.5} />
                            </div>
                            <div className="info-container">
                                <Text13>Резултат: </Text13>
                                <Text13 className="course-info-bold">{props.result}</Text13>
                            </div>
                        </li>
                    </ul>
                }
                <div className="heading-container">

                    <CourseHeadingText>
                        <CourseTitleContainer>
                            <Heading53>{props.courseTitle && props.courseTitle}</Heading53>
                        </CourseTitleContainer>
                        <CourseSubtitleContainer>
                            <Heading28>{props.courseSubtitle && props.courseSubtitle}</Heading28>
                        </CourseSubtitleContainer>
                        <CourseDescriptionContainer>
                            <Text13>{props.courseDescription && props.courseDescription}</Text13>
                        </CourseDescriptionContainer>
                        <StartLearningBtnContainer className="header-btn">
                            <ButtonBasic variation={props.isExclusiveCourse ? "secondary" : "primary"} text={props.progress === 0 ? t('general.start_learning') : props.progress >= 100 ? t("general.learn_again") : t('general.continue')} onClick={() => startLearning(startLearningRoute)} />
                        </StartLearningBtnContainer>
                    </CourseHeadingText>
                    <CourseHeadingImgContainer isExclusiveCourse={props.isExclusiveCourse} >
                        <CourseHeadingImg src={props.image ? props.image : ""} alt="course_image" isExclusiveCourse={props.isExclusiveCourse} />
                    </CourseHeadingImgContainer>
                </div>


            </CourseHeadingWrapper>
        </CourseHeadingContainer>
    );
}

export default withTheme(CourseHeading);