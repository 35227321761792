import React from 'react';
import styled, { withTheme } from 'styled-components';


import Icon from '../../../../Components/Icon/Icon';

import { Caption16 } from '../../../../Components/Text/Text';
import { useTranslation } from 'react-i18next';

const CourseInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 55px 0 84px 0;
    z-index: 1;
    @media (max-width: 768px){
        margin-bottom: 55px;
        margin-top: -20px;
    }
`

const InfoLeft = styled.div`
    display: flex;
    align-items: center;
    .left {
        padding-left: 0!important;
    }
`

const InfoRight = styled.div`
width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .time {
        border-right: 1.49302px solid #EFEFEF;
    }
    @media (max-width: 425px) {
        .time{
            padding: 0px;
        }
    }
    .right {
        padding-right: 0!important;
    }
`

const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 10px;
    @media (max-width: 500px){
        svg{
            width: 12px;
            height: 12px;
        }
    }
`




const TextContainer = styled.div`
    line-height: 9px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    @media (max-width: 500px){
        p{
            font-size: 9px;
        }
    }
`

const CourseInfo = (props) => {
    const { t } = useTranslation();
    return (
        <CourseInfoWrapper>
            <InfoLeft>
                <InfoContainer className="left">
                    <Icon className="points" viewBox="" pathStrokeWidth="1.5" icon={"icnPoints"} fill="none" width={20} height={20} pathStroke={props.theme.blue} alt={props.students + " STUDENTS"} />
                    <TextContainer>
                        <Caption16 fontFamily={"Manrope"}>{props.points} {parseFloat(props.points) > 1 ? t("general.points") : t('general.point')}</Caption16>
                    </TextContainer>
                </InfoContainer>
            </InfoLeft>

            <InfoRight>
                <InfoContainer className="time">
                    <Icon icon="icnTime" width={17} height={17} pathStroke={props.theme.blue} alt={props.time} />
                    <TextContainer>
                        <Caption16 fontFamily="Manrope">{props.time} {t('general.minutes')}</Caption16>
                    </TextContainer>
                </InfoContainer>
                <InfoContainer className="right">
                    <Icon icon="icnUser" width={17} height={17} pathStroke={props.theme.blue} alt={props.students + " STUDENTS"} />
                    <TextContainer>
                        <Caption16 fontFamily="Manrope">{props.students} {t('general.students')}</Caption16>
                    </TextContainer>
                </InfoContainer>
            </InfoRight>
        </CourseInfoWrapper>
    );
}

export default withTheme(CourseInfo);