import React, { useState, useEffect } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

//component imports
import { Heading35, MenuCapture, Text16 } from '../../../../Components/Text/Text';
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';
import SliderBlock from '../../../../Components/SliderBlock/SliderBlock';

//other imports
import { API } from '../../../../Consts/Api';
import { Link } from '@reach/router';

//styled-components
const StyledLifelongLearning = styled.div`
    width: 100%;
    &>header{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
        padding-top: 88px;
        ${Heading35}{
            margin-bottom: 97px;
        }
        &>${Wrapper}{
            align-items: flex-start;
            &>ul{
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
    section{
        padding: 0px 0 105px 0;
        display:flex;
        flex-direction: column;
        align-items: center;
        &>${Wrapper}{
            max-width: unset;
            width: 100%;
            margin-bottom: 120px;
            .slider-block-heading{
                display: none;
            }
                article{
                    
                    .card-cta{
                        display: none;
                    }
                    .card-cta, .card-info{
                        animation: unset;
                    }
                    &:hover{
                        box-shadow: none;
                        cursor: auto;
                        .card-cta, .card-info{
                            animation: unset;
                        }
                    }
                }
            
        }
        .show{
            visibility: visible;
        }
        .hide{
            visibility: hidden;
        }
        &>a{
            margin-top: 40px;
        }
    }
    
    @media (max-width: 1000px){
        &>header{
            &>${Wrapper}{
                &>ul{
                    width: 100%;
                    margin: 0 auto;
                    li{
                        width: 22%;
                    }
                }
            }
        }
    }

    @media (max-width: 900px){
        section{
            width: 100%;
            // overflow-x: hidden;
            
            &>${Wrapper}{
                width: 100%;
                // overflow-x: scroll;
                
                
                
            }
        }
    }
    @media (max-width: 768px){
        &>header{
            ${Heading35}{
                font-size: 26px;
                line-height: 30px;
                text-align: left;
                align-self: flex-start;
                width: 90%;
                margin:0 auto 97px auto;
            }
            
        }
        .swiper-container {
            width: 90%;
            margin: 0 auto;
        }

        ${Text16}{
            width: 90%;
            margin: 0 auto;
        }
    }
    @media (max-width: 500px){
        &>header{
            padding-top: 50px;
            ${Wrapper}{
                width: 95%;
            }
        }
        section{
            padding-bottom: 58px;
            ${Wrapper}{
                margin-bottom: 28px;
            }
        }
        .swiper-container {
            width: 95%;
            margin: 0 0 0 auto;
        }
    }
`

const LinkIcon = styled.li`
    width: 24%;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    transition: transform .1s;
    :hover {
        cursor: pointer;
        ${MenuCapture}{
            font-weight: 800!important;
            color: ${props => props.theme.black};
        }
    }
    ${MenuCapture}{
        padding-bottom: 30px;
        border-bottom: 4px solid ${props => props.selectedField === props.color ? props.theme[props.color] : "transparent"};
        color: ${props => props.selectedField === props.color ? props.black : props.theme.gray3};
        letter-spacing: 0.5px;
        font-weight: ${props => props.selectedField === props.color ? 800 : 600}; 
    }
    @media (max-width: 1141px){
        max-height: ${props => props.show ? "150px" : "0px"};
        align-items: center;
        height: unset;
    }
    @media (max-width: 830px){
        width: 25%;
        ${MenuCapture}{
            font-size: 17px;
            line-height: 20px;
        }
    }

    @media (max-width: 830px){
        ${MenuCapture}{
            font-size: 15px;
            line-height: 18px;
            padding-bottom: 20px;
        }
    }
    @media (max-width: 650px){
        ${MenuCapture}{
            font-size: 13px;
            line-height: 16px;
        }
    }

    @media (max-width: 550px){
        ${MenuCapture}{
            font-size: 9px;
            line-height: 12px;
            padding-bottom: 12px;
        }
    }
`

//main component
const LifelongLearning = (props) => {
    const { t } = useTranslation();
    const [selectedField, setSelectedField] = useState("ComputerScience");
    const [studyFieldsWithCourses, setStudyFieldsWithCourses] = useState({});
    const [currentStudyFieldCourses, setCurrentStudyFieldCourses] = useState([]);
    const isDesktop = useMediaQuery({ minWidth: 1141 });
    const isLaptop = useMediaQuery({ minWidth: 769, maxWidth: 1141 });
    const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 768 });
    const isMobile = useMediaQuery({ maxWidth: 500 })
    const studyFields = [
        {
            text: t("general.study_fields.computer_science"),
            name: "ComputerScience"
        },
        {
            text: t("general.study_fields.digital_marketing"),
            name: "DigitalMarketing"
        },
        {
            text: t("general.study_fields.graphic_design"),
            name: "GraphicDesign"

        },
        {
            text: t("general.study_fields.data_science"),
            name: "DataScience"
        },
    ]

    useEffect(() => {
        API.axios.get(API.createApiRoute("getLandingCourses"))
            .then(response => {
                let studyFieldsCourses = {};
                response.data.data.forEach(studyfield => {
                    studyFieldsCourses[studyfield.code] = studyfield.courses
                })
                setStudyFieldsWithCourses(studyFieldsCourses);
                setCurrentStudyFieldCourses(studyFieldsCourses[selectedField])

            })
            .catch(error => console.error(error))
        //eslint-disable-next-line
    }, [])



    useEffect(() => {
        selectedField !== "" && setCurrentStudyFieldCourses(studyFieldsWithCourses[selectedField])
        //eslint-disable-next-line
    }, [selectedField])


    return (
        <StyledLifelongLearning>
            <header>
                <Heading35>{t("homepage.lifelong_learning.header")}</Heading35>
                <Wrapper>
                    <ul>
                        {studyFields.map((studyField, index) => (
                            <LinkIcon key={index} color={studyField.name} onClick={() => setSelectedField(studyField.name)} selectedField={selectedField}>
                                <MenuCapture>{studyField.text}</MenuCapture>
                            </LinkIcon>
                        ))}
                    </ul>
                </Wrapper>
            </header>
            <section>
                <Wrapper>
                    {currentStudyFieldCourses?.length > 0 &&

                        <SliderBlock
                            // key={studyField.id}
                            style={{ width: "90%", margin: "0 auto" }}
                            cardData={currentStudyFieldCourses}
                            isMobile={isMobile}
                            cardsPerSlide={isDesktop ? 4 : isLaptop ? 3 : isTablet ? 2 : isMobile && 1}
                            navigation={false}
                            renderCard="CourseCard"
                            route="none"
                            heading={t("catalogue.popular_courses.heading")}
                            gridTemplateColumns={isDesktop ? "1fr 1fr 1fr 1fr" : isLaptop ? "1fr 1fr 1fr" : isTablet ? "1fr 1fr" : isMobile && "1fr"}
                            padingation={false}
                        // className={selectedField === studyField.code ? "show" : "hide"}
                        />

                    }


                </Wrapper>
                <Text16 fontWeight={"bold"}>{t("homepage.lifelong_learning.cta.tagline")}</Text16>
                <Link to={API.createRoute("signin")}><ButtonBasic text={t("homepage.lifelong_learning.cta.button")} /></Link>
            </section>
        </StyledLifelongLearning>
    )
}

export default withTheme(LifelongLearning);