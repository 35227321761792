import React, { useState, useEffect, useContext, useRef } from "react";

//npm imports
import styled, { css, withTheme } from "styled-components";

//component imports
import InputField from "../../Components/InputField/InputField";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import Wrapper from "../../Components/Wrapper/Wrapper";
import {
  Heading20,
  Text10,
  Caption12,
  Text16,
} from "../../Components/Text/Text";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";
import Checkbox from "../../Components/Checkbox/Checkbox";
import PasswordStrengthIndicator from "../../Components/PasswordStrengthIndicator/PasswordStrengthIndicator";
import Icon from "../../Components/Icon/Icon";
import UpdateMessage from "../../Components/UpdateMessage/UpdateMessage";
import { MyProfileSideBarItemsData } from "../../Consts/MyProfileSideBarItemsData";
import SelectInput from "../../Components/SelectInput/SelectInput";

//other imports
import { GlobalContext } from "../../Context/GlobalContext";
import { API } from "../../Consts/Api";
import { useTranslation } from "react-i18next";
import SidebarItem from "../../Components/SidebarItem/SidebarItem";
import PhotoCropper from "../../Components/PhotoCropper/PhotoCropper";
import ChooseButton from "../../Components/ChooseButton/ChooseButton";
import Loader from "../../Components/Loader/Loader";
import { highschoolDepartment } from "../../Static/data-base/data-base";
import { facultyYear } from "../../Static/data-base/data-base";

//styled-components
const MyProfileContainer = styled(PageWrapper)`
  background-color: ${(props) => props.theme.danger_select};
  padding: 36px 0 0 0;
  justify-content: flex-start;
  min-height: calc(100vh - (60px + 72px));
  .myprofile-shape-container {
    display: none;
    width: 100%;
    height: 232px;
    .myprofile-upper-shape-container {
      width: 100%;
      height: 116px;
      background-color: white;
      .myprofile-upper-shape {
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.gray0};
        border-bottom-right-radius: 97px;
      }
    }
    .myprofile-lower-shape-container {
      width: 100%;
      height: 116px;
      background-color: ${(props) => props.theme.gray0};
      .myprofile-lower-shape {
        width: 100%;
        height: 100%;
        background-color: white;
        border-top-left-radius: 87px;
      }
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    padding: 0;
    .myprofile-shape-container {
      display: block !important;
    }
  }
`;

// const MyProfileWrapper = styled(Wrapper)`
//     flex-direction: row;
//     display: ${props => props.editMode ? "none" : "flex"};
//     align-items: flex-start;
//     .my-info{
//         width: 35%;
//         padding-right: 106px;
//         border-right: 1px solid ${props => props.theme.gray1};
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         .profile-image-container{
//             width: 260px;
//             height: 260px;
//             margin-bottom: 41px;
//             border-radius: 50%;
//             align-self: center;
//             ${props => props.profileImageUrl.includes("storage") && css`
//                 background-image: url("${props.profileImageUrl}");
//                 background-size: cover;
//                 background-position: center;
//                 background-repeat: no-repeat;
//             `}
//         }

//         .full-name{
//             margin-bottom: 15px;
//         }

//         .joined-date {
//             align-self: center;
//             color: ${props => props.theme.gray3};
//             margin-bottom: 60px;
//         }

//         .joined-date > span {
//             font-weight: 600;
//             color: ${props => props.theme.black};
//         }

//         button {
//             padding-left: 40px;
//             padding-right: 40px;
//             display: flex;
//             justify-content: center;
//         }
//     }
//     .my-achievments{
//         width: 65%;
//         padding: 0 0 0 56px;
//         .my-achievments-title{
//             display: flex;
//             margin-bottom: 60px;
//             img{
//                 width: 14px;
//                 margin-right: 18px;
//             }
//             h5{
//                 color: ${props => props.theme.gray3};
//             }
//         }
//         .my-achievments-cards-container{
//             width: 100%;
//             display: flex;
//             flex-flow: row wrap;
//             filter: blur(1px);
//             &>div{
//                 width: 30%;
//                 margin-right: 17px;
//                 margin-bottom: 17px;
//             }
//         }
//     }
//     @media (max-width: 1141px){
//         .my-info{
//             width: 40%;
//             padding-right: 50px;
//         }
//         .my-achievments{
//             width: 60%;
//             .my-achievments-cards-container{
//                 &>div{
//                     margin-right: 8px;
//                     margin-bottom: 8px;
//                     width: 47%;
//                 }
//             }
//         }
//     }
//     @media (max-width: 500px){
//         width: 100%;
//         flex-direction: column;
//         .my-info{
//             width: 100%;
//             margin-bottom: 66px;
//             padding: 0;
//             .profile-image-container{
//                 width: 160px;
//                 height: 160px;
//                 margin-top: -180px;
//                 margin-bottom: 31px;
//             }
//             .full-name{
//                 margin-bottom: 7px;
//             }
//         }
//         .my-achievments{
//             width: 100%;
//             padding: 0 25px;
//             .my-achievments-title{
//                 margin-bottom: 52px;
//                 img{
//                     width: 10;
//                     height: 19;
//                 }
//                 h5{
//                     font-size: 11px;
//                     font-weight: 500;
//                     text-transform: uppercase;
//                 }
//             }
//             .my-achievments-cards-container{
//                 padding-bottom: 50px;
//             }
//         }
//     }
// `

const EditMyProfileWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  display: flex;
  .back-to-profile-container {
    align-self: flex-end;
  }
  .edit-profile-container {
    width: 100%;
    display: flex;
    .edit-profile-sidebar {
      width: 20%;
    }
    .change-photo-container-mobile {
      display: none;
      width: 34%;
      flex-direction: column;

      .edit-mode-profile-image-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        svg {
          margin-right: 9px;
        }
        .edit-mode-profile-image {
          width: 250px;
          height: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 50%;
          ${(props) =>
            props.profileImageUrl &&
            css`
              background-image: url("${(props) => props.profileImageUrl}");
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            `}
        }
      }
    }
    .edit-form-container {
      width: 80%;
      height: 82vh;
      padding: 0 5%;
      background-color: ${(props) => props.theme.white};
      padding-top: 60px;
      overflow-y: scroll;
      h5 {
        color: ${(props) => props.theme.gray3};
      }
      .input-label {
        color: ${(props) => props.theme.gray3};
      }
      .edit-my-info-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-bottom: 60px;
        border-bottom: 1px solid ${(props) => props.theme.gray3};

        .change-my-info-container {
          width: 66%;
          display: flex;
          flex-direction: column;
          .name-inputs-container {
            width: 100%;
          }
          h5 {
            margin-bottom: 45px;
            display: none;
          }
          .my-info-input {
            &:first-child {
              margin-right: 12px;
            }
            margin-bottom: 32px;
          }
          label > p {
            font-weight: 500;
          }
          .birth-date-container {
            margin-bottom: 42px;
            .birth-date-inputs-container {
              display: flex;
              .birth-date-input-container {
                width: 23%;
                margin-right: 10px;
              }
              .year-input {
                margin-right: 0 !important;
                width: 50% !important;
              }
            }
          }
          button {
            width: max-content;
            display: ${(props) =>
              props.showInfoMessage.success || props.showInfoMessage.error
                ? "none"
                : "block"};
            margin-top: 78px;
          }
        }
        .change-photo-container {
          width: 34%;
          display: flex;
          flex-direction: column;
          .edit-mode-profile-image-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            svg {
              margin-right: 9px;
            }
            .edit-mode-profile-image {
              width: 250px;
              height: 250px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              border-radius: 50%;
              ${(props) =>
                props.profileImageUrl &&
                css`
                  background-image: url("${(props) => props.profileImageUrl}");
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                `}
            }
          }
        }
      }
      .edit-my-interests-container {
        h5 {
          display: none;
        }
        padding: 60px 0;
        & > div {
          margin-bottom: 47px;
          & > p {
            margin-bottom: 6px;
          }
        }
        .occupations-container {
          & > div {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .occupation-option-container {
              flex-basis: 32%;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .highschools-container {
          & > div {
            width: 100%;
          }
          .mt-7 {
            margin-top: 7px;
          }
        }
        .goals-container {
          .goal-option-container {
            margin-bottom: 10px;
          }
        }
        .interests-container {
          .multiple-choice {
            margin-top: 15px;
          }
          & > div {
            display: flex;
            flex-wrap: wrap;
            .interest-option-container {
              margin-right: 10px;
              margin-bottom: 10px;
              flex-basis: 32%;
            }
          }
        }
      }
      .edit-my-password-container {
        width: 100%;
        padding-bottom: 60px;
        padding-top: 50px;
        padding-right: 40%;
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${(props) => props.theme.gray3};
        h5 {
          margin-bottom: 45px;
          display: none;
        }
        .reset-password-link {
          color: ${(props) => props.theme.gray3};
          margin-bottom: 38px;
          &:hover {
            color: ${(props) => props.theme.blue};
            cursor: pointer;
          }
        }
        .password-change-instructions-container {
          width: 85%;
          display: flex;
          svg {
            width: 12px;
            height: 12px;
            margin-right: 14px;
          }
          div {
            margin-bottom: 34px;
            p {
              font-weight: 600;
            }
          }
        }

        button {
          margin-top: 78px;
          align-self: flex-start;
          display: ${(props) =>
            props.showPasswordMessage.success || props.showPasswordMessage.error
              ? "none"
              : "block"};
        }
      }
    }
  }
  @media (max-width: 1141px) {
    width: 100%;
    padding: 0 20px;
    .edit-profile-container {
      .change-photo-container-mobile {
      }
      .edit-form-container {
        width: 100%;
        padding: 0 2%;
        padding-top: 60px;
        .edit-my-info-container {
          .change-photo-container {
            .edit-mode-profile-image-container {
              .edit-mode-profile-image {
                width: 200px;
                height: 200px;
              }
            }
          }
        }
        // .edit-my-password-container{

        // }
      }
    }
  }
  @media (max-width: 900px) {
    .edit-profile-container {
      .edit-form-container {
        .edit-my-info-container {
          .change-photo-container {
            .edit-mode-profile-image-container {
              .edit-mode-profile-image {
                width: 170px;
                height: 170px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
    flex-direction: column;
    .back-to-profile-container {
      display: none;
    }
    .edit-profile-container {
      flex-direction: column;
      .edit-profile-sidebar {
        display: none;
      }

      .change-photo-container-mobile {
        width: 100%;
        display: flex;
        justify-content: center;
        .myprofile-upper-shape {
          position: relative;
          & > div {
            display: flex;
            position: absolute;
            top: 15px;
            right: 18px;
          }
        }
        .edit-mode-profile-image-container {
          width: 153px;
          align-self: center;
          margin-top: -30px;
          display: flex;
          flex-direction: column;
          aling-items: center;
          justify-content: center;
          .edit-mode-profile-image {
            align-self: center;
            width: 153px;
            height: 153px;
            margin-top: -153px;
          }
        }
      }
      .edit-form-container {
        width: 100%;
        padding: 0 26px;
        overflow: unset;
        height: unset;

        .edit-my-info-container {
          width: 100%;
          margin-bottom: 0px;
          flex-direction: column;
          padding-bottom: 81px;

          .change-photo-container {
            display: none;
          }
          .change-my-info-container {
            width: 100%;
            padding: 0;
            h5 {
              display: block;
            }
            .name-inputs-container {
              display: block;
            }
            .birth-date-container {
              width: 100%;
              .birth-date-inputs-container {
                width: 100%;
                .birth-date-input-container {
                  width: 32%;
                  .select-input__indicators {
                    width: 30px;
                    & > div {
                      width: 28px;
                      padding: 0;
                      & > svg {
                        margin-right: 3px;
                      }
                    }
                  }
                }
                .year-input {
                  width: 33% !important;
                }
              }
            }
            button {
              width: 100%;
              justify-content: center;
              margin-top: 60px;
            }
          }
        }
        .edit-my-interests-container {
          padding: 10px 0px 60px 0px;
          h5 {
            display: block;
            margin-bottom: 60px;
          }
          .occupations-container {
            & > div {
              flex-direction: column;
              .occupation-option-container {
                margin-right: 0;
              }
            }
          }
          .highschools-container {
            & > div {
              width: 100%;
              .highschool-option-container {
                margin-right: 0;
              }
            }
          }
          .goals-container {
            & > div {
              height: 70px;
              & > div {
                height: 100%;
              }
            }
          }
          .interests-container {
            & > div {
              flex-direction: column;
              .interest-option-container {
                margin-right: 0;
              }
            }
          }
          button {
            width: 100%;
            justify-content: center;
          }
        }
        .edit-my-password-container {
          width: 100%;
          padding: 7px 0 0 0;
          h5 {
            display: block;
          }
          button {
            width: 100%;
            justify-content: center;
            margin: 60px 0px;
          }
        }
      }
    }
  }
`;

const ChangePhotoButton = styled.label`
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: ${(props) => props.theme.blue};
  border-radius: 50%;
  div {
    display: flex;
    svg {
      margin-top: -1px;
      margin-right: 0px !important;
      path {
        fill: white;
      }
    }
  }
  p {
    display: none;
  }
  input {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    width: 37px;
    height: 37px;
    div {
      svg {
        width: 20px;
        height: 16px;
      }
    }
  }
`;

// const BackToProfileContainer = styled.div`
//     display: flex;
//     align-self: flex-end;
//     margin-bottom: 38px;
//     p{
//         font-weight: 500;
//         color: ${props => props.theme.gray6};
//     }
//     &:hover{
//         cursor: pointer;
//         &>div{
//             border-color: ${props => props.theme.blue};
//             path{
//                 fill: ${props => props.theme.blue};
//             }
//         }
//         p{
//             color: ${props => props.theme.blue};
//         }
//     }
//     &>div{
//         width: 18px;
//         height: 18px;
//         margin-right: 8px;
//         border: 1px solid ${props => props.theme.gray6};
//         border-radius: 9px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         path{
//             fill: ${props => props.theme.gray6};
//         }
//     }
// `

//component
const MyProfile = (props) => {
  const { t } = useTranslation();
  const { signedUser, setSignedUser, logoutUser } = useContext(GlobalContext);

  //keep state for MyProfile in these hooks below
  // const [fullName, setFullName] = useState('');
  // const [editMode, setEditMode] = useState(true);
  const [profileImageUrl, setProfileImageUrl] = useState("");

  //keep state for Edit Mode of MyProfile in these hooks below

  //Account info values
  const [student, setStudent] = useState({
    name: null,
    surname: null,
    birth_date: null,
    gender: null,
    email: null,
    phoneNumber: null,
    birth_date_parts: {
      day: null,
      month: null,
      year: null,
    },
  });
  // const [joinedDate, setJoinedDate] = useState();
  const [days, setDays] = useState([{ value: null, label: "DD" }]);
  const [selectedDay, setSelectedDay] = useState(days[0]);
  const [monthsForDateOfBirth, setMonthsForDateOfBirth] = useState([
    { value: null, label: "MM" },
  ]);
  const [selectedMonth, setSelectedMonth] = useState(monthsForDateOfBirth[0]);
  const [years, setYears] = useState([{ value: null, label: "YYYY" }]);
  const [selectedYear, setSelectedYear] = useState(years[0]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const genders = [
    { value: "male", label: t("my_profile.gender.options.male") },
    { value: "female", label: t("my_profile.gender.options.female") },
    { value: null, label: t("my_profile.gender.options.none") },
  ];

  const [newProfileImage, setNewProfileImage] = useState(false);
  const [newName, setNewName] = useState("");
  const [newSurname, setNewSurname] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [gender, setGender] = useState({
    value: "",
    label: t("my_profile.gender.options.none"),
  });
  const [emailNotificationsChecked, setEmailNotificationsChecked] =
    useState(false);
  const [accountInfoButtonDisabled, setAccountInfoButtonDisabled] =
    useState(true);

  const [studentInfo, setStudentInfo] = useState([]);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [highschools, setHighschools] = useState([
    { label: t("my_profile.interests.highschool_placeholder"), value: null },
  ]);
  const [selectedHighschool, setSelectedHighschool] = useState(highschools[0]);
  const [highschoolOther, setHighschoolOther] = useState("");
  const [selectedHighschoolDepartment, setSelectedHighschoolDepartment] =
    useState(highschoolDepartment[0]);
  const [highschoolDepartmentOther, setHighschoolDepartmentOther] =
    useState("");

  const [faculties, setFaculties] = useState([
    { value: null, label: t("my_profile.interests.faculty_placeholder") },
  ]);
  const [selectedFaculty, setSelectedFaculty] = useState(faculties[0]);
  const [facultyOther, setFacultyOther] = useState("");
  const [selectedFacultyYear, setSelectedFacultyYear] = useState(
    facultyYear[0]
  );
  const [selectedFieldOfExpertise, setSelectedFieldOfExpertise] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [interests, setInterests] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [interestsButtonDisabled, setInterestsButtonDisabled] = useState(true);

  //Account password values
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [passwordChangeButtonDisabled, setPasswordChangeButtonDisabled] =
    useState(true);
  const [showInfoMessage, setShowInfoMessage] = useState({});
  const [showInterestsMessage, setShowInterestsMessage] = useState({});
  const [showPasswordMessage, setShowPasswordMessage] = useState({});
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const newPasswordConfirmationRef = useRef();
  //scrollspy for sidebar
  const [activeSideBarItem, setActiveSideBarItem] = useState(0);

  const desktopImageInputRef = useRef();
  const mobileImageInputRef = useRef();
  const infoRef = useRef();
  const interestsRef = useRef();
  const passwordRef = useRef();
  const refs = [infoRef, interestsRef, passwordRef];
  useEffect(() => {
    //the used refs bellow will likely have changed before the cleanup function runs, that's why we use the variables in the cleanup function
    let infoReference = infoRef.current;
    let interestsReference = interestsRef.current;
    let passwordReference = passwordRef.current;

    const observer = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === infoRef.current) {
              setActiveSideBarItem(0);
            } else if (entry.target === interestsRef.current) {
              setActiveSideBarItem(1);
            } else if (entry.target === passwordRef.current) {
              setActiveSideBarItem(2);
            }
          }
        });
      },
      { threshold: 0.3, rootMargin: "-80px" }
    );

    if (passwordRef.current && interestsRef.current && infoRef.current) {
      observer.observe(infoRef.current);
      observer.observe(interestsRef.current);
      observer.observe(passwordRef.current);
    }

    return () => {
      infoReference && observer.unobserve(infoReference);
      interestsReference && observer.unobserve(infoReference);
      passwordReference && observer.unobserve(infoReference);
    };
  }, [infoRef, interestsRef, passwordRef]);

  const scrollTabIntoView = (elementIndex) => {
    refs[elementIndex].current &&
      refs[elementIndex].current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  useEffect(() => {
    student.birth_date &&
      days.length > 1 &&
      setSelectedDay(days[parseFloat(student.birth_date_parts.day)]);
    monthsForDateOfBirth.length > 1 &&
      student.birth_date &&
      setSelectedMonth(
        monthsForDateOfBirth[parseInt(student.birth_date_parts.month)]
      );
    let yearIndex = 0;
    if (student.birth_date && years.length > 1) {
      years.forEach((year, index) => {
        if (year.value === parseFloat(student.birth_date_parts.year)) {
          yearIndex = index;
        }
      });
      setSelectedYear(years[yearIndex]);
    }
  }, [days, monthsForDateOfBirth, years, student]);

  //make an ajax call so that user is redirected to login page if logged out
  useEffect(() => {
    API.axios.get(API.createApiRoute("authenticated"));

    //get interests from backend and student information on them and set them in hooks
    API.axios
      .get(API.createApiRoute("studentInfo"))
      .then((response) => {
        let highschoolsForSelectInputArray = [
          {
            label: t("my_profile.interests.highschool_placeholder"),
            value: null,
          },
        ];
        let facultiesForSelectInputArray = [
          { label: t("my_profile.interests.faculty_placeholder"), value: null },
        ];
        response.data.data.faculties.length > 0 &&
          response.data.data.faculties.forEach((faculty) => {
            let value = faculty.id;
            let label = `${faculty.name}, ${faculty.city.name}`;
            facultiesForSelectInputArray.push({ value, label });
          });
        response.data.data.goals.length > 0 &&
          setGoals(response.data.data.goals);
        response.data.data.highschools.length > 0 &&
          response.data.data.highschools.forEach((highschool) => {
            let value = highschool.id;
            let label = `${highschool.name}, ${highschool.city.name}`;
            highschoolsForSelectInputArray.push({ value, label });
          });
        response.data.data.interests.length > 0 &&
          setInterests(response.data.data.interests);
        response.data.data.occupations.length > 0 &&
          setOccupations(response.data.data.occupations);
        setStudentInfo([response.data.data.studentInfo]);
        setHighschools(highschoolsForSelectInputArray);
        setFaculties(facultiesForSelectInputArray);
        setStudent(response.data.data.student);
      })
      .catch((error) => console.log(error));
    let daysTemp = [{ value: null, label: "DD" }];
    for (let i = 0; i < 31; i++) {
      daysTemp.push({ value: i + 1, label: i + 1 });
    }
    let mnthsTemp = [{ value: null, label: "MM" }];
    months.forEach((month, index) => {
      mnthsTemp.push({ value: index + 1, label: month.split().splice(0, 3) });
    });
    let yearTemp = new Date();
    let yearsTemp = [{ value: null, label: "YYYY" }];
    for (let i = yearTemp.getFullYear() - 13; i > 1940; i--) {
      yearsTemp.push({ value: i, label: i });
    }
    setDays(daysTemp);
    setMonthsForDateOfBirth(mnthsTemp);
    setYears(yearsTemp);
    //eslint-disable-next-line
  }, []);

  //if there is studentInfo object received from backend, store it's value to appropriate hooks in order to display choices in the interests tab
  useEffect(() => {
    let selectedInterestsIdsArray = [];
    studentInfo.length > 0 &&
      studentInfo.forEach((student) => {
        // If  user has selected a faculty, then with POST request only the value is stored and returned.
        // In order to display the label of that value coming from server, we need to to filter all faculties and return only the matching object of that faculty id.
        student.faculty_id !== null &&
          faculties.filter((faculty) => {
            if (faculty.value === student.faculty_id) {
              setSelectedFaculty(faculty);
            }
          });
        student.faculty_name !== null && setFacultyOther(student.faculty_name);
        student.faculty_year !== null &&
          facultyYear.filter((year) => {
            if (year.value === student.faculty_year) {
              setSelectedFacultyYear(year);
            }
          });

        // If  user has selected a highschool, then with POST request only the value is stored and returned.
        // In order to display the label of that value coming from server, we need to to filter all high schools and return only the matching object of highschool
        student.highschool_id !== null &&
          highschools.filter((highschool) => {
            if (highschool.value === student.highschool_id) {
              setSelectedHighschool(highschool);
            }
          });
        student.highschool_department !== null &&
          highschoolDepartment.filter((department) => {
            if (department.value === student.highschool_department) {
              setSelectedHighschoolDepartment(department);
            }
          });
        student.highschool_name !== null &&
          setHighschoolOther(student.highschool_name);
        student.highschool_department_other !== null &&
          setHighschoolDepartmentOther(student.highschool_department_other);
        student.interests.length > 0 &&
          student.interests.forEach((interest) =>
            selectedInterestsIdsArray.push(interest.id)
          );
        student.goal_id !== null && setSelectedGoal(student.goal_id);
        student.occupation_id !== null &&
          setSelectedOccupation(student.occupation_id);
        student.profession !== "" &&
          setSelectedFieldOfExpertise(student.profession);
        student.company !== null && setCompanyName(student.company);
        setSelectedInterests(selectedInterestsIdsArray);
      });
  }, [studentInfo, highschools, faculties]);

  //set default values to display for name and surname input fields based on signedUser object
  useEffect(() => {
    let name = student.name ? student.name : "";
    let surname = student.surname ? student.surname : "";
    let email = student.email ? student.email : "";
    let phone = student.phone ? student.phone : "";
    let profileImageUrl = API.storage + student.profile_image;
    let email_consent = student.email_consent;
    // let joined_date = new Date(student.created_at);
    let gender = genders.filter((gender) => gender.value === student.gender);

    setNewName(name);
    setNewSurname(surname);
    setPhoneNumber(phone);
    // setFullName(name + " " + surname);
    setProfileImageUrl(profileImageUrl);
    setNewEmail(email);
    setEmailNotificationsChecked(email_consent);
    // setJoinedDate(joined_date);
    setGender(gender[0]);
    //eslint-disable-next-line
  }, [student]);

  //listen on changes of name, surname and email input fields and if their values have changed, change the value for accountInfoButtonDisaled which will enable UPDATE button at Account Info in Edit Mode
  useEffect(() => {
    if (student.birth_date) {
      if (
        (newName && student.name && newName !== student.name) ||
        (newSurname && student.surname && newSurname !== student.surname) ||
        (newEmail && student.email && newEmail !== student.email) ||
        (student.gender && gender && gender.value !== student.gender) ||
        (selectedDay.value !== null &&
          selectedDay.value !== parseFloat(student.birth_date_parts.day) &&
          selectedMonth.value !== null &&
          selectedMonth.value !== parseFloat(student.birth_date_parts.month) &&
          selectedYear.value !== null &&
          selectedYear.value !== parseFloat(student.birth_date_parts.year))
      ) {
        setAccountInfoButtonDisabled(false);
      }
    } else if (
      (newName && student.name && newName !== student.name) ||
      (newSurname && student.surname && newSurname !== student.surname) ||
      (newEmail && student.email && newEmail !== student.email) ||
      (student.gender && gender && gender.value !== student.gender) ||
      (selectedDay.value !== null &&
        !student.birth_date_parts.day &&
        selectedMonth.value !== null &&
        !student.birth_date_parts.month &&
        selectedYear.value !== null &&
        !student.birth_date_parts.year)
    ) {
      setAccountInfoButtonDisabled(false);
    } else {
      setAccountInfoButtonDisabled(true);
    }
    if (
      (emailNotificationsChecked === 0 && student.email_consent === 1) ||
      (emailNotificationsChecked === 1 && student.email_consent === 0)
    ) {
      setAccountInfoButtonDisabled(false);
    }
  }, [
    newName,
    newSurname,
    newEmail,
    emailNotificationsChecked,
    student.birth_date,
    selectedYear,
    selectedDay,
    selectedMonth,
    gender,
    student.gender,
    student.name,
    student.surname,
    student.email_consent,
    student.email,
    student.birth_date_parts.day,
    student.birth_date_parts.month,
    student.birth_date_parts.year,
  ]);

  //listen on changes of Current Password, New Password and NewPassword(AGAIN) input fields and if their values have changed, change the value of passwordChangeButtonDisabled to false which will enable UPDATE button at Password section in Edit Mode
  useEffect(() => {
    if (
      currentPassword !== "" &&
      newPassword !== "" &&
      newPasswordConfirmation !== ""
    ) {
      setPasswordChangeButtonDisabled(false);
    }
  }, [newPasswordConfirmation, newPassword, currentPassword]);

  useEffect(() => {
    if (
      // interestsButtonDisabled &&
      studentInfo.length > 0 &&
      selectedOccupation !== null &&
      selectedOccupation !== studentInfo[0].occupation_id 
    ) {
      setInterestsButtonDisabled(false);
    } else if (!interestsButtonDisabled) {
      setInterestsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [selectedOccupation]);

  useEffect(() => {
    if (
      // interestsButtonDisabled &&
      studentInfo.length > 0 &&
      selectedHighschool !== null &&
      selectedHighschool !== studentInfo[0].highschool_id
    ) {
      setInterestsButtonDisabled(false);
    } else if (
      selectedHighschoolDepartment !== studentInfo[0]?.highschool_department
    ) {
      setInterestsButtonDisabled(false);
    } else if (highschoolOther !== studentInfo[0]?.highschool_name) {
      setInterestsButtonDisabled(false);
    } else if (
      highschoolDepartmentOther !== studentInfo[0]?.highschool_department_other
    ) {
      setInterestsButtonDisabled(false);
    } else if (!interestsButtonDisabled) {
      setInterestsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [
    selectedHighschool,
    selectedHighschoolDepartment,
    highschoolOther,
    highschoolDepartmentOther,
  ]);

  useEffect(() => {
    if (
      // interestsButtonDisabled &&
      studentInfo.length > 0 &&
      selectedFaculty !== null &&
      selectedFaculty !== studentInfo[0].faculty_id &&
      facultyOther !== studentInfo[0]?.faculty_name &&
      facultyYear !== studentInfo[0]?.faculty_year
    ) {
      setInterestsButtonDisabled(false);
    } else if (!interestsButtonDisabled) {
      setInterestsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [selectedFaculty, facultyOther, facultyYear]);

  useEffect(() => {
    if (
      // interestsButtonDisabled &&
      studentInfo.length > 0 &&
      selectedFieldOfExpertise !== "" &&
      selectedFieldOfExpertise !== studentInfo[0].profession &&
      companyName !== studentInfo[0]?.company
    ) {
      setInterestsButtonDisabled(false);
    } else if (!interestsButtonDisabled) {
      setInterestsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [selectedFieldOfExpertise, companyName]);

  useEffect(() => {
    if (
      // interestsButtonDisabled &&
      studentInfo.length > 0 &&
      selectedGoal !== null &&
      selectedGoal !== studentInfo[0].goal_id
    ) {
      setInterestsButtonDisabled(false);
    } else if (!interestsButtonDisabled) {
      setInterestsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [selectedGoal]);

  const hasInterestsArrayChanged = () => {
    let returnValue;
    if (studentInfo.length > 0) {
      if (
        studentInfo[0].interests &&
        studentInfo[0].interests.length !== selectedInterests.length
      ) {
        returnValue = true;
      } else if (
        selectedInterests.length > 0 &&
        studentInfo[0].interests.length > 0
      ) {
        let sortedStudentInfoInterests = [];
        studentInfo[0].interests.forEach((interest) =>
          sortedStudentInfoInterests.push(interest.id)
        );
        sortedStudentInfoInterests.sort();
        let sortedSelectedInterests = selectedInterests.sort();
        sortedSelectedInterests.forEach((interest, index) => {
          if (
            parseInt(interest) !== parseInt(sortedStudentInfoInterests[index])
          ) {
            returnValue = true;
          } else {
            returnValue = false;
          }
        });
      } else {
        returnValue = false;
      }
    }

    return returnValue;
  };
  useEffect(() => {
    if (hasInterestsArrayChanged()) {
      setInterestsButtonDisabled(false);
    } else if (!interestsButtonDisabled) {
      setInterestsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [selectedInterests.length]);

  const selectInterests = (interest) => {
    let selectedInterestsArray = [];
    selectedInterests.forEach((interest) =>
      selectedInterestsArray.push(interest)
    );
    if (
      selectedInterests.some(
        (selectedInterest) => selectedInterest === interest
      )
    ) {
      selectedInterestsArray.forEach((selectedInterest, index) => {
        if (selectedInterest === interest) {
          selectedInterestsArray.splice(index, 1);
        }
      });
    } else {
      selectedInterestsArray.push(interest);
    }
    setSelectedInterests(selectedInterestsArray);
  };

  //post the values from newName, newSurname and newEmail to "/student/edit/profile"
  //if it responds with status 200 (OK) then setSignedUser and set "credentials" item in localStorage with new and updated values from input fields
  const updateUserInfo = () => {
    API.axios
      .post(API.createApiRoute("editStudentInfo"), {
        name: newName,
        surname: newSurname,
        email: newEmail,
        phone: phoneNumber,
        email_consent: emailNotificationsChecked,
        birth_date: {
          day: selectedDay.value,
          month: selectedMonth.value,
          year: selectedYear.value,
        },
        gender: gender?.value,
      })
      .then((response) => {
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.data.student)
        );
        setSignedUser(response.data.data.student);
        setAccountInfoButtonDisabled(true);
        setShowInfoMessage({ success: t("messages.update_successful") });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          if (error.response.data.errors.email) {
            setShowInfoMessage({ error: error.response.data.errors.email });
          } else if (error.response.data.errors.name) {
            setShowInfoMessage({ error: error.response.data.errors.name });
          } else if (error.response.data.errors.surname) {
            setShowInfoMessage({ error: error.response.data.errors.surname });
          }
        }
      });
  };

  //post values to update user's interests
  const updateUserInterests = () => {
    let userInterests = {};
    if (selectedOccupation !== null) {
      if (selectedOccupation === 1) {
        if (selectedHighschool.value !== null) {
          userInterests = {
            ...userInterests,
            highschool_id: selectedHighschool.value,
            highschool_name: highschoolOther || null,
            highschool_department: selectedHighschoolDepartment.value,
            highschool_department_other: highschoolDepartmentOther || null,
          };
        }
      } else if (selectedOccupation === 2) {
        if (selectedFaculty.value !== null) {
          userInterests = {
            ...userInterests,
            faculty_id: selectedFaculty.value,
            faculty_name: facultyOther || null,
            faculty_year: selectedFacultyYear.value,
          };
        }
      } else if (selectedOccupation === 5) {
        userInterests = {
          ...userInterests,
          occupation_id: selectedOccupation,
        };
      } else {
        if (selectedFieldOfExpertise !== "") {
          userInterests = {
            ...userInterests,
            profession: selectedFieldOfExpertise,
            company: companyName,
          };
        }
      }
      userInterests = { ...userInterests, occupation_id: selectedOccupation };
    }
    if (selectedGoal !== null) {
      userInterests = { ...userInterests, goal_id: selectedGoal };
    }
    if (selectedInterests.length > 0) {
      userInterests = { ...userInterests, interests: selectedInterests };
    }
    API.axios
      .post(API.createApiRoute("studentInfo"), userInterests)
      .then(
        (response) =>
          response.status === 200 && [
            setInterestsButtonDisabled(true),
            setShowInterestsMessage({
              success: t("messages.update_successful"),
            }),
          ]
      )
      //ask backend how to provoke error and see how it's handled
      .catch((error) =>
        setShowInterestsMessage({ error: error?.response?.data?.errors[0] })
      );
  };

  const changePassword = (e) => {
    e.preventDefault();
    API.axios
      .post(API.createApiRoute("editStudentLogin"), {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      })
      .then(() => {
        setShowPasswordMessage({ success: t("messages.update_successful") });
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordConfirmation("");
        currentPasswordRef.current.value = "";
      })
      .catch((error) => {
        setShowPasswordMessage({
          error:
            error.response.data.errors.current_password ||
            error.response.data.errors.password,
        });
        currentPasswordRef.current.value = "";
      });
  };
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setNewProfileImage(imageDataUrl);
    } else {
      setNewProfileImage(false);
    }
  };

  const resetPassword = () => {
    logoutUser(API.appRoutes.studentRecover);
  };

  const uploadImage = (image) => {
    API.axios
      .post(API.createApiRoute("uploadProfileImage"), image)
      .then((response) => {
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.data.student)
        );
        setSignedUser(response.data.data.student);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeCropper = () => {
    setNewProfileImage(false);
    if (desktopImageInputRef.current) {
      desktopImageInputRef.current.value = "";
    }
    if (mobileImageInputRef.current) {
      mobileImageInputRef.current.value = "";
    }
  };
  return signedUser.guid ? (
    <MyProfileContainer>
      {/* <MyProfileWrapper editMode={editMode} profileImageUrl={profileImageUrl}>
                <div className="my-info">
                    <div className="myprofile-shape-container">
                        <div className="myprofile-upper-shape-container">
                            <div className="myprofile-upper-shape"></div>
                        </div>
                        <div className="myprofile-lower-shape-container">
                            <div className="myprofile-lower-shape"></div>
                        </div>
                    </div>
                    <div className="profile-image-container"></div>
                    <Text20 className="full-name"> {fullName}</Text20>
                    <Text10 className="joined-date">{t("my_profile.joined")} <span>{joinedDate && joinedDate.getDate()}th {months[joinedDate && joinedDate.getMonth()]}, 20{joinedDate && joinedDate.getYear().toString().split("").splice(1, 2).join("")}</span></Text10>
                    <ButtonBasic
                        text={t("my_profile.edit_profile")}
                        variation="primary"
                        type="button"
                        onClick={() => setEditMode(true)}
                    />
                </div>
                <div className="my-achievments">
                    <div className="my-achievments-title">
                        <img src={icn_achievment} alt="achievments" />
                        <Heading20>{t("my_profile.achievments")}</Heading20>
                    </div>
                    <div className="my-achievments-cards-container">
                        {achievmentCardsArray.map((card, index) => {
                            return (
                                <div key={index}>
                                    <AchievmentBadge badge={badge_achievment} title={card.title} description={card.description} key={index} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </MyProfileWrapper> */}
      <EditMyProfileWrapper
        profileImageUrl={profileImageUrl}
        showPasswordMessage={showPasswordMessage}
        showInfoMessage={showInfoMessage}
      >
        {newProfileImage && (
          <PhotoCropper
            photo={newProfileImage}
            closeCropper={closeCropper}
            uploadImage={uploadImage}
            setNewImage={setNewProfileImage}
          />
        )}
        {/* <div className="back-to-profile-container">
                    <BackToProfileContainer onClick={() => setEditMode(false)}>
                        <div>
                            <Icon icon={"icnArrowLeftTail"} />
                        </div>
                        <Text13>{t("my_profile.back_to_profile")}</Text13>
                    </BackToProfileContainer>
                </div> */}
        <div className="edit-profile-container">
          <div className="edit-profile-sidebar">
            {MyProfileSideBarItemsData.map((item, index) => (
              <SidebarItem
                text={t(item.text)}
                key={index}
                active={activeSideBarItem === index}
                argument_1={index}
                index={index}
                onClick={scrollTabIntoView}
              />
            ))}
          </div>
          <div className="change-photo-container-mobile">
            <div className="myprofile-shape-container">
              <div className="myprofile-upper-shape-container">
                <div className="myprofile-upper-shape">
                  {/* <BackToProfileContainer onClick={() => setEditMode(false)}>
                                        <div>
                                            <Icon icon={"icnArrowLeftTail"} />
                                        </div>
                                        <Text13>{t("my_profile.back_to_profile")}</Text13>
                                    </BackToProfileContainer> */}
                </div>
              </div>
              <div className="myprofile-lower-shape-container">
                <div className="myprofile-lower-shape"></div>
              </div>
            </div>
            <div className="edit-mode-profile-image-container">
              <div className="edit-mode-profile-image"></div>
              <ChangePhotoButton>
                <input
                  ref={mobileImageInputRef}
                  type="file"
                  accept="image/*, .pdf"
                  onChange={(e) => onFileChange(e)}
                />
                <div>
                  <Icon icon="icnCamera" width={30} height={24} />
                  <Caption12>{t("my_profile.change_photo")}</Caption12>
                </div>
              </ChangePhotoButton>
            </div>
          </div>
          <div className="edit-form-container">
            <div className="edit-my-info-container">
              <div ref={infoRef} className="change-my-info-container">
                <Heading20>{t("my_profile.account")}</Heading20>
                <div className="name-inputs-container">
                  <InputField
                    name="name"
                    value={newName}
                    inputLabel={t("general.name")}
                    type="text"
                    autocomplete="name"
                    color={(props) => props.theme.gray3}
                    focusColor={true}
                    className="my-info-input"
                    onChange={(e) =>
                      showInfoMessage
                        ? [setNewName(e.target.value), setShowInfoMessage({})]
                        : setNewName(e.target.value)
                    }
                  />
                  <InputField
                    name="surname"
                    value={newSurname}
                    inputLabel={t("general.surname")}
                    type="text"
                    autocomplete="surname"
                    color={(props) => props.theme.gray3}
                    focusColor={true}
                    className="my-info-input"
                    onChange={(e) =>
                      showInfoMessage
                        ? [
                            setNewSurname(e.target.value),
                            setShowInfoMessage({}),
                          ]
                        : setNewSurname(e.target.value)
                    }
                  />
                </div>
                <InputField
                  name="phone"
                  value={phoneNumber || ""}
                  inputLabel={t("general.phone_number")}
                  type="phone"
                  color={(props) => props.theme.gray3}
                  focusColor={true}
                  onChange={(e) =>
                    showInfoMessage
                      ? [setPhoneNumber(e.target.value), setShowInfoMessage({})]
                      : setPhoneNumber(e.target.value)
                  }
                  className="my-info-input    "
                />
                <InputField
                  name="email"
                  value={newEmail || ""}
                  inputLabel={t("general.email")}
                  type="text"
                  color={(props) => props.theme.gray3}
                  focusColor={true}
                  onChange={(e) =>
                    showInfoMessage
                      ? [setNewEmail(e.target.value), setShowInfoMessage({})]
                      : setNewEmail(e.target.value)
                  }
                  className="my-info-input    "
                />
                <div className="birth-date-container">
                  <Caption12 className="input-label">
                    {t("my_profile.birth_date_heading")}
                  </Caption12>
                  <div className="birth-date-inputs-container">
                    <div className="birth-date-input-container day-input">
                      <SelectInput
                        data={days}
                        selectedOption={selectedDay}
                        onChange={setSelectedDay}
                        typeOfValue="number"
                      />
                    </div>
                    <div className="birth-date-input-container month-input">
                      <SelectInput
                        data={monthsForDateOfBirth}
                        selectedOption={selectedMonth}
                        onChange={setSelectedMonth}
                      />
                    </div>
                    <div className="birth-date-input-container year-input">
                      <SelectInput
                        data={years}
                        selectedOption={selectedYear}
                        onChange={setSelectedYear}
                        typeOfValue="number"
                      />
                    </div>
                  </div>
                </div>
                <SelectInput
                  inputLabel={t("my_profile.gender.label")}
                  data={genders}
                  onChange={setGender}
                  className="my-info-input"
                  selectedOption={gender}
                  defaultValue="blabla"
                />
                <div className="email-notifications-checkbox-container">
                  <Caption12 className="input-label">
                    {t("my_profile.email_notifications")}
                  </Caption12>
                  <Checkbox
                    checked={emailNotificationsChecked}
                    onClick={() =>
                      emailNotificationsChecked === 1
                        ? setEmailNotificationsChecked(0)
                        : setEmailNotificationsChecked(1)
                    }
                    text={t("my_profile.email_me")}
                  />
                </div>
                <UpdateMessage show={showInfoMessage} />
                <ButtonBasic
                  text={t("general.save")}
                  variation={
                    accountInfoButtonDisabled ? "quaternary" : "secondary"
                  }
                  disabled={accountInfoButtonDisabled}
                  onClick={() => updateUserInfo()}
                />
              </div>
              <div className="change-photo-container">
                <div className="myprofile-shape-container">
                  <div className="myprofile-upper-shape-container">
                    <div className="myprofile-upper-shape">
                      {/* <BackToProfileContainer onClick={() => setEditMode(false)}>
                                                <div>
                                                    <Icon icon={"icnArrowLeftTail"} />
                                                </div>
                                                <Text13>{t("my_profile.back_to_profile")}</Text13>
                                            </BackToProfileContainer> */}
                    </div>
                  </div>
                  <div className="myprofile-lower-shape-container">
                    <div className="myprofile-lower-shape"></div>
                  </div>
                </div>
                <div className="edit-mode-profile-image-container">
                  <div className="edit-mode-profile-image"></div>
                  <ChangePhotoButton>
                    <input
                      ref={desktopImageInputRef}
                      type="file"
                      onChange={(e) => onFileChange(e)}
                    />
                    <div>
                      <Icon icon="icnCamera" width={30} height={24} />
                      <Caption12>{t("my_profile.change_photo")}</Caption12>
                    </div>
                  </ChangePhotoButton>
                </div>
              </div>
            </div>
            <div ref={interestsRef} className="edit-my-interests-container">
              <Heading20>{t("my_profile.account")}</Heading20>
              <div className="occupations-container">
                <Text16 fontWeight={900}>
                  {t("my_profile.interests.occupations_heading")}
                </Text16>
                <div>
                  {occupations.length > 0 &&
                    occupations.map((occupation, index) => (
                      <div className="occupation-option-container" key={index}>
                        <ChooseButton
                          text={occupation.name}
                          checkCircle={true}
                          active={occupation.id === selectedOccupation}
                          id={occupation.id}
                          onClick={setSelectedOccupation}
                        />
                      </div>
                    ))}
                </div>
              </div>
              {selectedOccupation && selectedOccupation === 1 ? (
                <div className="highschools-container">
                  <Text16 fontWeight={900}>
                    {t("my_profile.interests.highschool_heading")}
                  </Text16>
                  <SelectInput
                    inputLabel={t("")}
                    data={highschools}
                    onChange={setSelectedHighschool}
                    selectedOption={selectedHighschool}
                  />
                  {selectedHighschool.label === "Друго, Друго" ? (
                    <InputField
                      className="mt-7"
                      name="highschool_name"
                      value={highschoolOther}
                      placeholder={t(
                        "my_profile.interests.highschool_other_placeholder"
                      )}
                      type="text"
                      onChange={(e) => {
                        setHighschoolOther(e.target.value);

                      }}
                        focusColor={(props) => props.theme.blue}
                    />
                  ) : (
                    ""
                  )}

                  <div className="mb-14">
                    <SelectInput
                      data={highschoolDepartment}
                      onChange={setSelectedHighschoolDepartment}
                      selectedOption={selectedHighschoolDepartment}
                    />
                  </div>
                  {selectedHighschoolDepartment.label === "Друго" ? (
                    <InputField
                      name="highschool_department_other"
                      className="mt-7"
                      value={highschoolDepartmentOther}
                      placeholder={t(
                        "my_profile.interests.highschool_department_other_placeholder"
                      )}
                      type="text"
                      onChange={(e) => {
                        setHighschoolDepartmentOther(e.target.value);
                      }}
                        focusColor={(props) => props.theme.blue}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : selectedOccupation === 2 ? (
                <div className="faculties-container">
                  <Text16 fontWeight={900}>
                    {t("my_profile.interests.faculties_heading")}
                  </Text16>
                  <SelectInput
                    inputLabel={t("")}
                    data={faculties}
                    onChange={setSelectedFaculty}
                    selectedOption={selectedFaculty}
                  />
                  {selectedFaculty.label === "Друго, Друго" ? (
                    <div style={{ marginTop: "7px" }}>
                      <InputField
                        name="name"
                        value={facultyOther}
                        placeholder={t(
                          "my_profile.interests.faculty_other_placeholder"
                        )}
                        type="text"
                        onChange={(e) => {
                          setFacultyOther(e.target.value);

                        }}
                          focusColor={(props) => props.theme.blue}
                      />

                    </div>
                  ) : (

                    ""
                  )}
                  <div style={{ marginTop: "7px" }}>
                    <SelectInput
                      inputLabel={t("")}
                      data={facultyYear}
                      onChange={setSelectedFacultyYear}
                      selectedOption={selectedFacultyYear}
                    />
                  </div>
                </div>
              ) : (
                selectedOccupation === 3 && (
                  <div className="field-of-expertise-container">
                    <Text16 fontWeight={900}>
                      {t("my_profile.interests.field_of_expertise")}
                    </Text16>
                    <InputField
                      name="job_title"
                      defaultValue={selectedFieldOfExpertise}
                      type="text"
                      color={(props) => props.theme.gray3}
                      focusColor={true}
                      className="my-info-input"
                      placeholder={t("my_profile.interests.job_title")}
                      onChange={(e) =>
                        setSelectedFieldOfExpertise(e.target.value)
                      }
                    />
                    <div style={{ marginTop: "7px" }}>
                      <InputField
                        name="company_name"
                        value={companyName}
                        defaultValue={companyName}
                        type="text"
                        color={(props) => props.theme.gray3}
                        focusColor={true}
                        className="my-info-input"
                        placeholder={t("my_profile.interests.company_name")}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                  </div>
                )
              )}

              <div className="goals-container">
                <Text16 fontWeight={900}>
                  {t("my_profile.interests.goals_heading")}
                </Text16>
                {goals.length > 0 &&
                  goals.map((goal, index) => (
                    <div key={index} className="goal-option-container">
                      <ChooseButton
                        text={goal.name}
                        checkCircle={true}
                        active={goal.id === selectedGoal}
                        id={goal.id}
                        onClick={setSelectedGoal}
                      />
                    </div>
                  ))}
              </div>
              <div className="interests-container">
                <Text16 fontWeight={900}>
                  {t("my_profile.interests.interests_heading")}
                </Text16>
                <Text10 className="multiple-choice">
                  {t("my_profile.interests.multiple_choice")}
                </Text10>
                <div>
                  {interests.length > 0 &&
                    interests.map((interest, index) => (
                      <div key={index} className="interest-option-container">
                        <ChooseButton
                          text={interest.name}
                          active={selectedInterests.some(
                            (selectedInterest) =>
                              selectedInterest === interest.id
                          )}
                          id={interest.id}
                          onClick={selectInterests}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <UpdateMessage show={showInterestsMessage} />
              <ButtonBasic
                text={t("general.save")}
                variation="secondary"
                // variation={interestsButtonDisabled ? "quaternary" : "secondary"}
                // disabled={interestsButtonDisabled}
                onClick={() => updateUserInterests()}
              />
            </div>
            <form
              onSubmit={(e) => changePassword(e)}
              ref={passwordRef}
              className="edit-my-password-container"
            >
              <Heading20>{`${t("general.password").charAt(0).toUpperCase()}${t(
                "general.password"
              ).slice(1)}`}</Heading20>
              <input
                type="text"
                name="email"
                autoComplete="username email"
                style={{ display: "none" }}
              />
              <InputField
                ref={currentPasswordRef}
                name="current_password"
                value={currentPassword}
                inputLabel={t("my_profile.current_password")}
                type="password"
                autocomplete="current-password"
                color={(props) => props.theme.gray3}
                focusColor={true}
                className="my-info-input"
                onChange={(e) =>
                  showPasswordMessage.success || showPasswordMessage.error
                    ? [
                        setCurrentPassword(e.target.value),
                        setShowPasswordMessage({}),
                      ]
                    : setCurrentPassword(e.target.value)
                }
              />
              <Text10
                className="reset-password-link"
                onClick={() => resetPassword()}
              >
                {t("my_profile.forgot_password")}{" "}
                {t("my_profile.reset_password")}
              </Text10>
              <div className="password-change-instructions-container">
                <Icon icon="icnEdit" />
                <div>
                  <Text10>
                    {t("my_profile.password_hint.1")}
                    <br />
                    <br />
                    {t("my_profile.password_hint.2")}
                    <br />
                    {t("my_profile.password_hint.3")}
                    <br />
                    {t("my_profile.password_hint.4")}
                    <br />
                    {t("my_profile.password_hint.5")}
                  </Text10>
                </div>
              </div>
              <InputField
                ref={newPasswordRef}
                name="new_password"
                value={newPassword}
                inputLabel={t("my_profile.new_password")}
                type="password"
                autocomplete="new-password"
                color={(props) => props.theme.gray3}
                focusColor={true}
                className="my-info-input"
                onChange={(e) =>
                  showPasswordMessage.success || showPasswordMessage.error
                    ? [
                        setNewPassword(e.target.value),
                        setShowPasswordMessage({}),
                      ]
                    : setNewPassword(e.target.value)
                }
              />
              <PasswordStrengthIndicator password={newPassword} />
              <InputField
                ref={newPasswordConfirmationRef}
                name="new_password_confirmation"
                value={newPasswordConfirmation}
                inputLabel={t("my_profile.repeat_password")}
                type="password"
                autocomplete="new-password"
                color={(props) => props.theme.gray3}
                focusColor={true}
                className="my-info-input last-info-input"
                onChange={(e) =>
                  showPasswordMessage.success || showPasswordMessage.error
                    ? [
                        setNewPasswordConfirmation(e.target.value),
                        setShowPasswordMessage({}),
                      ]
                    : setNewPasswordConfirmation(e.target.value)
                }
              />
              <UpdateMessage show={showPasswordMessage} />
              <ButtonBasic
                text={t("general.save")}
                variation={
                  passwordChangeButtonDisabled ? "quaternary" : "secondary"
                }
                disabled={passwordChangeButtonDisabled}
                type="submit"
              />
            </form>
          </div>
        </div>
      </EditMyProfileWrapper>
    </MyProfileContainer>
  ) : (
    <Loader />
  );
};

export default withTheme(MyProfile);
