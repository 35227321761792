import React, { useContext } from 'react';

//npm imports
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

//component imports
import { GlobalContext } from '../../Context/GlobalContext';
import { SpecHeadingSmall, Text13, CardCaption15 } from '../../Components/Text/Text';
import Icon from '../../Components/Icon/Icon';

//other imports
import background from '../../Static/backgrounds/ReferAFriendBackground.svg';
import hero from '../../Static/backgrounds/ReferAFriendHero.svg';
import CopyLinkInput from '../../Components/CopyLinkInput/CopyLinkInput';
import { API } from '../../Consts/Api';

//styled-components
const ReferAFriendWrapper = styled.div`
    width: 100%;
    min-height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .refer-a-friend-background{
        width: 100%;
        height: 20vh;
        margin-top: 50px;
        background-image: url(${hero});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .refer-a-friend-heading{
        max-width: 574px;
        margin-top: 20px;
        h3{
            color: ${props => props.theme.blue};
            text-align: center;
        }
    }
    .refer-a-friend-description-container{
        margin-top: 22px;
        p{
            color: ${props => props.theme.black};
            font-weight: 600;
        }
    }
    .refer-a-friend-sharing-container{
        width: 277px;
        margin-top: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        p{
            color:${props => props.theme.gray3};
        }
    }
    .refer-a-friend-back-btn-container{
        margin-top: 60px;
        button{
            background: transparent;
            border: none;
            p{
                color: ${props => props.theme.gray5};
            }
        }
        &:hover{
            button{
                cursor: pointer;
                p{
                    color: ${props => props.theme.blue};
                }
            }
        }
    }
    @media (max-width: 500px){
        .refer-a-friend-background{
            height: 8vh;
            background-size: contain;
        }
        .refer-a-friend-heading{
            width: 100%;
        }
        .refer-a-friend-description-container{
            p{
                text-align: center;
            }
        }
    }
`

const SocialMediaShareIconWrapper = styled.div`
    width: 30px;
    height: 30px;
    position: relative;
    background-color: black;
    border-radius: 50%;
    svg{
        position: absolute;
        top: -1px;
        left: -1px;
    }
`

const socialMedia = [
    {
        link: API.externalLinks("brainsterNextFacebookPage"),
        icon: "icnFacebookCircled"
    },
    {
        link: API.externalLinks("brainsterNextLinkedInProfile"),
        icon: "icnLinkedInCircled"
    },
    {
        link: API.externalLinks("brainsterNextInstagramProfile"),
        icon: "icnInstagramCircled"
    }
]

//Social Media Share Icon component
const SocialMediaShareIcon = (props) => {
    return (
        <SocialMediaShareIconWrapper>
            <Icon icon={props.icon} />
        </SocialMediaShareIconWrapper>
    );
}

//component
const ReferAFriend = (props) => {
    const { t } = useTranslation();
    const { signedUser } = useContext(GlobalContext);
    return (
        <ReferAFriendWrapper>
            <div className="refer-a-friend-background"></div>
            <div className="refer-a-friend-heading">
                <SpecHeadingSmall>{t("refer_a_friend.heading")}</SpecHeadingSmall>
            </div>
            <div className="refer-a-friend-link-container">
                <CopyLinkInput
                    defaultValue={API.createRouteFromParts(process.env.REACT_APP_DOMAIN_URL, API.createRoute("registerWithReferral", { REFERRED_BY: signedUser.guid }))}
                    readOnly={true}
                    buttonText={t("general.copy_link")}
                />
            </div>
            {/* <div className="refer-a-friend-description-container">
                <Text10>{t('refer_a_friend.description')}</Text10>
            </div> */}
            <div className="refer-a-friend-sharing-container">
                <Text13>{t("refer_a_friend.share_your_link")}</Text13>
                {socialMedia.map((icon, index) => (
                    <a key={index} href={icon.link} target="_blank" rel="noopener noreferrer">
                        <SocialMediaShareIcon icon={icon.icon} />
                    </a>
                ))}
            </div>
            <div className="refer-a-friend-back-btn-container">
                <button onClick={() => window.history.back()}><CardCaption15>{t("general.take_me_back")}</CardCaption15></button>
            </div>

        </ReferAFriendWrapper>
    );
}

export default ReferAFriend;