import React from 'react';

//npm imports
import styled from 'styled-components';

// component imports
import { Text13 } from '../Text/Text';
import Icon from '../Icon/Icon';

const SocialMediaShareIconWrapper = styled.div`
    width: 173px;
    height: 56px;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 85px;
    background-color: ${props => props.theme.danger_select};
    &:hover{
        background-color: ${props => props.theme.black};
        p{
            color: ${props => props.theme.danger_select};
        }
        svg{
            path{
                fill: ${props => props.theme.danger_select};
            }
        }
    }
    @media (max-width: 1200px){
        width: 140px;
    }
    @media (max-width: 1000px){
        width: 110px;
        justify-content: center;
        p{
            display: none;
        }
    }
    @media (max-width: 500px){
        width: 84px;
    }
`

const SocialMediaShareButton = (props) => {
    return (
        <SocialMediaShareIconWrapper className={props.className} onClick={props.onClick}>
            <Text13 >{props.text}</Text13>
            <Icon icon={props.icon} pathFill={props.fill} />
        </SocialMediaShareIconWrapper>
    );
}

export default SocialMediaShareButton;