import React from 'react';

//imports
import styled from 'styled-components';
import Wrapper from '../../../Components/Wrapper/Wrapper';

//styled-components
const LessonArticleWrapper = styled(Wrapper)`
    width: 100%;
    display: block;
    padding: ${props => props.theme.spacer * 10}px 0px;
    transition: padding 0.2s ease;
    max-width: 762px;
    h1,h2,h3,h4,h5,p,strong,span,bold,blockquote,textarea{
        -webkit-user-select: text;  
        -moz-user-select: text;     
        -ms-user-select: text;      
        user-select: text;    
    }
    figcaption {
        text-align: center;
        font-size: 0.75rem;
        font-style: italic;
    }
    p{
        // word-break: keep-all;
        &>span{
            display: inline-block;
        }
    }
    @media (max-width: 1441px){
        padding-left: 140px;
        padding-right: 25px;

        blockquote{
            padding: ${props => props.theme.spacer * 11}px;
            border-left: 8px solid ${({ theme, studyField }) => theme[studyField] || theme.blue};
            background-color: ${props => props.theme.danger_select};
        }
        ul{
            margin: ${props => `${props.theme.spacer * 3}px 0px ${props.theme.spacer * 3}px ${props.theme.spacer}px`};
            li{
                display: grid;
                grid-template-columns: 0 1fr;
                column-gap: 70px;
                align-items: start;
                line-height: 1.25;
                margin-bottom: ${props => props.theme.spacer * 10}px;
                span{
                    font-size: 14px!important;
                }
            }
            li::before{
                content: "";
                width: 10px;
                height: 10px;
                background-color: ${({ theme, studyField }) => theme[studyField] || theme.blue};
                border-radius: 50%;
                margin-top: ${props => props.theme.spacer / 2}px;
            }
        }    
    }
    @media (max-width: 500px){
        font-size: 14px;
        line-height: 21px;
        padding: ${props => props.collapse ? "40px 43px 40px 59px" : "40px 43px 40px 109px"};
        color: ${props => props.theme.gray6}!important;
        ul{
            li{
                column-gap: 30px;
            }
        }
        blockquote{
            padding: ${props => props.theme.spacer * 5}px;
        }
    }
`

//component
const LessonArticle = (props) => {
    return (
        <LessonArticleWrapper dangerouslySetInnerHTML={{ __html: props.lessonContent }} collapse={props.collapse} studyField={props.studyField} />


    );
}

export default LessonArticle;