import React from 'react';

//npm imports
import styled, { withTheme } from 'styled-components';


//component imports
import { Caption12, SpecHeadingSmallest, Text13, Text10 } from '../Text/Text';


//other imports
import { brain_logo } from '../../Static/brain_logo';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import Wrapper from '../Wrapper/Wrapper';
import { API } from '../../Consts/Api';


const FooterWrapper = styled.footer`
    height: 400px;
    background: #302F38;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
    ${Wrapper}{
        
        section{
            width: 100%;
        }
        .footer-top-section{
            padding-bottom: 36px;
            border-bottom: 1px solid ${props => props.theme.darkBlack};
            display: flex;
            justify-content: space-between;
            .footer-top-left{
                .mobile-footer-top-right{
                    display: none;
                }
                .footer-heading{
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 20px;
                    }
                    .black-border{
                        width: 1px;
                        height: 40px;
                        background-color: ${props => props.theme.darkBlack};
                    }
                    ${SpecHeadingSmallest}{
                        max-width: 352px;
                        padding-left: 20px;
                    }
                }
                .footer-social{
                    margin-top: 110px;
                    .social-media-links{
                        margin-top: 14px;
                        a{
                            margin-right: 16px;
                        }
                    }
                }
            }
            .footer-top-right{
                width: 50%;
                display: flex;
                &>ul:last-child{
                    margin-left: 75px;
                }
                ul{
                    &>li:first-child{
                        margin-bottom: 40px;
                    }
                    li{
                        margin-bottom: 20px;
                    }
                }
            }
        }
        
        .footer-bottom-section{
            width: 100%;
            display: flex;
            padding-top: 13px;
            div{
                width: 50%;
                display: flex;
                &>p:nth-child(2){
                    margin-left: 10px;
                    border-left: 1px solid ${props => props.theme.white};
                    padding-left: 10px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        height: unset;
        ${Wrapper}{
            .footer-top-section{
                flex-direction: column;
                .footer-top-left{
                    .mobile-footer-top-right{
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        ul{
                            margin-top: 50px;
                            &>li:first-child{
                                margin-bottom: 40px;
                            }
                            li{
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .footer-social{
                        margin-top: 80px;
                    }
                }
                .footer-top-right{
                    display: none;
                }
            }
            .footer-bottom-section{
                flex-direction: column-reverse;
                div{
                    width: 100%;
                    display: flex;
                    &>p:nth-child(2){
                        margin-bottom: 60px;
                    }
                }
            }
        }
    }
`

const Footer = (props) => {

    const { t } = useTranslation();

    const resources = [
        {
            text: t("footer.detailed.resources.brainster_blog"),
            link: API.externalLinks("brainsterBlog")
        },
        {
            text: t("footer.detailed.resources.media_blog"),
            link: API.externalLinks("mediaLanding")
        },
        {
            text: t("footer.detailed.resources.career_quiz"),
            link: API.externalLinks("quiz")
        }
    ]

    const formalEducation = [
        {
            text: t("footer.detailed.formal_education.brainster_next"),
            link: API.externalLinks("nextLanding")
        }
    ]

    return (
        <FooterWrapper>
            <Wrapper>

                <section className='footer-top-section'>
                    <div className="footer-top-left">
                        <div className="footer-heading">
                            {brain_logo}
                            <div className='black-border'></div>
                            <SpecHeadingSmallest color={props.theme.white}>{t("footer.detailed.heading")}</SpecHeadingSmallest>
                        </div>
                        <div className="mobile-footer-top-right">
                            <ul>
                                <li>
                                    <Caption12 color={props.theme.white}>{t("footer.detailed.resources.header")}</Caption12>
                                </li>
                                {resources.map((resource, index) => (
                                    <li key={index}>
                                        <a href={resource.link}><Text13 color={props.theme.white}>{resource.text}</Text13></a>
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                <li>
                                    <Caption12 color={props.theme.white}>{t("footer.detailed.formal_education.header")}</Caption12>
                                </li>
                                {formalEducation.map((school, index) => (
                                    <li key={index}>
                                        <a href={school.link}><Text13 color={props.theme.white}>{school.text}</Text13></a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="footer-social">
                            <Text10 color={props.theme.white}>{t("footer.detailed.social")}</Text10>
                            <div className="social-media-links">
                                <a href={API.externalLinks("brainsterNextFacebookPage")} target="_blank" rel="noopener noreferrer">
                                    <Icon icon="icnFacebookCircled" />
                                </a>
                                <a href={API.externalLinks("brainsterNextInstagramProfile")} target="_blank" rel="noopener noreferrer">
                                    <Icon icon="icnInstagramCircled" />
                                </a>
                                <a href={API.externalLinks("brainsterNextLinkedInProfile")} target="_blank" rel="noopener noreferrer">
                                    <Icon icon="icnLinkedInCircled" />
                                </a>
                                <a href={API.externalLinks("brainsterYouTubeChannel")} target="_blank" rel="noopener noreferrer">
                                    <Icon icon="icnYouTubeCircled" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-top-right">
                        <ul>
                            <li>
                                <Caption12 color={props.theme.white}>{t("footer.detailed.resources.header")}</Caption12>
                            </li>
                            {resources.map((resource, index) => (
                                <li key={index}>
                                    <a href={resource.link} target="_blank" rel="noopener noreferrer"><Text13 color={props.theme.white}>{resource.text}</Text13></a>
                                </li>
                            ))}
                        </ul>
                        <ul>
                            <li>
                                <Caption12 color={props.theme.white}>{t("footer.detailed.formal_education.header")}</Caption12>
                            </li>
                            {formalEducation.map((school, index) => (
                                <li key={index}>
                                    <a href={school.link} target="_blank" rel="noopener noreferrer"><Text13 color={props.theme.white}>{school.text}</Text13></a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
                <section className="footer-bottom-section">
                    <div>
                        <Text10 color={props.theme.white}>&copy; Brainster 2021. Designed with love.</Text10>
                    </div>
                    <div>
                        <Text10 color={props.theme.white}>Privacy Policy</Text10>
                        <Text10 color={props.theme.white}>Terms of Service</Text10>
                    </div>
                </section>
            </Wrapper>
        </FooterWrapper>
    )
}

export default withTheme(Footer);
