import { navigate } from '@reach/router';
import { API } from '../Consts/Api';

export const redirectSignedUserToCatalogue = (signedUser, setSignedUser) => {
    //if user is already logged in, redirect him to catalogue page,
    //since he shouldn't be able to login again if already logged in
    if (Object.keys(signedUser).length !== 0) {
        //need to check the localStorage too, since there is no mechanism to update
        //the Context from the API.jsx where we handle the logout response from backend
        let localStorageUserData = JSON.parse(localStorage.getItem('user') || "[]");
        if (Object.keys(localStorageUserData).length !== 0) {
            navigate(API.createRoute('catalogue'));
        } else {
            setSignedUser({});
        }
    }
}