import React, { useState, useEffect } from 'react';

//imports
import styled from 'styled-components';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

//styled-components
const ProgressBarContainer = styled.div`
    width: ${props => props.width}px;
    align-self: center;
    :hover{
        cursor: pointer;
    }
`

const ProgressBar = styled(CircularProgressbarWithChildren)`
    width: 100%;
    text {
        font-weight: 800;
    }
    //priotity is given to props.percentage as it means the component is clickable and it's already been clicked, so we don't want it do dissapear after
    //if  it is not clicked and props.hoverable is false, we want it visible all the time
    //if props hoverable is true, then we want it hidden except when hovered
    visibility: ${props => props.percentage === 100 || !props.hoverable ? "visible" : (props.hovered ? "visible" : "hidden")};
    
`
//component

const CircularProgressBar = (props) => {
    //percentage hook is used to display progress calculated in percentage
    const [percentage, setPercentage] = useState(0);
    //hovered hook is used to either make CircularProgressbar component visible or hidden
    //it is needed in well-done/course route
    //it depends on hoverable prop that must be passed as a boolean upon invoking the component
    const [hovered, setHovered] = useState(false);

    //cycle which checks if percentage is passed as a prop and then sets percentage hook to equals the props.percentage
    //NOTE* pass percentage prop if hoverable prop is set to false
    //if hoverable prop is set to true, you can either pass percentage prop as 0 or not pass it at all
    //EXAMPLE: hoverable prop would be false, and percentage prop would be sent as fixed integer in MyCourses 
    //component where we only want to display fixed progress for given user from backend
    useEffect(() => {
        props.percentage && setPercentage(props.percentage)
    }, [props.percentage])
    return (
        <ProgressBarContainer
            //if props.children is true, means we have passed icon to the component and it's used in /well-done/course route, then we want click handler and use it as a loader
            onClick={() => props.children && setPercentage(100)}
            // and ofcourse if component is clickable, we want it to toggle hovered hook on mouse enter and mouse leave
            // this is used here because CircularProgressbar npm does not support event handlers
            onMouseEnter={() => props.children && setHovered(true)}
            onMouseLeave={() => props.children && setHovered(false)}
            //must receive width as an integer prop
            width={props.width}
        >
            <ProgressBar
                hoverable={props.children}
                percentage={percentage}
                hovered={hovered}
                value={percentage}
                strokeWidth={props.strokeWidth && props.strokeWidth}
                trailWidth={props.trailWidth}
                //if props.children is false, it means it's used in MyCourses page and we want to display the progress percentage of users progress in a given course or learning path
                text={!props.children && `${percentage}%`}
                circleRatio={props.dashboard && 0.5}
                styles={buildStyles({
                    //these props must be received
                    strokeLinecap: props.strokeLinecap,
                    pathTransitionDuration: props.pathTransitionDuration,
                    pathColor: props.color,
                    textColor: props.color,
                    textSize: props.textSize,
                    fontWeight: 900,
                    trailColor: props.trailColor,
                    rotation: props.rotation
                })}

            >
                {/* NOTE THAT ICONS AS CHILDREN MIGHT HAVE TO BE STYLED ADDITIONALLY FOR DIMENSIONS AND POSITION, ALSO COMPONENT IS NOT TESTED FOR OTHER ELEMENTS AS CHILDREN EXCEPT <img src={icon}/> */}
                {props.children}
            </ProgressBar>
        </ProgressBarContainer>
    );
}

export default CircularProgressBar;