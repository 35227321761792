import React, { useEffect } from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import PageWrapper from '../../Components/PageWrapper/PageWrapper';
import CareerQuiz from './Blocks/CareerQuiz/CareerQuiz';
import HomepageHeader from './Blocks/HomepageHeader/HomepageHeader';
import LifelongLearning from './Blocks/LifelongLearning/LifelongLearning';
import Footer from '../../Components/Footer/Footer';
import LearningSystem from './Blocks/LearningSystem/LearningSystem';

//other imports
import { redirectSignedUserToCatalogue } from '../../Functions/redirectSignedUserToCatalogue';
import { useContext } from 'react';
import { GlobalContext } from '../../Context/GlobalContext';
import NewestContent from './Blocks/NewestContent/NewestContent';

const HomePageWrapper = styled(PageWrapper)`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const HomePage = () => {
    const { signedUser, setSignedUser } = useContext(GlobalContext);
    useEffect(() => {
        redirectSignedUserToCatalogue(signedUser, setSignedUser)
    }, [signedUser, setSignedUser]);
    return (
        <HomePageWrapper>
            <HomepageHeader />
            <LifelongLearning />
            <CareerQuiz />
            <LearningSystem />
            <Footer />
        </HomePageWrapper>
    );
}

export default HomePage;