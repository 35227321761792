import React, { useState, useEffect, useContext, useCallback } from "react";

//imports
import styled, { withTheme } from "styled-components";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";
import InputFieldError from "../../Components/InputField/InputFieldError";
import InputField from "../../Components/InputField/InputField";
import { API } from "../../Consts/Api";
import { navigate } from "@reach/router";
import { logo } from "../../Static/logo";
import { illustration } from "../../Static/illustrations/PublicFacing/signinRegisterIllustration/signinRegisterIllustration";
import { illustrationMobile } from "../../Static/illustrations/PublicFacing/signinRegisterIllustration/signin_register_illustration_mobile";

import "../../Static/illustrations/PublicFacing/signinRegisterIllustration/signinRegisterIllustration.css";
import { Caption14, Text16 } from "../../Components/Text/Text";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { Link } from "@reach/router";
import { Text13, Heading20 } from "../../Components/Text/Text";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../Context/GlobalContext";
import PublicFacingPageWrapper from "../../Components/PublicFacingPageWrapper/PublicFacingPageWrapper";
import { redirectSignedUserToCatalogue } from "../../Functions/redirectSignedUserToCatalogue";
import AllRulesPopup from "../../Components/AllRulesPopup/AllRulesPopup";
import { useMediaQuery } from "react-responsive";
// Temporary changes for Brainster plus
import UpdateMessage from "../../Components/UpdateMessage/UpdateMessage";

//styled-components

const RegisterHeadline = styled.div`
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%) !important;
  padding-top: 10vh;

  div.illustration-container svg {
    display: block;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 8%;
    position: static;
    transform: translate(0, 0);
    height: unset;
  }
  ${Text16} {
    width: 50%;
    color: ${(props) => props.theme.white};
    font-size: 27px;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: -40px;
  }
  &&&&&& {
    @media (min-width: 424px) and (max-width: 768px) {
      padding: 34px 0 0 0;
      height: 11vh;
      position: static;
      transform: translate(0px, 0px) !important;
      .logo-container {
        margin-top: 50px;
      }
      div.illustration-container svg {
        display: block;
      }
      ${Text16} {
        width: 80%;
      }
    }
  }

  &&&&&& {
    @media (max-width: 424px) {
      padding: 0;
      height: 11vh;
      position: static;
      transform: translate(0px, 0px) !important;
      div.illustration-container svg {
        display: block;
        width: 90%;
      }
    }
  }
`;

const RegisterForm = styled.form`
  .desktop {
    display: none;
  }
  .mobile-hide {
    display: block;
  }
  .form-container {
    div.form-headline-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    ${Heading20} {
      font-size: 20px;
      font-family: "Raleway", sans-serif;
      font-style: normal;
      font-weight: 800;
      line-height: 23px;
    }
    .terms-of-use {
      margin-top: ${(props) => props.theme.spacer * 2}px;
    }
  }
  &&&&&& {
    @media (max-width: 768px) {
      margin-top: 180px;
      z-index: 1;
      .desktop {
        display: block;
      }
      .mobile-hide {
        display: none;
      }
      div.form-headline-container {
        display: block;
        text-align: center;
      }
    }
  }
`;

// const SocialMediaContainer = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: ${props => props.theme.spacer * 17}px;
//     &>div{
//         width: 48%;
//     }
//     @media (max-height: 770px){
//         margin-bottom: ${props => props.theme.spacer * 10}px;
//     }
//     @media (max-width: 768px){
//         margin-bottom: 0px;
//     }
// `

const InputContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  .names-container {
    width: 100%;
    display: flex;
    & > div:first-child {
      margin-right: 10px;
    }
    & > div {
      width: 50%;
    }
  }
  input {
    &:focus {
      border: 2px solid ${(props) => props.theme.blue};
    }
  }
  @media (max-width: 1141px) {
    .names-container {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    .names-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > div:first-child {
        margin-right: 0px;
      }
    }
  }
  @media (max-width: 500px) {
    &.first-input {
      margin-top: 70px;
    }
    margin-top: 25px;
  }
  @media (max-height: 820px) {
    margin-top: ${(props) => props.theme.spacer * 4}px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 60px;

  width: 100%;
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: ${(props) => props.theme.spacer * 4}px 0;
  }
  @media (max-height: 770px) {
    margin-top: ${(props) => props.theme.spacer * 7}px;
    margin-bottom: ${(props) => props.theme.spacer * 5}px;
  }
`;

// const socialMediaRegistrators = [
//     {
//         id: "facebook",
//         text: "Facebook",
//         icon: "icnFacebook",
//         link: "https://www.facebook.com/sharer/sharer.php?u={URL}&hashtag=%23brainster"
//     },
//     {
//         id: "google",
//         text: "Google",
//         icon: "icnGoogle",
//         link: "https://www.facebook.com/sharer/sharer.php?u={URL}&hashtag=%23brainster"
//     },
// ]

//component
const Register = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const resetErrors = {
    name: [],
    email: [],
    phone: [],
    password: [],
    termsOfUse: null,
  };

  const { signedUser, setSignedUser } = useContext(GlobalContext);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [errors, setErrors] = useState(resetErrors);
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(true);
  const [showInfoMessage, setShowInfoMessage] = useState({});

  const [showAllRulesPopup, setShowAllRulesPopup] = useState({
    display: false,
    privacy: false,
  });

  useEffect(() => {
    if (props.referredBy) {
      setUser({ ...user, referred_by: props.referredBy });
    }
    //eslint-disable-next-line
  }, [props.referredBy]);

  const registerStudent = useCallback(
    (e) => {
      e.preventDefault();
      let errrors = {
        name: [],
        password: [],
        email: [],
        phone: [],
      };
      e.preventDefault();
      if (
        user.name !== "" &&
        user.email !== "" &&
        user.phone !== "" &&
        user.password !== "" &&
        user.year !== null
      ) {
        if (termsOfUseChecked) {
          setErrors({ ...resetErrors, termsOfUse: true });
          API.axios.get(API.createApiRoute("csrf")).then((response) => {
            API.axios
              .post(API.createApiRoute("studentRegister"), user)
              .then((response) => {
                if (response.status === 200) {
                  //remove inputted data for security reasons
                  setUser({});
                  //store signed user data in global context
                  localStorage.setItem("user", JSON.stringify(response.data));
                  setSignedUser(response.data);
                  navigate("/register/steps");
                }
              })
              .catch((error) => {
                if (error.response.status === 422) {
                  setErrors({ ...resetErrors, ...error.response.data.errors });
                  // Temporary changes for Brainster plus
                  setShowInfoMessage({
                    info: t("messages.update_successful"),
                  });
                } else {
                  setErrors({
                    ...errors,
                    additional: "An error occured. Please try again later.",
                  });
                }
              });
          });
        } else {
          setErrors({ ...errors, termsOfUse: false });
        }
      } else {
        if (user.name === "") {
          errrors = { ...errrors, name: [t("errors.register.name")] };
        }
        if (user.email === "") {
          errrors = { ...errrors, email: [t("errors.register.email")] };
        }
        if (user.phone === "") {
          errrors = { ...errrors, phone: [t("errors.register.phone")] };
        }
        if (user.password === "") {
          errrors = { ...errrors, password: [t("errors.register.password")] };
        }
        setErrors(errrors);
      }
    },
    [errors, resetErrors, setSignedUser, t, termsOfUseChecked, user]
  );

  // const selectYearMethod = (year_of_birth) => {
  //     setSelectedYearOfBirth(year_of_birth);
  //     setErrors({ ...errors, year: [] });
  // }

  const submitOnEnter = useCallback(
    (e) => {
      //it triggers by pressing the enter key
      if (e.keyCode === 13) {
        registerStudent(e);
      }
    },
    [registerStudent]
  );

  useEffect(() => {
    window.addEventListener("keydown", submitOnEnter);

    return () => {
      window.removeEventListener("keydown", submitOnEnter);
    };
  }, [submitOnEnter]);

  return (
    <PublicFacingPageWrapper backgroundColor={props.theme.black}>
      <AllRulesPopup
        showAllRulesPopup={showAllRulesPopup}
        setShowAllRulesPopup={setShowAllRulesPopup}
      />
      <RegisterHeadline className="headline-container">
        <div className="illustration-container">
          {isMobile ? illustrationMobile : illustration}
        </div>
      </RegisterHeadline>
      <RegisterForm onSubmit={(e) => registerStudent(e)}>
        <div className="form-container">
          <div className="form-headline-container">
            <Heading20 className="form-headline-text">
              {t("register.register")}
            </Heading20>
            <Link className="already-registered mobile-hide" to="/signin">
              <Text13>
                {`${t("register.already_have_profile")}? `}
                <strong>{t("general.sign_in")}</strong>
              </Text13>
            </Link>
          </div>
          <InputFieldError error={errors.additional || ""} />
          <InputContainer className="first-input">
            <InputField
              name="name"
              defaultValue={user.name}
              placeholder={t("general.full_name_placeholder")}
              inputLabel={t("general.full_name")}
              type="text"
              onChange={(e) => {
                setUser({ ...user, name: e.target.value });
                setErrors({ ...errors, name: [] });
              }}
              focusColor={(props) => props.theme.blue}
              required={true}
            />
            <InputFieldError error={errors.name[0]} />
          </InputContainer>
          <InputContainer>
            <InputField
              name="email"
              defaultValue={user.email}
              placeholder={t("general.email_placeholder")}
              inputLabel={t("general.email")}
              type="text"
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
                setErrors({ ...errors, email: [] });
              }}
              focusColor={(props) => props.theme.blue}
              required={true}
            />
            <InputFieldError error={errors.email[0]} />
          </InputContainer>

          <InputContainer>
            <InputField
              name="phone"
              defaultValue={user.phone}
              placeholder={t("general.phone_number_placeholder")}
              inputLabel={t("general.phone_number")}
              type="number"
              onChange={(e) => {
                setUser({ ...user, phone: e.target.value });
                setErrors({ ...errors, phone: [] });
              }}
              focusColor={(props) => props.theme.blue}
              required={true}
            />
            <InputFieldError error={errors.phone[0]} />
          </InputContainer>

          <InputContainer>
            <InputField
              name="password"
              defaultValue={user.password}
              placeholder={t("general.password_placeholder")}
              inputLabel={t("general.password")}
              type="password"
              autocomplete="new-password"
              onChange={(e) => {
                setUser({ ...user, password: e.target.value });
                setErrors({ ...errors, password: [] });
              }}
              focusColor={(props) => props.theme.blue}
              required={true}
            />
            <InputFieldError error={errors.password[0]} />
          </InputContainer>
          <Checkbox
            text={`${t("general.i_accept")} `}
            className="terms-of-use"
            checked={termsOfUseChecked}
            error={errors.termsOfUse === false}
            onClick={() => setTermsOfUseChecked(!termsOfUseChecked)}
            link={{
              text: t("general.terms_of_use"),
              onClick: () =>
                setShowAllRulesPopup({
                  ...showAllRulesPopup,
                  display: true,
                  privacy: true,
                }),
            }}
          />
          <ButtonContainer>
            {/* ---------------------------- */}
            {/* Temporary changes for Brainster plus */}
            <UpdateMessage show={showInfoMessage} />
            {/* ---------------------------- */}
            <ButtonBasic
              text={t("register.register")}
              variation="formBtn"
              type="submit"
            />
          </ButtonContainer>
          {/* <Caption14 className="or-register-via">{t("register.or_register_via")}</Caption14> */}
          {/* <SocialMediaContainer>

                        {socialMediaRegistrators.map((media, index) => (
                            <SocialMediaShareButton key={index} className={`${media.id}-button`} text={media.text} icon={media.icon} />
                        ))}
                    </SocialMediaContainer> */}
          <Link className="already-registered desktop" to="/signin">
            <Text13>
              {`${t("register.already_have_profile")}? `}
              <strong>{t("general.sign_in")}</strong>
            </Text13>
          </Link>
        </div>
      </RegisterForm>
    </PublicFacingPageWrapper>
  );
};

export default withTheme(Register);
