import React from 'react';

//imports
import styled, { withTheme } from 'styled-components';
import { Caption16 } from '../../Text/Text';
import Icon from '../../Icon/Icon';
import { useTranslation } from 'react-i18next';

const PopularSearchTerms = [
    {
        value: "javaScript"
    },
    {
        value: "photoshop"
    },
    {
        value: "programming"
    },
    {
        value: "php"
    },
    {
        value: "reac"
    },
    {
        value: "illustrator"
    },
    {
        value: "adobe"
    },
    {
        value: "marketing"
    },
    {
        value: "ux/ui"
    },
    {
        value: "coding"
    },
    {
        value: "AI"
    },
    {
        value: "QA"
    },
    {
        value: "indesign"
    },
    {
        value: "software"
    },
    {
        value: "web"
    },
    {
        value: "figma"
    },
    {
        value: "learning path"
    },
    {
        value: "course"
    },

]
//styled-components
const PopularSearchesWrapper = styled.div`
    width: 100%;
    display: flex;
`

const PopularSearchesList = styled.ul`
    width: 100%;
    padding: 8px 8px 0;
    list-style: none;
    p{
        text-transform: none;
        font-weight: 700;
        color: ${props => props.theme.gray3};
        margin-bottom: 10px;
    }
`

const PopularSearchItem = styled.li`
    display: inline-block;
    margin: 8px 8px 8px 0;
    &>span{
        
        border: 1px solid transparent;
        &:hover{
            border: 1px solid ${props => props.theme.blue};
        }
    }
    @media (max-width: 500px){

        &>span{
            font-size: 16px;
        }
    }
`

const PopularSearchSpan = styled.span`
    padding: 13px 22px;
    cursor: pointer;
    font-size: ${props => props.theme.normalText};
    color: ${props => props.theme.lightBlack};
    font-weight: 400;
    background-color: #F3F3F5;
    border-radius: 30px;
    box-shadow: ${props => props.theme.boxShadow1};
    display: flex;
    align-items: center;
    &>svg{
        margin-left: 50px;
    }
    
    @media (max-width: 500px){
        padding: 7px 11px;
        &>svg{
            margin-left: 10px;
        }
    }
`


//component
const PopularSearches = (props) => {
    const { t } = useTranslation()
    return (
        <PopularSearchesWrapper>
            <PopularSearchesList>
                <Caption16>{t('catalogue.search_bar.popular_searches')}</Caption16>
                {PopularSearchTerms.map((popularSearchTerm, i) => {
                    return (
                        <PopularSearchItem key={i}>
                            <PopularSearchSpan onClick={() => props.applyPopularSearch(popularSearchTerm.value)}>{popularSearchTerm.value}  <Icon icon="icnSearch" fill={props.theme.gray4} width={14} /></PopularSearchSpan>

                        </PopularSearchItem>
                    )
                })}
            </PopularSearchesList>
        </PopularSearchesWrapper>
    );
}

export default withTheme(PopularSearches);