import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";

//imports
import styled, { keyframes, withTheme } from "styled-components";
import { Link } from "@reach/router";
import "./style/SearchBar.css";
import { Heading28, MenuCapture, Text10, Text13 } from "../Text/Text";
import iconComputerScience from "../../Static/icn-computer-science.svg";
import Checkbox from "../Checkbox/Checkbox";
import iconDataScience from "../../Static/icn-data-science.svg";
import iconMarketing from "../../Static/icn-marketing.svg";
import iconDesign from "../../Static/icn-design.svg";
import Icon from "../Icon/Icon";
import LearningPathCard from "../LearningPathCard/LearningPathCard";
import CourseCard from "../CourseCard/CourseCard";
import PopularSearches from "./subComponents/PopularSearches";
import Wrapper from "../Wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import { theme } from "styled-tools";

// Animation
const iconXInitial = keyframes`
    from{
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(-310%, 0%);
    }
`;

const iconX = keyframes`
    from {
        transform: translate(-310%, 0%);
    }
    to {
        transform: translate(0%, 0%);
    }
`;

const iconSearchRightInitial = keyframes`
    from{
        transform: translate(250%, 0%);
    }
    to{
        transform: translate(0%, 0%);
    }
`;

const iconSearchRight = keyframes`
    from {
        transform: translate(0%, 0%);
    }
    to {
        transform: translate(250%, 0%);
    }
`;

const iconSearchLeftInitial = keyframes`
    from {
        transform: translate(0%, 0%);
    }
    to{
        transform: translate(-250%, 0%);
    }
`;

const iconSearchLeft = keyframes`
    from {
        transform: translate(-250%, 0%);
    }
    to {
        transform: translate(0%, 0%);
    }
`;

//styled-components
const SearchBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) =>
    props.isAlignedToTopSearchBar ? "transparent" : props.theme.gray0};
  margin-top: 45px;
  z-index: ${(props) => props.isAlignedToTopSearchBar && "9"};
  position: ${(props) => props.isAlignedToTopSearchBar && "relative"};
  &:hover {
    cursor: ${(props) => props.isScrolledSearchBar && "pointer"};
  }
  @media (max-width: 500px) {
    margin-top: 26px;
  }
`;

const SearchBarWrapper = styled.div`
  padding: 42px 0 26px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.background};
  box-shadow: ${(props) => props.background && ""};
  z-index: 9;
  @media (max-width: 500px) {
    padding: 22px 0 0 0;
  }
`;

const SearchWrapper = styled.div`
  width: 75%;
  height: ${(props) => (props.show ? "0px" : "30px")};
  color: black;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  transition: height 0.4s ease;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SearchBarFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  div,
  input,
  h4 {
    transition: height 0.4s ease, padding 0.1s linear;
  }
  & > h4 {
    height: ${(props) => (props.show ? "0" : "30")}px;
    margin: ${(props) => props.show && "0px!important"};
    margin-bottom: 27px;
    transition: margin 0.2 ease;
    overflow: hidden;
  }
  @media (max-width: 500px) {
    & > h4 {
      height: ${(props) => (props.show ? "0px" : "unset")};
      font-size: 16px;
      line-height: unset;
      margin-bottom: 15px;
    }
  }
`;

const SearchIconRight = styled.div`
  position: absolute;
  width: 39px;
  height: ${(props) => (props.show ? "0px" : "38px")};
  overflow: hidden;
  transition: height 0.4s ease;
  right: 0px;
  top: 0px;
  z-index: 999;
  .icon {
    position: absolute;
    top: 10px;
    left: 0px;
  }
  .search-icon-right {
    animation: ${iconSearchRightInitial} 0.1s linear forwards;
  }
  .iconX {
    transform: translateX(-310%);
    transition-delay: 0.1s;
    top: 7px;
    animation: ${iconXInitial} 0.1s linear forwards;
    :hover {
      cursor: pointer;
    }
  }

  .searchIconRight {
    animation: ${iconSearchRight} 0.1s linear forwards;
  }

  .xIconAnimatedForwards {
    opacity: ${(props) => (props.dropDown ? "1" : "0")};
    animation: ${iconX} 0.2s linear forwards;
  }
  @media (max-width: 500px) {
    height: ${(props) => (props.show ? "0px" : "32px")};
    .iconX {
      top: 4px;
    }
    .search-icon-right {
      top: 7px;
    }
  }
`;

const SearchIconLeft = styled.div`
  position: absolute;
  width: 39px;
  height: ${(props) => (props.show ? "0px" : "38px")};
  overflow: hidden;
  transition: height 0.4s ease;
  left: 0px;
  top: 0px;
  z-index: 999;

  .icon {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .search-icon-left-base {
    animation: ${iconSearchLeftInitial} 0.1s linear forwards;
  }
  .search-icon-left {
    animation-name: ${iconSearchLeft};
  }
  @media (max-width: 500px) {
    height: ${(props) => (props.show ? "0px" : "32px")};
    .icon {
      top: 7px;
    }
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: ${(props) =>
    props.show
      ? "none"
      : props.dropDown
      ? `1px solid ${props.theme.blue}`
      : `1px solid ${props.theme.gray3}`};
  border-radius: 25px;
  height: ${(props) => (props.show ? "0px" : "38px")};
  overflow: hidden;
  padding: ${(props) => (props.dropDown ? "0 30px 0 40px" : "0px 30px 0 24px")};
  background-color: white;
  transition: height 0.05s ease;
  color: #234bdb;
  z-index: 300;
  ::placeholder {
    color: ${(props) => (props.show ? "transparent" : "#B9BBC4")};
  }
  :focus,
  :active,
  :visited {
    outline: none;
  }
  @media (max-width: 500px) {
    height: ${(props) => (props.show ? "0px" : "32px")};
  }
`;

const SearchDropdown = styled.div`
  width: 100%;
  position: absolute;
  top: 22px;
  padding-bottom: ${(props) => (props.dropDown ? "15px" : "0px")};
  transition: padding 0.05s;
  z-index: 9;
  border: ${(props) => (props.dropDown ? "1px solid #46415C" : "none")};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: white;
  & > div {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: ${(props) => (props.dropDown ? "40px" : "0px")};
    padding-bottom: ${(props) => (props.dropDown ? "18px" : "0px")};
    max-height: ${(props) => (props.dropDown ? "742px" : "0px")};
    transition: padding 0.05s, max-height 0.2s;
    overflow-y: scroll;
    width: 100%;
  }

  .search-title {
    font-size: 11px;
    letter-spacing: 1px;
    color: ${(props) => props.theme.gray6};
  }
  @media (max-width: 1141px) {
    & > div {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (max-width: 500px) {
    overflow-y: unset;
    overflow: hidden;
    max-height: ${(props) => (props.dropDown ? "350px" : "0px")};
    padding: 0;
    & > div {
      max-height: ${(props) => (props.dropDown ? "330px" : "0px")};
      padding: ${(props) => (props.dropDown ? "15px 5px 15px 10px" : "0")};
      overflow-y: ${(props) => (props.dropDown ? "scroll" : "hidden")};
      ul {
        padding: 0;
      }
    }
  }
`;

const LearningPathsSearchResultWrapper = styled.div`
  width: 80%;
  padding: 15px;
  @media (max-width: 500px) {
    width: 100%;
    p {
      margin-bottom: 11px !important;
    }
  }
`;

const CoursesSearchResultWrapper = styled.div`
  width: 100%;
  padding: 15px;
  @media (max-width: 500px) {
    p {
      margin-bottom: 11px !important;
    }
  }
`;

const CoursesSearchResultCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 1141px) {
    height: max-content;
  }
`;

const CourseSearchResultCardWrapper = styled.div`
  width: 31%;
  margin-right: 15px;
  margin-bottom: 15px;
  @media (max-width: 1141px) {
    width: 44%;
  }
`;

const LinksAndFiltersContainer = styled.div`
  width: 100%;
  padding: 50px 0 0 0;
  display: flex;
  flex-direction: row;

  div.divider {
    width: 1px;
    height: 92px;
    background-color: #e1e2e7;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    padding: 20px 0 0 0;

    div.divider {
      display: none;
    }
  }
`;

const LinksContainer = styled.div`
  width: 80%;
  display: flex;
  @media (max-width: 500px) {
    width: 100%;
    border-right: none;
  }
`;
const IconWrap = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.gray3};
  padding: 5px 11px;
  border-radius: 21px;
  margin-bottom: 14px;

  &:hover {
    background-color: ${(props) => props.categoryColor && props.categoryColor};
  }

  @media (max-width: 500px) {
    margin-bottom: 0;
    &:hover {
      background-color: ${(props) => props.theme.gray3};
    }
  }
`;

const LinkIcon = styled.li`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.1s;
  padding-bottom: ${(props) => (props.isScrolledSection ? "20px" : 0)};

  border-bottom: 4px solid transparent;
  :hover {
    cursor: pointer;
  }
  ${MenuCapture} {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.54px;
    color: ${(props) => props.theme.black};
  }
  &:hover ${MenuCapture} {
    font-weight: 800 !important;
    color: ${(props) => props.theme.black} !important;
  }
  &:hover ${IconWrap} {
    background-color: ${(props) => props.categoryColor && props.categoryColor};
  }
  &.active {
    ${IconWrap} {
      background-color: ${(props) =>
        props.categoryColor && props.categoryColor};
    }
    ${MenuCapture} {
      font-weight: 800 !important;
      color: ${(props) => props.theme.black} !important;
    }
    border-color: ${(props) =>
      props.categoryColor && props.categoryColor} !important;
  }
  h4 {
    letter-spacing: 0.5px;
    font-weight: 600;
  }

  @media (max-width: 1141px) {
    max-height: ${(props) => (props.show ? "150px" : "0px")};
    align-items: center;
    height: unset;
    ${MenuCapture} {
      display: none;
    }
  }
  @media (max-width: 500px) {
    width: 25%;
    padding-bottom: 15px;
    &:hover ${IconWrap} {
      background-color: ${(props) => props.theme.gray3};
    }
    &.active {
      ${IconWrap} {
        background-color: ${(props) =>
          props.categoryColor && props.categoryColor};
      }
    }

    .computer-science-icon {
      width: 100%;
    }

    .data-science-icon {
      width: 100%;
    }

    .graphic-design-icon {
      width: 100%;
    }

    .digital-marketing-icon {
      width: 100%;
    }
  }
`;

const ImgIcon = styled.img``;

const FilterButtons = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  @media (max-width: 1150px) {
    padding-right: 0px;
  }
  @media (max-width: 500px) {
    padding-right: 0px;
    align-items: center;
  }
`;

const FiltersContainer = styled.form`
  width: 24%;
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .desktop-filterby {
    height: ${(props) => (props.show ? "0px" : "35px")};
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const MobileFiltersWrapper = styled.div`
  width: 100%;
  display: none;
  background-color: ${(props) =>
    props.isAlignedToTopSearchBar ? "transparent" : "white"};
  position: ${(props) => props.isAlignedToTopSearchBar && "fixed"};
  top: ${(props) =>
    props.isScrolledSearchBar
      ? "75px"
      : props.isAlignedToTopSearchBar && "125px"};
  right: ${(props) => props.isAlignedToTopSearchBar && "0px"};
  transition: top 0.4s;
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 18px 18px 0 0;
  }
`;

const MobileFiltersContainer = styled.form`
  display: none;
  width: max-content;
  @media (max-width: 500px) {
    p {
      font-size: 10px;
      margin: 2px 0 0 5px;
      line-height: 11px;
      width: max-content;
    }
    .mobile-filterby {
      color: ${(props) => props.theme.blue};
    }
    .see-all-mobile {
      height: 12px !important;
      border-bottom: 0.538186px solid #302f38;
    }
    width: ${(props) => (props.openFilters ? "100%" : "max-content")};
    float: right;
    display: flex;
    justify-content: flex-end;
    label > div {
      width: 10px;
      height: 10px;
      border: 0.538186px solid ${(props) => props.theme.black};
    }

    & > div:last-child {
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
`;

const MobileFiltersOptions = styled.div`
  background-color: ${(props) => props.theme.gray0};
  width: ${(props) => (props.openFilters ? "100%" : "0px")};
  overflow: hidden;
  transition: width 0.25s ease;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

const MobileFiltersTogglerContainer = styled.div`
  border-radius: ${(props) => (!props.openFilters ? "0 30px 30px 0" : "50%")};
  background-color: ${(props) => props.theme.gray0};
  padding: 15px;
  transition: border-radius 0.07s ease;
`;

const CheckboxContainer = styled.div`
  .mobile-checkbox {
    opacity: ${(props) => (props.openFilters ? "1" : "0")};
    transition: opacity 0.4s ease;
  }

  overflow: hidden;
  display: flex;
  align-items: center;
  &:hover ${Text13} {
    cursor: pointer;
  }
`;

const FilterIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchBar = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [learningPathChecked, setLearningPathChecked] = useState(false);
  const [courseChecked, setCourseChecked] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [learningPathsSearchResult, setLearningPathsSearchResult] = useState(
    []
  );
  const [coursesSearchResult, setCoursesSearchResult] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [filtersBorderRadius, setFiltersBorderRadius] = useState(true);
  const [activeSection, setActiveSection] = useState("");
  const searchInputRef = useRef();
  const dropDownRef = useRef();

  const searchTitleRef = useRef();
  const searchWrapperRef = useRef();
  const computerScienceRef = useRef();
  const dataScienceRef = useRef();
  const graphicDesignRef = useRef();
  const digitalMarketingRef = useRef();

  const xIconRef = useRef();
  const xIconContainerRef = useRef();

  useEffect(() => {
    let currentRef = "";
    if (props?.refs && props?.refs[0] === computerScienceRef) {
      currentRef = props?.refs[0];
    } else if (props?.refs && props?.refs[1] === dataScienceRef){
      currentRef = props?.refs[1];
    }  else if (props?.refs && props?.refs[2] === digitalMarketingRef){
      currentRef = props?.refs[2];
    } else if (props?.refs && props?.refs[3] === graphicDesignRef){
      currentRef = props?.refs[3];
    }
    setActiveSection(currentRef);
  }, [props?.refs]);

  //on change of dropDownRef add event listener on window to listen on clicks outside of the search dropdown div and then hide it
  useEffect(() => {
    const handleClose = (e) => {
      if (
        dropDownRef.current &&
        e.target !== dropDownRef.current &&
        e.target !== searchInputRef.current &&
        e.target !== xIconRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        props.setDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownRef, props.setDropDown]);

  useEffect(() => {
    if (!openFilters) {
      setTimeout(() => {
        setFiltersBorderRadius(true);
      }, 200);
    }
  }, [openFilters]);

  //function for "see all" filter, to clear filter values and display both learning paths and courses
  const clearFilters = () => {
    setLearningPathChecked(false);
    setCourseChecked(false);
  };

  //display search result dropdown if it is not already displayed
  const showDropdown = () => {
    if (!props.dropDown) {
      props.setDropDown(true);
    }
  };

  //if popular search is chosen from searchbar result dropdown suggestions, apply it's value as a searchValue
  const applyPopularSearch = (value) => {
    if (value !== "") {
      setSearchValue(value);
    }
  };

  //itterate trough learning path titles and subtitles and discover if searchValue is contained and set it inside learningPathsSearchResult
  const searchTroughLearningPaths = useCallback(() => {
    let value = searchValue.toLowerCase();

    if (value === "") {
      setLearningPathsSearchResult([]);
    } else {
      let filteredLearningPathsBySearchValue = props.learningPaths.filter(
        (learningPath, i) => {
          let learningPathTitleString = learningPath.title.toLowerCase();
          let learningPathSubtitleString = learningPath.subtitle.toLowerCase();
          return (
            learningPathTitleString.includes(value) ||
            learningPathSubtitleString.includes(value)
          );
        }
      );

      setLearningPathsSearchResult(
        filteredLearningPathsBySearchValue.slice(0, 1)
      );
    }
  }, [props.learningPaths, searchValue]);

  //itterate trough courses titles and subtitles and discover if searchValue is contained and set it inside coursesSearchResult
  const searchTroughCourses = useCallback(() => {
    let value = searchValue.toLowerCase();

    if (value === "") {
      setCoursesSearchResult([]);
    } else {
      let filteredCoursesBySearchValue = props.courses.filter((course, i) => {
        let coursesTitleString = course.title.toLowerCase();
        let coursesSubtitleString = course.subtitle.toLowerCase();
        return (
          coursesTitleString.includes(value) ||
          coursesSubtitleString.includes(value)
        );
      });
      if (props.isLaptop || props.isMobile) {
        setCoursesSearchResult(filteredCoursesBySearchValue.slice(0, 4));
      } else {
        setCoursesSearchResult(filteredCoursesBySearchValue.slice(0, 3));
      }
    }
  }, [props.courses, searchValue, props.isLaptop, props.isMobile]);

  //on change of searchValue invoke adequate functions to search trough learning paths and courses
  useEffect(() => {
    if (props.dropDown) {
      searchTroughLearningPaths();
      searchTroughCourses();
      searchInputRef.current.value = searchValue;
    }
  }, [
    searchValue,
    props.dropDown,
    searchTroughCourses,
    searchTroughLearningPaths,
  ]);


  return (
    <SearchBarContainer
      ref={ref}
      isAlignedToTopSearchBar={props.isAlignedToTopSearchBar}
      isScrolledSearchBar={props.isScrolledSearchBar}
      onClick={(e) =>
        e.currentTarget === ref.current &&
        props.isScrolledSearchBar &&
        props.setIsScrolledSearchBar(false)
      }
    >
      <SearchBarWrapper
        className={props.isAlignedToTopSearchBar && "aligned-to-top scrolled"}
        isScrolledSearchBar={props.isScrolledSearchBar}
      >
        <Wrapper>
          <SearchBarFrame show={props.isAlignedToTopSearchBar}>
            <Heading28 ref={searchTitleRef}>
              {t("catalogue.search_bar.browse_all_courses").replace(
                "{COURSE_NUMBER}",
                props.coursesLength
              )}
            </Heading28>
            <SearchWrapper
              ref={searchWrapperRef}
              show={props.isScrolledSearchBar}
            >
              <SearchIconLeft
                show={props.isScrolledSearchBar}
                dropDown={props.dropDown}
              >
                <Icon
                  icon="icnSearch"
                  fill={props.theme.blue}
                  className={
                    props.dropDown
                      ? "search-icon-left-base search-icon-left"
                      : "search-icon-left-base"
                  }
                />
              </SearchIconLeft>
              <SearchIconRight
                ref={xIconContainerRef}
                show={props.isScrolledSearchBar}
                dropDown={props.dropDown}
              >
                <Icon
                  ref={xIconRef}
                  icon={"icnXMobile"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearchValue("");
                  }}
                  width="24px"
                  height="24px"
                  className={
                    props.dropDown
                      ? "iconX xIconAnimatedForwards icon"
                      : "iconX icon"
                  }
                />
                <Icon
                  icon="icnSearch"
                  fill={props.theme.gray3}
                  className={
                    props.dropDown
                      ? "searchIconRight search-icon-right"
                      : "search-icon-right"
                  }
                />
              </SearchIconRight>
              <SearchInput
                ref={searchInputRef}
                show={props.isScrolledSearchBar}
                placeholder={t("catalogue.search_bar.search_by")}
                background={(props) =>
                  props.dropDown ? "white" : props.theme.gray0
                }
                onClick={(e) => showDropdown(e, dropDownRef)}
                onChange={(e) => {
                  searchTroughLearningPaths(e);
                  searchTroughCourses(e);
                  setSearchValue(e.target.value);
                }}
                isAlignedToTopSearchBar={props.isAlignedToTopSearchBar}
                dropDown={props.dropDown}
              />

              <SearchDropdown dropDown={props.dropDown} ref={dropDownRef}>
                <div>
                  {learningPathsSearchResult.length === 0 &&
                  coursesSearchResult.length === 0 ? (
                    <PopularSearches
                      applyPopularSearch={(value) => applyPopularSearch(value)}
                    />
                  ) : learningPathsSearchResult.length > 0 &&
                    coursesSearchResult.length > 0 ? (
                    <React.Fragment>
                      <LearningPathsSearchResultWrapper>
                        <Text10
                          className="section-title search-title"
                          font="Raleway"
                          style={{
                            textTransform: "uppercase",
                            margin: "5px 0px 20px 0px",
                          }}
                        >
                          {t("general.learning_paths").toUpperCase()}
                        </Text10>
                        <Link
                          to={`learning-path/${learningPathsSearchResult[0].id}`}
                        >
                          <LearningPathCard
                            cardTitle={learningPathsSearchResult[0].title}
                            cardImage={learningPathsSearchResult[0].image}
                          />
                        </Link>
                      </LearningPathsSearchResultWrapper>
                      <CoursesSearchResultWrapper>
                        <Text10
                          className="section-title search-title"
                          font="Raleway"
                          style={{
                            textTransform: "uppercase",
                            margin: "5px 0px 20px 0px",
                            width: "100%",
                          }}
                        >
                          {t("general.courses").toUpperCase()}
                        </Text10>
                        <CoursesSearchResultCardContainer>
                          {coursesSearchResult.map((course, i) => {
                            return (
                              <CourseSearchResultCardWrapper key={i}>
                                <Link to={`courses/${course.id}`}>
                                  <CourseCard
                                    style={{ width: "33%" }}
                                    cardTitle={course.title}
                                    cardSubtitle={course.subtitle}
                                    cardImage={course.image}
                                    cardTime={course.duration}
                                    cardStudentsNumber={course.total_students}
                                    cardPoints={course.points}
                                  />
                                </Link>
                              </CourseSearchResultCardWrapper>
                            );
                          })}
                        </CoursesSearchResultCardContainer>
                      </CoursesSearchResultWrapper>
                    </React.Fragment>
                  ) : learningPathsSearchResult.length > 0 ? (
                    <LearningPathsSearchResultWrapper>
                      <Text10
                        className="section-title"
                        font="Raleway"
                        style={{
                          textTransform: "uppercase",
                          margin: "5px 0px 20px 0px",
                        }}
                      >
                        {t("general.learning_paths").toUpperCase()}
                      </Text10>
                      <Link
                        to={`learning-path/${learningPathsSearchResult[0].id}`}
                      >
                        <LearningPathCard
                          cardTitle={learningPathsSearchResult[0].title}
                          cardImage={learningPathsSearchResult[0].image}
                        />
                      </Link>
                    </LearningPathsSearchResultWrapper>
                  ) : (
                    coursesSearchResult.length > 0 && (
                      <CoursesSearchResultWrapper>
                        <Text10
                          className="section-title"
                          font="Raleway"
                          style={{
                            textTransform: "uppercase",
                            margin: "5px 0px 20px 0px",
                            width: "100%",
                          }}
                        >
                          {t("general.courses").toUpperCase()}
                        </Text10>
                        <CoursesSearchResultCardContainer>
                          {coursesSearchResult.map((course, i) => {
                            return (
                              <CourseSearchResultCardWrapper key={i}>
                                <Link to={`courses/${course.id}`}>
                                  <CourseCard
                                    style={{ width: "33%" }}
                                    cardTitle={course.title}
                                    cardSubtitle={course.subtitle}
                                    cardImage={course.image}
                                    cardTime={course.duration}
                                    cardStudentsNumber={course.total_students}
                                    cardPoints={course.points}
                                    cardComingSoon={course.coming_soon !== 0}
                                  />
                                </Link>
                              </CourseSearchResultCardWrapper>
                            );
                          })}
                        </CoursesSearchResultCardContainer>
                      </CoursesSearchResultWrapper>
                    )
                  )}
                </div>
              </SearchDropdown>
            </SearchWrapper>
            <LinksAndFiltersContainer
              className={props.isScrolledSearchBar && "collapse"}
            >
              <LinksContainer>
                <FilterButtons>
                  <LinkIcon
                    categoryColor="#234BDB"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.scrollToStudyField(props.refs[0]);
                      setActiveSection(props.refs[0]);
                    }}
                    id="computer-science"
                    show={props.isScrolledSearchBar ? 42 : 150}
                    isScrolledSection={props.isScrolledSearchBar === true}
                    className={
                     props.category === "" ||
                      (props.isScrolledSearchBar &&
                        props.category === "computer-science") === true
                        ? "active"
                        : ""
                    }
                  >
                    <IconWrap categoryColor="#234BDB">
                      <ImgIcon
                        show={props.isScrolledSearchBar}
                        src={iconComputerScience}
                      />
                    </IconWrap>

                    <MenuCapture
                      ref={computerScienceRef}
                    >
                      {t("catalogue.search_bar.links.computer_science")}
                    </MenuCapture>
                  </LinkIcon>

                  <LinkIcon
                    categoryColor="#FED445"
                    isScrolledSection={props.isScrolledSearchBar === true}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.scrollToStudyField(props.refs[1]);
                      setActiveSection(props.refs[1]);
                    }}
                    id="data-science"
                    show={props.isScrolledSearchBar ? 42 : 150}
                    className={
                      activeSection === props.refs[1] ||
                      (props.isScrolledSearchBar &&
                        props.category === "data-science") === true
                        ? "active"
                        : ""
                    }
                  >
                    <IconWrap categoryColor="#FED445">
                      <ImgIcon
                        show={props.isScrolledSearchBar}
                        src={iconDataScience}
                      />
                    </IconWrap>
                    <MenuCapture
                      ref={dataScienceRef}
                    >
                      {t("catalogue.search_bar.links.data_science")}
                    </MenuCapture>
                  </LinkIcon>

                  <LinkIcon
                    categoryColor="#F43C28"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.scrollToStudyField(props.refs[2]);
                      setActiveSection(props.refs[2])
                    }}
                    id="digital-marketing"
                    show={props.isScrolledSearchBar ? 42 : 150}
                    isScrolledSection={props.isScrolledSearchBar === true}
                    className={
                      activeSection === props.refs[2] ||
                      (props.isScrolledSearchBar &&
                        props.category === "digital-marketing") === true
                        ? "active"
                        : ""
                    }
                  >
                    <IconWrap categoryColor="#F43C28">
                      <ImgIcon
                        show={props.isScrolledSearchBar}
                        src={iconMarketing}
                      />
                    </IconWrap>
                    <MenuCapture
                      ref={digitalMarketingRef}
                    >
                      {t("catalogue.search_bar.links.digital_marketing")}
                    </MenuCapture>
                  </LinkIcon>

                  <LinkIcon
                    categoryColor="#FFA9C4"
                    isScrolledSection={props.isScrolledSearchBar === true}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.scrollToStudyField(props.refs[3]);
                      setActiveSection(props.refs[3]);
                    }}
                    id="graphic-design"
                    show={props.isScrolledSearchBar ? 42 : 150}
                    className={
                      activeSection === props.refs[3] ||
                      (props.isScrolledSearchBar &&
                        props.category === "graphic-design") === true
                        ? "active"
                        : ""
                    }
                  >
                    <IconWrap categoryColor="#FFA9C4">
                      <ImgIcon
                        show={props.isScrolledSearchBar}
                        src={iconDesign}
                      />
                    </IconWrap>
                    <MenuCapture
                      ref={graphicDesignRef}
                    >
                      {t("catalogue.search_bar.links.graphic_design")}
                    </MenuCapture>
                  </LinkIcon>
                </FilterButtons>
              </LinksContainer>
              <div className="divider"></div>
              <FiltersContainer show={props.isScrolledSearchBar}>
                <CheckboxContainer className="desktop-filterby">
                  <Checkbox
                    checked={learningPathChecked}
                    isFilterButton={"true"}
                    onClick={() => {
                      setLearningPathChecked(true);
                      setCourseChecked(false);
                      props.filterStudyFields("learning_paths");
                    }}
                    text={t("catalogue.search_bar.filters.learning_paths")}
                  />
                </CheckboxContainer>
                <CheckboxContainer className="desktop-filterby">
                  <Checkbox
                    checked={courseChecked}
                    isFilterButton={true}
                    onClick={() => {
                      setCourseChecked(true);
                      setLearningPathChecked(false);
                      props.filterStudyFields("courses");
                    }}
                    text={t("catalogue.search_bar.filters.courses")}
                  />
                </CheckboxContainer>
                <CheckboxContainer
                  className={props.isScrolledSearchBar && "checkbox-collapse"}
                  style={{
                    marginBottom: "10px",
                    marginLeft: "25px",
                    height: "35px",
                  }}
                >
                  <Text13
                    className={`see-all ${
                      !courseChecked && !learningPathChecked && "all-border"
                    }`}
                    onClick={() => {
                      clearFilters();
                      props.filterStudyFields("all");
                    }}
                  >
                    {t("catalogue.search_bar.filters.see_all")}
                  </Text13>
                </CheckboxContainer>
              </FiltersContainer>
            </LinksAndFiltersContainer>
          </SearchBarFrame>
        </Wrapper>
      </SearchBarWrapper>
      <MobileFiltersWrapper
        isAlignedToTopSearchBar={props.isAlignedToTopSearchBar}
        isScrolledSearchBar={props.isScrolledSearchBar}
        className={props.isScrolledSearchBar && "fixed-mobile-filters"}
      >
        <MobileFiltersContainer openFilters={openFilters}>
          <MobileFiltersOptions openFilters={openFilters}>
            <CheckboxContainer openFilters={openFilters}>
              <Text13
                className="mobile-filterby"
                onClick={() => {
                  clearFilters();
                  props.filterStudyFields("all");
                }}
              >
                {t("catalogue.search_bar.filters.filter_by")}
              </Text13>
            </CheckboxContainer>
            <CheckboxContainer openFilters={openFilters}>
              <Checkbox
                checked={learningPathChecked}
                onClick={() => {
                  setLearningPathChecked(true);
                  setCourseChecked(false);
                  props.filterStudyFields("learning_paths");
                }}
                text={t("catalogue.search_bar.filters.learning_paths")}
              />
            </CheckboxContainer>
            <CheckboxContainer openFilters={openFilters}>
              <Checkbox
                checked={courseChecked}
                onClick={() => {
                  setCourseChecked(true);
                  setLearningPathChecked(false);
                  props.filterStudyFields("courses");
                }}
                text={t("catalogue.search_bar.filters.courses")}
              />
            </CheckboxContainer>
            <CheckboxContainer
              openFilters={openFilters}
              className={props.isScrolledSearchBar && "checkbox-collapse"}
            >
              <Text13
                className={`see-all-mobile ${
                  !courseChecked && !learningPathChecked && "all-border"
                }`}
                onClick={() => {
                  clearFilters();
                  props.filterStudyFields("all");
                }}
              >
                {t("catalogue.search_bar.filters.see_all")}
              </Text13>
            </CheckboxContainer>
          </MobileFiltersOptions>
          <MobileFiltersTogglerContainer openFilters={filtersBorderRadius}>
            <FilterIconContainer
              onClick={(e) => {
                e.stopPropagation();
                setOpenFilters(!openFilters);
                setFiltersBorderRadius(false);
              }}
            >
              <Icon
                icon={openFilters ? "icnXMobile" : "icnFilter"}
                pathFill={openFilters ? props.theme.blue : null}
                width={openFilters ? 24 : 22}
                height={openFilters ? 26 : 20}
              />
            </FilterIconContainer>
          </MobileFiltersTogglerContainer>
        </MobileFiltersContainer>
      </MobileFiltersWrapper>
    </SearchBarContainer>
  );
});

export default withTheme(SearchBar);
