import React, { useState, useEffect, useCallback } from "react";

//npm imports
import styled, { withTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";

//component imports
import PublicFacingPageWrapper from "../../Components/PublicFacingPageWrapper/PublicFacingPageWrapper";
import {
  SpecHeading2,
  Text10,
  Text13,
  Text16,
} from "../../Components/Text/Text";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";
import ChooseButton from "../../Components/ChooseButton/ChooseButton";
import SelectInput from "../../Components/SelectInput/SelectInput";
import InputField from "../../Components/InputField/InputField";

//other imports
import birthday_illustration from "../../Static/illustrations/PublicFacing/birthday.svg";
import birthday_illustration_mobile from "../../Static/illustrations/PublicFacing/birthday_mobile.svg";
import status_illustration from "../../Static/illustrations/PublicFacing/status.svg";
import status_illustration_mobile from "../../Static/illustrations/PublicFacing/status_mobile.svg";
import school_faculty_profession_illustration from "../../Static/illustrations/PublicFacing/school_faculty_profession.svg";
import school_faculty_profession_illustration_mobile from "../../Static/illustrations/PublicFacing/school_faculty_profession_mobile.svg";
import goal_illustration_mobile from "../../Static/illustrations/PublicFacing/goal_mobile.svg";
import goal_illustration from "../../Static/illustrations/PublicFacing/goal.svg";
import { API } from "../../Consts/Api";
import Icon from "../../Components/Icon/Icon";
import InputFieldError from "../../Components/InputField/InputFieldError";
import { useMediaQuery } from "react-responsive";
import { highschoolYear } from "../../Static/data-base/data-base";
import { highschoolDepartment } from "../../Static/data-base/data-base";
import { highschoolGBA } from "../../Static/data-base/data-base";
import { facultyYear } from "../../Static/data-base/data-base";

//styled-components
const RegisterStepsHeadline = styled.div`
  top: 0px !important;
  position: relative;
  overflow: hidden;
  transform: translate(0px, 0px) !important;
  .register-steps-logo {
 
    
    position: fixed;
    top: 100px;
    left: 10%;
  }
  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transition: top 1s ease-in-out 0.2s;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 100px;
    align-items: center;
    p {
      margin-bottom: 18px;
      font-weight: 700;
      line-height: 20px;
    }

    .content-container {
      margin-top: 48px;
      width: 80%;
    }
    .content-container p {
      width: 75%;
    }
  }
  .birthday-headline {
    top: ${(props) =>
      props.currentScreen === 0 ? "50%" : props.currentScreen > 0 && "150%"};

    div.illustration-container {
    }
    img {
      display: block;
      width: 100%;
      margin: 0 0 5% 20%;
    }
  }
  .status-headline {
    top: ${(props) =>
      props.currentScreen < 1
        ? "-150%"
        : props.currentScreen === 1
        ? "50%"
        : props.currentScreen > 1 && "150%"};
    & > div {
      width: 100%;
    }

    .status-illustration {
      margin-right: 30%;
      display: block;
      width: 100%;
    }
  }
  .school-faculty-profession-headline {
    top: ${(props) =>
      props.currentScreen < 2
        ? "-150%"
        : props.currentScreen === 2
        ? "50%"
        : props.currentScreen > 2 && "150%"};
    p {
      margin-bottom: 18px;
    }
    img {
      display: inline-block;
      width: 60%;
    }
  }
  .goal-headline {
    top: ${(props) =>
      props.currentScreen < 3
        ? "-150%"
        : props.currentScreen === 3
        ? "50%"
        : props.currentScreen > 3 && "150%"};
    img.goal-illustration {
      display: block;
      width: 70%;
      margin-top: 50px;
    }
  }
  .field-of-interest-headline {
    top: ${(props) =>
      props.currentScreen < 4
        ? "-150%"
        : props.currentScreen === 4
        ? "50%"
        : props.currentScreen > 4 && "150%"};
  }
  @media (max-width: 500px) {
    height: 25vh !important;
    .register-steps-logo {
      top: 34px;
      left: unset;
    }
    .container {
      padding-top: 34px;
      justify-content: flex-start;
      transition: left 1s ease-in-out 0.2s;

      .content-container {
        margin: 16px auto 0;
        width: 90%;
      }
      .content-container p {
        width: 80%;
      }
      img {
        order: -2;
        align-self: flex-end;
        margin-bottom: 0px;
        width: 90%;
        margin-right: auto;
        display: inline-block;
        margin-left: 0;
        position: relative;
      }
      p {
        margin: 10px 0;
        line-height: 18px;
      }
      h1 {
        font-size: 36px;
        line-height: 42px;
      }
    }

    .birthday-headline {
      top: 50%;
      left: ${(props) =>
        props.currentScreen === 0 ? "50%" : props.currentScreen > 0 && "150%"};
    }
    .content-container p {
      width: 100%;
    }
    .status-headline {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 1
          ? "-150%"
          : props.currentScreen === 1
          ? "50%"
          : props.currentScreen > 1 && "150%"};

      img.status-illustration {
        margin-right: auto;
        display: block;
        width: 90%;
      }
    }
    .school-faculty-profession-headline {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 2
          ? "-150%"
          : props.currentScreen === 2
          ? "50%"
          : props.currentScreen > 2 && "150%"};

      img {
        width: 60%;
        margin-left: 30%;
      }
    }

    .goal-headline {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 3
          ? "-150%"
          : props.currentScreen === 3
          ? "50%"
          : props.currentScreen > 3 && "150%"};

      img.goal-illustration {
        display: block;
        width: 90%;
        margin: 0 10% 0 0;
      }
    }

    .field-of-interest-headline {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 4
          ? "-150%"
          : props.currentScreen === 4
          ? "50%"
          : props.currentScreen > 4 && "150%"};
    }
  }
`;

const RegisterStepsForm = styled.form`
  position: relative;
  overflow: hidden;
  justify-content: flex-start !important;
  .progress-bar-container {
    width: 100%;
    height: 7px;
    align-self: flex-start;
    .progress-bar {
      width: ${
        (props) =>
          props.currentScreen === 0
            ? "25%"
            : props.currentScreen === 1
            ? "50%"
            : props.currentScreen === 2
            ? "75%"
            : props.currentScreen === 3 && "100%"
      };
      height: 7px;
      background-color: ${(props) => props.theme.black};
      transition: width 1s ease-in-out 0.2s;
    }
  }
  .interest-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: top 1s ease-in-out 0.2s;
  }
  .birthday-container {
    width: 60%;
    top: ${(props) =>
      props.currentScreen === 0 ? "50%" : props.currentScreen > 0 && "-150%"};
    display: flex;
    flex-direction: column;
    .bold {
      font-weight: 800;
    }
    .birth-date-container {
      margin-bottom: 22px;
      .birth-date-inputs-container {
        display: flex;
        .birth-date-input-container {
          width: 23%;
          margin-right: 10px;
        }
        .year-input {
          margin-right: 0 !important;
          width: 50% !important;
        }
      }
    }
    .my-info-input {
      margin-bottom: 7px;
    }
    & > button {
      margin-top: 43px;
      align-self: flex-end;
    }
  }
  .status-container {
    .bold {
      font-weight: 800;
      margin-bottom: 14px;
    }
    width: 45%;
    top: ${(props) =>
      props.currentScreen < 1
        ? "150%"
        : props.currentScreen === 1
        ? "50%"
        : props.currentScreen > 1 && "-150%"};
    display: flex;
    flex-direction: column;
    & > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 14px;
      .occupation-option-container {
        flex-basis: 32%;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    & > button {
      align-self: flex-end;
      margin-top: 36px;
    }
  }
  .school-faculty-profession-container {
    width: 60%;
    top: ${(props) =>
      props.currentScreen < 2
        ? "150%"
        : props.currentScreen === 2
        ? "50%"
        : props.currentScreen > 2 && "-150%"};
    display: flex;
    flex-direction: column;
    & > div {
      width: 100%;
    }
    .mb-14 {
      margin-bottom: 14px;
    }
    button {
      margin-top: 36px;
      align-self: flex-end;
    }
    div.select-inputs-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      p.input-label {
        display: none;
      }

      div.width-49 {
        width: 49% !important;
      }
    }
    @media (max-width: 500px) {
      div.select-inputs-container {
        display: block;

        div.width-49 {
          width: 100% !important;
        }
      }
    }
  }
  .goal-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    top: ${(props) =>
      props.currentScreen < 3
        ? "150%"
        : props.currentScreen === 3
        ? "50%"
        : props.currentScreen > 3 && "-150%"};

    .goal-option-container {
      margin-bottom: 14px;
    }
    button {
      margin-top: 36px;
      align-self: flex-end;
    }
    ${Text16} {
      margin-bottom: 14px;
    }
  }
  .field-of-interests-container {
    width: 64%;
    display: flex;
    flex-direction: column;
    top: ${(props) =>
      props.currentScreen < 4
        ? "150%"
        : props.currentScreen === 4
        ? "50%"
        : props.currentScreen > 4 && "-150%"};

    .multiple-choice {
      margin-top: 15px;
      margin-bottom: 10px;
    }
    & > div {
      display: flex;
      flex-wrap: wrap;
      .interest-option-container {
        margin-right: 10px;
        margin-bottom: 10px;
        width: fit-content;
        & > div {
          padding: 23px 43px;
        }
      }
    }

    button {
      margin-top: 36px;
      align-self: flex-end;
    }
  }
  .skip-onboarding {
    color: ${(props) => props.theme.gray3};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    &:hover {
      color: ${(props) => props.theme.blue};
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    height: 75vh !important;
    overflow-y: auto;
    min-height: unset !important;
    ::-webkit-scrollbar {
      width: 2px;
    }

    .greeting-user .greet-part-two {
      line-height: 18px;
      margin-top: 5px;
    }
    .progress-bar-container {
      display: none;
    }
    .interest-container {
      width: 85%;
      height: 85%;
      transition: left 1s ease-in-out 0.2s;
    }
    .form-title-mobile {
      font-weight: 400;
      font-size: 34px;
      line-height: 39px;
      margin-bottom: 38px;
    }
    .submit-button {
      width: 100%;
      justify-content: center;
      padding: 16px 0;
    }
    .birthday-container {
      top: 50%;
      left: ${(props) =>
        props.currentScreen === 0 ? "50%" : props.currentScreen > 0 && "-150%"};
      .birth-date-container {
        .birth-date-inputs-container {
          margin-right: 7px;
        }
      }
    }
    .status-container {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 1
          ? "150%"
          : props.currentScreen === 1
          ? "50%"
          : props.currentScreen > 1 && "-150%"};
    }
    .school-faculty-profession-container {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 2
          ? "150%"
          : props.currentScreen === 2
          ? "50%"
          : props.currentScreen > 2 && "-150%"};
    }
    .goal-container {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 3
          ? "150%"
          : props.currentScreen === 3
          ? "50%"
          : props.currentScreen > 3 && "-150%"};
    }
    .field-of-interests-container {
      top: 50%;
      left: ${(props) =>
        props.currentScreen < 4
          ? "150%"
          : props.currentScreen === 4
          ? "50%"
          : props.currentScreen > 4 && "-150%"};
    }
    .skip-onboarding {
      bottom: 30px;
    }
  }
`;

//component
const RegisterSteps = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [currentScreen, setCurrentScreen] = useState(0);

  const [days, setDays] = useState([{ value: null, label: "DD" }]);
  const [selectedDay, setSelectedDay] = useState(days[0]);
  const [months, setMonths] = useState([{ value: null, label: "MM" }]);
  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [years, setYears] = useState([{ value: null, label: "YYYY" }]);
  const [selectedYear, SetSelectedYear] = useState(years[0]);
  const genders = [
    { value: "male", label: t("my_profile.gender.options.male") },
    { value: "female", label: t("my_profile.gender.options.female") },
    { value: "na", label: t("my_profile.gender.options.none") }, // na = not applicable
  ];
  const [gender, setGender] = useState(genders[2]);

  const resetErrors = {
    dob: [],
    occupation: [],
    highschool: [],
    highschoolOther: [],
    highschoolDepartment: [],
    highschoolDepartmentOther: [],
    highschoolYear: [],
    highschoolGBA: [],
    faculty: [],
    facultyOther: [],
    facultyYear: [],
    goal: [],
  };
  const [errors, setErrors] = useState(resetErrors);

  const [occupations, setOccupations] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [highschools, setHighschools] = useState([
    { label: t("my_profile.interests.highschool_placeholder"), value: null },
  ]);
  const [selectedHighschool, setSelectedHighschool] = useState(highschools[0]);
  const [highschoolOther, setHighschoolOther] = useState("");
  const [selectedHighschoolDepartment, setSelectedHighschoolDepartment] =
    useState(highschoolDepartment[0]);
  const [highschoolDepartmentOther, setHighschoolDepartmentOther] =
    useState("");
  const [selectedHighschoolYear, setSelectedHighschoolYear] = useState(
    highschoolYear[0]
  );
  const [selectedHighschoolGBA, setSelectedHighschoolGBA] = useState(
    highschoolGBA[0]
  );
  const [faculties, setFaculties] = useState([
    { value: null, label: t("my_profile.interests.faculty_placeholder") },
  ]);
  const [selectedFaculty, setSelectedFaculty] = useState(faculties[0]);
  const [facultyOther, setFacultyOther] = useState("");
  const [selectedFacultyYear, setSelectedFacultyYear] = useState(
    facultyYear[0]
  );
  const [selectedFieldOfExpertise, setSelectedFieldOfExpertise] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const [interests, setInterests] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  useEffect(() => {
    API.axios
      .get("/student/info")
      .then((response) => {
        let highschoolsForSelectInputArray = [
          {
            label: t("my_profile.interests.highschool_placeholder"),
            value: null,
          },
        ];
        let facultiesForSelectInputArray = [
          { label: t("my_profile.interests.faculty_placeholder"), value: null },
        ];
        response.data.data.faculties.length > 0 &&
          response.data.data.faculties.forEach((faculty) => {
            let value = faculty.id;
            let label = `${faculty.name}, ${faculty.city.name}`;
            facultiesForSelectInputArray.push({ value, label });
          });
        response.data.data.goals.length > 0 &&
          setGoals(response.data.data.goals);
        response.data.data.highschools.length > 0 &&
          response.data.data.highschools.forEach((highschool) => {
            let value = highschool.id;
            let label = `${highschool.name}, ${highschool.city.name}`;
            highschoolsForSelectInputArray.push({ value, label });
          });
        response.data.data.interests.length > 0 &&
          setInterests(response.data.data.interests);
        response.data.data.occupations.length > 0 &&
          setOccupations(response.data.data.occupations);
        setHighschools(highschoolsForSelectInputArray);
        setFaculties(facultiesForSelectInputArray);
      })
      .catch((error) => console.log(error));
    let days = [{ value: null, label: "DD" }];
    for (let i = 0; i < 31; i++) {
      days.push({ value: i + 1, label: i + 1 });
    }
    let mnths = [{ value: null, label: "MM" }];
    let allMonths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    allMonths.forEach((month, index) => {
      mnths.push({ value: index + 1, label: month });
    });
    let year = new Date();
    let years = [{ value: null, label: "YYYY" }];
    for (let i = year.getFullYear() - 13; i > 1940; i--) {
      years.push({ value: i, label: i });
    }
    setDays(days);
    setMonths(mnths);
    setYears(years);
  }, [t]);

  // Function selectInterests is part of the the code that is temporary commented at the end of this file. Do not remove it!
  const selectInterests = (interest) => {
    let selectedInterestsArray = [];
    selectedInterests.forEach((interest) =>
      selectedInterestsArray.push(interest)
    );
    if (
      selectedInterests.some(
        (selectedInterest) => selectedInterest === interest
      )
    ) {
      selectedInterestsArray.forEach((selectedInterest, index) => {
        if (selectedInterest === interest) {
          selectedInterestsArray.splice(index, 1);
        }
      });
    } else {
      selectedInterestsArray.push(interest);
    }
    setSelectedInterests(selectedInterestsArray);
  };

  const validateBirthdayAndGender = () => {
    let errors = {
      dob: [],
    };
    if (!selectedDay.value || !selectedMonth.value || !selectedYear.value) {
      errors = {
        ...errors,
        dob: [t("errors.register_steps.dob")],
      };
      setErrors(errors);
    } else {
      setCurrentScreen(1);
    }
  };

  const validateOccupation = () => {
    // If the user has not selected an occupation OR if the user has selected OTHER,
    // AND has not entered any other status in the input field, in this case show the error message.
    if (!selectedOccupation) {
      setErrors({
        ...errors,
        occupation: [t("errors.register_steps.occupation")],
      });
    } else if (selectedOccupation === 4) {
      setCurrentScreen(3);
    } else {
      setCurrentScreen(2);
    }
  };

  const validateHighschoolAndFacultyQuestions = () => {
    let highschoolErrors = {};
    // If the user has selected a HIGHSCHOOL as an occupation
    if (selectedOccupation === 1) {
      // add error message on empty questions
      if (selectedHighschool.value === null) {
        highschoolErrors = {
          ...highschoolErrors,
          highschool: [t("errors.register_steps.highschool")],
        };
      }
      if (selectedHighschoolDepartment.value === null) {
        highschoolErrors = {
          ...highschoolErrors,
          highschoolDepartment: [
            t("errors.register_steps.highschoolDepartment"),
          ],
        };
      }
      if (
        selectedHighschoolDepartment.value === "Друго" ||
        highschoolDepartmentOther === ""
      ) {
        highschoolErrors = {
          ...highschoolErrors,
          highschoolDepartmentOther: [
            t("errors.register_steps.highschoolDepartment"),
          ],
        };
      }
      if (selectedHighschool.label === "Друго" || highschoolOther === "") {
        highschoolErrors = {
          ...highschoolErrors,
          highschoolOther: [t("errors.register_steps.highschool")],
        };
      }

      if (selectedHighschoolYear.value === null) {
        highschoolErrors = {
          ...highschoolErrors,
          highschoolYear: [t("errors.register_steps.highschoolYear")],
        };
      }
      if (selectedHighschoolGBA.value === null) {
        highschoolErrors = {
          ...highschoolErrors,
          highschoolGBA: [t("errors.register_steps.highschoolGBA")],
        };
      }
      setErrors(highschoolErrors);

      // validate empty fields
      if (
        selectedHighschool.value === null ||
        selectedHighschoolDepartment.value === null ||
        selectedHighschoolYear.value === null ||
        selectedHighschoolGBA.value === null
      ) {
        return;
      } else if (
        selectedHighschool.label === "Друго, Друго" &&
        highschoolOther === ""
      ) {
        return;
      } else if (
        selectedHighschoolDepartment.value === "Друго" &&
        highschoolDepartmentOther === ""
      ) {
        return;
      } else {
        setCurrentScreen(3);
      }
    }

    // If the user has selected a STUDENT as an occupation
    if (selectedOccupation === 2) {
      // add error message on empty questions
      if (selectedFaculty.value === null) {
        highschoolErrors = {
          ...highschoolErrors,
          faculty: [t("errors.register_steps.faculty")],
        };
      }
      if (selectedFaculty.label === "Друго, Друго" && facultyOther === "") {
        highschoolErrors = {
          ...highschoolErrors,
          facultyOther: [t("errors.register_steps.faculty")],
        };
      }
      if (selectedFacultyYear.value === null) {
        highschoolErrors = {
          ...highschoolErrors,
          facultyYear: [t("errors.register_steps.faculty_year")],
        };
      }
      setErrors(highschoolErrors);

      // validate empty fields
      if (
        selectedFaculty.value === null ||
        selectedFacultyYear.value === null
      ) {
        return;
      } else if (
        selectedFaculty.label === "Друго, Друго" &&
        facultyOther === ""
      ) {
        return;
      } else {
        setCurrentScreen(3);
      }
    }
  };

  const validateGoals = () => {
    if (selectedGoal === null) {
      setErrors({
        ...errors,
        goal: [t("errors.register_steps.goal")],
      });
    } else {
      postInterests();
    }
  };
  const handleFieldsOfExpertise = () => {
    if (selectedOccupation === 3) {
      setCurrentScreen(3);
    } else {
      validateHighschoolAndFacultyQuestions();
    }
  };

  const postInterests = useCallback(() => {
    let interests = {
      occupation_id: selectedOccupation,
      faculty_id: selectedFaculty.value,
      faculty_name: facultyOther || null,
      faculty_year: selectedFacultyYear.value,
      goal_id: selectedGoal,
      highschool_id: selectedHighschool.value,
      highschool_name: highschoolOther || null,
      highschool_year: selectedHighschoolYear.value,
      highschool_gpa: selectedHighschoolGBA.value,
      highschool_department: selectedHighschoolDepartment.value,
      highschool_department: selectedHighschoolDepartment.value,
      highschool_department_other: highschoolDepartmentOther,
      interests: selectedInterests,
      profession: selectedFieldOfExpertise,
      company: companyName,
      gender: gender.value,
      birth_date: {
        day: selectedDay.value,
        month: selectedMonth.value,
        year: selectedYear.value,
      },
    };

    API.axios
      .post(API.createApiRoute("studentInfo"), interests)
      .then((response) => {
        navigate(API.createRoute("catalogue"));
      })
      .catch((error) => {
        navigate(API.createRoute("catalogue"));
      });
  }, [
    selectedOccupation,
    selectedFaculty.value,
    selectedGoal,
    selectedHighschool.value,
    selectedInterests,
    selectedFieldOfExpertise,
    gender.value,
    selectedDay.value,
    selectedMonth.value,
    selectedYear.value,
  ]);

  // const changeScreenOnEnter = useCallback(
  //   (e) => {
  //     if (e.keyCode === 13) {
  //       if (currentScreen === 0) {
  //         validateBirthdayAndGender();
  //         // setCurrentScreen(1);
  //       } else if (currentScreen === 1) {
  //         validateOccupation();

  //         // setCurrentScreen(2);
  //       } else if (currentScreen === 2) {
  //         handleFieldsOfExpertise()
  //         validateHighschoolAndFacultyQuestions();
  //         // setCurrentScreen(3);
  //       } else if (currentScreen === 3) {
  //         postInterests();
  //         // setCurrentScreen(4);
  //       }
  //       // else if (currentScreen === 4) {
  //       //   postInterests();
  //       // }
  //     }
  //   },
  //   [postInterests, setCurrentScreen, currentScreen]
  // );

  // useEffect(() => {
  //   window.addEventListener("keydown", changeScreenOnEnter);

  //   return () => {
  //     window.removeEventListener("keydown", changeScreenOnEnter);
  //   };
  // }, [changeScreenOnEnter]);

  return (
    <PublicFacingPageWrapper backgroundColor={(props) => props.theme.black}>
      <RegisterStepsHeadline
        className="headline-container"
        currentScreen={currentScreen}
      >
            <Icon
            className="register-steps-logo"
            icon="brainsterNextLogo"
            pathFill={props.theme.white}
          />
        <div className="birthday-headline container">
          <div className="content-container">
            {!isMobile && (
              <Text13 color={props.theme.white}>
                {t("register_steps.greet_partOne")}
                <br />
                {t("register_steps.greet_partTwo")}
                <br />
                {t("register_steps.greet_partThree")}
              </Text13>
            )}
            {!isMobile && (
              <SpecHeading2 color={props.theme.white}>
                {t("register_steps.birthday_partOne")}
                <br />
                {t("register_steps.birthday_partTwo")}
              </SpecHeading2>
            )}
          </div>

          <img
            src={
              !isMobile ? birthday_illustration : birthday_illustration_mobile
            }
            alt="blowing candles"
          />
        </div>
        <div className="status-headline container">
          <div className="content-container">
            {!isMobile && (
              <Text13 color={props.theme.white}>
                {t("register_steps.greet_partOne")}
                <br />
                {t("register_steps.greet_partTwo")}
                <br />
                {t("register_steps.greet_partThree")}
              </Text13>
            )}
            {!isMobile && (
              <SpecHeading2 color={props.theme.white}>
                {t("register_steps.status_partOne")}
                <br />
                {t("register_steps.status_partTwo")}
              </SpecHeading2>
            )}
          </div>
          <img
            src={!isMobile ? status_illustration : status_illustration_mobile}
            className="status-illustration"
            alt="girl holding megaphone"
          />
        </div>
        <div className="school-faculty-profession-headline container">
          <div className="content-container">
            {!isMobile && (
              <Text13 color={props.theme.white}>
                {t("register_steps.greet_partOne")}
                <br />
                {t("register_steps.greet_partTwo")}
                <br />
                {t("register_steps.greet_partThree")}
              </Text13>
            )}
            {!isMobile && (
              <SpecHeading2 color={props.theme.white}>
                {t("register_steps.status_partOne")}
                <br />
                {t("register_steps.status_partTwo")}
              </SpecHeading2>
            )}
          </div>
          <img
            src={
              !isMobile
                ? school_faculty_profession_illustration
                : school_faculty_profession_illustration_mobile
            }
            alt="boy holding circle"
          />
        </div>
        <div className="goal-headline container">
          <div className="content-container">
            {!isMobile && (
              <Text13 color={props.theme.white}>
                {t("register_steps.greet_partOne")}
                <br />
                {t("register_steps.greet_partTwo")}
                <br />
                {t("register_steps.greet_partThree")}
              </Text13>
            )}
            {!isMobile && (
              <SpecHeading2 color={props.theme.white}>
                {t("register_steps.goal_partOne")}
                <br />
                {t("register_steps.goal_partTwo")}
              </SpecHeading2>
            )}
          </div>
          <img
            className="goal-illustration"
            src={!isMobile ? goal_illustration : goal_illustration_mobile}
            alt="boy typing on laptop"
          />
        </div>
      </RegisterStepsHeadline>
      <RegisterStepsForm currentScreen={currentScreen}>
        <div className="progress-bar-container">
          <div className="progress-bar"></div>
        </div>
        <div className={"birthday-container interest-container"}>
          {isMobile && (
            <>
              <div style={{ marginBottom: "30px" }} className="greeting-user">
                <Text13 color={props.theme.black} fontWeight={800}>
                  {t("register_steps.greet_partOne_mobile")}
                </Text13>
                <Text13
                  color={props.theme.black}
                  fontWeight={600}
                  className="greet-part-two"
                >
                  {t("register_steps.greet_partTwo_mobile")}
                </Text13>
              </div>

              <SpecHeading2 className="form-title-mobile">
                {t("register_steps.birthday_partOne")} <bt />
                {t("register_steps.birthday_partTwo")}
              </SpecHeading2>
            </>
          )}
          <div className="birth-date-container">
            <Text16
              className="input-label"
              fontWeight="900"
              fontFamily="Raleway"
            >
              {t("my_profile.birth_date_heading")} *
            </Text16>
            <div className="birth-date-inputs-container">
              <div className="birth-date-input-container day-input">
                <SelectInput
                  data={days}
                  selectedOption={selectedDay}
                  onChange={setSelectedDay}
                />
              </div>
              <div className="birth-date-input-container month-input">
                <SelectInput
                  data={months}
                  selectedOption={selectedMonth}
                  onChange={setSelectedMonth}
                />
              </div>
              <div className="birth-date-input-container year-input">
                <SelectInput
                  data={years}
                  selectedOption={selectedYear}
                  onChange={SetSelectedYear}
                />
              </div>
            </div>
            {!selectedDay.value ||
            !selectedMonth.value ||
            !selectedYear.value ? (
              <InputFieldError
                error={errors?.dob?.length > 0 && errors?.dob[0]}
              />
            ) : (
              ""
            )}
          </div>
          <Text16 fontWeight="900" fontFamily="Raleway">
            {t("my_profile.gender.label")} *
          </Text16>
          <SelectInput
            data={genders}
            onChange={setGender}
            className="my-info-input"
            selectedOption={gender}
          />
          <ButtonBasic
            type="button"
            className="submit-button"
            variation="formBtn"
            text={t("general.next")}
            onClick={() => validateBirthdayAndGender()}
          />
        </div>
        {occupations.length > 0 && (
          <div className={"status-container interest-container"}>
            {isMobile && (
              <>
                <div style={{ marginBottom: "30px" }} className="greeting-user">
                  <Text13 color={props.theme.black} fontWeight={800}>
                    {t("register_steps.greet_partOne_mobile")}
                  </Text13>
                  <Text13
                    color={props.theme.black}
                    fontWeight={600}
                    className="greet-part-two"
                  >
                    {t("register_steps.greet_partTwo_mobile")}
                  </Text13>
                </div>

                <SpecHeading2 className="form-title-mobile">
                  {t("register_steps.status_partOne")} <bt />
                  {t("register_steps.status_partTwo")}
                </SpecHeading2>
              </>
            )}
            <Text16 className="bold" fontFamily="Raleway">
              {t("my_profile.interests.occupations_heading")} *
            </Text16>

            {occupations.map((occupation, index) => (
              <div className="occupation-option-container" key={index}>
                <ChooseButton
                  text={occupation.name}
                  checkCircle={true}
                  active={occupation.id === selectedOccupation}
                  id={occupation.id}
                  onClick={setSelectedOccupation}
                />
              </div>
            ))}
              {selectedOccupation === null ? (
                    <InputFieldError error={errors?.occupation?.[0]} />
                  ) : (
                    ""
                  )}
            <ButtonBasic
              type="button"
              variation="formBtn"
              className="submit-button"
              text={t("general.next")}
              onClick={() => validateOccupation()}
            />
          </div>
        )}
        {highschools.length > 0 && faculties.length > 0 && (
          <div
            className={"school-faculty-profession-container interest-container"}
          >
            {isMobile && (
              <>
                <div style={{ marginBottom: "30px" }} className="greeting-user">
                  <Text13 color={props.theme.black} fontWeight={800}>
                    {t("register_steps.greet_partOne_mobile")}
                  </Text13>
                  <Text13
                    color={props.theme.black}
                    fontWeight={600}
                    className="greet-part-two"
                  >
                    {t("register_steps.greet_partTwo_mobile")}
                  </Text13>
                </div>

                <SpecHeading2 className="form-title-mobile">
                  {t("register_steps.status_partOne")} <bt />
                  {t("register_steps.status_partTwo")}
                </SpecHeading2>
              </>
            )}
            {selectedOccupation && selectedOccupation === 1 ? (
              <div className="highschools-container">
                <Text16 fontWeight={900} className="mb-14">
                  {t("my_profile.interests.highschool_heading")} *
                </Text16>
                <div className="mb-14">
                  <SelectInput
                    inputLabel={t("")}
                    data={highschools}
                    onChange={setSelectedHighschool}
                    selectedOption={selectedHighschool}
                  />

                  {selectedHighschool.value === null ? (
                    <InputFieldError error={errors?.highschool?.[0]} />
                  ) : (
                    ""
                  )}
                </div>

                {selectedHighschool.label === "Друго, Друго" ? (
                  <div className="mb-14">
                    <InputField
                      name="highschool_name"
                      type="text"
                      value={highschoolOther}
                      placeholder={t(
                        "my_profile.interests.highschool_other_placeholder"
                      )}
                      onChange={(e) => {
                        setHighschoolOther(e.target.value);
                        setErrors({ ...errors, name: [] });
                      }}
                      focusColor={(props) => props.theme.blue}
                    />
                    {highschoolOther === "" ? (
                      <InputFieldError error={errors?.highschoolOther?.[0]} />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="mb-14">
                  <SelectInput
                    data={highschoolDepartment}
                    onChange={setSelectedHighschoolDepartment}
                    selectedOption={selectedHighschoolDepartment}
                  />
                  {selectedHighschoolDepartment.value === null && (
                    <InputFieldError
                      error={errors?.highschoolDepartment?.[0]}
                    />
                  )}
                </div>
                {selectedHighschoolDepartment.value === "Друго" ? (
                  <div className="mb-14">
                    <InputField
                      name="highschool_department_other"
                      type="text"
                      value={highschoolDepartmentOther}
                      placeholder={t(
                        "my_profile.interests.highschool_department_other_placeholder"
                      )}
                      onChange={(e) => {
                        setHighschoolDepartmentOther(e.target.value);
                        setErrors({ ...errors, highschoolDepartment: [] });
                      }}
                      focusColor={(props) => props.theme.blue}
                    />
                    {highschoolDepartmentOther === "" ? (
                      <InputFieldError
                        error={errors?.highschoolDepartmentOther?.[0]}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div className="select-inputs-container mb-14">
                  <div className="width-49 mb-14">
                    <SelectInput
                      data={highschoolYear}
                      onChange={setSelectedHighschoolYear}
                      selectedOption={selectedHighschoolYear}
                    />
                    {selectedHighschoolYear.value === null ? (
                      <InputFieldError error={errors?.highschoolYear?.[0]} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="width-49 mb-14">
                    <SelectInput
                      data={highschoolGBA}
                      onChange={setSelectedHighschoolGBA}
                      selectedOption={selectedHighschoolGBA}
                    />
                    {selectedHighschoolGBA.value === null ? (
                      <InputFieldError error={errors?.highschoolGBA?.[0]} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : selectedOccupation === 2 ? (
              <div className="faculties-container">
                <Text16 fontWeight={900}>
                  {t("my_profile.interests.faculties_heading")} *
                </Text16>
                <SelectInput
                  inputLabel={t("")}
                  data={faculties}
                  onChange={setSelectedFaculty}
                  selectedOption={selectedFaculty}
                />
                {selectedFaculty.value === null ? (
                  <InputFieldError error={errors?.faculty?.[0]} />
                ) : (
                  ""
                )}

                {selectedFaculty.label === "Друго, Друго" ? (
                  <div style={{ marginTop: "20px" }}>
                    <InputField
                      name="faculty_name"
                      value={facultyOther}
                      placeholder={t(
                        "my_profile.interests.faculty_other_placeholder"
                      )}
                      type="text"
                      onChange={(e) => {
                        setFacultyOther(e.target.value);
                        setErrors({ ...errors, facultyOther: [] });
                      }}
                      focusColor={(props) => props.theme.blue}
                    />
                    {facultyOther === "" ? (
                      <InputFieldError error={errors?.facultyOther?.[0]} />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div style={{ marginTop: "20px" }}>
                  <SelectInput
                    inputLabel={t("")}
                    data={facultyYear}
                    onChange={setSelectedFacultyYear}
                    selectedOption={selectedFacultyYear}
                  />
                  {selectedFacultyYear.value === null ? (
                    <InputFieldError error={errors?.facultyYear?.[0]} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div className="field-of-expertise-container">
                <Text16 fontWeight={900}>
                  {t("my_profile.interests.field_of_expertise")}
                </Text16>
                <InputField
                  name="field_of_expertise"
                  value={selectedFieldOfExpertise}
                  type="text"
                  color={(props) => props.theme.gray3}
                  focusColor={true}
                  className="my-info-input"
                  placeholder={t("my_profile.interests.job_title")}
                  onChange={(e) => setSelectedFieldOfExpertise(e.target.value)}
                />
                <div style={{ marginTop: "20px" }}>
                  <InputField
                    name="company_name"
                    value={companyName}
                    type="text"
                    color={(props) => props.theme.gray3}
                    focusColor={true}
                    className="my-info-input"
                    placeholder={t("my_profile.interests.company_name")}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
            )}
            <ButtonBasic
              type="button"
              variation="formBtn"
              className="submit-button"
              text={t("general.next")}
              onClick={handleFieldsOfExpertise}
            />
          </div>
        )}

        <div className={"goal-container interest-container"}>
          {isMobile && (
            <>
              <div style={{ marginBottom: "30px" }} className="greeting-user">
                <Text13 color={props.theme.black} fontWeight={800}>
                  {t("register_steps.greet_partOne_mobile")}
                </Text13>
                <Text13
                  color={props.theme.black}
                  fontWeight={600}
                  className="greet-part-two"
                >
                  {t("register_steps.greet_partTwo_mobile")}
                </Text13>
              </div>

              <SpecHeading2 className="form-title-mobile">
                {t("register_steps.goal_partOne")} <br />
                {t("register_steps.goal_partTwo")}
              </SpecHeading2>
            </>
          )}
          <Text16 fontWeight={900}>
            {t("my_profile.interests.goals_heading")} *
          </Text16>
          {goals.length > 0 &&
            goals.map((goal, index) => (
              <div key={index} className="goal-option-container">
                <ChooseButton
                  text={goal.name}
                  checkCircle={true}
                  active={goal.id === selectedGoal}
                  id={goal.id}
                  onClick={setSelectedGoal}
                />
              </div>
            ))}
          {!selectedGoal ? <InputFieldError error={errors?.goal?.[0]} /> : ""}
          <ButtonBasic
            type="button"
            variation="formBtn"
            className="submit-button"
            text={t("general.next")}
            onClick={validateGoals}
          />
        </div>
        {/* ------------------------!!!! CODE BELOW IS NOT ACTIVE SINCE IT WAS DECIDED TO AVOID THIS QUESTION TEMPORARY !!!--------------- */}

        {/* <div className={"field-of-interests-container interest-container"}>
          <Text16 fontWeight={900}>
            {t("my_profile.interests.interests_heading")}
          </Text16>
          <Text10 className="multiple-choice">
            {t("my_profile.interests.multiple_choice")}
          </Text10>
          <div>
            {interests.length > 0 &&
              interests.map((interest, index) => (
                <div key={index} className="interest-option-container">
                  <ChooseButton
                    text={interest.name}
                    active={selectedInterests.some(
                      (selectedInterest) => selectedInterest === interest.id
                    )}
                    id={interest.id}
                    onClick={selectInterests}
                  />
                </div>
              ))}
          </div>
          <ButtonBasic
            type="button"
            text={t("general.next")}
            onClick={() => postInterests()}
          />
        </div> */}
        {/* <Text10 onClick={() => postInterests()} className="skip-onboarding">
          {t("register_steps.skip_onboarding")}
        </Text10> */}
      </RegisterStepsForm>
    </PublicFacingPageWrapper>
  );
};

export default withTheme(RegisterSteps);
