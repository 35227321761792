import React from 'react';

export const icnUsersGradient =
    <svg width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.875 13C28.29 13 30.2325 11.04 30.2325 8.625C30.2325 6.21 28.29 4.25 25.875 4.25C23.46 4.25 21.5 6.21 21.5 8.625C21.5 11.04 23.46 13 25.875 13ZM12.75 11.25C15.655 11.25 17.9825 8.905 17.9825 6C17.9825 3.095 15.655 0.75 12.75 0.75C9.845 0.75 7.5 3.095 7.5 6C7.5 8.905 9.845 11.25 12.75 11.25ZM25.875 16.5C22.6725 16.5 16.25 18.11 16.25 21.3125V23.5C16.25 24.4625 17.0375 25.25 18 25.25H33.75C34.7125 25.25 35.5 24.4625 35.5 23.5V21.3125C35.5 18.11 29.0775 16.5 25.875 16.5ZM12.75 14.75C8.6725 14.75 0.5 16.7975 0.5 20.875V23.5C0.5 24.4625 1.2875 25.25 2.25 25.25H12.75V21.3125C12.75 19.825 13.3275 17.2175 16.8975 15.24C15.375 14.925 13.905 14.75 12.75 14.75Z" fill="url(#paint0_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="18" y1="0.75" x2="18" y2="25.25" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F9F9F9" />
                <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
            </linearGradient>
        </defs>
    </svg>