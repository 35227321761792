import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import ButtonBasic from '../../Components/ButtonBasic/ButtonBasic';
import InputField from '../../Components/InputField/InputField';
import InputFieldError from '../../Components/InputField/InputFieldError';
import { SpecHeading2, Heading20, Text13, Text10 } from "../../Components/Text/Text";
import Wrapper from '../../Components/Wrapper/Wrapper';
import { API } from '../../Consts/Api';
import Icon from '../../Components/Icon/Icon';
import { useTranslation, Trans } from 'react-i18next';

//styled-components
const ForgotPasswordContainer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.green};
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    &>div {
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 500px){
            width: 100%;
            flex-direction: column;
        }
    }
    @media (max-width: 768px){
        flex-direction: column;
        justify-content: flex-end;
    }
`
const ForgotPasswordHeadLine = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px){
        width: 100%;
        padding:8%;
    }
    ${SpecHeading2}, ${Heading20}{
        color: ${props => props.theme.beige};
    }
    ${SpecHeading2}{
        @media (max-width: 1031px){
            font-size: 52px;
            line-height: 60px;
        }
        
        @media (max-width: 910px){
            font-size: 48px;
            line-height: 52px;
        }
        @media (max-width: 768px){
            font-size: 36px;
            line-height: 48px;
        }

        @media (max-width: 500px), (max-height: 500px){
            font-size: 26px;
            line-height: 30px;
        }

    
    }
`

const ForgotPasswordForm = styled.form`
    width: 50%;
    height: 100vh;
    padding: 0 74px;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    display: flex;
    h5, p{
        align-self: flex-start;
    }
    h5{
        margin-bottom: 30px;
    }
    .forgot-password-instructions{
        margin-bottom: 55px;
    }
    
    @media (max-width: 768px){
        width: 100%;
        height: unset;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        padding: 8%;
    }
`

const ResetPasswordField = styled.div`
    display: ${props => props.response ? "none" : "block"};
`

const InputContainer = styled.div`
    width: 100%;
    margin-bottom: 40px;
    @media (max-width: 768px){
        margin-bottom: 10px;
    }
`

const ButtonContainer = styled.div`
    align-self: flex-end;
`

const ConfirmReset = styled.div`
    display: ${props => props.response ? "block" : "none"};
    .password-changed-icon{
        width: 62px;
        height: 62px;
        margin-bottom: 60px;
        background-color: ${props => props.theme.success};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    a{
        color: ${props => props.theme.blue};
    }
`

const ErrorReset = styled.div`
    display: ${props => props.response ? "flex" : "none"};
    flex-direction: column;
    .error-icon{
        width: 62px;
        height: 62px;
        margin-bottom: 60px;
        background-color: ${props => props.theme.incorrect};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .confirm-password-instructions{
        margin-bottom: 40px;
    }
    .input-label{
        color: ${props => props.theme.gray6};
        font-size: 11px;
        margin-bottom: 6px;
    }
    input{
        margin-bottom: 60px;
    }
    button{
        align-self: flex-end;
    }
`

const ConfirmResend = styled.div`
    display: ${props => props.response ? "block" : "none"};
    .send-icon-container{
        width: 62px;
        height: 62px;
        margin-bottom: 60px;
        border-radius: 50%;
        background-color: ${props => props.theme.gray4};
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .resend-button {
        color: ${props => props.theme.blue};
        &:hover{
            cursor: pointer;
        }
    }
    span{
        color: ${props => props.theme.red};
    }
    .confirm-password-instructions{
        margin-bottom: 55px;
    }
    .resend-cta{
        display: ${props => props.resend ? "none" : "block"};
    }
    .confirm-resend-message {
        display: ${props => props.resend ? "block" : "none"};
    }
`


//component
const ResetPassword = (props) => {
    const { t } = useTranslation()
    const resetErrors = { newpassword: [] };
    const [errors, setErrors] = useState(resetErrors);
    const [passwords, setPasswords] = useState({ password: "", password_confirmation: "" });
    const [renderForm, setRenderForm] = useState(false)
    const [confirmReset, setConfirmReset] = useState(false);
    const [errorReset, setErrorReset] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [confirmResend, setConfirmResend] = useState(false);
    const [email, setEmail] = useState({ email: [] })
    const emailRef = useRef();

    //on mount, check if token is valid in backend, if it is then mount the component (setRenderForm(true)), 
    //if it is not valid, then setErrorReset to true so that ErrorReset component is displayed and we ask for a mail address to essentially send a new token to
    useEffect(() => {
        API.axios.get(API.createApiRoute("resetPassword", { RESET_TOKEN: props.reset_token }))
            .then(() => { setRenderForm(true) })
            .catch(() => {
                // if (error.response.data.msg === "Token invalid") {
                // }
                setConfirmReset(false);
                setErrorReset(true);
                setRenderForm(true);
            })
    }, [props.reset_token])



    const setNewPassword = useCallback(() => {
        if (passwords.newPassword === passwords.confirmNewPassword) {
            API.axios.post(API.createApiRoute("resetPassword", { RESET_TOKEN: props.reset_token }), passwords)
                .then(response => {
                    setConfirmReset(true)
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        setErrors({ ...resetErrors, ...error.response.data.errors });
                    } else {
                        setErrors({ ...errors, additional: "An error occured. Please try again later." });
                    }
                })
        } else if (passwords.newPassword === "" || passwords.confirmNewPassword === "") {
            setErrors({ ...resetErrors, newPassword: ["You must choose a new password..."] })
        } else {
            setErrors({ ...resetErrors, newPassword: ["Passwords Must Match"] })
        }
    }, [passwords, errors, resetErrors, props.reset_token])


    const requestPasswordResetLink = useCallback(() => {
        API.axios.post(API.createApiRoute("recoverPassword"), email)
            .then(() => setShowResend(true))
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors({ ...resetErrors, ...error.response.data.errors });
                } else {
                    setErrors({ ...errors, additional: "An error occured. Please try again later." });
                }
            })
    }, [setShowResend, setErrors, email, resetErrors, errors])

    const resendPasswordResetLink = () => {
        API.axios.post(API.routes.recoverPassword, email)
            .then(response => {
                if (response.data.success === true) {
                    setConfirmResend(true)
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors({ ...resetErrors, ...error.response.data.errors });
                } else {
                    setErrors({ ...errors, additional: "An error occured. Please try again later." });
                }
            })
    }

    const submitOnEnter = useCallback((e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            if (!confirmReset && !errorReset) {
                setNewPassword();
            }
            if (errorReset) {
                requestPasswordResetLink();
            }
        }
    }, [setNewPassword, requestPasswordResetLink, confirmReset, errorReset])

    //submit form on enter
    useEffect(() => {
        window.addEventListener('keydown', submitOnEnter);

        return () => {
            window.removeEventListener('keydown', submitOnEnter);
        };
    }, [passwords, submitOnEnter]);
    return (
        <ForgotPasswordContainer>
            <Wrapper>
                <ForgotPasswordHeadLine>
                    <SpecHeading2><Trans key={t}>Внеси лозинка <br /> погрешно <br /> погрешно <br /> погрешно <br /> промени ?</Trans></SpecHeading2>
                    <Heading20>{t("forgot_password.header")}</Heading20>
                </ForgotPasswordHeadLine>
                <ForgotPasswordForm>
                    {renderForm
                        && <React.Fragment>
                            <ResetPasswordField response={confirmReset ? confirmReset : errorReset && errorReset}>
                                <Heading20>{t("forgot_password.reset.reset_password.header")}</Heading20>
                                <Text13 className="forgot-password-instructions"><Trans>Внеси нова лозинка <br /> Brainster те советува да имаш силна лозинка. Ако твојата лозинка е премногу слаба, осигурај се дека: <br /> <br /> Користиш најмалку 6 карактери <br /> Користиш комбинација од големи и мали карактери <br /> Користиш најмалку 1 бројка <br /> Користиш најмалку еден специјален карактер како '.','&' или '@'</Trans></Text13>
                                <InputFieldError />
                                <InputContainer>
                                    <InputField
                                        name="new-password"
                                        defaultValue={passwords.newpassword}
                                        inputLabel={t("forgot_password.reset.reset_password.new_password")}
                                        type="password"
                                        autocomplete="new-password"
                                        onChange={e => { setPasswords({ ...passwords, password: e.target.value }) }}
                                    />
                                    <InputField
                                        name="confirm-new-password"
                                        defaultValue={passwords.confirmNewPassword}
                                        inputLabel={t("forgot_password.reset.reset_password.confirm_new_password")}
                                        type="password"
                                        autocomplete="new-password"
                                        onChange={e => { setPasswords({ ...passwords, password_confirmation: e.target.value }) }}
                                    />
                                    <InputFieldError error={errors.password} />
                                </InputContainer>
                                <ButtonContainer>
                                    <ButtonBasic
                                        text={t("general.submit")}
                                        variation="primary"
                                        type="button"
                                        onClick={(e) => setNewPassword(e)}
                                    />
                                </ButtonContainer>
                            </ResetPasswordField>
                            <ConfirmReset response={confirmReset}>
                                <div className="password-changed-icon">
                                    <Icon icon="icnSend" />
                                </div>
                                <Heading20>{t("forgot_password.reset.confirm_reset.header")}</Heading20>
                                <Text13 className="forgot-password-instructions">{t("forgot_password.reset.confirm_reset.description")}<br /><Trans i18nKey={t}>Please <span> sign in </span> to continue</Trans></Text13>
                            </ConfirmReset>
                            {!showResend
                                && <ErrorReset response={errorReset}>
                                    <div className="error-icon">
                                        <Icon icon="icnX" width={20} height={20} pathFill={props.theme.white} />
                                    </div>
                                    <Heading20>{t("forgot_password.reset.link_expired.header")}</Heading20>
                                    <Text13 className="confirm-password-instructions">{t("forgot_password.reset.link_expired.description")}</Text13>
                                    <InputField
                                        name="email"
                                        ref={emailRef}
                                        defaultValue={email.email}
                                        inputLabel={t("general.email")}
                                        type="text"
                                        autocomplete="username"
                                        onChange={e => setEmail({ ...email, email: e.target.value })}
                                    />
                                    <ButtonBasic
                                        text={t("general.submit")}
                                        variation="primary"
                                        disabled={email === ""}
                                        type="button"
                                        onClick={(e) => requestPasswordResetLink(e)}
                                    />
                                </ErrorReset>}
                        </React.Fragment>
                    }
                    {showResend
                        && <ConfirmResend response={showResend} resend={confirmResend}>
                            <div className="send-icon-container">
                                <Icon icon="icnSend" />
                            </div>
                            <Heading20>{t("forgot_password.reset.confirm_resend.header")}.</Heading20>
                            <Text13 className="confirm-password-instructions">{t("forgot_password.reset.confirm_resend.description")}</Text13>
                            <Text10 className="resend-cta">{t("forgot_password.reset.confirm_resend.no_link")}? <span className="resend-button" onClick={() => resendPasswordResetLink()}>{t("general.resend")}</span></Text10>
                            <Text10 className="confirm-resend-message">{t("forgot_password.reset.confirm_resend.confirm_resend_message")}</Text10>
                        </ConfirmResend>}
                </ForgotPasswordForm>
            </Wrapper>
        </ForgotPasswordContainer>
    );
}

export default withTheme(ResetPassword);