export const highschoolYear = [
  { value: null, label: "Која година си?" },
  { value: "Прва година", label: "Прва година" },
  { value: "Втора година", label: "Втора година" },
  { value: "Трета година", label: "Трета година" },
  { value: "Четврта година", label: "Четврта година" },
];

export const highschoolDepartment = [
  { value: null, label: "Смер / Подрајче" },
  { value: "ОХА", label: "ОХА" },
  { value: "ОХБ", label: "ОХБ" },
  { value: "ПМА", label: "ПМА" },
  { value: "ПМБ", label: "ПМБ" },
  { value: "ЈУА", label: "ЈУА" },
  { value: "ЈУБ", label: "ЈУБ" },
  { value: "Друго", label: "Друго" },
];

export const highschoolGBA = [
  { value: null, label: "Просек (во моментот)" },
  { value: "Одличен", label: "Одличен" },
  { value: "Многу добар", label: "Многу добар" },
  { value: "Добар", label: "Добар" },
  { value: "Доволен", label: "Доволен" },
];

export const facultyYear = [
  { value: null, label: "Година на факултет" },
  { value: "Прва година", label: "Прва година" },
  { value: "Втора година", label: "Втора година" },
  { value: "Трета година", label: "Трета година" },
  { value: "Магистерски студии", label: "Магистерски студии" },
  { value: "Докторски студии", label: "Докторски студии" },
];
