import { API } from '../Consts/Api';
import { navigate } from "@reach/router"


export const logout = (callback, navigateTo) => {
    API.axios.get(API.createApiRoute("studentLogout"))
        .then(response => {
            if (response.status === 200) {
                localStorage.removeItem('user')
                callback();
                navigate(navigateTo || API.appRoutes.signin)
            }
        }).catch(error => {
            navigate(API.appRoutes.catalogue);
        })
}
