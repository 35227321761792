import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import {
  CardCaption10,
  Heading20,
  Heading53,
} from "../../../../Components/Text/Text";
import ButtonBasic from "../../../../Components/ButtonBasic/ButtonBasic";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import Icon from "../../../../Components/Icon/Icon";
import theme from "../../../../Consts/Theme";
import CourseMobileBackground from "../../../Course/Blocks/CourseMobileBackground/CourseMobileBackground";
import CaptionInfo from "../../../../Components/CaptionInfo/CaptionInfo";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { API } from "../../../../Consts/Api";
import Skeleton from "../../../../Components/Skeleton/Skeleton";

const StyledContainer = styled(Wrapper)`
  flex-direction: row;
  margin-top: 100px;
  display: block;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: start;
    margin-top: -7vh;
    width: 100%;

    padding: 0 16px;
  }
`;
const ContentInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  ${CardCaption10} {
    font-size: 9px;
  }

  .caption-info {
    margin-left: 4px;
  }
  div.divider {
    align-self: center;
    background-color: ${(props) => props.theme.gray6};
    width: 0.75px;
    height: 9.061px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CourseHeadingText = styled.div`
  width: 60%;
  padding-right: 5%;

  .header-btn {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    padding-right: 0px;
    width: 100%;
    h3 {
      font-size: 28px;
      line-height: 33px;
    }

    h4 {
      font-size: 13px;
      line-height: 16px;
    }
    h5 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      text-transform: capitalize;
    }

    p {
      line-height: 16px;
    }
  }

  @media (max-width: 500px) {
    .header-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 42px;
    }
  }
`;
const CourseTitleContainer = styled.div`
  margin-bottom: 15px;
  @media (max-width: 768px) {
    margin-bottom: 5px;
    width: 80%;
  }
`;
const CourseSubtitleContainer = styled.div`
  margin-bottom: 35px;

  ${Heading20} {
    font-weight: 700;
  }
  @media (max-width: 768px) {
    margin-bottom: 15px;
    width: 90%;
  }
`;

const CourseHeadingImg = styled.img`
  width: 100%;
  display: block;
`;
const StartLearningBtnContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 35px;
    button {
      width: 100%;
      text-align: center;
      display: inline-block;
      padding: 18px 0;
    }
  }
`;
const CourseHeadingImgContainer = styled.div`
  width: 40%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CaptionInfoWrapper = styled.div`
  width: fit-content;
  margin-bottom: 48px;
`;
const InnerContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
`;
function NewestContent(props) {
  const data = props.data;




  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 500 });

  const calculateTime = (number) => {
    if (typeof number !== "number" || number < 0) {
      return;
    }

    if (number < 60) {
      return `${number} MIN`;
    }

    const hours = Math.floor(number / 60);
    const minutes = number % 60;

    if (minutes === 0) {
      return `${hours}H`;
    }

    return `${hours}H ${minutes}MIN`;
  };

  const startLearning = (learningPathID) => {
    navigate(
      API.createRoute("getLearningPath", { LEARNINGPATH_ID: learningPathID })
    );
  };

  const getTotalDuration = (array) => {
    const allDurations = array.map((item) => item.duration);
    const sumDuration = allDurations.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    return calculateTime(sumDuration);
  };
  return data?.length > 0 ? data?.map((item) => (
        <div key={item.id} style={{ width: "100%" }}>
          <CourseMobileBackground image={item.image} bgColor={theme.black} />
          <StyledContainer className="heading-container">
            <InnerContainer>
              <CourseHeadingText>
                <CaptionInfoWrapper>
                  <CaptionInfo border="1px solid #302F38" borderRadius="25px" />
                </CaptionInfoWrapper>
                <ContentInfoContainer>
                  <IconWrapper>
                    <svg
                      width="8"
                      height="10"
                      viewBox="0 0 8 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7.97518V2.21484C1 1.66256 1.44772 1.21484 2 1.21484H7.16702V7.15575"
                        stroke="#5D6174"
                        stroke-width="0.75"
                      />
                      <rect
                        x="2.02783"
                        y="2.64844"
                        width="4.11135"
                        height="1.02429"
                        rx="0.512147"
                        stroke="#5D6174"
                        stroke-width="0.75"
                      />
                      <path
                        d="M7.16702 7.15625H1.92186C1.41273 7.15625 1 7.56898 1 8.07811V8.07811C1 8.58725 1.41273 8.99998 1.92186 8.99998H7.16702"
                        stroke="#5D6174"
                        stroke-width="0.75"
                        stroke-linecap="square"
                      />
                    </svg>
                    <CardCaption10
                      className="caption-info"
                      color={theme.gray6}
                      fontWeight={400}
                    >
                      {item.courses.length}{" "}
                      {t("catalogue.featured_courses.total_courses")}
                    </CardCaption10>
                  </IconWrapper>
                  <IconWrapper>
                    <svg
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.88889 4.58288V2.87401M6.41422 6.075L5 4.7156M9 4.68969C9 6.8132 7.20914 8.53465 5 8.53465C2.79086 8.53465 1 6.8132 1 4.68969C1 2.56617 2.79086 0.844727 5 0.844727C7.20914 0.844727 9 2.56617 9 4.68969Z"
                        stroke="#5D6174"
                        stroke-width="0.75"
                        stroke-linecap="round"
                      />
                    </svg>
                    <CardCaption10
                      className="caption-info"
                      color={theme.gray6}
                      fontWeight={400}
                    >
                      {getTotalDuration(item.courses)}
                    </CardCaption10>
                    <div className="divider"></div>

                    <svg
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.38948 8.15972C0.567299 5.86837 2.36007 4.10352 4.5 4.10352C6.63993 4.10352 8.4327 5.86837 8.61052 8.15972H0.38948Z"
                        stroke="#5D6174"
                        stroke-width="0.75"
                      />
                      <path
                        d="M6.77214 2.68215C6.77214 3.86648 5.76902 4.85161 4.50009 4.85161C3.23115 4.85161 2.22803 3.86648 2.22803 2.68215C2.22803 1.49783 3.23115 0.512695 4.50009 0.512695C5.76902 0.512695 6.77214 1.49783 6.77214 2.68215Z"
                        fill="white"
                        stroke="#5D6174"
                        stroke-width="0.75"
                      />
                    </svg>
                    <CardCaption10
                      className="caption-info"
                      color={theme.gray6}
                      fontWeight={400}
                    >
                      {item.total_students}{" "}
                      {item.total_students > 1
                        ? t("catalogue.featured_courses.total_students_plural")
                        : t(
                            "catalogue.featured_courses.total_students_singular"
                          )}
                    </CardCaption10>
                  </IconWrapper>
                </ContentInfoContainer>
                <CourseTitleContainer>
                 <Heading53>{item.title}</Heading53>
                </CourseTitleContainer>
                <CourseSubtitleContainer>
                  <Heading20>{item.subtitle}</Heading20>
                </CourseSubtitleContainer>
                <StartLearningBtnContainer className="header-btn">
                  <ButtonBasic
                    text={
                      item.progress === 0
                        ? t("general.start_learning")
                        : item.progress >= 100
                        ? t("general.learn_again")
                        : t("general.continue")
                    }
                    onClick={() => startLearning(item.id)}
                  />
                </StartLearningBtnContainer>
              </CourseHeadingText>
              <CourseHeadingImgContainer
                isExclusiveCourse={props.isExclusiveCourse}
              >
               <CourseHeadingImg src={item.image} alt="course_image" />
              </CourseHeadingImgContainer>
            </InnerContainer>
          </StyledContainer>
        </div>
      )) : ""
    
}

export default NewestContent;
