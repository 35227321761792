import { useCallback } from 'react';
import { useState } from 'react';
import { API } from '../../Consts/Api';

const useResetCourseProgress = () => {
    const [courseProgress, setCourseProgress] = useState()

    const resetCourseProgress = useCallback((courseId, value) => {
        if (courseId) {
            API.axios.get(API.createApiRoute("resetCourse", { COURSE_ID: courseId }))
                .then(() => setCourseProgress(0))
                .catch(() => setCourseProgress(0))
        } else {
            setCourseProgress(value)
        }
    }, [setCourseProgress])
    return [courseProgress, resetCourseProgress];
}

export default useResetCourseProgress;