import React, { useState } from 'react';
import Select from 'react-select';

//npm imports
import styled from 'styled-components';

//component imports
import { Caption12 } from '../Text/Text';

//styled-components
const SelectInputWrapper = styled.div`
    .css-yk16xz-control, .css-1pahdxg-control{
        border-color: ${(props) => props.isSelectOpen ? props.theme.ComputerScience : props => props.theme.gray3};
        border-radius: 7px;
        height: 60px;

        svg {
            height: 15px;
            width: 15px;
        }

        &:hover {
            border: 1px solid ${props => props.theme.ComputerScience};
        }
     
    }
    .css-1pahdxg-control{
        outline: none!important;
        box-shadow: 0 0 0 2px transparent!important;
        /* border-color: ${props => props.theme.gray3}!important; */
    }

    .css-1hb7zxy-IndicatorsContainer{
   
        svg{
            transform: scale(1.6);
            margin-right: 11px;
        }
    }
    .input-label{
        margin-bottom: 8px;
    }
    .select-input__single-value {
        letter-spacing: 1px;
        font-family: ${props => props.typeOfValue === 'number' ? 'Manrope' : 'Raleway'};
        font-size: 14px;

    }

    .select-input__option{
        letter-spacing: 1px;
        font-size: 14px;
        font-family: ${props => props.typeOfValue === 'number' ? 'Manrope' : 'Raleway'};
        &:hover{
            cursor: pointer;
        }
    }

    @media (max-width: 500px){

        ${Caption12} {
            font-size: 14px !important;
        }
        .css-yk16xz-control, .css-1pahdxg-control{
            height: 55px;
            border-radius: 8px;
        }
        .css-g1d714-ValueContainer{
            flex: 1;
        }
        .css-1hb7zxy-IndicatorsContainer{
            width: 25px;
            .css-1mbzne4-indicatorContainer, .css-1hkm3ox-indicatorContainer, .css-1f5px50-indicatorContainer {
                width: 100%;
                padding: 0px!important;
            }
            svg{
                transform: scale(1.6);
                margin-right: 6px;
            }
        }
    }
`

//component
const SelectInput = (props) => {
    const [isSelectOpen, setIsSelectOpen] = useState();
    const customStyles = {
        indicatorSeparator: () => ({
            display: "none"
        }),
        dropdownIndicator: (provided) => {
            const color = isSelectOpen ? "#234BDB" : "#B9BBC4"
            return ({
                ...provided,
                color
            })
        }
    }



    return (
        <SelectInputWrapper className={props.className} isSelectOpen={isSelectOpen} typeOfValue={props.typeOfValue}>
            <Caption12 className="input-label" >{props.inputLabel}</Caption12>
            <Select
                defaultValue={props.defaultValue && props.defaultValue}
                value={props.selectedOption}
                onChange={props.onChange}
                onMenuOpen={() => setIsSelectOpen(true)}
                onMenuClose={() => setIsSelectOpen(false)}
                options={props.data}
                isSearchable={true}
                styles={customStyles}
                placeholder={false}
                onFocus={() => props.setIsSelectFocused && props.setIsSelectFocused(true)}
                onBlur={() => props.setIsSelectFocused && props.setIsSelectFocused(false)}
                escapeClearsValue={true}
                menuShouldScrollIntoView={true}
                classNamePrefix="select-input"
            />
        </SelectInputWrapper>

    );
}

export default SelectInput;