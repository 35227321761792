import React from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import { Caption16, Heading35, Text13, Text16 } from '../../../../Components/Text/Text';

//other imports

//styled-components
const PremiumClassroomWrapper = styled.div`
    width: 100%;
    padding: 80px 0;
    background-color: ${props => props.theme.blue};

    ${Caption16}{
        letter-spacing: 3px;
        font-weight: 600;
    }
    h1,h2,h3,h4,h5,h6,p,span{
        color: ${props => props.theme.white};
    }
    .content-container{
        width: 100%;
        max-width: 950px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4{
            margin-top: 30px;
            margin-bottom: 50px;
        }
        .classroom-perks-container{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 80px;
            .white-dash{
                width: 5%;
                height: 1px;
                background-color: ${props => props.theme.white};
                opacity: 0.3;
            }
            .classroom-perks-item{
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                p{
                    text-align: center;
                    line-height: 20px;
                    margin-top: 50px;
                }
            }
        }
    }
    @media (max-width: 500px){
        padding: 66px 0;
        ${Caption16}{
            font-size: 12px;
            line-height: 20px;
        }
        .content-container{
            width: 90%;
            margin: 0 auto;
            align-items: flex-start;
            h4{
                font-size: 28px;
                line-height: 32px;
                text-align: left;
                margin-top: 24px;
                margin-bottom: 40px;
            }
            &>p:last-of-type{
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 11px;
            }
            .classroom-perks-container{
                flex-direction: column;
                align-items: flex-start;
                margin-top: 0;
                .white-dash{
                    display: none;
                }
                .classroom-perks-item{
                    width: 59%;
                    padding-left: 24px;
                    align-items: flex-start;
                    margin-top: 62px;
                    p{
                        text-align: left;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
`

const envelopeIcon = <svg width="76" height="60" viewBox="0 0 76 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M68 7.5H38L32.7125 2.2125C31.2875 0.787501 29.375 0 27.3875 0H8C3.875 0 0.5 3.375 0.5 7.5V52.5C0.5 56.625 3.875 60 8 60H68C72.125 60 75.5 56.625 75.5 52.5V15C75.5 10.875 72.125 7.5 68 7.5ZM56.525 46.5375L49.25 42.3L41.975 46.5375C40.55 47.3625 38.825 46.0875 39.2 44.475L41.1125 36.225L34.775 30.75C33.5375 29.6625 34.175 27.6 35.825 27.45L44.1875 26.7375L47.4875 19.0125C48.125 17.5125 50.3 17.5125 50.9375 19.0125L54.2375 26.7375L62.6 27.45C64.25 27.6 64.925 29.6625 63.65 30.75L57.3125 36.225L59.225 44.475C59.6375 46.0875 57.9125 47.3625 56.525 46.5375Z" fill="url(#paint0_linear)" />
    <defs>
        <linearGradient id="paint0_linear" x1="38" y1="0" x2="38" y2="60" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
    </defs>
</svg>

const rocketIcon = <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.49041 41.6507C9.12245 42.3847 9.26577 43.2719 9.84549 43.8521L10.3111 44.3177C11.6566 42.7234 13.2246 40.9932 15.1264 39.0918C15.2608 38.9574 15.3992 38.8925 15.5341 38.7685L12.5383 35.7722C10.7966 38.9222 9.78102 41.0745 9.49041 41.6507Z" fill="url(#paint0_linear)" />
    <path d="M25.8965 49.8638C23.9902 51.77 22.2644 53.3411 20.679 54.6875L21.3098 55.3183C21.895 55.9035 22.7906 56.0443 23.527 55.6654C24.5134 55.1571 26.0646 54.4271 29.3574 52.5933L26.2203 49.4561C26.0963 49.5915 26.0314 49.7294 25.8965 49.8638Z" fill="url(#paint1_linear)" />
    <path d="M24.8769 40.1077C23.5008 38.7315 19.6307 39.9654 17.8127 41.7829C11.7309 47.8647 8.984 52.1767 7.75067 54.7227C7.39709 55.4522 7.54437 56.3255 8.11765 56.8987C8.69191 57.4735 9.5667 57.6193 10.2967 57.2657C12.7857 56.0572 17.045 53.3232 23.1993 47.1695C25.1983 45.1694 26.1847 41.4159 24.8784 40.1092L24.8769 40.1077Z" fill="url(#paint2_linear)" />
    <path d="M5.94681 25.3546L0.560728 30.7407C-0.04825 31.3496 -0.173219 32.2924 0.258223 33.0392C0.679251 33.7761 1.56098 34.1515 2.40006 33.9269C5.31106 33.1463 7.87243 32.8165 9.59721 33.2589C12.051 28.8964 14.6877 24.8805 17.4802 21.2325C15.865 20.1971 10.5275 20.7738 5.94681 25.3546Z" fill="url(#paint3_linear)" />
    <path d="M32.2311 64.7951C32.9645 65.1661 33.8527 65.0228 34.4334 64.4416L39.82 59.0555C43.9901 54.8844 44.7241 49.5027 43.7779 47.7963C39.7094 50.8625 35.5879 53.4517 31.7426 55.6193C32.0079 57.1175 31.8314 58.571 31.2041 62.816C31.0816 63.6377 31.5061 64.4326 32.2311 64.7951Z" fill="url(#paint4_linear)" />
    <path d="M37.8057 19.1027C35.5786 21.3304 35.5786 24.954 37.8057 27.1816C40.0334 29.4092 43.6575 29.4092 45.8851 27.1816C48.1128 24.954 48.1128 21.3304 45.8851 19.1027C43.6575 16.8751 40.0334 16.8751 37.8057 19.1027Z" fill="url(#paint5_linear)" />
    <path d="M64.8883 1.85898C64.8199 0.917247 64.0701 0.172885 63.1313 0.102466C58.4544 -0.249631 53.7957 0.325626 49.2294 1.72211C49.5731 4.85924 51.1957 8.25424 53.9658 11.0244C56.7692 13.8273 60.2565 15.4494 63.4497 15.7529C64.6795 11.443 65.2627 6.82701 64.8883 1.85898Z" fill="url(#paint6_linear)" />
    <path d="M51.2725 13.7167C48.1037 10.5478 46.1686 6.74714 45.5477 3.04665C34.1125 7.73648 23.1658 17.978 14.5156 32.3654L18.8132 36.6629C21.7495 35.4708 25.3141 35.1559 27.575 37.4162C29.8388 39.6806 29.5179 43.2437 28.3263 46.176L32.7652 50.6149C43.8349 43.9434 56.7226 33.4326 62.2029 19.4484C58.4122 18.8756 54.493 16.9371 51.2725 13.7167ZM48.5788 29.8749C44.8669 33.5868 38.8262 33.5868 35.1138 29.8749C31.4014 26.1625 31.4014 20.1214 35.1138 16.4095C38.8262 12.6976 44.8669 12.6976 48.5788 16.4095C52.2911 20.1219 52.2911 26.1625 48.5788 29.8749Z" fill="url(#paint7_linear)" />
    <defs>
        <linearGradient id="paint0_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id="paint4_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id="paint5_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id="paint6_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id="paint7_linear" x1="32.5017" y1="0.00146484" x2="32.5017" y2="64.9995" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
    </defs>
</svg>

const usersIcon = <svg width="84" height="60" viewBox="0 0 84 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60.7497 29.9997C66.4997 29.9997 71.1247 25.333 71.1247 19.583C71.1247 13.833 66.4997 9.16634 60.7497 9.16634C54.9997 9.16634 50.333 13.833 50.333 19.583C50.333 25.333 54.9997 29.9997 60.7497 29.9997ZM29.4997 25.833C36.4163 25.833 41.958 20.2497 41.958 13.333C41.958 6.41634 36.4163 0.833008 29.4997 0.833008C22.583 0.833008 16.9997 6.41634 16.9997 13.333C16.9997 20.2497 22.583 25.833 29.4997 25.833ZM60.7497 38.333C53.1247 38.333 37.833 42.1663 37.833 49.7914V54.9997C37.833 57.2914 39.708 59.1664 41.9997 59.1664H79.4997C81.7913 59.1664 83.6664 57.2914 83.6664 54.9997V49.7914C83.6664 42.1663 68.3747 38.333 60.7497 38.333ZM29.4997 34.1663C19.7913 34.1663 0.333008 39.0413 0.333008 48.7497V54.9997C0.333008 57.2914 2.20801 59.1664 4.49967 59.1664H29.4997V49.7914C29.4997 46.2497 30.8747 40.0413 39.3747 35.333C35.7497 34.583 32.2497 34.1663 29.4997 34.1663Z" fill="url(#paint0_linear)" />
    <defs>
        <linearGradient id="paint0_linear" x1="41.9997" y1="0.833008" x2="41.9997" y2="59.1664" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
        </linearGradient>
    </defs>
</svg>



const PremiumClassroom = (props) => {
    return (
        <PremiumClassroomWrapper>
            <div className="content-container">

                <Caption16>Една поинаква училница</Caption16>
                <Heading35>Поинаков пристап на онлајн учење</Heading35>
                <Text13>Ова нема да биде типичен онлајн курс кој можете да го следите насекаде. Ова ќе биде интензивно, динамично искуство каде ќе учите со темпото кое ви одговара, ќе ги имате инструкторите на располагање за прашања во нивните Q&A сесии и ќе работите два денови во неделата онлајн со нив! Оваа училница е трансофрмирана и целосно дигитализирана, па така ќе ви дозволи да ги следите часовите од вашиот дом, на планина, покрај езеро или море, или каде и да се наоѓате. Ќе ве поврземе со заедница која сака да создава и трча кон нови предизвици!</Text13>
                <div className="classroom-perks-container">
                    <div className="classroom-perks-item">
                        {usersIcon}
                        <Text16 fontWeight="700">Инструктори на дофат во секое време и на секој чекор</Text16>
                    </div>
                    <div className="white-dash"></div>
                    <div className="classroom-perks-item">
                        {rocketIcon}
                        <Text16 fontWeight="700">Приклучување на заедница која споделува и решава предизвици</Text16>
                    </div>
                    <div className="white-dash"></div>
                    <div className="classroom-perks-item">
                        {envelopeIcon}
                        <Text16 fontWeight="700">4 проекти кои може да се користат на најразлични начини</Text16>
                    </div>
                </div>

            </div>
        </PremiumClassroomWrapper>
    );
}

export default PremiumClassroom;