import React, { useContext, useEffect, useState } from 'react';

//imports
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

//component imports
import MyCoursesNavigation from './Blocks/MyCoursesNavigation/MyCoursesNavigation';
import SliderBlock from '../../Components/SliderBlock/SliderBlock';
import RafBlock from '../../Components/RafBlock/RafBlock';
import CtaBlock from '../../Components/CtaBlock/CtaBlock';
import { SpecHeadingSmall } from '../../Components/Text/Text';
import { CtaBlockCommunity } from '../../Components/CtaBlocks/CtaBlocks';

//other imports
import { API } from '../../Consts/Api';
import ClippedRectanglePurple from '../../Static/illustrations/CtaBlockCommunity/ClippedRectanglePurple.svg';
import ScribbledLeaf from '../../Static/illustrations/CtaBlockCommunity/ScribbledLeaf.svg';
import YellowSlice from '../../Static/illustrations/CtaBlockCommunity/YellowSlice.svg';
import { GlobalContext } from '../../Context/GlobalContext';
import Loader from '../../Components/Loader/Loader';


//styled-components
const MyCoursesContainer = styled.div`
    width: 100%;
    .gray-bg{
        background-color: ${props => props.theme.gray3};
    }
    &>div.continue-courses-slider{
        padding-bottom: 0px;
        padding-top: 40px;
        .slider-block-heading{
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 23px;
            height: unset;
        }
        .swiper-container-horizontal{
            padding-bottom: 10px;
        }
    }
    .my-courses-slider-block{
        padding-bottom: 61px;
    }
    .meet-our-community-block{
        margin-bottom: 80px;
    }
    @media (max-width: 500px){
        .my-courses-slider-block{
            padding-bottom: 31px;
        }
        .meet-our-community-block{
            margin-bottom: 40px;
        }
        &>div.continue-courses-slider{
            .slider-block-heading{
                margin-bottom: 25px;
            }
            .swiper-container-horizontal{
                padding-bottom: 30px;
            }
        }
    }
`



//component
const MyCourses = (props) => {
    const { t } = useTranslation();
    const { signedUser } = useContext(GlobalContext);
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const isLaptop = useMediaQuery({ maxWidth: 1141 });



    //keep unfinishedCourses in this hook if there are any
    const [unfinishedCourses, setUnfinishedCourses] = useState(false);

    //keep unfinishedLearningPaths in this hook if there are any
    const [unfinishedLearningPaths, setUnfinishedLearningPaths] = useState(false);

    //keep recomended courses in this hook
    const [recommendedCourses, setRecommendedCourses] = useState([]);

    //keep finished courses and or learning paths in this hook if there are any
    const [finishedCourses, setFinishedCourses] = useState([]);

    //keep related course and courses for first "Because You Watched Block" in this hook
    const [firstRelatedRecommendedCourses, setFirstRelatedRecommendedCourses] = useState({});

    //keep recomended courses from computer science if no courses are finished in this hook
    const [maybeComputerScience, setMaybeComputerScience] = useState({});

    //keep related course and courses for the second "Because You Watched Block" in this hook
    // const [secondRelatedRecommendedCourses, setSecondRelatedRecommendedCourses] = useState({});

    //keep recomended courses from computer science if no courses are finished in this hook
    // const [maybeDigitalMarketing, setMaybeDigitalMarketing] = useState({});


    //onMount get all courses and itterate to check if there is progress in them that is above 0 and if it's below 100, if so, push the course in unfinishedCourses hook array
    useEffect(() => {
        let unfinishedCourses = [];
        let unfinishedLearningPaths = [];
        let finished = [];
        API.axios.get(API.createApiRoute("unfinishedCourses"))
            .then(response => {
                response.data.data.forEach((course, i) => {
                    if (course.progress < 100) {
                        unfinishedCourses.push(course);
                    }
                    if (course.progress === 100) {
                        finished.push(course);
                    }
                })
                setFinishedCourses(finished);
                setUnfinishedCourses(unfinishedCourses);
            })
            .catch(error => console.log(error))

        API.axios.get(API.createApiRoute("unfinishedLearningPaths"))
            .then(response => {
                response.data.data.forEach((learning_path, i) => {
                    if (learning_path.progress && learning_path.progress > 0 && learning_path.progress < 100) {
                        unfinishedLearningPaths.push(learning_path);
                    }
                })
                setUnfinishedLearningPaths(unfinishedLearningPaths)

            })
            .catch(error => console.log(error))

        API.axios.get(API.createApiRoute("recommendedCoursesGeneral"))
            .then(response => {
                setRecommendedCourses(response.data.data)
            })
            .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        let relatedCourses = [];
        let relatedObject = {
            relatedCourse: {},
            courses: []
        }
        let maybeObject = {
            title: "",
            courses: []
        }
        if (finishedCourses.length > 0) {
            let offset = Math.floor(Math.random() * finishedCourses.length);
            relatedCourses.push(finishedCourses.slice(offset, offset + 1)[0])
            API.axios.get(API.createApiRoute("recommendedCoursesByCourse", { COURSE_ID: relatedCourses[0].id }))
                .then(response => setFirstRelatedRecommendedCourses({ ...relatedObject, relatedCourse: relatedCourses[0], courses: response.data.data }))
                .catch(error => console.log(error))
        } else {
            API.axios.get(API.createApiRoute("recommendedCoursesFromComputerScience"))
                .then(response => setMaybeComputerScience({ ...maybeObject, title: t("general.study_fields.computer_science"), courses: response.data.data }))
                .catch(error => console.log(error, "here"))
        }
        // if (finishedCourses.length > 1) {
        //     let offset = Math.floor(Math.random() * finishedCourses.length);
        //     relatedCourses.push(finishedCourses.slice(offset, offset + 1)[0])
        //     API.axios.get(API.createApiRoute("recommendedCoursesByCourse", { COURSE_ID: relatedCourses[1].id }))
        //         .then(response => setSecondRelatedRecommendedCourses({ ...relatedObject, relatedCourse: relatedCourses[1], courses: response.data.data }))
        //         .catch(error => console.log(error))
        // }
        // {
        //     API.axios.get(API.createApiRoute("recommendedCoursesFromDigitalMarketing"))
        //         .then(response => setMaybeDigitalMarketing({ ...maybeObject, title: t("general.study_fields.digital_marketing"), courses: response.data.data }))
        //         .catch(error => console.log(error, "there"))
        // }
    }, [finishedCourses, t]);
    return (
        signedUser.guid
            ? <MyCoursesContainer>
                {/* {unfinishedCourses.length > 1
                && <SliderBlock
                    className="gray-bg my-courses-slider-block continue-courses-slider"
                    isMobile={isMobile}
                    cardData={unfinishedCourses && unfinishedCourses}
                    cardsPerSlide={isMobile ? 1 : 2}
                    renderCard="LearningPathCard"
                    route="courses"
                    heading={t("my_courses.continue_courses")}
                    navigation={false}
                    pagination={false}
                />
            } */}

                <MyCoursesNavigation unfinishedCourses={unfinishedCourses} unfinishedLearningPaths={unfinishedLearningPaths} finishedCourses={finishedCourses} isMobile={isMobile} />

                <SliderBlock
                    className="gray-bg my-courses-slider-block"
                    isMobile={isMobile}
                    cardsPerSlide={isMobile ? 2 : isLaptop ? 3 : 4}
                    isLaptop={isLaptop}
                    cardData={recommendedCourses && recommendedCourses}
                    renderCard={"CourseCard"}
                    route={"courses"}
                    heading={t("my_courses.recomended_courses")}
                    gridTemplateColumns={isLaptop && "1fr 1fr 1fr"}
                    navigation={false}
                />

                <RafBlock />
                {firstRelatedRecommendedCourses.courses
                    ? <SliderBlock
                        className="my-courses-slider-block"
                        isMobile={isMobile}
                        cardsPerSlide={isMobile ? 2 : isLaptop ? 3 : 4}
                        cardData={firstRelatedRecommendedCourses.courses}
                        renderCard={"CourseCard"}
                        route={"courses"}
                        heading={`${t("my_courses.because_you_watched")} ${firstRelatedRecommendedCourses.relatedCourse.title}`}
                        navigation={false}
                        gridTemplateColumns={isLaptop && "1fr 1fr 1fr"}
                        exclusive={true}
                    />
                    : maybeComputerScience.courses
                    && <SliderBlock
                        className="my-courses-slider-block"
                        isMobile={isMobile}
                        cardsPerSlide={isMobile ? 2 : isLaptop ? 3 : 4}
                        cardData={maybeComputerScience.courses}
                        renderCard={"CourseCard"}
                        route={"courses"}
                        heading={`${t("my_courses.maybe_from")} ${maybeComputerScience.title}`}
                        navigation={false}
                        gridTemplateColumns={isLaptop && "1fr 1fr 1fr"}
                    />
                }

                <CtaBlockCommunity>
                    <CtaBlock
                        href="http://facebook.com"
                    >
                        <div className="children-container">
                            <div className="heading-container">
                                <SpecHeadingSmall>{t('catalogue.community_cta.introduce_yourself')}</SpecHeadingSmall>
                            </div>
                            <div className="community-illustration">
                                <img src={ClippedRectanglePurple} alt="purple clipped rectangle" className="purple-clipped-rectangle" />
                                <div className="black-circle"></div>
                                <img src={ScribbledLeaf} alt="scribbled leaf" className="scribbled-leaf" />
                                <img src={YellowSlice} alt="yellow slice" className="yellow-slice" />
                            </div>
                        </div>
                    </CtaBlock>
                </CtaBlockCommunity>

                {/* {secondRelatedRecommendedCourses.courses
                ? <SliderBlock
                    className="my-courses-slider-block"
                    isMobile={isMobile}
                    cardsPerSlide={isMobile ? 2 : isLaptop ? 3 : 4}
                    isLaptop={isLaptop}
                    cardData={secondRelatedRecommendedCourses.courses}
                    renderCard={"CourseCard"}
                    route={"courses"}
                    heading={`${t("my_courses.because_you_watched")} ${secondRelatedRecommendedCourses.relatedCourse.title}`}
                    gridTemplateColumns={isLaptop && "1fr 1fr 1fr"}
                    navigation={false}
                />
                : maybeDigitalMarketing.courses && maybeDigitalMarketing.courses.length > 0
                && <SliderBlock
                    className="my-courses-slider-block"
                    isMobile={isMobile}
                    cardsPerSlide={isMobile ? 2 : isLaptop ? 3 : 4}
                    cardData={maybeDigitalMarketing.courses}
                    renderCard={"CourseCard"}
                    route={"courses"}
                    heading={`${t("my_courses.maybe_from")} ${maybeDigitalMarketing.title}`}
                    navigation={false}
                    gridTemplateColumns={isLaptop && "1fr 1fr 1fr"}
                />
            } */}

            </MyCoursesContainer>
            : <Loader />
    );
}

export default MyCourses;