import React from 'react';

//npm imports
import styled, { keyframes } from 'styled-components';

//animations

const SkeletonAnimation = (gray1, gray7) => keyframes`
    0%{
        background-color: ${gray7};
    }
    50%{
        background-color: ${gray1};
    }
    100%{
        background-color: ${gray7};
    }
`


const SkeletonWrapper = styled.span`
    display: inline-block;
    width: 100%;
    height: 100%;
    min-height: 15px;
    min-width: 40px;
    background-color: ${props => props.theme.gray7};
    animation: ${props => SkeletonAnimation(props.theme.gray1, props.theme.gray4)} 1.5s ease-out infinite;
`

const Skeleton = () => {
    return (
        <SkeletonWrapper>

        </SkeletonWrapper>
    )
}

export default Skeleton;