import React, { useState, useEffect } from 'react';

//imports
import styled from 'styled-components';
import { API } from '../../Consts/Api';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';
import Wrapper from '../../Components/Wrapper/Wrapper';
import CourseHeading from './Blocks/CourseHeading/CourseHeading';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import CourseContent from './Blocks/CourseContent/CourseContent';
import CourseMobileBackground from './Blocks/CourseMobileBackground/CourseMobileBackground';
import useResetCourseProgress from '../../Hooks/useResetProgress/useResetProgress';
import LargeCtaBlock from '../../Components/LargeCtaBlock/LargeCtaBlock';
import Loader from '../../Components/Loader/Loader';

//styled-components
const CourseWrapper = styled(PageWrapper)`
    justify-content: flex-start;
`

//component
const Course = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [currentCourse, setCurrentCourse] = useState(false);
    const [firstLessonId, setFirstLessonId] = useState();
    const [courseProgress, resetCourseProgress] = useResetCourseProgress();

    //get adequate course from database on component mount and set it inside currentCourse hook
    useEffect(() => {
        // API.axios.get(API.routes.getCourse.replace("{COURSE_ID}", props.courseId))
        API.axios.get(API.createApiRoute('getCourse', { COURSE_ID: props.courseId }))
            .then((response) => setCurrentCourse(response.data.data))
            .catch((error) => console.log(error));


    }, [props.courseId]);

    useEffect(() => {
        if (currentCourse?.id) {
            const timeout = setTimeout(() => {
                setIsLoading(false)
            }, 1700)
            return () => clearTimeout(timeout)
        }
    }, [currentCourse])

    useEffect(() => {
        if (currentCourse && currentCourse.sections.length > 0) {
            let uncompletedLessons = [];
            currentCourse.sections && currentCourse.sections.forEach((section, index) => {
                section.lessons && section.lessons.forEach((lesson, i) => lesson.is_completed === 0 && uncompletedLessons.push(lesson))
            })
            setFirstLessonId(uncompletedLessons.length > 0 ? uncompletedLessons[0].id : currentCourse.sections[0].lessons[0].id);
            resetCourseProgress(null, currentCourse.progress);
        }
        //eslint-disable-next-line
    }, [currentCourse])

    useEffect(() => {
        courseProgress === 0 && setFirstLessonId(currentCourse.sections[0].lessons[0].id);
    }, [courseProgress, currentCourse.sections]);

    return (
        isLoading
            ? <Loader />
            :
            <CourseWrapper>
                <CourseMobileBackground image={currentCourse && currentCourse.image} />
                <CourseHeading
                    courseId={currentCourse && currentCourse.id}
                    lessonId={firstLessonId}
                    image={currentCourse && currentCourse.image}
                    courseTitle={currentCourse && currentCourse.title}
                    courseSubtitle={currentCourse && currentCourse.subtitle}
                    courseDescription={currentCourse && currentCourse.description}
                    time={currentCourse && currentCourse.duration}
                    students={currentCourse && currentCourse.total_students}
                    points={currentCourse && currentCourse.points}
                    progress={courseProgress}
                />

                <Wrapper>
                    <ProgressBar progress={courseProgress} resetCourseProgress={resetCourseProgress} title={currentCourse && currentCourse.category.name} resetId={props.courseId} studyField={currentCourse?.category?.study_field?.code} />
                    <CourseContent currentCourse={currentCourse} courseId={props.courseId} />
                </Wrapper>
                {
                    currentCourse?.category?.study_field?.id === 1 && <LargeCtaBlock category={currentCourse?.category?.study_field?.code} />
                }
            </CourseWrapper>

    );
}

export default Course;