import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

//npm imports
import styled, { withTheme } from 'styled-components';
import { useLocation } from '@reach/router';

//component imports
import { GlobalContext } from '../../Context/GlobalContext';
import { Text13 } from "../Text/Text";
import { API } from '../../Consts/Api';
import Wrapper from '../Wrapper/Wrapper';


//styled-components
const VerificationMessageWrapper = styled.div`
    width: 100%;
    padding: 10px;
    background-color: ${props => props.theme.incorrect};
    display: ${props => !props.showMessage ? "none" : !props.verified ? "flex" : "none"};
    // visibility: ${props => props.showMessage ? "visible" : "hidden"};
    
    .pointer-image{
        width: 27px;
        margin-right: 8px;
    }
    &>div{

        align-items: flex-start;
    }
    z-index: 999999;
    p{
        display: flex;
        align-items: center;
        margin-right: 15px;
        color: ${props => props.theme.white};
        button{
        background: none;
            border: none;
            padding: 0;
            margin-left: 7px;
            p{
                text-decoration: underline;
                color: ${props => props.resent === 1 ? props.theme.white : props.theme.pink};
            }
            &:hover{
                cursor: ${props => props.resent !== 1 ? "pointer" : "initial"};
            }
        }
    }
    @media (max-width: 500px){
        p, p> button> p{
            width: 100%;
            text-align: center;
        }
    }
`

//component
const VerificationMessage = (props) => {
    const { t } = useTranslation();
    const { signedUser } = useContext(GlobalContext);
    const [verified, setVerified] = useState(true);
    const [resent, setResent] = useState(0);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (signedUser.verified) {
            setVerified(true);
        } else {
            setVerified(false);
        }
    }, [signedUser.verified, verified]);

    useEffect(() => {
        //as 'except' prop we pass the routes where we don't need the Component as an array (like /login, /regiser etc.)
        props.except && setIsMessageVisible(!props.except.some(exceptRoute => (new RegExp(`^${exceptRoute}/?$`)).test(location.pathname)))
    }, [location.pathname, props.except, setIsMessageVisible]);

    const resendVerificationLink = () => {
        if (process.env.REACT_APP_STATUS === "prelaunch") {

            API.axios.get(API.createApiRoute("prelaunchResendEmailVerificationLink").replace("{STUDENT}", signedUser.student))
                .then(() => setResent(1))
                .catch(() => setResent(2))
        } else {
            API.axios.get(API.createApiRoute("resendEmailVerificationLink"))
                .then(() => setResent(1))
                .catch(() => setResent(2))
        }
    }
    return (
        <VerificationMessageWrapper verified={verified} resent={resent} showMessage={isMessageVisible}>
            <Wrapper>
                <Text13>{props.children}<button onClick={() => resent !== 1 && resendVerificationLink()}><Text13>{resent === 1 ? `${t("verification.resent")}` : resent === 2 ? `${t("verification.resent_failed")}` : `${t("verification.resend")}`}</Text13></button></Text13>
            </Wrapper>
        </VerificationMessageWrapper >

    );
}

export default withTheme(VerificationMessage);