import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';

//the variable to store the interval
//we can't store it in state, because that way we can't clear it on unmount
//since when unmouting first the state is cleared and then the unmount function is called
//so if we try to clear it on unmount the sesson is null so the interval is null
var interval = null

//component
const Timer = (props) => {
    const { t } = useTranslation();
    //the secconds that will be displayed counting down
    const [seconds, setSeconds] = useState();

    //keeping this in state in order to know where the countdown is running or not
    //and to show the appropriate button (either Pause or Continue)
    const [countdown, setCountdown] = useState(null);

    //setSeconds based on the passed prop seconds where the component is invoked
    useEffect(() => {
        setSeconds(props.seconds)
        startTimer()

        return () => pauseTimer()
    }, [props.seconds])

    //when countdown expires (seconds == 0), check if there is a timeoutAction passed as prop
    //if there is invoke it. Also stop the timer so it doesn't count to -infinity
    useEffect(() => {
        if (seconds === 0) {
            pauseTimer();
            if (typeof props.timeoutAction == "function") {
                props.timeoutAction();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds, props.timeoutAction])

    const pauseTimer = () => {
        clearInterval(interval)
        //set countdown to null to toggle play/pause icons
        setCountdown(null)
    }

    const startTimer = () => {
        interval = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);

        //set countdown to true to toggle play/pause icons
        setCountdown(true);
    }

    return (
        <span className="timer">
            {/* display seconds as plain text where ever called */}
            <span className="seconds">{seconds}</span>
            <span className="line">|</span>
            {
                countdown !== null
                    ? <Icon icon={"icnPause"} />
                    : <Icon icon="icnPlay" />
            }
            {
                countdown == null
                    ? <span onClick={startTimer}> {`${t('general.continue').charAt(0).toUpperCase()}${t('general.continue').slice(1)}`}</span>
                    : <span onClick={pauseTimer}> {`${t('general.pause').charAt(0).toUpperCase()}${t('general.pause').slice(1)}`}</span>
            }
        </span>
    );
}

export default Timer;