import React, { useState, useEffect, useRef, useContext } from "react";

// npm imports
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useTranslation } from "react-i18next";

// component imports
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import SearchBar from "../../Components/SearchBar/SearchBar";
import CatalogueCourses from "./Blocks/CatalogueCourses";
import SliderBlock from "../../Components/SliderBlock/SliderBlock";
import CtaBlock from "../../Components/CtaBlock/CtaBlock";
import {
  SpecHeadingSmall,
  Text20,
  Caption12,
} from "../../Components/Text/Text";
import {
  CtaBlockCommunity,
  CtaBlockQuiz,
  StyledSoftwareEngineeringCta,
} from "../../Components/CtaBlocks/CtaBlocks";

// other imports
import { API } from "../../Consts/Api";
import { GlobalContext } from "../../Context/GlobalContext";
import ClippedRectanglePurple from "../../Static/illustrations/CtaBlockCommunity/ClippedRectanglePurple.svg";
import ScribbledLeaf from "../../Static/illustrations/CtaBlockCommunity/ScribbledLeaf.svg";
import YellowSlice from "../../Static/illustrations/CtaBlockCommunity/YellowSlice.svg";
import CtaBlockQuizIllustration from "../../Static/illustrations/CtaBlockQuiz/CtaBlockQuiz.png";
import {
  blackCircle,
  silverLeaf,
  brackets,
  quarterCircleYellow,
} from "../../Static/illustrations/CtaBlockSoftwareEngineering/CtaBlockSoftwareEngineering";
import Loader from "../../Components/Loader/Loader";
import CourseHeading from "../Course/Blocks/CourseHeading/CourseHeading";
import NewestContent from "../Homepage/Blocks/NewestContent/NewestContent";
import Skeleton from "../../Components/Skeleton/Skeleton";

const Spacer = styled.div`
  width: 100%;
  padding-top: ${(props) =>
    props.isScrolledSearchBar
      ? "280px"
      : props.isAlignedToTopSearchBar
      ? "280px"
      : "0px"};
  .meet-our-community-block {
    margin-bottom: 80px;
  }
  .mb-60 {
    margin-bottom: 60px;
  }
  @media (max-width: 500px) {
    padding-top: ${(props) =>
      props.isScrolledSearchBar
        ? "220px"
        : props.isAlignedToTopSearchBar
        ? "220px"
        : "0px"};
    .meet-our-community-block {
      margin-bottom: 40px;
    }
  }
`;

//component
const Catalogue = () => {
  const { t } = useTranslation();

  const { appConfig, signedUser } = useContext(GlobalContext);

  const isDesktop = useMediaQuery({ minWidth: 1141 });
  const isLaptop = useMediaQuery({ minWidth: 950, maxWidth: 1141 });
  const isSmallLaptop = useMediaQuery({ minWidth: 769, maxWidth: 950 });
  const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 500 });

  const [carouselCourseGridColumns, setCarouselCourseGridColumns] =
    useState("1fr 1fr 1fr 1fr");
  const [carouselLearningPathGridColumns, setCarouselLearningPathGridColumns] =
    useState("1fr 1fr");
  const [carouselCourseCardsPerSlide, setCarouselCourseCardsPerSlide] =
    useState(2);
  const [
    carouselLearningPathCardsPerSlide,
    setCarouselLearningPathCardsPerSlide,
  ] = useState(2);

  useEffect(() => {
    let carouselCourseGridColumns = isDesktop
      ? "1fr 1fr 1fr 1fr"
      : isLaptop
      ? "1fr 1fr 1fr"
      : isMobile && "1fr";
    let carouselLearningPathGridColumns = isDesktop
      ? "1fr 1fr"
      : (isLaptop || isTablet || isMobile) && "1fr";
    let carouselCourseCardsPerSlide = isDesktop
      ? 4
      : isLaptop
      ? 3
      : isTablet
      ? 2
      : isMobile && 1;
    let carouselLearningPathCardsPerSlide = isDesktop
      ? 4
      : isLaptop || isTablet
      ? 2
      : isMobile && 1;
    setCarouselCourseGridColumns(carouselCourseGridColumns);
    setCarouselLearningPathGridColumns(carouselLearningPathGridColumns);
    setCarouselCourseCardsPerSlide(carouselCourseCardsPerSlide);
    setCarouselLearningPathCardsPerSlide(carouselLearningPathCardsPerSlide);
  }, [isDesktop, isLaptop, isTablet, isMobile]);

  const [filteredStudyFields, setFilteredStudyFields] = useState([]);

  const [popularLearningPaths, setPopularLearningPaths] = useState([]);

  const [popularCourses, setPopularCourses] = useState([]);

  const [filterValue, setFilterValue] = useState("all");

  const [courses, setCourses] = useState([]);
  const [learningPaths, setLearningPaths] = useState([]);
  const [featuredLearningPaths, setFeaturedLearningPaths] = useState([]);

  const computerScienceRef = useRef();
  const dataScienceRef = useRef();
  const graphicDesignRef = useRef();
  const digitalMarketingRef = useRef();
  const refs = [

    computerScienceRef,
    dataScienceRef,
    digitalMarketingRef,
    graphicDesignRef,
  ];

  const searchBarRef = useRef();
  const [isScrolledSearchBar, setIsScrolledSearchBar] = useState(false);
  const [isAlignedToTopSearchBar, setIsAlignedToTopSearchBar] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [category, setCategory] = useState("");

  useEffect(() => {
    API.axios
      .get(API.createApiRoute("featuredLearningPaths"))
      .then((response) => {
        setFeaturedLearningPaths(response?.data?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  //get all Learning Paths and Courses from database on component mount and set them inside courses, studyFields, filteredCourses and filteredStudyFields
  useEffect(() => {
    API.axios
      .get(API.createApiRoute("getStudyFieldsWithCourses"))
      .then((response) => {
        let learningPathsAll = [];
        let coursesAll = [];
        response.data.data.forEach((studyField) => {
          studyField.categories.forEach((category) => {
            if (category.courses) {
              category.courses.forEach((course) => {
                coursesAll.push(course);
              });
            }
            if (category.learning_paths) {
              category.learning_paths.forEach((learning_path, i) => {
                learningPathsAll.push(learning_path);
              });
            }
          });
        });
        setFilteredStudyFields(response.data.data);
        setLearningPaths(learningPathsAll);
        setCourses(coursesAll);
      })
      .catch((error) => console.error(error));

    API.axios
      .get(API.createApiRoute("popularLearningPaths"))
      .then((response) => setPopularLearningPaths(response.data.data))
      .catch((error) => console.log(error));
    API.axios
      .get(API.createApiRoute("popularCourses"))
      .then((response) => setPopularCourses(response.data.data))
      .catch((error) => console.error(error));
  }, []);

  //listen on scroll position change of SearchBar component and adequate hooks for SearchBar transitions
  useScrollPosition(
    ({ currPos }) => {
      const offset = currPos.y;
      if (offset <= 10 && !isAlignedToTopSearchBar) {
        setIsAlignedToTopSearchBar(true);
      } else {
        setIsAlignedToTopSearchBar(false);
      }
      if (isMobile) {
        if (offset <= -90 && !isScrolledSearchBar) {
          setIsScrolledSearchBar(true);
          setDropDown(false);
        } else {
          setIsScrolledSearchBar(false);
        }
      } else {
        if (offset <= -100 && !isScrolledSearchBar) {
          setIsScrolledSearchBar(true);
          setDropDown(false);
        } else {
          setIsScrolledSearchBar(false);
        }
      }
    },
    [],
    searchBarRef,
    false,
    0
  );

  //ScrollSpy for tracking scroll on computer-science block with learning paths and courses
  useScrollPosition(
    ({ currPos }) => {
      const offset = currPos.y;
      if (offset <= 100 && category !== "computer-science") {
        setCategory("computer-science");
      }
      if (offset > 100) {
        setCategory("");
      }
    },
    [],
    refs[0],
    false,
    0
  );
  //ScrollSpy for tracking scroll on data-science block with learning paths and courses
  useScrollPosition(
    ({ currPos }) => {
      const offset = currPos.y;
      if (offset <= 100 && category !== "data-science") {
        setCategory("data-science");
      }
    },
    [],
    refs[1],
    false,
    0
  );

  //ScrollSpy for tracking scroll on digital-marketing block with learning paths and courses
  useScrollPosition(
    ({ currPos }) => {
      const offset = currPos.y;
      if (offset <= 100 && category !== "digital-marketing") {
        setCategory("digital-marketing");
      }
    },
    [],
    refs[2],
    false,
    0
  );

  //ScrollSpy for tracking scroll on graphic-design block with learning paths and courses
  useScrollPosition(
    ({ currPos }) => {
      const offset = currPos.y;
      if (offset <= 100 && category !== "graphic-design") {
        setCategory("graphic-design");
      }
    },
    [],
    refs[3],
    false,
    0
  );

  //function for scrolling referenced element into view, used in search bar on study field links
  const scrollToStudyField = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  //function for setting filterValue in order to filter study fields, courses or see them all
  const filterStudyFields = (filterValue) => {
    setFilterValue(filterValue);
  };

  return signedUser.guid ? (
    <PageWrapper>
      {featuredLearningPaths.length > 0 ? (
        <NewestContent data={featuredLearningPaths} />
      ) : (
        <CtaBlockQuiz>
          <CtaBlock href={API.externalLinks("quiz")}>
            <div className="children-container">
              <div className="heading-container">
                <SpecHeadingSmall>
                  {t("catalogue.quiz_cta.not_sure_where_to_begin")}
                </SpecHeadingSmall>
                <Text20>
                  {t("catalogue.quiz_cta.take_our_personality_quiz")}
                </Text20>
              </div>
              <img
                src={CtaBlockQuizIllustration}
                alt="illustration"
                className="quiz-illustration"
              />
            </div>
          </CtaBlock>
        </CtaBlockQuiz>
      )}

      {appConfig.learning_paths === "1" && (
        <SliderBlock
          cardData={popularLearningPaths}
          isMobile={isMobile}
          renderCard="LearningPathCard"
          route="learning-path"
          cardsPerSlide={carouselLearningPathCardsPerSlide}
          navigation={false}
          heading={t("catalogue.popular_learning_paths.heading")}
          gridTemplateColumns={"1fr 1fr"}
        />
      )}
      {/* ---------------- Block below is deactivated temporary due to Math in Python launching -------------------*/}
      {/* <SliderBlock
        style={{ width: "90%", margin: "0 auto" }}
        cardData={popularCourses}
        isMobile={isMobile}
        cardsPerSlide={carouselCourseCardsPerSlide}
        navigation={false}
        renderCard="CourseCard"
        route="courses"
        heading={t("catalogue.popular_courses.heading")}
        gridTemplateColumns={carouselCourseGridColumns}
      /> */}
      <SearchBar
        courses={courses}
        learningPaths={learningPaths}
        coursesLength={courses.length}
        ref={searchBarRef}
        isScrolledSearchBar={isScrolledSearchBar}
        isAlignedToTopSearchBar={isAlignedToTopSearchBar}
        scrollToStudyField={(ref) => scrollToStudyField(ref)}
        refs={refs}
        category={category}
        filterStudyFields={(filterValue) => filterStudyFields(filterValue)}
        dropDown={dropDown}
        setDropDown={setDropDown}
        setIsScrolledSearchBar={() => setIsScrolledSearchBar()}
        isMobile={isMobile}
        isLaptop={isLaptop}
      />
      <Spacer
        isScrolledSearchBar={isScrolledSearchBar}
        isAlignedToTopSearchBar={isAlignedToTopSearchBar}
      />
      {filteredStudyFields.length
        ? filteredStudyFields.map((studyField, i) => {
            return (
              <React.Fragment key={i}>
                <CatalogueCourses
                  ref={refs[i]}
                  index={i}
                  categories={studyField.categories}
                  blockId={studyField.name.replace(/ /g, "")}
                  filterValue={filterValue}
                  isMobile={isMobile}
                  isLaptop={isLaptop}
                  carouselLearningPathCardsPerSlide={
                    carouselLearningPathCardsPerSlide
                  }
                  carouselLearningPathGridColumns={
                    carouselLearningPathGridColumns
                  }
                  carouselCourseCardsPerSlide={carouselCourseCardsPerSlide}
                  carouselCourseGridColumns={carouselCourseGridColumns}
                  isSmallLaptop={isSmallLaptop}
                />
                {i === 0 && (
                  <StyledSoftwareEngineeringCta>
                    <CtaBlock href="https://next.edu.mk">
                      <div className="children-container">
                        <div className="heading-container">
                          <Caption12>
                            {t(
                              "catalogue.software_engineering_cta.pre_heading"
                            )}
                          </Caption12>
                          <SpecHeadingSmall>
                            {t("catalogue.software_engineering_cta.heading")}
                          </SpecHeadingSmall>
                        </div>
                        <div className="software-engineering-illustration">
                          {brackets}
                          {silverLeaf}
                          {blackCircle}
                          {quarterCircleYellow}
                        </div>
                      </div>
                    </CtaBlock>
                  </StyledSoftwareEngineeringCta>
                )}
              </React.Fragment>
            );
          })
        : Array(4)
            .fill()
            .map((studyField, index) =>
              index % 2 === 0 ? (
                <SliderBlock
                  key={index}
                  cardData={[]}
                  cardsPerSlide={isLaptop ? 4 : 5}
                  renderCard="CourseCard"
                />
              ) : (
                <SliderBlock
                  cardData={[]}
                  cardsPerSlide={2}
                  key={index}
                  renderCard="LearningPathCard"
                />
              )
            )}
      {/* ------------------------- This block is temporary commented ---------------------- */}
      {/* <CtaBlockCommunity>
        <CtaBlock href={API.externalLinks("brainsterNextFacebookGroup")}>
          <div className="children-container">
            <div className="heading-container">
              <SpecHeadingSmall>
                {t("catalogue.community_cta.introduce_yourself")}
              </SpecHeadingSmall>
            </div>
            <div className="community-illustration">
              <img
                src={ClippedRectanglePurple}
                alt="purple clipped rectangle"
                className="purple-clipped-rectangle"
              />
              <div className="black-circle"></div>
              <img
                src={ScribbledLeaf}
                alt="scribbled leaf"
                className="scribbled-leaf"
              />
              <img
                src={YellowSlice}
                alt="yellow slice"
                className="yellow-slice"
              />
            </div>
          </div>
        </CtaBlock>
      </CtaBlockCommunity> */}

      {featuredLearningPaths.length > 0 ? (
        <CtaBlockQuiz bottom>
          <CtaBlock href={API.externalLinks("quiz")}>
            <div className="children-container">
              <div className="heading-container">
                <SpecHeadingSmall>
                  {t("catalogue.quiz_cta.not_sure_where_to_begin")}
                </SpecHeadingSmall>
                <Text20>
                  {t("catalogue.quiz_cta.take_our_personality_quiz")}
                </Text20>
              </div>
              <img
                src={CtaBlockQuizIllustration}
                alt="illustration"
                className="quiz-illustration"
              />
            </div>
          </CtaBlock>
        </CtaBlockQuiz>
      ) : (
        <CtaBlockCommunity>
          <CtaBlock href={API.externalLinks("brainsterNextFacebookGroup")}>
            <div className="children-container">
              <div className="heading-container">
                <SpecHeadingSmall>
                  {t("catalogue.community_cta.introduce_yourself")}
                </SpecHeadingSmall>
              </div>
              <div className="community-illustration">
                <img
                  src={ClippedRectanglePurple}
                  alt="purple clipped rectangle"
                  className="purple-clipped-rectangle"
                />
                <div className="black-circle"></div>
                <img
                  src={ScribbledLeaf}
                  alt="scribbled leaf"
                  className="scribbled-leaf"
                />
                <img
                  src={YellowSlice}
                  alt="yellow slice"
                  className="yellow-slice"
                />
              </div>
            </div>
          </CtaBlock>
        </CtaBlockCommunity>
      )}
    </PageWrapper>
  ) : (
    <Loader />
  );
};
export default Catalogue;
