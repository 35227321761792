import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

//imports
import styled from 'styled-components';
import SidebarItem from '../../../../Components/SidebarItem/SidebarItem';
import Skeleton from '../../../../Components/Skeleton/Skeleton';
import { GlobalContext } from '../../../../Context/GlobalContext';
import { NavigationItems } from '../../NavigationItems/NavigationItems';
import UnfinishedCourses from './Blocks/UnfinishedCourses';
import UnfinishedLearningPaths from './Blocks/UnfinishedLearningPaths';
import WatchHistory from './Blocks/WatchHistory';

//styled-components
const MyCoursesNavigationContainer = styled.div`
    width: 100%;
    display: flex;
    background-image: linear-gradient(to right, ${props => props.theme.danger_select} , ${props => props.theme.danger_select}, white, white);
    &>div{
        width: 85%;
        max-width: 1141px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        @media (max-width: 500px){
            width: 100%;
        }
    }
`

const MyCoursesSidebarContainer = styled.div`
    background-color: ${props => props.theme.danger_select};
    width: 15%;
    @media (max-width: 500px){
        width: 40px;
    }
`

const MyCoursesSideBar = styled.div`
    width: 100%;
    padding-top: 50px;
    .skeleton-container{
        height: 40px;
        margin: ${props => props.theme.spacer * 8}px 0px;
    }
`

const MyCoursesContent = styled.div`
    width: 90%;
    max-height: 718px;
    overflow: hidden;
    background-color: white;
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
      }
      .hide-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    .empty-state-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding-bottom: 60px;
        &>img{
            width: 153px;
            height: 153px;
            margin-bottom: 32px;
        }
        h3 , .empty-state-message{
            text-align: center;
            margin-bottom: 16px;
            color: ${props => props.theme.gray5};
        }
        button{
            width: max-content;
            background-color: white;
            border: 1px solid ${props => props.theme.blue};
            border-radius: 28px;
            padding: 17px 41px;
            outline: none;
            box-shadow: none;
            color: ${props => props.theme.gray6};
            font-size: 16px;
            line-height: 16px;
        }
        button:hover{
            cursor: pointer;
        }
        @media (max-width: 500px){
            padding: 0;
        }
    }
    .courses-state-info{
        width: 100%;
        height: max-content; 
        display: flex;
        justify-content: flex-end;
        padding-bottom: 30px;
        padding-top: 30px;
        position: absolute;
        background-color: white;
        z-index: 9999;
        overflow: unset;
        svg{
            margin-right: 4px;
        }
        p{
            display: flex;
            align-items: center;
        }
        span{
            color: ${props => props.theme.blue};
        }
        .current-info{
            padding-right: 11px;
            border-right: 1px solid ${props => props.theme.gray3}
        }
        .completed-info{
            padding-left: 11px;
        }

        .current-info, .completed-info{
            display: flex;
            align-items: center;
        }
        @media (max-width: 500px){
            top: 0px;
            right: 0px;
            padding-right: 23px;
            p{
                font-size: 10px;
                line-height: 12px;
            }
            .current-info{
                padding-right: 8px;
            }
            .completed-info{
                padding-left: 8px;
            }
        }
    }
    @media (max-width: 500px){
        width: 90%;
        max-height: 585px;
    }
`

//component
const MyCoursesNavigation = (props) => {
    const { t } = useTranslation();
    const { appConfig } = useContext(GlobalContext);
    //keep the index of the active sidebar item in this hook needed to display transition of the active sidebar item
    const [activeSidebarItem, setActiveSidebarItem] = useState();
    useEffect(() => {
        if (appConfig.learning_paths) {
            appConfig.learning_paths === "1" ? setActiveSidebarItem(0) : setActiveSidebarItem(1)
        }
    }, [appConfig.learning_paths])
    return (
        <MyCoursesNavigationContainer>
            <div>

                <MyCoursesSidebarContainer>
                    <MyCoursesSideBar>
                        {appConfig.learning_paths
                            ? NavigationItems.map((item, index) => {
                                if (appConfig.learning_paths === "1") {
                                    return (
                                        <SidebarItem
                                            key={index}
                                            text={t(item.text)}
                                            icon={item.icon}
                                            width={item.width}
                                            height={item.height}
                                            active={activeSidebarItem === index}
                                            argument_1={index}
                                            index={index}
                                            onClick={setActiveSidebarItem}
                                        />
                                    )
                                } else {
                                    if (item.name !== "Learning Paths") {
                                        return (
                                            <SidebarItem
                                                key={index}
                                                text={t(item.text)}
                                                icon={item.icon}
                                                width={item.width}
                                                height={item.height}
                                                active={activeSidebarItem === index}
                                                argument_1={index}
                                                index={index}
                                                onClick={setActiveSidebarItem}
                                            />
                                        )
                                    } else {
                                        return <></>
                                    }
                                }
                            })
                            : Array(3).fill().map((sidebar_item, index) => {
                                return (
                                    <div className="skeleton-container" key={index}>
                                        <Skeleton />
                                    </div>
                                )
                            })
                        }
                    </MyCoursesSideBar>
                </MyCoursesSidebarContainer>
                <MyCoursesContent>
                    {appConfig.learning_paths && <UnfinishedLearningPaths unfinishedLearningPaths={props.unfinishedLearningPaths} completed={props.finishedCourses.length} spread={activeSidebarItem === 0} />}
                    <UnfinishedCourses unfinishedCourses={props.unfinishedCourses} isMobile={props.isMobile} spread={activeSidebarItem === 1} completed={props.finishedCourses.length} />
                    <WatchHistory finished={props.finishedCourses} spread={activeSidebarItem === 2 ? true : false} />
                </MyCoursesContent>
            </div>
        </MyCoursesNavigationContainer>
    );
}

export default MyCoursesNavigation;