import React from 'react';

//imports
import styled from 'styled-components';
import { Link } from '@reach/router';
import Icon from '../Icon/Icon';
import { CardCaption15 } from '../Text/Text';

//styled-components
const BackLinkContainer = styled(Link)`
    margin-top: 25px;
    display: flex;
    align-items: center;
    &>p{
        margin-left: 9px;
        color: ${props => props.theme.gray3};
    }
    &>svg{
        margin-top: 1px;    
        &>path{
            stroke: ${props => props.theme.gray3};
        }
    }
    &:hover {
        &>p{
            color: ${props => props.theme.blue};
        }
        &>svg {
            &>path{
                stroke: ${props => props.theme.blue};
            }
        }
    }
    @media (max-height: 820px){
            margin-top: ${props => props.theme.spacer * 3}px;
    }
`

//component
const BackLink = (props) => {
    return (
        <BackLinkContainer className={props.className} arrow={props.arrow} to={props.url} fill={props.fill}>
            <Icon className="left" icon="icnArrowLeft" pathStrokeWidth={4} pathStroke={props.fill} width={5} height={9} />
            <CardCaption15>{props.text}</CardCaption15>
        </BackLinkContainer>
    );
}

export default BackLink;