import React from 'react';

//npm imports
import styled from 'styled-components';

//component imports
import ButtonBasic from '../ButtonBasic/ButtonBasic';
import { SpecHeading, Text16 } from '../Text/Text';

//other imports
import x from '../../Static/prelaunch-prizes/X.svg';
import { useTranslation } from 'react-i18next';
import BlueButton from '../BlueButton/BlueButton';
import Confetto from '../Confetto/Confetto';

//styled-components
const PrelaunchPopupWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    .dimmed-background{
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.black};
        opacity: 0.9;
    }
    .popup-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
        width: 60%;
        max-width: 830px;
        padding: 85px 0px 60px 0px;
        border-radius: 64px;
        background-color: ${props => props.theme.gray0};
        display: flex;
        justify-content: space-between;
        .prize{
            width: 35%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                margin-left: -20%;
                width: 130%;
            }
        } 
        .popup-content-container{
            width: 60%;
            margin-left: 15px;
            .popup-x-container{
                &:hover{
                    cursor: pointer;
                }
                img{
                    position: absolute;
                    top: 40px;
                    right: 50px;
                
                }
            }
            h1{
                font-size: 84px;
                margin-bottom: 5px;
                color: ${props => props.theme.blue};
            }
            p{
                width: 78%;
                margin-bottom: 63px;
            }
            .buttons-container{
                width: 85%;
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                margin-left: -5px;
                button{
                    width: 49%;
                    font-size:14px;
                    padding: 19px 5px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    @media (max-width: 1141px){
        .popup-container{
            padding: 50px 0px;
            min-width: unset;
            .popup-content-container{
                .popup-x-container{
                    img{
                        top: 30px;
                        right: 50px;
                    
                    }
                }
                h1{
                    font-size: 80px;
                }
                p{
                    width: 80%;
                    margin-bottom: 30px;
                }
            }
            .popup-content-container{
                .buttons-container{
                    flex-direction: column;
                    button{
                        width: 100%;
                        align-self: flex-start;
                        margin: 10px 0;
                        span{
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 900px){
        .popup-container{
            flex-direction: column;
            padding: 50px 30px;
            .prize{
                width: 100%;
                margin-top: -30%;
                transform: rotate(${props => props.verified_referrals >= 100 ? -78 : 0}deg);
                img{
                    width: 40%;
                    margin-left: 0;
                }
            }
            .popup-content-container{
                width: 100%;
                margin: 0;
                align-self: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                h1{
                    font-size: 60px;
                }
            }
        }
    }
    @media (max-width: 500px){
        .popup-container{
            width: 90%;
            padding: 58px 0;
            .popup-content-container{
                margin: 0 auto;
                img{
                    display: none;
                }
                h1{
                    font-size: 50px;
                    line-height: 62px;
                }
                p{
                    text-align: center;
                }
                    button{
                        width: 49%;
                    }
            }
        }
    }
`

//component
const PrelaunchPopup = ({ greet, description, onClick, checkpoint, prizePhoto, verified_referrals, onShareJoy }) => {
    const { t } = useTranslation();


    return (
        <PrelaunchPopupWrapper verified_referrals={verified_referrals}>
            <div className="popup-container">
                <div className="prize">
                    <img src={prizePhoto()} alt="prize" />
                </div>
                <div className="popup-content-container">
                    <div className="popup-x-container" onClick={() => onClick(checkpoint)}>
                        <img src={x} alt="close" className="x" />
                    </div>
                    <SpecHeading>{greet}</SpecHeading>
                    <Text16>{description}</Text16>
                    <div className="buttons-container">
                        <ButtonBasic className="invite-more" onClick={() => onClick(checkpoint)} text={t("prelaunch.invite_more")} />
                        <BlueButton className="share-joy" text={t("prelaunch.share_the_news")} onClick={onShareJoy} />

                    </div>
                </div>
            </div>
            <div className="dimmed-background">
                {Array(60).fill().map((confetti, index) => {
                    return (
                        <Confetto key={index} index={index} />
                    )
                })}
            </div>

        </PrelaunchPopupWrapper>

    );
}

export default PrelaunchPopup;