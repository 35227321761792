import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import styled, { withTheme } from "styled-components";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";
import InputField from "../../Components/InputField/InputField";
import InputFieldError from "../../Components/InputField/InputFieldError";
import { logo } from "../../Static/logo";
import {
  SpecHeading2,
  Heading20,
  ButtonText16,
  Text16,
} from "../../Components/Text/Text";
import { Link } from "@reach/router";
import { Text13 } from "../../Components/Text/Text";
import { API } from "../../Consts/Api";
import { navigate } from "@reach/router";
import { GlobalContext } from "../../Context/GlobalContext";
import { useTranslation } from "react-i18next";
import PublicFacingPageWrapper from "../../Components/PublicFacingPageWrapper/PublicFacingPageWrapper";
import { illustration } from "../../Static/illustrations/PublicFacing/signinRegisterIllustration/signinRegisterIllustration";
import { illustrationMobile } from "../../Static/illustrations/PublicFacing/signinRegisterIllustration/signin_register_illustration_mobile";

import "../../Static/illustrations/PublicFacing/signinRegisterIllustration/signinRegisterIllustration.css";
import { redirectSignedUserToCatalogue } from "../../Functions/redirectSignedUserToCatalogue";
import { useMediaQuery } from "react-responsive";

//styled-components
const SignInHeadLine = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%) !important;
  padding-top: 10vh;
  .illustration-container {
    display: block;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 8%;
    position: static;
    transform: translate(0, 0);
    height: unset;
  }
  ${Text16} {
    width: 50%;
    color: ${(props) => props.theme.yellow};
    margin-top: -40px;
  }
  ${SpecHeading2} {
    @media (max-width: 1031px) {
      font-size: 52px;
      line-height: 60px;
    }

    @media (max-width: 910px) {
      font-size: 48px;
      line-height: 52px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media (max-width: 500px), (max-height: 500px) {
      font-size: 26px;
      line-height: 30px;
    }
  }
  &&&&&& {
    @media (min-width: 424px) and (max-width: 768px) {
      padding: 34px 0 0 0;
      height: 11vh;
      position: static;
      transform: translate(0px, 0px) !important;
      .logo-container {
        margin-top: 50px;
      }
      div.illustration-container svg {
        display: block;
      }
      ${Text16} {
        width: 80%;
      }
    }
  }

  &&&&&& {
    @media (max-width: 424px) {
      padding: 0;
      height: 11vh;
      position: static;
      transform: translate(0px, 0px) !important;
      div.illustration-container svg {
        display: block;
        width: 90%;
      }
    }
  }
  /* &&&&&& {
    @media (max-width: 768px) {
      position: static;
      padding: 0;
      height: 11vh;
      transform: translate(0px, 0px) !important;
      .logo-container {
        margin-top: 50px;
      }
      .illustration-container svg {
        display: block;
        width: 90%;
      }
      ${Text16} {
        width: 80%;
      }
    }
  } */
`;

const SignInForm = styled.form`
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  background-color: white;
  .form-container {
    width: 60%;
    min-width: 423px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .form-header-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      & > h5 {
        margin-bottom: ${(props) => props.theme.spacer * 10}px;
        text-align: center;
      }
    }
    .or-sign-in-via {
      color: ${(props) => props.theme.gray3};
      margin-bottom: ${(props) => props.theme.spacer * 10}px;
    }
    .new-user {
      p {
        strong {
          color: ${(props) => props.theme.blue};
        }
      }
    }
    .new-user.desktop {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    padding: 0 3%;
    .form-container {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    z-index: 1;
    margin-top: 180px;
    width: 100%;
    height: unset;
    min-height: max-content !important;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 8%;
    padding-top: 77px;
    align-self: flex-start !important;
    .form-container {
      width: 100%;
      min-width: unset;
      .form-header-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > h5 {
          align-self: flex-start;
          text-align: left;
          margin-bottom: 0;
          margin-top: 0px;
        }
        ${Heading20} {
          font-size: 20px;
          font-family: "Raleway", sans-serif;
          font-style: normal;
          font-weight: 800;
          line-height: 23px;
        }
        .new-user {
          display: none;
        }
      }
      .or-sign-in-via {
        margin-bottom: 24px;
      }
      .new-user.desktop {
        display: block;
        margin-bottom: 50px;
      }
    }
  }
`;
const InputContainer = styled.div`
  width: 100%;
  margin-top: 25px;

  @media (max-width: 500px) {
    &.first-input {
      margin-top: 70px;
    }
    margin-top: 15px;
  }
`;

const RegistrationFormFooter = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.spacer * 2}px;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  a {
    display: flex;
    align-items: center;
    &:hover {
      p {
        color: ${(props) => props.theme.blue};
      }
    }
  }
  p {
    color: ${(props) => props.theme.b};
    display: inline-block;
    strong {
      color: ${(props) => props.theme.blue};
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: ${(props) => props.theme.spacer * 4}px 0;
    margin-bottom: ${(props) => props.theme.spacer * 10}px;
  }
  .register-button {
    width: 200px;
    margin-top: 26px;
    background-color: ${(props) => props.theme.gray5};
    color: ${(props) => props.theme.black};
    padding: 14px 0;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 500px) {
    button,
    a {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    button {
      padding: 14px 0;
      margin-bottom: 60px;
    }
  }
`;

// const SocialMediaContainer = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: ${props => props.theme.spacer * 25}px;
//     &>div{
//         width: 48%;
//     }
//     @media (max-width: 500px){
//         margin-bottom: 0px;
//         &> div:last-child{
//             svg{
//                 path{
//                     stroke: ${props => props.theme.black};
//                     stroke-width: 1px;
//                 }
//             }
//         }
//     }
//     @media (max-height: 770px){
//         margin-bottom: ${props => props.theme.spacer * 15}px;
//     }
// `

// const socialMediaRegistrators = [
//     {
//         id: "facebook",
//         text: "Facebook",
//         icon: "icnFacebook",
//         link: "https://www.facebook.com/sharer/sharer.php?u={URL}&hashtag=%23brainster"
//     },
//     {
//         id: "google",
//         text: "Google",
//         icon: "icnGoogle",
//         link: "https://www.facebook.com/sharer/sharer.php?u={URL}&hashtag=%23brainster"
//     },
// ]

//component
const SignIn = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 500 });

  const resetErrors = { email: [], password: [] };
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { signedUser, setSignedUser } = useContext(GlobalContext);
  const [errors, setErrors] = useState(resetErrors);

  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    redirectSignedUserToCatalogue(signedUser, setSignedUser);
  }, [signedUser, setSignedUser]);

  const loginStudent = useCallback(
    (e) => {
      e.preventDefault();
      API.axios.get(API.createApiRoute("csrf")).then((response) => {
        API.axios
          .post(API.createApiRoute("studentLogin"), user)
          .then((response) => {
            if (response.status === 200) {
              //remove inputted data for security reasons
              setUser({});
              //store signed user data in global context
              localStorage.setItem("user", JSON.stringify(response.data));
              setSignedUser(response.data);
              navigate(API.createRoute("catalogue"));
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              setErrors({ ...resetErrors, ...error.response.data.errors });
            } else {
              setErrors({
                ...errors,
                additional: "An error occured. Please try again later.",
              });
            }
          });
      });
    },
    [errors, resetErrors, setSignedUser, user]
  );

  const submitOnEnter = useCallback(
    (e) => {
      //it triggers by pressing the enter key
      if (e.keyCode === 13) {
        loginStudent(e);
      }
    },
    [loginStudent]
  );

  useEffect(() => {
    window.addEventListener("keydown", submitOnEnter);

    return () => {
      window.removeEventListener("keydown", submitOnEnter);
    };
  }, [user, submitOnEnter]);

  return (
    <PublicFacingPageWrapper backgroundColor={props.theme.black}>
      <SignInHeadLine className="headline-container">
   
        <div className="illustration-container">
          {isMobile ? illustrationMobile : illustration}
        </div>
      </SignInHeadLine>
      <SignInForm>
        <div className="form-container">
          <div className="form-header-container">
            <Heading20 className="form-headline-text">
              {t("sign_in.sign_in")}
            </Heading20>
            <Link to={API.createRoute("register")} className="new-user">
              <ButtonText16>
                {`${t("sign_in.new_user")}? `}
                <strong>{t("register.register")}</strong>
              </ButtonText16>
            </Link>
          </div>
          <InputFieldError error={errors.additional || false} />
          <InputContainer className="first-input">
            <InputField
              name="email"
              ref={emailRef}
              defaultValue={user.email}
              placeholder={t("general.email_placeholder")}
              inputLabel={t("general.email")}
              type="text"
              autocomplete="username"
              required={true}
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
                setErrors({ ...errors, email: [] });
              }}
            />
            <InputFieldError error={errors.email[0]} />
          </InputContainer>
          <InputContainer>
            <InputField
              ref={passwordRef}
              name="password"
              defaultValue={user.password}
              placeholder={t("general.password_placeholder")}
              inputLabel={t("general.password")}
              type="password"
              autocomplete="current-password"
              required={true}
              onChange={(e) => {
                setUser({ ...user, password: e.target.value });
                setErrors({ ...errors, password: [] });
              }}
            />
            <InputFieldError error={errors.password[0]} />
          </InputContainer>
          <RegistrationFormFooter>
            {/* <Checkbox text={t("general.remember_me")} checked={rememberMeChecked} onClick={() => setRememberMeChecked(!rememberMeChecked)} /> */}
            <Link to="/student/recover">
              <Text13>
                {`${t("sign_in.forgot_password")}?`}{" "}
                <strong>{t("general.click_here")}</strong>
              </Text13>
            </Link>
          </RegistrationFormFooter>
          <ButtonContainer>
            <ButtonBasic
              text={t("general.sign_in")}
              variation="formBtn"
              type="button"
              onClick={(e) => loginStudent(e)}
            />
          </ButtonContainer>

          <Link to={API.createRoute("register")} className="new-user desktop">
            <ButtonText16>
              {`${t("sign_in.new_user")}? `}
              <strong>{t("register.register")}</strong>
            </ButtonText16>
          </Link>
          {/* <Caption14 className="or-sign-in-via">{t("sign_in.or_sign_in_via")}</Caption14>
                    <SocialMediaContainer>
                        {socialMediaRegistrators.map((media, index) => (
                            <SocialMediaShareButton key={index} className={`${media.id}-button`} text={media.text} icon={media.icon} />
                        ))}
                    </SocialMediaContainer> */}
        </div>
      </SignInForm>
    </PublicFacingPageWrapper>
  );
};

export default withTheme(SignIn);
