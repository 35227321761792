import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//npm imports
import styled, { withTheme } from 'styled-components';
import { GlobalContext } from '../../Context/GlobalContext';

//component imports
import step_1_bw from '../../Static/prelaunch-prizes/step-1-bw.png';
import step_1_colored from '../../Static/prelaunch-prizes/step-1-colored.png';
import step_2_bw from '../../Static/prelaunch-prizes/step-2-bw.png';
import step_2_colored from '../../Static/prelaunch-prizes/step-2-colored.png';
import step_3_bw from '../../Static/prelaunch-prizes/step-3-bw.png';
import step_3_colored from '../../Static/prelaunch-prizes/step-3-colored.png';
import step_4_bw from '../../Static/prelaunch-prizes/step-4-bw.png';
import step_4_colored from '../../Static/prelaunch-prizes/step-4-colored.png';
import Icon from '../Icon/Icon';
import { Text10, Text13, CardCaption11 } from '../Text/Text';

//styled-components
const PrelaunchProgressWrapper = styled.div`
width: 100%;
padding: 138px 0 172px 0;
position: relative;
.progress-container{
    width: 90%;
    height: 20px;
    background-color: ${props => props.theme.gray7};
    border-radius: 10px;
    position: relative;
    display: flex;
    z-index: 99;
    .progress{
        width: ${props => props.progress}%;
        transition: width 1.5s ${props => props.theme.expo} .6s;
        height: 100%;
        border-radius: 10px;
        background: linear-gradient(to right, ${props => props.theme.dark_blue} 0% , ${props => props.theme.green} 100%);
    }
    .indicator{
        width: 30px;
        height: 20px;
        margin-left: -15px;
        background-color: ${props => props.theme.yellow};
        border-radius: 10px;
        display: ${props => props.progress < 25.1 || props.progress > 99 ? "none" : "flex"};
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 9999;
        p{
            color: ${props => props.theme.white};
            .referal-info-sign{
                width: 7px;
                height: 7px;
                background-color: black;
                border-radius: 50%;
                position: absolute;
                top: 0px;
                right: 0px;
                font-size: 5px;
                line-height: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .referal-info-popover{
            position: absolute;
            left: -50%;
            bottom: 30px;
            background-color: ${props => props.theme.darkBlack};
            padding: 18px 15px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            z-index: 9999;
            align-items: center;
            display: none;
                p{
                    min-width: 140px;
                    display: flex;
                    justify-content: space-between;
                    color: ${props => props.theme.white};
                    line-height: 20px;
                }
                .blue{
                    color: ${props => props.theme.blue};
                }
                .yellow{
                    color: ${props => props.theme.yellow};
                }
        }
        &:hover{
            cursor: pointer;
            .referal-info-popover{
                display: flex;
            }
        }
    }
    .step{
        width: 20px;
        height: 20px;
        position: absolute;
        z-index: 999;
        margin-left: -20px;
        .step-indicator{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid ${props => props.theme.blue};
            background-color: ${props => props.theme.gray7};
            display: flex;
            justify-content: center;
            align-items: center;
            .prize{
                width: 78px;
                height: 78px;
                position: absolute;
                bottom: 40px;
                z-index: 99;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                background-color: ${props => props.theme.gray3};
                border-radius: 50%;
                .hide{
                    display: none;
                }
                .prize-1-image{
                    width: 80%;
                    margin: 20px auto 0 auto;
                }
                .prize-2-image{
                    width: 110%;
                    margin: 10px auto 0 auto;
                }
                .prize-3-image{
                    width: 80%;
                    margin: 25px auto 0 auto;
                }
                .prize-4-image{
                    width: 70%;
                    margin-top: 45px;
                }
            }
            .prize-explanation{
                position: absolute;
                top: 35px;
                z-index: 99;
                p{
                    min-width: max-content;
                    text-align: center;
                    color: ${props => props.theme.white};
                }

                .active {
                    color: ${props => props.theme.dark_blue};
                }

                .dim {
                    opacity: 0.3;
                }

                .mobile-step{
                    display: none;
                }

                .prize-description{
                    font-weight: bold;
                }

                .prize-explanation-border{
                    width: 100px;
                    height: 1px;
                    background-color: ${props => props.theme.darkBlack};
                    margin: 9px auto;
                }

                .active-border{
                    background-color: ${props => props.theme.lightBlack};
                    opacity: 0.4;
                }

                .prize-description{
                    margin-bottom: 43px;
                }
            }
        }
        .step-1-indicator{
            background-color: ${props => props.verified && props.theme.blue};
        }
        .step-2-indicator{
            background-color: ${props => props.verified && props.verified_referrals > 9 && props.theme.blue};
        }
        .step-3-indicator{
            background-color: ${props => props.verified && props.verified_referrals > 29 && props.theme.blue};
        }
        .step-4-indicator{
            background-color: ${props => props.verified && props.verified_referrals > 99 && props.theme.blue};
        }
    }
    .step-1-container{
        top: 0px;
        left: 25%;
    }
    .step-2-container{
        top: 0px;
        left: 50%;
    }
    .step-3-container{
        top: 0px;
        left: 75%;
    }
    .step-4-container{
        top: 0px;
        left: 100%;
    }
}
.background-indicators-container{
    position: absolute;
    top: 0;
    left: 10%;
    width: 90%;
    height: 100%;
    z-index: 9;
    display: flex;
    .background-indicator{
        width: 25%;
        height: 100%;
        border-radius: 33px;
    }
    .active{
        background: linear-gradient(180deg, rgba(16, 8, 36, 0) 0%, #100824 49.27%, rgba(16, 8, 36, 0) 100%);
    }
}
@media (max-width: 1141px){
    width: 100%;
}
@media (max-width: 500px){
    padding: 0;
    .progress-container{
        width: 20px;
        height: 821px;
        margin: 0 auto;
        flex-direction: column;
        .progress{
            width: 20px;
            height: ${props => props.progress}%;
            transition: height .6s ${props => props.theme.expo} .4s;
            background: linear-gradient(to bottom, ${props => props.theme.dark_blue} 0% , ${props => props.theme.green} 100%);
        }
        .indicator{
            margin-left: -5px;
            margin-top: -10px;
        }
        .step {
            left: 20px;
            margin-top: -20px;
            .step-indicator{
                .prize{
                    bottom: -30px;
                    left: -102px;
                }
                .prize-explanation{
                    top: -324%;
                    left: 26px;
                    margin-left: 26px;
                    p{
                        min-width: 110px;
                        text-align: left;
                    }
                    .prize-explanation-border{
                        margin: 9px 0;
                    }
                    .mobile-step{
                        display: block;
                        margin-bottom: 26px;
                    }

                    .desktop-step{
                        display: none;
                    }
                }
            }
        }
        .step-1-container{
            top: 25%;
        }
        .step-2-container{
            top: 50%;
        }
        .step-3-container{
            top: 75%;
        }
        .step-4-container{
            top: 100%;
        }
    }
    .background-indicators-container{
        left: 0;
        bottom: -12.5%;
        top: unset;
        flex-direction: column;
        .background-indicator{
            flex-direction: column;
            width: 100%;
            height: 25%;
        }
        .active{
            background: linear-gradient(to right, rgba(16, 8, 36, 0) 0%, #100824 49.27%, rgba(16, 8, 36, 0) 100%);
        }
    }
}
`

const getPercent = (referrals) => {
    let number;
    let starting_offset = 25;
    let weight1 = 2.60;
    let weight2 = 1.29;
    let weight3 = 0.08;
    if (referrals <= 10) {
        number = Math.floor(starting_offset + referrals * weight1);
    } else if (referrals <= 30) {
        number = Math.floor(starting_offset + (10 * weight1) + ((referrals - 10) * weight2));
    } else if (referrals < 80) {
        number = starting_offset + (10 * weight1) + (20 * weight2) + ((referrals - 30) * weight3);
        number = number.toFixed(2);
    } else if (referrals > 100) {
        number = 100
    } else {
        number = referrals;
    }

    return number;
}

//component
const PrelaunchProgress = (props) => {
    const { t } = useTranslation();
    const { signedUser } = useContext(GlobalContext);
    const [progress, setProgress] = useState(0);



    useEffect(() => {
        let progress = getPercent(signedUser.verified_referrals)
        setProgress(progress);
    }, [signedUser.verified_referrals]);

    return (
        <PrelaunchProgressWrapper progress={progress ? progress : 0} verified_referrals={signedUser.verified_referrals} verified={signedUser.verified}>
            <div className="progress-container">
                <div className="progress">
                </div>
                <div className="indicator">
                    <Text13>
                        {signedUser.verified_referrals}
                        <span className="referal-info-sign">i</span>
                    </Text13>
                    <div className="referal-info-popover">
                        <Text10>{t("prelaunch.refer_a_friend.all_referrals")} <span className="blue">{signedUser.all_referrals}</span> </Text10>
                        <Text10>{t("prelaunch.refer_a_friend.verified_referrals")} <span className="yellow">{signedUser.verified_referrals}</span> </Text10>
                    </div>
                </div>
                <div className="step-1-container step">
                    <div className="step-1-indicator step-indicator">
                        <div className="prize">
                            <img className={signedUser.verified ? "hide prize-1-image" : "show prize-1-image"} src={step_1_bw} alt="step 1 prize" />
                            <img className={signedUser.verified ? "show prize-1-image" : "hide prize-1-image"} src={step_1_colored} alt="step 1 prize" />
                        </div>
                        <Icon icon="icnChecked" width={13} pathFill={props.theme.gray7} />
                        <div className="prize-explanation">
                            <CardCaption11 className="dim mobile-step">{t("prelaunch.prizes.prize1.step_number")}</CardCaption11>
                            <Text13 className="dim">{t(`prelaunch.prizes.prize1.instruction`)}</Text13>
                            <div className={signedUser.verified_referrals === 0 ? "prize-explanation-border active-border" : "prize-explanation-border"}></div>
                            <Text13 className="dim prize-description">{t("prelaunch.prizes.prize1.description")}</Text13>
                            <CardCaption11 className="dim desktop-step">{t("prelaunch.prizes.prize1.step_number")}</CardCaption11>
                        </div>
                    </div>
                </div>
                <div className="step-2-container step">
                    <div className="step-2-indicator step-indicator">
                        <div className="prize">

                            <img className={!signedUser.verified ? "show prize-2-image" : signedUser.verified_referrals < 10 ? "show prize-2-image" : "hide prize-2-image"} src={step_2_bw} alt="step 2 prize" />
                            <img className={!signedUser.verified ? "hide prize-2-image" : signedUser.verified_referrals >= 10 ? "show prize-2-image" : "hide prize-2-image"} src={step_2_colored} alt="step 2 prize" />
                        </div>
                        {signedUser.verified_referrals > 9 && <Icon icon="icnChecked" width={13} pathFill={props.theme.gray7} />}
                        <div className="prize-explanation">
                            <CardCaption11 className={signedUser.verified_referrals > 9 ? "dim mobile-step" : signedUser.verified_referrals >= 0 && "active mobile-step"}>{t("prelaunch.prizes.prize2.step_number")}</CardCaption11>
                            <Text13 className={signedUser.verified_referrals > 9 && "dim"}>{t(`prelaunch.prizes.prize2.instruction`)}</Text13>
                            <div className={signedUser.verified_referrals < 10 ? "prize-explanation-border active-border" : "prize-explanation-border"}></div>
                            <Text13 className={signedUser.verified_referrals > 9 ? "dim prize-description" : "prize-description"}>{t("prelaunch.prizes.prize2.description")}</Text13>
                            <CardCaption11 className={signedUser.verified_referrals > 9 ? "dim desktop-step" : signedUser.verified_referrals >= 0 && "active desktop-step"}>{t("prelaunch.prizes.prize2.step_number")}</CardCaption11>
                        </div>
                    </div>
                </div>
                <div className="step-3-container step">
                    <div className="step-3-indicator step-indicator">
                        <div className="prize">
                            <img className={!signedUser.verified ? "show prize-3-image" : signedUser.verified_referrals < 30 ? "show prize-3-image" : "hide prize-3-image"} src={step_3_bw} alt="step 3 prize" />
                            <img className={!signedUser.verified ? "hide prize-3-image" : signedUser.verified_referrals >= 30 ? " show prize-3-image" : "hide prize-3-image"} src={step_3_colored} alt="step 3 prize" />
                        </div>
                        {signedUser.verified_referrals > 29 && <Icon icon="icnChecked" width={13} pathFill={props.theme.gray7} />}
                        <div className="prize-explanation">
                            <CardCaption11 className={signedUser.verified_referrals > 29 ? "dim mobile-step" : signedUser.verified_referrals > 9 ? "active mobile-step" : "neutral mobile-step"}>{t("prelaunch.prizes.prize3.step_number")}</CardCaption11>
                            <Text13 className={signedUser.verified_referrals > 29 && "dim"}>{t(`prelaunch.prizes.prize3.instruction`)}</Text13>
                            <div className={signedUser.verified_referrals > 9 && signedUser.verified_referrals < 30 ? "prize-explanation-border active-border" : "prize-explanation-border"}></div>
                            <Text13 className={signedUser.verified_referrals > 29 ? "dim prize-description" : "prize-description"}>{t("prelaunch.prizes.prize3.description")}</Text13>
                            <CardCaption11 className={signedUser.verified_referrals > 29 ? "dim desktop-step" : signedUser.verified_referrals > 9 ? "active desktop-step" : "neutral desktop-step"}>{t("prelaunch.prizes.prize3.step_number")}</CardCaption11>
                        </div>
                    </div>
                </div>
                <div className="step-4-container step">
                    <div className="step-4-indicator step-indicator">
                        <div className="prize">
                            <img className={!signedUser.verified ? "show prize-4-image" : signedUser.verified_referrals < 100 ? "show prize-4-image" : "hide prize-4-image"} src={step_4_bw} alt="step 4 prize" />
                            <img className={!signedUser.verified ? "hide prize-4-image" : signedUser.verified_referrals >= 100 ? "show prize-4-image" : "hide prize-4-image"} src={step_4_colored} alt="step 4 prize" />
                        </div>
                        {signedUser.verified_referrals > 99 && <Icon icon="icnChecked" width={13} pathFill={props.theme.gray7} />}
                        <div className="prize-explanation">
                            <CardCaption11 className={signedUser.verified_referrals > 99 ? "dim mobile-step" : signedUser.verified_referrals > 29 ? "active mobile-step" : "neutral mobile-step"}>{t("prelaunch.prizes.prize4.step_number")}</CardCaption11>
                            <Text13 className={signedUser.verified_referrals > 99 && "dim"}>{t(`prelaunch.prizes.prize4.instruction`)}</Text13>
                            <div className={signedUser.verified_referrals > 29 && signedUser.verified_referrals < 100 ? "prize-explanation-border active-border" : "prize-explanation-border"}></div>
                            <Text13 className={signedUser.verified_referrals > 90 ? "dim prize-description" : "prize-description"}>{t("prelaunch.prizes.prize4.description")}</Text13>
                            <CardCaption11 className={signedUser.verified_referrals > 99 ? "dim desktop-step" : signedUser.verified_referrals > 29 ? "active desktop-step" : "neutral desktop-step"}>{t("prelaunch.prizes.prize4.step_number")}</CardCaption11>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-indicators-container">
                <div className="background-indicator"></div>
                <div className={signedUser.verified_referrals < 10 ? "active background-indicator" : "background-indicator"}></div>
                <div className={signedUser.verified_referrals >= 10 && signedUser.verified_referrals < 30 ? "active background-indicator" : "background-indicator"}></div>
                <div className={signedUser.verified_referrals >= 30 && signedUser.verified_referrals < 99 ? "active background-indicator" : "background-indicator"}></div>
            </div>
        </PrelaunchProgressWrapper >

    );
}

export default withTheme(PrelaunchProgress);