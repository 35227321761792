const ratingIcons = [

    {
        text: "HELPFUL",
        icon: "icnHelpful",
        color: "#86A799",
    },
    {
        text: "INTERESTING",
        icon: "icnInteresting",
        color: "#879BE3"
    },
    {
        text: "BRILIANT",
        icon: "icnBriliant",
        color: "#F5CAD8"
    },
    {
        text: "CONFUSING",
        icon: "icnConfusing",
        color: "#F4E099"

    }
];

export default ratingIcons;