import React, { useRef } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components';

//component imports
import Icon from '../Icon/Icon';
import { ButtonText16 } from '../Text/Text';
import theme from '../../Consts/Theme';

//styled-components
const CopyLinkInputWrapper = styled.div`
    border-radius: 28px;
    margin-top: 34px;
    background-color: ${props => props.theme.danger_select};
    display: flex;
    justify-content: space-between;
    align-items: center;
    input{
        width: 100%;
        min-width: 300px;
        background-color: transparent;
        border: none;
        margin-left: 14px;
        color: ${props => props.theme.gray6};
        font-weight: 600;
        padding-left: 24px;
        &:focus{
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
    .button-container{
        display: flex;
        background-color: ${props => props.theme.gray4};
        padding: 15px 29px 15px 22px;
        border-top-right-radius: 28px;
        border-bottom-right-radius: 28px;
        margin-right: -3px;
        &:hover{
            cursor: pointer;
            background-color: ${props => props.theme.black};
            svg{
                path{
                    fill: ${props => props.theme.white};
                }
            }
            button{
                p{
                    color: ${props => props.theme.white};
                }
            }
        }
        button{
            border: none;
            background-color: transparent;
            p{
                text-transform: none;
                color: ${props => props.theme.black};
                font-weight: 600;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    @media (max-width: 960px){
        input{
            min-width: unset;
            width: 100%;
        }
        .button-container{
            svg{
                display: none;
            }
        }
    }
    @media (max-width: 500px){
        margin-top: 0px;
        input{
            min-width: 40%;
        }
        .button-container{
            padding: 17px 10px;
            button{
                width: 80px;
                padding: 0;
                background-color: transparent;
            }
        }
    }
`

//component
const CopyLinkInput = ({ defaultValue, readOnly, buttonText }) => {
    const linkInputRef = useRef();

    const copyLinkToClipboard = (e) => {
        linkInputRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    }
    return (
        <CopyLinkInputWrapper>
            <input ref={linkInputRef} type="text" defaultValue={defaultValue} readOnly={readOnly} />
            <div className="button-container">
                <Icon icon="icnLink" pathFill={theme.black} />
                <button onClick={e => copyLinkToClipboard(e)}><ButtonText16>{buttonText}</ButtonText16></button>
            </div>
        </CopyLinkInputWrapper>

    );
}

export default withTheme(CopyLinkInput);