import React from 'react';

//imports
import styled from 'styled-components';

//styled-components
const BlueButtonContainer = styled.button`
    width: max-content;
    background-color: white;
    border: 1px solid ${props=> props.theme.blue};
    border-radius: 28px;
    padding: 17px 35px;
    outline: none;
    box-shadow: none;
    color: ${props=> props.theme.gray6}
`

//component
const BlueButton = (props) => {
    return ( 
        <BlueButtonContainer onClick={props.onClick}>
            {props.text}
        </BlueButtonContainer>
     );
}
 
export default BlueButton;