import React from 'react';

export const icnStarFolderGradient =
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.3335 3.49935H15.0002L13.0027 1.50185C12.4643 0.963516 11.7418 0.666016 10.991 0.666016H3.66683C2.1085 0.666016 0.833496 1.94102 0.833496 3.49935V20.4993C0.833496 22.0577 2.1085 23.3327 3.66683 23.3327H26.3335C27.8918 23.3327 29.1668 22.0577 29.1668 20.4993V6.33268C29.1668 4.77435 27.8918 3.49935 26.3335 3.49935ZM21.9985 18.2468L19.2502 16.646L16.5018 18.2468C15.9635 18.5585 15.3118 18.0768 15.4535 17.4677L16.176 14.351L13.7818 12.2827C13.3143 11.8718 13.5552 11.0927 14.1785 11.036L17.3377 10.7668L18.5843 7.84851C18.8252 7.28185 19.6468 7.28185 19.8877 7.84851L21.1343 10.7668L24.2935 11.036C24.9168 11.0927 25.1718 11.8718 24.6902 12.2827L22.296 14.351L23.0185 17.4677C23.1743 18.0768 22.5227 18.5585 21.9985 18.2468Z" fill="url(#paint0_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="15.0002" y1="0.666016" x2="15.0002" y2="23.3327" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F9F9F9" />
                <stop offset="1" stopColor="#F9F9F9" stopOpacity="0.4" />
            </linearGradient>
        </defs>
    </svg>